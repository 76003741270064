import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button} from "@pg-design/button";
import {FormikForm} from "@pg-design/formik-utils";
import {mb, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";
import {Form, Formik} from "formik";
import i18n from "i18next";

import {useAppDispatch, useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {handleFormikSubmit} from "../../../components/form/handle_submit";
import {SubmitFormikButton} from "../../../components/SubmitFormikButton";
import {selectAuthFormsSharedData, setAuthFormsSharedData} from "../../auth/auth_slice";
import {useConfirmNewPasswordMutation} from "../api/resetPasswordApi";

interface ISetNewPasswordFormProps {
    uid: string;
    token: string;
}

export const SetNewPasswordForm = (props: ISetNewPasswordFormProps) => {
    const {uid, token} = props;
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const authFormsSharedData = useAppSelector(selectAuthFormsSharedData);
    const [confirmNewPassword, confirmNewPasswordMutation] = useConfirmNewPasswordMutation();

    if (confirmNewPasswordMutation.isSuccess) {
        return (
            <>
                <Text as="p" variant="headline_3" align="center" css={mb(2)}>
                    {t("user.set_new_password_form.password_was_changed")}
                </Text>
                <Link to={chartsRoutes[i18n.language].root}>
                    <Button variant="filled_primary" css={w100}>
                        {t("nav.home")}
                    </Button>
                </Link>
            </>
        );
    }

    return (
        <>
            <Text css={mb(3)} variant="headline_3" as="p" align="center">
                {t("user.set_new_password_form.set_new_password")}
            </Text>

            <Formik
                initialValues={{
                    password: ""
                }}
                onSubmit={async (values, helpers) =>
                    handleFormikSubmit(
                        confirmNewPassword({
                            token,
                            uid,
                            password: values.password
                        }).unwrap(),
                        helpers
                    )
                }
            >
                {({handleBlur, isSubmitting, values}) => (
                    <Form>
                        <FormikForm.Input
                            css={mb(3)}
                            onBlur={(e) => {
                                handleBlur(e);
                                dispatch(
                                    setAuthFormsSharedData({
                                        ...authFormsSharedData,
                                        password: values.password
                                    })
                                );
                            }}
                            name="password"
                            placeholder={t("user.set_new_password_form.your_new_password")}
                        />

                        <SubmitFormikButton
                            css={w100}
                            copy={t("user.set_new_password_form.next")}
                            disabled={isSubmitting}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};
