import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {Button} from "@pg-design/button";
import {flexJustifyCenter, mt, textAlign, w100} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {Text} from "@pg-design/text";

import {getClientCookies} from "../../../client/get_client_cookies";
import {castToFetchBaseQueryError} from "../../../store/cast_to_fetch_base_query_error";
import {useSendVerificationLinkMutation} from "../api/sendVerificationLinkApi";

const VALID_ACTIVATION_HASH = "#verification-successful";
const INVALID_ACTIVATION_HASH = "#verification-failed";

const VerificationPositive = ({onCloseClick}: {onCloseClick: () => void}) => {
    return (
        <>
            <Modal.Header css={textAlign("center")}>Twoje konto jest już aktywne</Modal.Header>
            <Modal.Content css={flexJustifyCenter}>
                <Text variant="body_copy_2">Możesz już zacząć korzystać ze swojego konta</Text>
                <Button css={[mt(2), w100]} variant="filled_primary" onClick={onCloseClick}>
                    Zamknij
                </Button>
            </Modal.Content>
        </>
    );
};

interface ILinkExpiredProps {
    onAfterSend: () => void;
}

const LinkExpired = (props: ILinkExpiredProps) => {
    const {onAfterSend} = props;
    const [sendVerificationLink, _] = useSendVerificationLinkMutation();
    const {t} = useTranslation();

    const onSendClick = async () => {
        const {csrfToken} = getClientCookies();
        try {
            await sendVerificationLink().unwrap();
            toast.success(() => t("common.chart_lock.confirm_email_toast"));
            onAfterSend();
        } catch (e: unknown) {
            const error = castToFetchBaseQueryError<{detail: string}>(e);

            if (error && error.status === 403 && error.data.detail === "Nie podano danych uwierzytelniających.") {
                toast.error(() => t("landing.unauthenticated.title"));
            } else {
                toast.error(() => t("auth.errors.generic"));
            }
        }
    };

    return (
        <>
            <Modal.Header css={textAlign("center")}>Odnośnik potwierdzający wygasł</Modal.Header>
            <Modal.Content css={flexJustifyCenter}>
                <Text variant="body_copy_2">Sprawdź ponownie swój adres email</Text>
                <Button css={[mt(2), w100]} variant="filled_primary" onClick={onSendClick}>
                    Wyślij e-mail ponownie
                </Button>
            </Modal.Content>
        </>
    );
};

export function EmailConfirmationModal() {
    const [isValid, setIsValid] = useState<boolean | null>(null);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash !== VALID_ACTIVATION_HASH && hash !== INVALID_ACTIVATION_HASH) {
            return;
        }
        const isActivationValid = hash === VALID_ACTIVATION_HASH;
        setIsValid(isActivationValid); // opens the modal
    }, []);

    const closeModal = () => {
        window.location.hash = "";
        setIsValid(null);
    };

    return (
        <Modal isOpen={isValid !== null} onModalClose={closeModal}>
            {isValid ? <VerificationPositive onCloseClick={closeModal} /> : <LinkExpired onAfterSend={closeModal} />}
        </Modal>
    );
}
