import React, {useState} from "react";
import {useCookies} from "react-cookie";
import Marquee from "react-fast-marquee";
import {Link, useLocation} from "react-router-dom";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {display, flexAlignCenter, m, p, position} from "@pg-design/helpers-css";
import {BrandRPLogoIcon, CloseIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {isToday} from "date-fns";
import {compile} from "path-to-regexp";

import {chartsRoutes} from "../../common/app/routing/charts_routes";
import {useGetNewsBarEntriesQuery} from "../../features/newshub/api/get_entries";
import {EntryType} from "../../features/newshub/types/Entry";

interface NewsBarItemProps {
    newsItem: {
        title: string;
        id: number;
        slug: string;
        type: EntryType;
    };
}

const NewsBarItem = ({newsItem}: NewsBarItemProps) => {
    const theme = useTheme();
    const route =
        newsItem.type === EntryType.ARTICLE
            ? chartsRoutes.pl.newshub.marketInformation.details
            : chartsRoutes.pl.newshub.bigDataNews.details;
    const compiledPath = compile(route);
    const path = compiledPath({newsId: String(newsItem.id), slug: newsItem.slug});

    return (
        <div css={item}>
            <Link to={path}>
                <Text variant="body_copy_2" color={theme.colors.primary}>
                    {newsItem.title}
                </Text>
            </Link>
            <BrandRPLogoIcon css={m(0, 2.5)} size="1.5" wrapperSize="2.4" wrapperColor={theme.colors.primary} />
        </div>
    );
};

const NEWS_BAR_CLOSE_ISO_DATE_COOKIE_NAME = "news_bar_close_iso_date";

export const NewsBar = () => {
    const theme = useTheme();
    const {pathname} = useLocation();
    const [cookies, setCookie] = useCookies([NEWS_BAR_CLOSE_ISO_DATE_COOKIE_NAME]);
    const {data, isLoading} = useGetNewsBarEntriesQuery();
    const newsList = data?.results ?? [];

    const [isMounted, setIsMounted] = useState(false);
    const [closedTodayByUser, setClosedTodayByUser] = useState(isToday(cookies[NEWS_BAR_CLOSE_ISO_DATE_COOKIE_NAME]));

    const shouldRenderInCurrentPath = pathname.startsWith(chartsRoutes.pl.newshub.marketInformation.root);

    const handleClose = () => {
        setCookie(NEWS_BAR_CLOSE_ISO_DATE_COOKIE_NAME, new Date().toISOString(), {path: "/"});
        setClosedTodayByUser(true);
    };

    const handleMount = () => {
        setIsMounted(true);
    };

    if (!shouldRenderInCurrentPath || isLoading || newsList.length === 0 || closedTodayByUser) {
        return null;
    }

    return (
        <StyledWrapper isMounted={isMounted}>
            <Marquee pauseOnHover onMount={handleMount}>
                {newsList.map((newsItem, index) => (
                    <NewsBarItem key={index} newsItem={newsItem} />
                ))}
            </Marquee>
            <CloseIcon
                size="2.4"
                wrapperSize="3.6"
                wrapperColor={theme.colors.primary}
                css={closeButton}
                onClick={handleClose}
            />
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div<{isMounted: boolean}>`
    ${display("flex")};
    ${flexAlignCenter};
    justify-content: end;
    overflow: hidden;
    ${position("sticky")};
    ${p(2, 0, 2, 3)};
    background-color: ${(props) => props.theme.colors.secondary};
    bottom: 0;
    z-index: 999;
    ${(props) => (props.isMounted ? "opacity: 1;" : "opacity: 0;")};
    transition: opacity 0.5s;
`;

const item = () => css`
    ${display("flex")};
    ${flexAlignCenter};
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const closeButton = () => css`
    ${m(0, 3)};
    cursor: pointer;
`;
