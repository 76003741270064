import {css, Theme} from "@emotion/react";
import {backgroundColor, borderRadius, display, mb, mr, mt, p} from "@pg-design/helpers-css";

import {ZIndex} from "../constants/z_index";

export const highcharts = (theme: Theme) => css`
    .highcharts-menu {
        ${borderRadius(0.5)};
    }

    .highcharts-tooltip-container {
        z-index: ${ZIndex.HIGHCHARTS_TOOLTIP} !important;

        & > .highcharts-root {
            width: 100%;
            height: 100%;
        }
    }

    .highcharts-tooltip {
        & > span {
            ${p(1, 2)};
            ${borderRadius(0.5)};
            box-shadow: var(--box-shadow);
            ${backgroundColor("#fff")}
        }

        &.colored-map-tooltip {
            & > span {
                ${p(0)};
                overflow: hidden;

                .map-colored-tooltip-wrapper {
                    border-left: 1rem solid black;
                    ${p(1)};
                }
            }
        }

        &.map-tooltip {
            & > span {
                ${backgroundColor("transparent")}
                box-shadow: none;
                text-align: center;
                ${p(0)};

                .values {
                    box-shadow: var(--box-shadow);
                    width: max-content;
                    ${mt(1)};
                    ${p(0.5)};
                    ${backgroundColor("#fff")}
                    ${borderRadius(1)};
                }

                .value {
                    min-width: 6rem;
                    color: white;
                    font-weight: 700;
                    margin-bottom: 2px;
                    padding: 2px 5px;

                    &:first-of-type {
                        ${borderRadius(0.5, 0.5, 0, 0)};
                        border-top-width: 4px;
                    }

                    &:last-of-type {
                        ${mb(0)};
                        ${borderRadius(0, 0, 0.5, 0.5)};
                        border-bottom-width: 4px;
                    }
                }
            }
        }

        &.pie-tooltip {
            & > span {
                min-width: 175px;

                strong {
                    ${display("block")};
                    width: 100%;
                }

                small {
                    ${display("block")};
                    width: 100%;
                    color: ${theme.colors.gray[700]};
                    ${mb(1)};
                }
            }
        }

        &.multiline-tooltip,
        &.shared-tooltip {
            & > span {
                .title {
                    ${display("block")};
                    padding: 5px 0;
                }

                .point {
                    padding: 5px 0;

                    &.matchedPoint {
                        font-weight: 600;
                    }
                }

                .dot {
                    ${display("inline-block")};
                    width: 10px;
                    height: 10px;
                    ${backgroundColor("#000")}
                    border-radius: 50%;
                    ${mr(0.5)}
                }
            }
        }
    }
`;
