import {CitySlug} from "./cities";

export enum RegionType {
    CITY = "city",
    AGGLOMERATION = "agglomeration",
    SUBURBS = "suburbs"
}

export const relatedRegions: CitySlug[][] = [
    [CitySlug.WARSZAWA, CitySlug.WARSZAWA_AGGLOMERATION, CitySlug.WARSZAWA_SUBURBS],
    [CitySlug.KRAKOW, CitySlug.KRAKOW_AGGLOMERATION, CitySlug.KRAKOW_SUBURBS],
    [CitySlug.WROCLAW, CitySlug.WROCLAW_AGGLOMERATION, CitySlug.WROCLAW_SUBURBS],
    [CitySlug.POZNAN, CitySlug.POZNAN_AGGLOMERATION, CitySlug.POZNAN_SUBURBS],
    [CitySlug.LODZ, CitySlug.LODZ_AGGLOMERATION, CitySlug.LODZ_SUBURBS]
];

export const mapCitySlugToRegionType = (citySlug: CitySlug | null): RegionType | null => {
    switch (citySlug) {
        case CitySlug.BIALYSTOK:
        case CitySlug.BYDGOSZCZ:
        case CitySlug.KATOWICE:
        case CitySlug.GDANSK:
        case CitySlug.KIELCE:
        case CitySlug.KRAKOW:
        case CitySlug.LODZ:
        case CitySlug.LUBLIN:
        case CitySlug.OLSZTYN:
        // case CitySlug.Opole:
        // eslint-disable-next-line no-fallthrough
        case CitySlug.POZNAN:
        case CitySlug.RZESZOW:
        case CitySlug.SZCZECIN:
        case CitySlug.WARSZAWA:
        case CitySlug.WROCLAW:
        case CitySlug.ZIELONA_GORA:
            return RegionType.CITY;
        case CitySlug.KRAKOW_AGGLOMERATION:
        case CitySlug.LODZ_AGGLOMERATION:
        case CitySlug.POZNAN_AGGLOMERATION:
        case CitySlug.WARSZAWA_AGGLOMERATION:
        case CitySlug.WROCLAW_AGGLOMERATION:
            return RegionType.AGGLOMERATION;
        case CitySlug.KRAKOW_SUBURBS:
        case CitySlug.LODZ_SUBURBS:
        case CitySlug.POZNAN_SUBURBS:
        case CitySlug.WARSZAWA_SUBURBS:
        case CitySlug.WROCLAW_SUBURBS:
            return RegionType.SUBURBS;
        default:
            return null;
    }
};

export const getRelatedCitySlug = (citySlug: CitySlug | null, regionType: RegionType | null): CitySlug | null => {
    if (!citySlug || regionType === null) {
        return null;
    }
    const relatedRegionIndex = relatedRegions.findIndex((region) => region.includes(citySlug));
    if (relatedRegionIndex < 0) {
        return null;
    }
    if (regionType == RegionType.SUBURBS) {
        return relatedRegions[relatedRegionIndex][2];
    }
    if (regionType == RegionType.AGGLOMERATION) {
        return relatedRegions[relatedRegionIndex][1];
    }
    if (regionType == RegionType.CITY) {
        return relatedRegions[relatedRegionIndex][0];
    }
    return null;
};
