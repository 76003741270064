import {css} from "@emotion/react";
import {mb, mt, onDesktop, pt} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IHeadingProps {
    text: string;
}

export const Heading = ({text}: IHeadingProps) => {
    return (
        <Text css={heading} variant="headline_3" as="h1">
            {text}
        </Text>
    );
};

const heading = css`
    ${pt(3)}
    ${mt(0)}
    ${mb(4)}
    
    ${onDesktop(css`
        ${pt(4)}
        ${mb(6)}
    `)}
`;
