import {ChangeEventHandler, MutableRefObject, ReactElement, useEffect, useRef} from "react";
import {css} from "@emotion/react";
import {calculateRemSize, flexAbsoluteCenter, h100, onDesktop} from "@pg-design/helpers-css";
import {Pill} from "@pg-design/pill";
import {Text} from "@pg-design/text";

import {SearchAutocompleteInputCore} from "./SearchAutocompleteInputCore";

interface IAutocompleteInputProps {
    openDropdown: () => void;
    onChange: ChangeEventHandler<HTMLInputElement>;
    closeDropdown: () => void;
    placeholder: string;
    inputIcon?: ReactElement;
    onInputMount?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
    pillLabel?: string | null;
    onRemoveOption: () => void;
}

export const AutoCompleteInput = ({
    openDropdown,
    onChange,
    placeholder,
    inputIcon,
    onInputMount,
    pillLabel,
    onRemoveOption
}: IAutocompleteInputProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        onInputMount && onInputMount(inputRef);
    }, [inputRef]);

    const onWrapperClick = () => {
        inputRef.current?.focus();
        openDropdown();
    };

    return (
        <div onClick={onWrapperClick} css={wrapper}>
            <div css={iconWrapper}>{inputIcon}</div>

            {!!pillLabel && (
                <div css={pillWrapper}>
                    <Pill size="small" onCloseClick={onRemoveOption}>
                        <Text css={pill} variant="info_txt_1" noWrap>
                            {pillLabel}
                        </Text>
                    </Pill>
                </div>
            )}

            <SearchAutocompleteInputCore
                ref={inputRef}
                placeholder={placeholder}
                onChange={onChange}
                hasPill={!!pillLabel}
            />
        </div>
    );
};

const wrapper = css`
    height: ${calculateRemSize(6)};
    position: relative;
    margin-bottom: 2rem;
    z-index: 1001;

    ${onDesktop(css`
        margin-bottom: 0;
    `)};
`;

export const iconWrapper = css`
    position: absolute;
    cursor: text;
    z-index: 1;
    ${h100};
    width: 6.4rem;
    right: 0;
    ${flexAbsoluteCenter};
`;

const pillWrapper = css`
    position: absolute;
    display: none;
    left: 16px;
    top: 10px;

    ${onDesktop(css`
        display: block;
    `)}
`;

const pill = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80px;
`;
