import {css} from "@emotion/react";
import {borderRadius, flex, flexDirection, mb, p, w100} from "@pg-design/helpers-css";
import {Highlight, Text} from "@pg-design/text";

import {flexGap} from "../../../../components/css_helpers/flex";

export const NewsroomEmailCard = () => (
    <div css={wrapper}>
        <Text variant="headline_4" align="center">
            Chcesz, aby Twoja informacja prasowa pojawiła się na naszej stronie?
        </Text>
        <Text variant="body_copy_1" align="center">
            Prześlij ją na adres:
        </Text>
        <Text variant="button_micro" align="center">
            <Highlight>
                <a href="mailto:newsroom@rynekpierwotny.pl">newsroom@&#8203;rynekpierwotny.pl</a>
            </Highlight>
        </Text>
    </div>
);

const wrapper = css`
    ${w100};
    ${mb(4)};
    ${p(2)};
    ${flex("center", "center")};
    ${flexDirection("column")}
    ${flexGap(2)};
    ${borderRadius(2)}
    background-color: #fff;
`;
