import React, {SyntheticEvent, useEffect} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {calculateRemSize, flex, m, mv, onDesktop, pl} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {appendQueryString} from "../../../utils/append_query_string";

interface IProps {
    className?: string;
    onChangePageClick?: (page: number) => void;
    locationPathname?: string;
    list:
        | {
              count: number;
              page_size: number;
          }
        | undefined;
    multiNumbersInside?: boolean;
    currentPage: number;
    setCurrentPage: (value: number) => void;
    onPageChange: (currentPage: number) => void;
}

export const Pagination = ({
    className,
    list,
    multiNumbersInside,
    currentPage,
    setCurrentPage,
    onPageChange
}: IProps) => {
    const pageCount = list ? Math.ceil(list.count / list.page_size) : 1;
    const history = useHistory();
    const {pathname} = useLocation();

    const hrefBuilder = (page: number) => {
        return appendQueryString(pathname, page > 1 ? {page: page} : {});
    };

    useEffect(() => {
        onPageChange(currentPage);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, [currentPage]);

    const renderPrevButton = () => {
        const prevPageNumber = currentPage - 1 || 1;
        const isDisabled = currentPage <= 1;
        const onClick = (e: SyntheticEvent) => {
            setCurrentPage(prevPageNumber);
            e.preventDefault();
        };

        return (
            <PrevNextLinkWrapper isDisabled={isDisabled} css={prev}>
                <Link css={paginationLink} to={hrefBuilder(prevPageNumber)} onClick={onClick} rel="prev">
                    <ChevronLeftIcon size="1.8" />
                </Link>
            </PrevNextLinkWrapper>
        );
    };

    const renderNextButton = () => {
        const nextPageNumber = currentPage == pageCount ? currentPage : currentPage + 1;
        const isDisabled = currentPage >= pageCount;
        const onClick = (e: SyntheticEvent) => {
            setCurrentPage(nextPageNumber);
            e.preventDefault();
        };

        return (
            <PrevNextLinkWrapper isDisabled={isDisabled} css={next}>
                <Link css={paginationLink} to={hrefBuilder(nextPageNumber)} onClick={onClick} rel="next">
                    <ChevronRightIcon size="1.8" />
                </Link>
            </PrevNextLinkWrapper>
        );
    };

    const renderPageButton = (value: number, current: number) => {
        const isCurrentPage = value === current;

        const onClick = (e: SyntheticEvent) => {
            setCurrentPage(value);
            e.preventDefault();
        };

        return (
            <LinkWrapper isCurrentPage={isCurrentPage}>
                <Link css={paginationLink} to={hrefBuilder(value)} onClick={onClick}>
                    <Text as="span" variant="headline_6">
                        {value}
                    </Text>
                </Link>
            </LinkWrapper>
        );
    };

    const renderPageSeparator = () => {
        return (
            <li>
                <Text css={paginationSeparator} as="span" variant="headline_6">
                    ...
                </Text>
            </li>
        );
    };

    const generatePages = (pages: number[], current: number) => {
        const itemsToShow = 3;

        const last = pages.length;
        let bottomRange = current - 4 >= 0 ? current - 4 : 0;

        let topRange = current < 5 ? 5 : current + 1;
        if (topRange + 2 >= last) {
            topRange = topRange + 2;
            bottomRange = last >= 7 ? last - 7 : 0;
        }
        const pagesRange = pages.slice(bottomRange, topRange);

        const pagesArr = [];
        pagesArr.push(renderPageButton(1, current));

        const hasBottomRangeSeparator = last > 7 && current > 4;
        const hasTopRangeSeparator = last > 7 && current + 3 < last;

        if (hasBottomRangeSeparator) {
            pagesArr.push(renderPageSeparator());
            pagesRange.slice(2).forEach((page: number) => {
                pagesArr.push(renderPageButton(page, current));
            });
        } else if (current + itemsToShow - 1 < last) {
            pagesRange.slice(1, topRange).forEach((page: number) => {
                pagesArr.push(renderPageButton(page, current));
            });
        } else {
            pagesRange.slice(1).forEach((page: number) => {
                pagesArr.push(renderPageButton(page, current));
            });
        }
        if (hasTopRangeSeparator) {
            pagesArr.push(renderPageSeparator());
            pagesArr.push(renderPageButton(last, current));
        }
        return pagesArr.map((element: JSX.Element, index: number) => (
            <React.Fragment key={index}>{element}</React.Fragment>
        ));
    };

    const renderPageCount = (pageCount: number) => {
        if (multiNumbersInside) {
            return generatePages(
                Array.from({length: pageCount}, (pageCount, p) => p + 1),
                currentPage
            );
        }
        return `${currentPage} z ${pageCount}`;
    };

    if (pageCount < 2) {
        return null;
    }

    return (
        <nav css={paginationHolder} className={className}>
            {renderPrevButton()}
            <ul css={paginationList}>{renderPageCount(pageCount)}</ul>
            {renderNextButton()}
        </nav>
    );
};

const PAGINATION_LINK_SIZE = calculateRemSize(6);

const paginationHolder = css`
    ${flex("center", "center")};
    gap: ${calculateRemSize(0.5)};
    flex-wrap: wrap;

    ${onDesktop(css`
        flex-wrap: nowrap;
        gap: ${calculateRemSize(1)};
    `)};
`;

const paginationLink = css`
    ${flex("center", "center")};
    border-radius: 50%;
    background-color: #fff;
    width: 4rem;
    height: 4rem;

    ${onDesktop(css`
        width: ${PAGINATION_LINK_SIZE};
        height: ${PAGINATION_LINK_SIZE};
    `)}
`;

const paginationSeparator = css`
    ${flex("center", "center")};
    height: 4rem;

    ${onDesktop(css`
        height: ${PAGINATION_LINK_SIZE};
    `)}
`;

const LinkWrapper = styled.li<{isCurrentPage: boolean}>`
    & > a {
        background-color: ${(props) => (props.isCurrentPage ? props.theme.colors.primary : `#fff`)};
    }
`;

const PrevNextLinkWrapper = styled.div<{isDisabled: boolean}>`
    & > a {
        ${(props) =>
            props.isDisabled &&
            css`
                opacity: 0.5;
                cursor: default;
                pointer-events: none;
            `};
    }
`;

const paginationList = css`
    ${flex("center", "center")};
    gap: ${calculateRemSize(0.5)};
    flex-grow: 1;
    flex-basis: 100%;
    list-style: none;
    ${pl(0)};
    ${m(0)};

    ${onDesktop(css`
        flex-grow: 0;
        flex-basis: auto;
        ${mv(0.5)}
        gap: ${calculateRemSize(1)};
    `)};
`;

const next = css`
    display: none;

    ${onDesktop(css`
        display: flex;
    `)};
`;

const prev = css`
    display: none;

    ${onDesktop(css`
        display: flex;
    `)};
`;
