import {createSlice} from "@reduxjs/toolkit";

import {IRootState} from "../../../store/store";
import {ProvincialCity} from "../api/get_entries";

interface RegionsState {
    regionId?: ProvincialCity;
}

const initialState: RegionsState = {
    regionId: undefined
};

export const regionsSlice = createSlice({
    name: "regions",
    initialState,
    reducers: {
        setRegionId: (state, action) => {
            state.regionId = action.payload;
        },
        resetRegionId: (state) => {
            state.regionId = undefined; // Reset the vendorId state to its initial value
        }
    }
});

export const {setRegionId, resetRegionId} = regionsSlice.actions;

export const selectRegionId = (state: IRootState) => state.regions.regionId;
