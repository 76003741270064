import {useTranslation} from "react-i18next";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {css, keyframes, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {borderRadius, calculateRemSize, flex, onDesktop, p, pt, pv} from "@pg-design/helpers-css";
import {
    AdditionalRoomIcon,
    HomeIcon,
    MapMarkerOutlineIcon,
    MegaphoneIcon,
    PoiShopIcon,
    PriceIcon,
    ProgressIcon
} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {CitySlug, mapParamSlugToCitySlug} from "../../../../config/cities";
import {dataLayer} from "../../../../utils/data_layer";
import {useAppDispatch} from "../../../../utils/hooks/store_hooks";
import {chartsRoutes, ICityParams} from "../../common/app/routing/charts_routes";
import {getMenuItems} from "../../common/app/routing/menu_items";
import {breakpoints} from "../../common/app/styles/breakpoints";
import {OnboardingTrigger} from "../../features/analytical_platform/components/onboarding/OnboardingTrigger";
import {toggleOnboarding} from "../../features/analytical_platform/redux/onboarding_slice";

export const SideMenu = () => {
    const {
        params: {city},
        path
    } = useRouteMatch<ICityParams>();
    const citySlug = city ? mapParamSlugToCitySlug(city) : CitySlug.WARSZAWA;
    const history = useHistory();
    const {pathname} = useLocation();
    const {i18n} = useTranslation();
    const dispatch = useAppDispatch();

    const isLinkActive = (targetPath: string, i: number): boolean => {
        if (getHomepagePaths(i18n.language).includes(path) && i === 0) {
            return true;
        }
        return targetPath === pathname;
    };

    const handleMenuItemClick = (path: string, name: string) => {
        history.push(path);
        // setTimeout to prevent race condition between history.push and window.scrollTo
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);

        dataLayer({
            event: "left_menu_click",
            button: name,
            city
        });
    };

    const sideMenuIconName = (name: string) => {
        switch (name) {
            case "home":
                return <HomeIcon size="2.4" fill="#fff" />;
            case "place":
                return <MapMarkerOutlineIcon size="2.4" fill="#fff" />;
            case "euro":
                return <PriceIcon size="2.4" fill="#fff" />;
            case "home_work":
                return <AdditionalRoomIcon size="2.4" fill="#fff" />;
            case "trending_up":
                return <ProgressIcon size="2.4" fill="#fff" />;
            case "work_outline":
                return <PoiShopIcon size="2.4" fill="#fff" />;
            case "flag":
                return <MegaphoneIcon size="2.4" fill="#fff" />;
            default:
                throw new Error("Invalid SideMenu icon name");
        }
    };

    return (
        <div css={sideMenu}>
            <nav css={sideMenuNav}>
                {getMenuItems(citySlug, pathname, i18n).map(({name, path, secondPath, icon}, i) => (
                    <StyledNavLink
                        onClick={() => handleMenuItemClick(path, name)}
                        isActive={isLinkActive(path, i) || isLinkActive(secondPath, i)}
                        key={i}
                    >
                        {sideMenuIconName(icon)}

                        <Text className="name-label" variant="body_copy_2">
                            {name}
                        </Text>
                    </StyledNavLink>
                ))}
            </nav>

            <OnboardingTrigger css={onboarding} onTriggerClick={() => dispatch(toggleOnboarding(true))} button />
        </div>
    );
};

const getHomepagePaths = (lang: string) => [
    chartsRoutes[lang].properties.root,
    chartsRoutes[lang].flats.root,
    chartsRoutes[lang].houses.root
];

const sideMenu = (theme: Theme) => css`
    display: none;

    @media (min-width: ${breakpoints.md}) {
        min-height: 100vh;
        background-color: ${theme.colors.secondary};
        ${pt(8)};
        display: block;
        position: relative;
        z-index: 99;
    }
`;

const sideMenuNav = css`
    ${onDesktop(css`
        position: sticky;
        top: ${calculateRemSize(8)};
        width: var(--sidebar-width);
    `)};
`;

export const showLabelAnimation = keyframes`
    from {
        left: 50%;
        opacity: 0;
    }
    to {
        left: 85%;
        opacity: 1;
    }
`;

export const labelName = css`
    background: #fff;
    ${p(0.5, 3)};
    ${borderRadius(0.5)};
    box-shadow: var(--box-shadow);
    font-weight: 600;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
`;

const StyledNavLink = styled.div<{isActive?: boolean}>`
    ${pv(3)};
    text-align: center;
    transition:
        opacity 0.2s,
        left 0.2s,
        background 0.2s;
    position: relative;
    cursor: pointer;
    background: ${(props) => (props.isActive ? props.theme.colors.gray[100] : "none")};

    & > svg {
        transition: fill 0.2s;
        fill: ${(props) => (props.isActive ? props.theme.colors.secondary : "#fff")};
    }

    &:hover {
        background: ${(props) => (props.isActive ? "#fff" : props.theme.colors.primary)};

        & > svg {
            fill: ${(props) => props.theme.colors.secondary};
        }

        .name-label {
            animation: ${showLabelAnimation} 0.35s ease 1;
            animation-fill-mode: forwards;
        }
    }

    .name-label {
        display: ${(props) => (props.isActive ? "none" : "block")};
        color: ${(props) => props.theme.colors.secondary};
        ${labelName};
    }
`;

const onboarding = (theme: Theme) => css`
    display: none;

    ${onDesktop(css`
        position: fixed;
        bottom: 0;
        ${pv(3)};
        width: var(--sidebar-width);
        transition:
            opacity 0.2s,
            left 0.2s,
            background 0.2s;
        ${flex("center", "center")};

        & > svg {
            transition: fill 0.2s;
        }

        &:hover {
            background: ${theme.colors.primary};

            & > svg {
                fill: ${theme.colors.secondary};
            }
        }
    `)};
`;
