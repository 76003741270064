import {Suspense} from "react";
import {flexAbsoluteCenter} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";

import {flexGrow} from "../css_helpers/flex";

interface ISuspendedChartProps {
    children: JSX.Element;
}

export const SuspendedChart = ({children}: ISuspendedChartProps) => (
    <Suspense
        fallback={
            <div css={[flexAbsoluteCenter, flexGrow(1)]}>
                <Loader size="lg" />
            </div>
        }
    >
        {children}
    </Suspense>
);
