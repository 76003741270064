import {useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {css, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Col} from "@pg-design/grid";
import {
    backgroundColor,
    flex,
    flexAlignCenter,
    flexDirection,
    mb,
    ml,
    mr,
    mt,
    onDesktop,
    p,
    ph,
    pointer,
    pr,
    textAlign,
    underline
} from "@pg-design/helpers-css";
import {ClockIcon} from "@pg-design/icons";
import {Image} from "@pg-design/image";
import {Highlight, Text} from "@pg-design/text";

import {dataLayer} from "../../../../../../utils/data_layer";
import {getPublicationAccessStatus} from "../../../../../../utils/get_publication_access_status";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {AccessStatus, useAccessStatus} from "../../../../../../utils/hooks/useAccessStatus";
import {flexGrow, flexShrink} from "../../../../components/css_helpers/flex";
import {Divider} from "../../../../components/Divider";
import {selectAuthStatus, setAuthModal} from "../../../auth/auth_slice";
import {selectUserData} from "../../../users/users_slice";
import {useDownloadFreePublicationMutation} from "../../api/download_publication";
import {PublicationPricingType} from "../../types/Publication";
import {DownloadPublicationModal} from "../download_publication_modal/DownloadPublicationModal";
import {FreeDownloadSuccessModal} from "../FreeDownloadSuccessModal";
import {subTitle} from "../landing/Hero";

interface IHeroProps {
    publication: {
        id: number;
        image: string;
        name: string;
        lead: string;
        pricing_type: PublicationPricingType;
        date_of_publication: string;
        price: number;
        link: string | null;
        file: string | null;
        promotional_price: number | null;
    };
}

export const Hero = ({publication}: IHeroProps) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [isDownloadPublicationModalOpen, setIsDownloadPublicationModalOpen] = useState(false);
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
    const userData = useAppSelector(selectUserData);
    const dispatch = useAppDispatch();
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const accessStatus = useAccessStatus();
    const [downloadPublication, {reset}] = useDownloadFreePublicationMutation({
        fixedCacheKey: `orderPublication${publication.id}`
    });
    const {isFreeForBD, isFree, isPaid} = getPublicationAccessStatus(publication.pricing_type);

    // As we are not logged in, or do not have marketing permission we want to open a small modal, otherwise we download
    // immediately on click
    const downloadWithUserComFree = () => {
        if (!isLoggedIn || (userData && !userData.bigdata_marketing_consent)) {
            dataLayer({
                event: "free_publication_form_open",
                publication: publication.name
            });
            setIsDownloadPublicationModalOpen(true);
        }

        if (isLoggedIn && userData && userData.bigdata_marketing_consent) {
            downloadPublication({
                publication: publication.id,
                first_name: userData.first_name,
                last_name: userData.last_name,
                email_form_value: userData.email,
                bigdata_marketing_consent: userData.bigdata_marketing_consent
            })
                .unwrap()
                .catch((err) => err.data.publication && toast.error(err.data.publication[0]));
            reset();
            setSuccessModalOpen(true);
        }
    };

    const downloadWithUserComPaid = () => {
        dataLayer({
            event: "paid_publication_form_open",
            publication: publication.name
        });
        setIsDownloadPublicationModalOpen(true);
    };

    return (
        <Col lg={10} lgOffset={3} md={8} mdOffset={2} sm={4}>
            <div css={[mt(3), mb(9), backgroundColor("#fff")]}>
                <FreeDownloadSuccessModal isOpen={isSuccessModalOpen} setOpen={setSuccessModalOpen} />

                <DownloadPublicationModal
                    isOpen={isDownloadPublicationModalOpen}
                    setOpen={setIsDownloadPublicationModalOpen}
                    id={publication.id}
                    pricing_type={publication.pricing_type}
                    price={publication.price}
                    promotional_price={publication.promotional_price}
                    name={publication.name}
                />

                <Image
                    src={publication.image}
                    alt={publication.name}
                    width="800"
                    height="400"
                    ratio={{xs: "2:1"}}
                    css={imageWrapper}
                />

                <div css={p(3, 3, 0, 3)}>
                    <Text variant="headline_1" css={mb(2)}>
                        <Highlight>{publication.name}</Highlight>
                    </Text>

                    <Text css={[mb(5), flexAlignCenter]} as="p" variant="headline_6" color={theme.colors.gray[700]}>
                        <ClockIcon css={[mr(2), ml(1)]} fill={theme.colors.gray[700]} size="3" />
                        {new Date(publication.date_of_publication).toLocaleString("pl-pl", {
                            month: "long",
                            year: "numeric"
                        })}
                    </Text>

                    <div css={subTitle} dangerouslySetInnerHTML={{__html: publication.lead}} />
                </div>

                <Divider />

                <div
                    css={[
                        p(4, 3),
                        flex("center", `${isFree ? "center" : "space-between"}`),
                        flexDirection("column"),
                        onDesktop(flexDirection("row"))
                    ]}
                >
                    {!isFree && (
                        <div css={[mb(3), onDesktop(pr(4), mb(0))]}>
                            <Text as="p" variant="headline_2" align="center" css={onDesktop(textAlign("left"))}>
                                {publication.pricing_type === PublicationPricingType.FREE_BD_USER
                                    ? "Bezpłatnie*/"
                                    : publication.promotional_price
                                      ? `${publication.promotional_price}*/`
                                      : ""}
                                {publication.price}zł
                            </Text>

                            {(publication.promotional_price || isFreeForBD) &&
                                (isLoggedIn ? (
                                    <Text variant="body_copy_2" align="center" css={onDesktop(textAlign("left"))}>
                                        {accessStatus === AccessStatus.FULL
                                            ? "* Twoja cena posiadasz aktywny dostęp do platformy BIG DATA"
                                            : "* dla klientów RynekPierwotny.pl posiadających aktywne konto na platformie BIG DATA"}
                                    </Text>
                                ) : (
                                    <Text variant="body_copy_2" align="center" css={onDesktop(textAlign("left"))}>
                                        * dla klientów RynekPierwotny.pl posiadających aktywne konto na platformie BIG
                                        DATA - Masz już konto?&nbsp;
                                        <span
                                            css={[underline, pointer]}
                                            onClick={() => dispatch(setAuthModal("login"))}
                                        >
                                            Zaloguj się
                                        </span>
                                        , nie masz jeszcze dostępu?&nbsp;
                                        <span
                                            css={[underline, pointer]}
                                            onClick={() => dispatch(setAuthModal("registerUser"))}
                                        >
                                            Zarejestruj się
                                        </span>
                                    </Text>
                                ))}
                        </div>
                    )}

                    {(isPaid || (isFreeForBD && accessStatus !== AccessStatus.FULL)) && (
                        <Button
                            onClick={() => {
                                downloadWithUserComPaid();
                            }}
                            css={button}
                            variant="filled_primary"
                            size="big"
                        >
                            {t("publications.landing.order_button")}
                        </Button>
                    )}

                    {(isFree || (isFreeForBD && accessStatus === AccessStatus.FULL)) && (
                        <Button
                            onClick={() => {
                                downloadWithUserComFree();
                            }}
                            css={button}
                            variant="filled_primary"
                            size="big"
                        >
                            Pobierz
                        </Button>
                    )}
                </div>
            </div>
        </Col>
    );
};

const imageWrapper = css`
    & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

const button = css`
    ${flexGrow(0)};
    ${flexShrink(0)};
    ${ph(4)};
    min-width: 20rem;
`;
