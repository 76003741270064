import {ReactNode} from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {calculateRemSize, display, mb, mr, onDesktop, p, position, w100, zIndex} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {showOnHoverScroll} from "../../../components/css_helpers/scroll";

interface IExtendableTextBlockProps {
    title?: string;
    contentHeight?: number;
    children: ReactNode | Array<ReactNode>;
}

export const ExtendableTextBlock = ({children, title, contentHeight}: IExtendableTextBlockProps) => {
    const theme = useTheme();
    return (
        <StyledWrapper height={contentHeight}>
            <div css={content}>
                {title && (
                    <Text variant="mini_header" as="h4" css={mb(2)} color={theme.colors.gray[700]}>
                        {title}
                    </Text>
                )}
                {children}
            </div>

            <div css={gradient} />
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div<{height?: number}>`
    width: auto;
    height: ${(props) => (props.height ? props.height + "px" : "100%")};
    ${mr(2)};
    ${showOnHoverScroll("#ebff00", "#ebff00")}
`;

const gradient = css`
    height: ${calculateRemSize(6)};
    ${w100};
    ${position("sticky")};
    bottom: 0;
    background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
    ${zIndex(1)};
    ${display("none")};

    ${onDesktop(css`
        ${display("block")};
    `)};
`;

const content = css`
    ${p(2)};
    ${position("relative")};

    ${onDesktop(css`
        ${p(0, 5, 0, 2)};
    `)};
`;
