import dorota from "../../../features/users/assets/dorota_wachnicka_new.jpg";

export interface IPerson {
    name: string;
    picture: string;
    role: string;
    phone: string;
    email: string;
}

export const salesPerson: IPerson = {
    picture: dorota,
    name: "Dorota Wachnicka",
    role: "common.customer_service_manager",
    phone: "+48 784 930 517",
    email: "d.wachnicka@rynekpierwotny.pl"
};
