import {Trans, useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import {Container} from "@pg-design/grid";
import {flexAbsoluteCenter, flexDirection, onDesktop, p, textAlign} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

import logo from "../../../common/app/assets/dea22_logo.png";

export const AwardShowcase = () => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Container css={container} as="section" fluid>
            <Image src={logo} alt="logo Digital Excellence Awards 2022" width="199" height="99" loading="lazy" />

            <Text
                css={[textAlign("center"), onDesktop(textAlign("start"))]}
                variant="body_copy_2"
                color={theme.colors.gray[800]}
            >
                <Trans>{t("landing.awards.digital_br")}</Trans>
            </Text>
        </Container>
    );
};

const container = css`
    ${p(2)};
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
    background-color: #fff;

    ${onDesktop(css`
        ${flexDirection("row")}
    `)}
`;
