import {css} from "@emotion/react";
import {onDesktop} from "@pg-design/helpers-css";

export const inputWrapper = css`
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;

    ${onDesktop(css`
        padding: 0;
    `)}
`;
