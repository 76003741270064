import {useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {calculateRemSize, flexAlignCenter, flexDirection, mb, mt, onDesktop, pt} from "@pg-design/helpers-css";
import {ArrowIcon, BrandEmailIcon, BrandFlagIcon, BrandListIcon, BrandMapMarkersIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {rotate} from "../../../../components/css_helpers/rotate";

export const Instructions = () => {
    const {t} = useTranslation();
    const theme = useTheme();

    const steps = [
        {
            icon: BrandMapMarkersIcon,
            text: t("monitoring.instructions.step1")
        },
        {icon: BrandFlagIcon, text: t("monitoring.instructions.step2")},
        {icon: BrandEmailIcon, text: t("monitoring.instructions.step3")},
        {icon: BrandListIcon, text: t("monitoring.instructions.step4")}
    ];

    return (
        <Container as="section" fluid css={[mb(11), onDesktop(mb(18))]}>
            <Container>
                <Row>
                    <Col lg={6} lgOffset={5} md={6} mdOffset={3} sm={4}>
                        <Text as="h2" variant="headline_1" align="center">
                            {t("monitoring.instructions.question")}
                        </Text>
                    </Col>
                </Row>
                {steps.map((step, i) => (
                    <Row css={pt(2)} key={step.text}>
                        <Col css={iconWrapper} lg={1} lgOffset={5} md={1} mdOffset={3} sm={1}>
                            {step.icon({
                                size: "7.2",
                                wrapperColor: theme.colors.primary,
                                wrapperSize: "7.2"
                            })}
                            {i !== steps.length - 1 && <ArrowIcon css={[mt(2), mb(1), rotate("270")]} size="4" />}
                        </Col>
                        <Col lg={5} md={5} sm={3}>
                            <div css={textWrapper}>
                                <Text variant="body_copy_1">{step.text}</Text>
                            </div>
                        </Col>
                    </Row>
                ))}
            </Container>
        </Container>
    );
};

const iconWrapper = css`
    ${flexAlignCenter};
    ${flexDirection("column")};
    height: fit-content;
`;

const textWrapper = css`
    height: ${calculateRemSize(9)};
    ${flexAlignCenter};
`;
