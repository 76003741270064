import {i18n} from "i18next";

import {CitySlug, mapCitySlugToLocalizedParamSlug, validateOfferType} from "../../../../../config/cities";
import {chartsRoutes, compilePath} from "./charts_routes";
import {mapOfferTypeToValidPath, mapPathnameToOfferType, OfferType} from "./offer_type";

interface IMenuItem {
    name: string;
    path: string;
    secondPath: string;
    icon: string;
}

export const getMenuItems = (
    citySlug: CitySlug | null = CitySlug.WARSZAWA,
    pathname: string,
    i18n: i18n
): IMenuItem[] => {
    const offerType = mapPathnameToOfferType(pathname);
    const validOfferType = validateOfferType(citySlug, offerType);
    const city = mapCitySlugToLocalizedParamSlug(citySlug, i18n.language);
    const detailedOfferType = offerType === OfferType.HOUSE ? OfferType.FLAT : offerType;
    return [
        {
            name: i18n.t("nav.home"),
            path: mapOfferTypeToValidPath(offerType, chartsRoutes[i18n.language].flats.root, i18n),
            icon: "home",
            secondPath: ""
        },
        {
            name: i18n.t("nav.dashboard"),
            path: compilePath(
                mapOfferTypeToValidPath(validOfferType, chartsRoutes[i18n.language].flats.city.root, i18n),
                {city}
            ),
            icon: "place",
            secondPath: ""
        },
        {
            name: i18n.t("nav.prices"),
            path: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].flats.city.prices, i18n),
                {
                    city
                }
            ),
            secondPath: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].properties.city.prices, i18n),
                {city}
            ),
            icon: "euro"
        },
        {
            name: i18n.t("nav.offer"),
            path: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].flats.city.offer, i18n),
                {
                    city
                }
            ),
            secondPath: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].properties.city.offer, i18n),
                {city}
            ),
            icon: "home_work"
        },
        {
            name: i18n.t("nav.demand"),
            path: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].flats.city.demand, i18n),
                {
                    city
                }
            ),
            secondPath: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].properties.city.demand, i18n),
                {city}
            ),
            icon: "trending_up"
        },
        {
            name: i18n.t("nav.sold"),
            path: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].flats.city.sold, i18n),
                {
                    city
                }
            ),
            secondPath: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].properties.city.sold, i18n),
                {city}
            ),
            icon: "work_outline"
        },
        {
            name: i18n.t("nav.added"),
            path: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].flats.city.added, i18n),
                {
                    city
                }
            ),
            secondPath: compilePath(
                mapOfferTypeToValidPath(detailedOfferType, chartsRoutes[i18n.language].properties.city.added, i18n),
                {city}
            ),
            icon: "flag"
        }
    ];
};
