import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {getBigDataApiPath} from "../../../../../utils/get_big_data_api_path";
import {getClientCookies} from "../../../client/get_client_cookies";
import {isServer} from "../../../common/app/read_charts_web_environment_variables";
import {IRootState} from "../../../store/store";

export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${getBigDataApiPath()}users/`,
        prepareHeaders: (headers, api) => {
            if (isServer) {
                const rootState = api.getState() as IRootState;

                const sessionId = rootState.appStatus.serverSessionId ? rootState.appStatus.serverSessionId : "";
                const csrfToken = rootState.appStatus.serverCsrfToken ? rootState.appStatus.serverCsrfToken : "";
                headers.set("Cookie", `bd_sessionid=${sessionId}; bd_csrftoken=${csrfToken}`);
            }

            const {csrfToken} = getClientCookies();
            headers.set("X-CSRFToken", csrfToken);

            return headers;
        },
        credentials: "include"
    }),
    endpoints: () => ({})
});
