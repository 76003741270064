export const cities: IDictionary = {
    warszawa: "Warszawa",
    krakow: "Kraków",
    wroclaw: "Wrocław",
    gdansk: "Gdańsk",
    poznan: "Poznań",
    lodz: "Łódź",
    szczecin: "Szczecin",
    olsztyn: "Olsztyn",
    bialystok: "Białystok",
    "zielona-gora": "Zielona Góra",
    bydgoszcz: "Bydgoszcz",
    katowice: "Katowice",
    kielce: "Kielce",
    lublin: "Lublin",
    opole: "Opole",
    rzeszow: "Rzeszów"
};

export const citiesWhere: IDictionary = {
    warszawa: "w Warszawie",
    krakow: "w Krakowie",
    wroclaw: "we Wrocławiu",
    gdansk: "w Gdańsku",
    poznan: "w Poznaniu",
    lodz: "w Łodzi",
    szczecin: "w Szczecinie",
    olsztyn: "w Olsztynie",
    bialystok: "w Białymstoku",
    "zielona-gora": "w Zielonej Górze",
    bydgoszcz: "w Bydgoszczy",
    katowice: "w Katowicach",
    kielce: "w Kielcach",
    lublin: "w Lublinie",
    opole: "w Opolu",
    rzeszow: "w Rzeszowie"
};

export const citiesWhat: IDictionary = {
    warszawa: "Warszawy",
    krakow: "Krakowa",
    wroclaw: "Wrocławia",
    gdansk: "Gdańska",
    poznan: "Poznania",
    lodz: "Łodzi",
    szczecin: "Szczecina",
    olsztyn: "Olsztyna",
    bialystok: "Białegostoku",
    "zielona-gora": "Zielonej Góry",
    bydgoszcz: "Bydgoszczy",
    katowice: "Katowic",
    kielce: "Kielc",
    lublin: "Lublina",
    opole: "Opola",
    rzeszow: "Rzeszowa"
};

export const citiesWho: IDictionary = {
    warszawa: "warszawiacy",
    krakow: "krakowianie",
    wroclaw: "wrocławianie",
    gdansk: "gdańszczanie",
    poznan: "poznaniacy",
    lodz: "łodzianie",
    szczecin: "szczecinianie",
    olsztyn: "olsztynianie",
    bialystok: "białostoczczanie",
    "zielona-gora": "zielonogórzanie",
    bydgoszcz: "bydgoszczanie",
    katowice: "katowiczanie",
    kielce: "kielczanie",
    lublin: "lublinianie",
    opole: "opolanie",
    rzeszow: "rzeszowiacy"
};

export const citiesWhose: IDictionary = {
    warszawa: "warszawskich",
    krakow: "krakowskich",
    wroclaw: "wrocławskich",
    gdansk: "gdańskich",
    poznan: "poznańskich",
    lodz: "łódzkich",
    szczecin: "szczecińskich",
    olsztyn: "olsztyńskich",
    bialystok: "białostockich",
    "zielona-gora": "zielonogórskich",
    bydgoszcz: "bydgoskich",
    katowice: "katowickich",
    kielce: "kieleckich",
    lublin: "lubelskich",
    opole: "opolskich",
    rzeszow: "rzeszowskich"
};

export const citiesWhom: IDictionary = {
    warszawa: "warszawskim",
    krakow: "krakowskim",
    wroclaw: "wrocławskim",
    gdansk: "gdańskim",
    poznan: "poznańskim",
    lodz: "łódzkim",
    szczecin: "szczecińskich",
    olsztyn: "olsztyńskich",
    bialystok: "białostockich",
    "zielona-gora": "zielonogórskich",
    bydgoszcz: "bydgoskich",
    katowice: "katowickich",
    kielce: "kieleckich",
    lublin: "lubelskich",
    opole: "opolskich",
    rzeszow: "rzeszowskich"
};

export const voivodeshipCapitals: IDictionary = {
    warszawa: "mazowieckie",
    krakow: "malopolskie",
    wroclaw: "dolnoslaskie",
    gdansk: "pomorskie",
    poznan: "wielkopolskie",
    lodz: "lodzkie",
    szczecin: "zachodnio-pomorskie",
    olsztyn: "warminsko-mazurskie",
    bialystok: "podlaskie",
    "zielona-gora": "lubuskie",
    bydgoszcz: "kujawsko-pomorskie",
    katowice: "slaskie",
    kielce: "swietokrzyskie",
    lublin: "lubelskie",
    opole: "opolskie",
    rzeszow: "podkarpackie"
};

export const voivodeshipCapitalsByName: IDictionary = {
    mazowieckie: "warszawa",
    malopolskie: "krakow",
    dolnoslaskie: "wroclaw",
    pomorskie: "gdansk",
    wielkopolskie: "poznan",
    lodzkie: "lodz",
    "zachodnio-pomorskie": "szczecin",
    "warminsko-mazurskie": "olsztyn",
    podlaskie: "bialystok",
    lubuskie: "zielona-gora",
    "kujawsko-pomorskie": "bydgoszcz",
    slaskie: "katowice",
    swietokrzyskie: "kielce",
    lubelskie: "lublin",
    opolskie: "opole",
    podkarpackie: "rzeszow"
};

export const getCityAdjective: IDictionary = {
    warszawa: "Mazowsza",
    krakow: "Małopolski",
    wroclaw: "Dolnego Śląska",
    gdansk: "Trójmiasta",
    poznan: "Wielkopolski",
    lodz: "województwa łódzkiego",
    szczecin: "województwa zachodnio-pomorskiego",
    olsztyn: "Mazur",
    bialystok: "Podlasia",
    "zielona-gora": "województwa lubuskiego",
    bydgoszcz: "województwa kujawsko-pomorskiego",
    katowice: "aglomeracji śląskiej",
    kielce: "województwa świętokrzyskiego",
    lublin: "Lubelszczyzny",
    opole: "województwa opolskiego",
    rzeszow: "Podkarpacia"
};

export const cityPronoun: IDictionary = {
    warszawa: "której",
    krakow: "którym",
    wroclaw: "którym",
    gdansk: "którym",
    poznan: "którym",
    lodz: "której",
    szczecin: "którym",
    olsztyn: "którym",
    bialystok: "którym",
    "zielona-gora": "której",
    bydgoszcz: "której",
    katowice: "których",
    kielce: "których",
    lublin: "którym",
    opole: "którym",
    rzeszow: "którym"
};

export const cityDescriptive: IDictionary = {
    warszawa: "warszawskiej",
    krakow: "krakowskiej",
    wroclaw: "wrocławskiej",
    gdansk: "gdańskiej",
    poznan: "poznańskiej",
    lodz: "łódzkiej",
    szczecin: "szczecińskiej",
    olsztyn: "olsztyńskiej",
    bialystok: "białostockiej",
    "zielona-gora": "zielonogórskiej",
    bydgoszcz: "bydgoskiej",
    katowice: "katowickiej",
    kielce: "kieleckiej",
    lublin: "lubelskiej",
    opole: "opolskiej",
    rzeszow: "rzeszowskiej"
};

export const districts: {[key: string]: IDictionary} = {
    warszawa: {
        bemowo: "Bemowo",
        bialoleka: "Białołęka",
        bielany: "Bielany",
        mokotow: "Mokotów",
        ochota: "Ochota",
        "praga-polnoc": "Praga-Północ",
        "praga-poludnie": "Praga-Południe",
        rembertow: "Rembertów",
        srodmiescie: "Śródmieście",
        targowek: "Targówek",
        ursus: "Ursus",
        ursynow: "Ursynów",
        wawer: "Wawer",
        wesola: "Wesoła",
        wilanow: "Wilanów",
        wlochy: "Włochy",
        wola: "Wola",
        zoliborz: "Żoliborz"
    },
    krakow: {
        bienczyce: "Bieńczyce",
        "biezanow-prokocim": "Bieżanów-Prokocim",
        bronowice: "Bronowice",
        czyzyny: "Czyżyny",
        debniki: "Dębniki",
        grzegorzki: "Grzegórzki",
        krowodrza: "Krowodrza",
        "lagiewniki-borek_falecki": "Łagiewniki-Borek Fałęcki",
        mistrzejowice: "Mistrzejowice",
        nowa_huta: "Nowa Huta",
        podgorze: "Podgórze",
        podgorze_duchackie: "Podgórze Duchackie",
        pradnik_bialy: "Prądnik Biały",
        pradnik_czerwony: "Prądnik Czerwony",
        stare_miasto: "Stare Miasto",
        swoszowice: "Swoszowice",
        wzgorza_krzeslawickie: "Wzgórza Krzesławickie",
        zwierzyniec: "Zwierzyniec"
    },
    wroclaw: {
        fabryczna: "Fabryczna",
        krzyki: "Krzyki",
        psie_pole: "Psie Pole",
        srodmiescie: "Śródmieście",
        stare_miasto: "Stare Miasto"
    },
    gdansk: {
        brzezno: "Brzeźno",
        chelm_i_gdansk_poludnie: "Chełm i Gdańsk Południe",
        jasien: "Jasień",
        kokoszki: "Kokoszki",
        letnica: "Letnica",
        oliwa: "Oliwa",
        "orunia-sw._wojciech-lipce": "Orunia-Św. Wojciech-Lipce",
        "piecki-migowo": "Piecki-Migowo",
        przymorze_male: "Przymorze Małe",
        siedlce: "Siedlce",
        srodmiescie: "Śródmieście",
        "ujescisko-lostowice": "Ujeścisko-Łostowice",
        wrzeszcz: "Wrzeszcz"
    },
    poznan: {
        grunwald: "Grunwald",
        jezyce: "Jeżyce",
        nowe_miasto: "Nowe Miasto",
        stare_miasto: "Stare Miasto",
        wilda: "Wilda"
    },
    lodz: {
        baluty: "Bałuty",
        gorna: "Górna",
        polesie: "Polesie",
        srodmiescie: "Śródmieście",
        widzew: "Widzew"
    },
    katowice: {
        zespol_dzielnic_polnocnych: "Zespół Dzielnic Północnych",
        zespol_dzielnic_poludniowych: "Zespół Dzielnic Południowych",
        zespol_dzielnic_srodmiejskich: "Zespół Dzielnic Śródmiejskich",
        zespol_dzielnic_wschodnich: "Zespół Dzielnic Wschodnich",
        zespol_dzielnic_zachodnich: "Zespół Dzielnic Zachodnich"
    },
    szczecin: {
        zachod: "Zachód",
        srodmiescie: "Śródmieście",
        prawobrzeze: "Prawobrzeże",
        polnoc: "Północ"
    }
};

export const districtsWhere: {[key: string]: IDictionary} = {
    warszawa: {
        bemowo: "na Bemowie",
        bialoleka: "na Białołęce",
        bielany: "na Bielanach",
        mokotow: "na Mokotowie",
        ochota: "na Ochocie",
        "praga-polnoc": "na Pradze-Północ",
        "praga-poludnie": "na Pradze-Południe",
        rembertow: "w Rembertowie",
        srodmiescie: "w Śródmieściu",
        targowek: "na Targówku",
        ursus: "w Ursusie",
        ursynow: "na Ursynowie",
        wawer: "na Wawrze",
        wesola: "w Wesołej",
        wilanow: "na Wilanowie",
        wlochy: "na Włochach",
        wola: "na Woli",
        zoliborz: "na Żoliborzu"
    },
    krakow: {
        bienczyce: "w Bieńczycach",
        "biezanow-prokocim": "na Bieżanowie-Prokocimiu",
        bronowice: "na Bronowicach",
        czyzyny: "na Czyżynach",
        debniki: "na Dębnikach",
        grzegorzki: "na Grzegórzkach",
        krowodrza: "na Krowodrzy",
        "lagiewniki-borek_falecki": "w Łagiewnikach-Borku Fałęckim",
        mistrzejowice: "na Mistrzejowicach",
        nowa_huta: "w Nowej Hucie",
        podgorze: "na Podgórzu",
        podgorze_duchackie: "na Podgórzu Duchackim",
        pradnik_bialy: "na Prądniku Białym",
        pradnik_czerwony: "na Prądniku Czerwonym",
        stare_miasto: "na Starym Mieście",
        swoszowice: "w Swoszowicach",
        wzgorza_krzeslawickie: "na Wzgórzach Krzesławickich",
        zwierzyniec: "na Zwierzyńcu"
    },
    wroclaw: {
        fabryczna: "w Fabrycznej",
        krzyki: "w Krzykach",
        psie_pole: "na Psim Polu",
        srodmiescie: "na Śródmieściu",
        stare_miasto: "na Starym Mieście"
    },
    gdansk: {
        brzezno: "na Brzeźnie",
        chelm_i_gdansk_poludnie: "w Chełmie i Gdańsku Południe",
        jasien: "na Jasieniu",
        kokoszki: "w Kokoszkach",
        letnica: "na Letnicy",
        oliwa: "w Oliwie",
        "orunia-sw._wojciech-lipce": "na Oruni-Św. Wojciecha-Lipcach",
        "piecki-migowo": "na Pieckach-Migowo",
        przymorze_male: "na Przymorzu Małym",
        siedlce: "na Siedlcach",
        srodmiescie: "w Śródmieściu",
        "ujescisko-lostowice": "na Ujeścisku-Łostowicach",
        wrzeszcz: "we Wrzeszczu"
    },
    poznan: {
        grunwald: "na Grunwaldzie",
        jezyce: "na Jeżycach",
        nowe_miasto: "na Nowym Mieście",
        stare_miasto: "na Starym Mieście",
        wilda: "na Wildzie"
    },
    lodz: {
        baluty: "na Bałutach",
        gorna: "w Górnej",
        polesie: "na Polesiu",
        srodmiescie: "w Śródmieściu",
        widzew: "w Widzewie"
    },
    katowice: {
        zespol_dzielnic_polnocnych: "w Zespole Dzielnic Północnych",
        zespol_dzielnic_poludniowych: "w Zespole Dzielnic Południowych",
        zespol_dzielnic_srodmiejskich: "w Zespole Dzielnic Śródmiejskich",
        zespol_dzielnic_wschodnich: "w Zespole Dzielnic Wschodnich",
        zespol_dzielnic_zachodnich: "w Zespole Dzielnic Zachodnich"
    },
    szczecin: {
        zachod: "na Zachodzie",
        srodmiescie: "w Śródmieściu",
        prawobrzeze: "na Prawobrzeżu",
        polnoc: "na Północy"
    }
};
