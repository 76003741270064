import {css, Theme} from "@emotion/react";
import {elevation} from "@pg-design/elevation";
import {
    backgroundColor,
    borderRadius,
    color,
    display,
    flex,
    flexDirection,
    mb,
    mr,
    onDesktop,
    pointer,
    position,
    w100
} from "@pg-design/helpers-css";
import {ArrowIcon, PlusIcon} from "@pg-design/icons";

import {IOfferBoxOffer, OfferType} from "../mocks/singleOfferMock";
import {OfferBox} from "./OfferBoxBase";

export interface IMapOffer {
    geo_point: {
        type: string;
        coordinates: [number, number];
    };
    id: number;
    name: string;
    stats: {
        properties_count_for_sale: number;
        properties_with_roi_count: number;
    };
    vendor: {
        id: number;
        name: string;
        slug: string;
    };
    configuration?: {
        is_autoresponder_enabled: boolean;
    };
    change_color: boolean;
    has_required_prices: boolean | null;
    has_distinction: boolean;
    type?: OfferType;
}

interface IMapOfferBoxProps {
    offer: IOfferBoxOffer | null;
    onOfferBoxClose: () => void;
}

export const MapOfferBox = ({offer, onOfferBoxClose}: IMapOfferBoxProps) => {
    return (
        <div css={offerBoxHolder}>
            <div css={[offerBoxMargin]}>
                <OfferBox offer={offer} />
            </div>

            <div css={[display("none"), onDesktop(display("block"))]}>
                <div css={closeBtnHolder} onClick={onOfferBoxClose}>
                    <PlusIcon size="2.2" css={closeIcon} />
                </div>
            </div>

            <div css={mobileCloseButtonHolder} onClick={onOfferBoxClose}>
                <ArrowIcon size="2" />
                Powróć do mapy
            </div>
        </div>
    );
};

const offerBoxHolder = (theme: Theme) => css`
    ${position("absolute")};
    ${flex()};
    ${flexDirection("column")};
    z-index: 1;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 30rem;

    @media (min-width: ${theme.breakpoints.sm}) {
        left: 2rem;
        transform: translateX(0);
    }

    ${onDesktop(css`
        ${flexDirection("row")};
        width: 37.5rem;
    `)};
`;

const offerBoxMargin = css`
    ${mb(1.5)};
    flex-grow: 1;
    min-height: 20rem;

    ${onDesktop(css`
        ${mb(0)};
        ${mr(1)}
    `)}
`;

const offerBoxShadow = css`
    ${elevation(2)};
`;

const mobileCloseButtonHolder = (theme: Theme) => css`
    ${w100};
    flex-basis: 3.8rem;
    ${flex("center", "center")};
    ${color(theme.colors.secondary)};
    ${backgroundColor("#fff")};
    border: none;
    ${borderRadius(1)};
    ${offerBoxShadow};

    > svg {
        ${mr(1.5)};
    }

    ${onDesktop(display("none"))};
`;

const closeBtnHolder = (theme: Theme) => css`
    ${backgroundColor("#fff")};
    ${offerBoxShadow};
    transition: background-color 0.2s ease-in-out;
    ${pointer};
    ${borderRadius(1)};
    width: 3.8rem;
    height: 3.8rem;
    ${flex("center", "center")};

    &:hover,
    &:focus,
    &:active {
        ${backgroundColor(theme.colors.gray[100])};
    }
`;

const closeIcon = css`
    rotate: 45deg;
`;
