import {css, Theme} from "@emotion/react";
import {listUnStyled} from "@pg-design/helpers-css";

import {highcharts} from "./highcharts";
import {normalize} from "./normalize";
import {scaffolding} from "./scaffolding";

import "./tippy.css";
import "react-toastify/dist/ReactToastify.css";

//  Created this object to easily get hex values when needed, usually to get rgba from it
//  TODO: If such approach is accepted, move other values to this object as well and provide them in globalStyles as vars
export const colors = {
    green: "#44bb81ff"
};

export const globalStyles = (theme: Theme) => css`
    :root {
        --green-checkbox-checked: #02d054;
        //(Mikołaj): red colour is used only in ChartError component because it's a class component and we cannot use theme there
        --red: #ff4085;

        --font-family: "Poppins", sans-serif;
        --font-root-size: 10px;
        --font-size: 1.4rem;
        --font-weight: 400;
        --line-height: 1.428571429;

        --transition: all 0.2s;
        --box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.05), 0px 24px 38px 3px rgba(0, 0, 0, 0.03),
            0px 9px 46px 8px rgba(0, 0, 0, 0.02);
        --mobile-header-height: 4.8rem;
        --desktop-header-height: 7.2rem;
        --sidebar-width: 9.4rem;

        --modal-width-small: 43rem;
        --modal-width-medium: 63rem;
        --modal-width-larg: 93rem;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    ${normalize};
    ${scaffolding(theme)};
    ${highcharts(theme)};

    /* Custom   ========================================================================== */

    html {
        //TODO: check why this class not always is removed from body after closing modal
        //&:has(.ReactModal__Body--open) {
        //    overflow: hidden;
        //}
    }

    //TODO: this should be css in some Modal Wrapper
    .ReactModal__Content--after-open {
        max-height: 100vh;
    }

    /*toast - bgcolors*/
    .Toastify__toast-theme--colored.Toastify__toast--success {
        background: ${theme.colors.success};
    }
    .Toastify__toast-theme--colored.Toastify__toast--error {
        background: ${theme.colors.danger};
    }

    ul {
        ${listUnStyled};
        margin: 0;
    }
`;
