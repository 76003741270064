import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {getPublicationsApi} from "../../../publications/api/get_publications";
import {IActionContext} from "../create_path_to_action";
import {setAuthAndDate} from "./common_action";

export const publicationsListingAction = async (ctx: IActionContext<ICityParams>): Promise<void> => {
    await setAuthAndDate(ctx);

    const {url} = ctx.route;

    const [_, paramsString] = url.split("?");

    const params = new URLSearchParams(paramsString);

    const page = params.get("page");

    ctx.store.dispatch(getPublicationsApi.endpoints.getPublicationsByPage.initiate(Number(page ?? 1)));

    await Promise.all(ctx.store.dispatch(getPublicationsApi.util.getRunningQueriesThunk()));
};
