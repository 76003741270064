import {Trans, useTranslation} from "react-i18next";
import {useHistory, useRouteMatch} from "react-router-dom";

import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {
    CitySlug,
    getCityOption,
    mapCitySlugToLocalizedParamSlug,
    mapParamSlugToCitySlug,
    validateOfferType
} from "../../../../../config/cities";
import {getRelatedCitySlug, mapCitySlugToRegionType, RegionType} from "../../../../../config/regions";
import {originDate} from "../../../../../utils/dates";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {useCityPermission} from "../../../../../utils/hooks/useCityPermission";
import {chartsRoutes, compilePath, ICityParams} from "../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, mapPathnameToOfferType, OfferType} from "../../../common/app/routing/offer_type";
import {BarChartCard} from "../../../components/charts/chart_cards/BarChartCard";
import {ColumnStackedChartCard} from "../../../components/charts/chart_cards/ColumnStackedChartCard";
import {DistrictsMapChartCard} from "../../../components/charts/chart_cards/DistrictsMapChartCard";
import {LineColumnChartCard} from "../../../components/charts/chart_cards/LineColumnChartCard";
import {MultilineChartCard} from "../../../components/charts/chart_cards/MultilineChartCard";
import {Grid} from "../../../components/Grid";
import {withDateGuard} from "../../../components/hoc/withDateGuard";
import {withGTM} from "../../../components/hoc/withGTM";
import {ChartsLayout as Layout} from "../../../components/layout/charts_layout/ChartsLayout";
import {Division, MathEquation} from "../../../components/MathEquation";
import {selectAuthStatus} from "../../auth/auth_slice";
import {selectLatestEntry} from "../../ssr/redux/latest_entry_slice";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {pageviewUserComHit} from "../../tracking/user_com/hit_user_com";
import {ViewType} from "../../tracking/view_type";
import {
    useGetMonthCityStatsQuery,
    useGetMonthCityTypeStatsQuery,
    useGetMonthDistrictStatsQuery
} from "../api/analytical_platform_api";
import {mapRtkQueryStatusToRequestStatus} from "../utils/rtq_status_to_request_status";

export const Demand = withDateGuard(
    withGTM(() => {
        const {
            path,
            params: {city}
        } = useRouteMatch<ICityParams>();
        const citySlug = mapParamSlugToCitySlug(city);
        const regionType = mapCitySlugToRegionType(citySlug);
        usePageview(() => {
            pageviewAlgolyticsHit({citySlug, regionType, viewType: ViewType.DEMAND_DETAILS});
            pageviewUserComHit();
        });
        const checkOfferType = mapPathnameToOfferType(path);
        const isFlatOfferType = checkOfferType === OfferType.FLAT;
        const isPermitted = useCityPermission(city);
        const history = useHistory();
        const {t, i18n} = useTranslation();
        const {currentDate} = useAppSelector(selectLatestEntry);
        const lastMonth = currentDate ?? "";
        const isAgglomerationView = getCityOption(citySlug).useAgglomerationApi;
        const {isLoggedIn, isAuthorized} = useAppSelector(selectAuthStatus);
        const shouldFetch = isLoggedIn && isAuthorized;

        const requestSlugKey = isAgglomerationView ? "slug_agglomeration" : "slug_city";
        const requestCitySlug = getCityOption(citySlug).requestCitySlug;

        const {
            data = [],
            isError,
            isSuccess
        } = useGetMonthCityStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                scenario: "demand"
            },
            {skip: !shouldFetch}
        );
        const {
            data: typeData = [],
            isError: isTypeDataError,
            isSuccess: isTypeDataSuccess
        } = useGetMonthCityTypeStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                offer_type: "Mieszkania",
                scenario: "demand"
            },
            {skip: !shouldFetch}
        );

        const {
            data: monthDistrictData = [],
            isError: isMonthDistrictDataError,
            isSuccess: isMonthDistrictDataSuccess
        } = useGetMonthDistrictStatsQuery(
            {
                slug_city: citySlug,
                date_start: lastMonth,
                date_end: lastMonth,
                scenario: "demand",
                ...(isFlatOfferType ? {offer_type: "Mieszkania"} : {})
            },
            {
                skip: !shouldFetch || isAgglomerationView
            }
        );

        const dataStatus = mapRtkQueryStatusToRequestStatus(isError, isSuccess);
        const typeDataStatus = mapRtkQueryStatusToRequestStatus(isTypeDataError, isTypeDataSuccess);
        const monthDistrictStatus = mapRtkQueryStatusToRequestStatus(
            isMonthDistrictDataError,
            isMonthDistrictDataSuccess
        );

        const monthData = Array.isArray(data) && data.length > 0 ? [data[data.length - 1]] : [];
        const flatMonthData = Array.isArray(typeData) && typeData.length > 0 ? [typeData[typeData.length - 1]] : [];

        // display districts map only on cities (not agglomerations) with districts enabled
        const districtsMapAvailable = getCityOption(citySlug).districtsMap && !isAgglomerationView;
        const handleCitySelection = (city: CitySlug) => {
            const validOfferType = validateOfferType(city, checkOfferType);
            const targetPath = mapOfferTypeToValidPath(
                validOfferType,
                chartsRoutes[i18n.language].properties.city.demand,
                i18n
            );
            const localizedCityParam = mapCitySlugToLocalizedParamSlug(city, i18n.language);
            const compiledPath = compilePath(targetPath, {city: localizedCityParam});
            history.push(compiledPath);
        };
        const handleRegionChange = (region: RegionType) => {
            const targetCitySlug = getRelatedCitySlug(citySlug, region);
            const paramCitySlug = mapCitySlugToLocalizedParamSlug(targetCitySlug, i18n.language);
            const compiledPath = compilePath(path, {city: paramCitySlug});
            history.push(compiledPath);
        };
        return (
            <Layout onCitySelection={handleCitySelection} onRegionChange={handleRegionChange}>
                <Grid type={districtsMapAvailable ? "subpage" : "subpageMapless"}>
                    <LineColumnChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart1"
                        chartId="demand-chart1"
                        title={<Trans>{t("demand.chart1.title")}</Trans>}
                        helper={
                            <>
                                {t("demand.chart1.description")}
                                <br />
                                <br />
                                <MathEquation
                                    leftSide={t("common.formulas.number_of_offers")}
                                    rightSide={`${t("common.formulas.sold_offers")} + ${t(
                                        "common.formulas.demand_offers"
                                    )}`}
                                />
                                <br />
                                <MathEquation
                                    leftSide={t("common.formulas.demand_indicator")}
                                    rightSide={
                                        <Division
                                            dividend={t("common.formulas.sold_offers")}
                                            divisor={t("common.formulas.number_of_offers")}
                                        />
                                    }
                                />
                                <br />
                                <Trans>{t("demand.chart1.additional_description")}</Trans>
                            </>
                        }
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={dataStatus}
                        columnFields={["available_offers", "sold"]}
                        lineField="demand"
                        lineAxisMinValue={0}
                        tooltipUnits={{
                            line: "%"
                        }}
                        lineValueMultiplier={100}
                        locked={!isPermitted}
                    />

                    <MultilineChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart2"
                        chartId="demand-chart2"
                        title={<Trans>{t("demand.chart2.title")}</Trans>}
                        helper={
                            <>
                                {t("demand.chart2.description")}
                                <br />
                                <br />
                                <MathEquation
                                    leftSide={t("common.formulas.demand_distribution")}
                                    rightSide={
                                        <Division
                                            dividend={t("common.formulas.rooms_seekers")}
                                            divisor={t("common.formulas.all_seekers")}
                                        />
                                    }
                                />
                            </>
                        }
                        data={data}
                        dataStatus={dataStatus}
                        fields={[
                            "demand_studio",
                            "demand_2_rooms",
                            "demand_3_rooms",
                            "demand_4_plus_rooms",
                            ...(isFlatOfferType ? [] : ["demand_house"])
                        ]}
                        unit="%"
                        valueMultiplier={100}
                        locked={!isPermitted}
                    />

                    <BarChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart3"
                        chartId="demand-chart3"
                        title={<Trans>{t("demand.chart3.title")}</Trans>}
                        helper={
                            <>
                                <Trans>{t("demand.chart3.description")}</Trans>
                                <br />
                                <br />
                                <MathEquation
                                    leftSide={t("common.formulas.demand_distribution")}
                                    rightSide={
                                        <Division
                                            dividend={
                                                <Trans>{t("common.formulas.seekers_within_pricing_segment")}</Trans>
                                            }
                                            divisor={t("common.formulas.all_seekers")}
                                        />
                                    }
                                />
                            </>
                        }
                        data={isFlatOfferType ? flatMonthData : monthData}
                        dataStatus={dataStatus}
                        fields={[
                            "demand_offers_to_6k",
                            "demand_offers_between_6k_and_7k",
                            "demand_offers_between_7k_and_8k",
                            "demand_offers_between_8k_and_9k",
                            "demand_offers_between_9k_and_10k",
                            "demand_offers_between_10k_and_12k",
                            "demand_offers_between_12k_and_15k",
                            "demand_offers_between_15k_and_20k",
                            "demand_offers_above_20k"
                        ]}
                        height={250}
                        unit="%"
                        valueMultiplier={100}
                        locked={!isPermitted}
                    />

                    <MultilineChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart4"
                        chartId="demand-chart4"
                        title={<Trans>{t("demand.chart4.title")}</Trans>}
                        helper={
                            <>
                                <Trans>{t("demand.chart4.description")}</Trans>
                                <br />
                                <br />
                                <MathEquation
                                    leftSide={t("common.formulas.demand_distribution")}
                                    rightSide={
                                        <Division
                                            dividend={
                                                <Trans>{t("common.formulas.seekers_within_pricing_segment")}</Trans>
                                            }
                                            divisor={t("common.formulas.all_seekers")}
                                        />
                                    }
                                />
                            </>
                        }
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={dataStatus}
                        fields={[
                            "demand_offers_to_300k",
                            "demand_offers_between_300k_and_400k",
                            "demand_offers_between_400k_and_500k",
                            "demand_offers_between_500k_and_700k",
                            "demand_offers_between_700k_and_1_mln",
                            "demand_offers_above_1_mln"
                        ]}
                        unit="%"
                        valueMultiplier={100}
                        locked={!isPermitted}
                    />

                    <ColumnStackedChartCard
                        area="chart5"
                        chartId="demand-chart5"
                        title={<Trans>{t("demand.chart5.title")}</Trans>}
                        helper={
                            <>
                                <Trans>{t("demand.chart5.description")}</Trans>
                                <br />
                                <br />
                                <MathEquation
                                    leftSide={t("common.formulas.demand_distribution")}
                                    rightSide={
                                        <Division
                                            dividend={t("common.formulas.seekers_within_investment_size")}
                                            divisor={t("common.formulas.all_seekers")}
                                        />
                                    }
                                />
                            </>
                        }
                        data={monthData}
                        dataStatus={dataStatus}
                        categories={[
                            "investment_type_intimate",
                            "investment_type_small",
                            "investment_type_medium",
                            "investment_type_large",
                            "investment_type_x_large",
                            ...(isFlatOfferType ? [] : ["investment_type_house"])
                        ]}
                        fieldGroups={[
                            [
                                "demand_investment_type_intimate_offer_studio",
                                "demand_investment_type_small_offer_studio",
                                "demand_investment_type_medium_offer_studio",
                                "demand_investment_type_large_offer_studio",
                                "demand_investment_type_x_large_offer_studio",
                                ...(isFlatOfferType ? [] : ["demand_houses_to_100m2"])
                            ],
                            [
                                "demand_investment_type_intimate_offer_2_rooms",
                                "demand_investment_type_small_offer_2_rooms",
                                "demand_investment_type_medium_offer_2_rooms",
                                "demand_investment_type_large_offer_2_rooms",
                                "demand_investment_type_x_large_offer_2_rooms",
                                ...(isFlatOfferType ? [] : ["demand_houses_between_100_and_150m2"])
                            ],
                            [
                                "demand_investment_type_intimate_offer_3_rooms",
                                "demand_investment_type_small_offer_3_rooms",
                                "demand_investment_type_medium_offer_3_rooms",
                                "demand_investment_type_large_offer_3_rooms",
                                "demand_investment_type_x_large_offer_3_rooms",
                                ...(isFlatOfferType ? [] : ["demand_houses_between_150_and_200m2"])
                            ],
                            [
                                "demand_investment_type_intimate_offer_4_plus_rooms",
                                "demand_investment_type_small_offer_4_plus_rooms",
                                "demand_investment_type_medium_offer_4_plus_rooms",
                                "demand_investment_type_large_offer_4_plus_rooms",
                                "demand_investment_type_x_large_offer_4_plus_rooms",
                                ...(isFlatOfferType ? [] : ["demand_houses_between_200_and_250m2"])
                            ],
                            ["", "", "", "", "", ...(isFlatOfferType ? [] : ["demand_houses_above_250m2"])]
                        ]}
                        minValue={0}
                        unit="%"
                        valueMultiplier={100}
                        locked={!isPermitted}
                    />
                    {monthDistrictData && (
                        <DistrictsMapChartCard
                            chartId="demand-map"
                            title={<Trans>{t("demand.map.title", {city: citySlug})}</Trans>}
                            helper={t("demand.map.description", {city: citySlug})}
                            visible={districtsMapAvailable}
                            city={citySlug as string}
                            data={monthDistrictData}
                            dataStatus={monthDistrictStatus}
                            valueField="demand"
                            legendItems={[
                                [0, 0.049, "<5%", "#FFEED4"],
                                [0.05, 0.099, "5-10%", "#FFE298"],
                                [0.1, 0.149, "10-15%", "#FFC56D"],
                                [0.15, 0.199, "15-20%", "#FFA740"],
                                [0.2, Infinity, ">20%", "#FF8800"]
                            ]}
                            unit="percent"
                            valueMultiplier={100}
                            locked={!isPermitted}
                        />
                    )}
                </Grid>
            </Layout>
        );
    })
);
