import {Component, ReactNode} from "react";
import {flexAbsoluteCenter, h100, mb, w100} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons";

import {bugsnagNotify} from "../../../../utils/bugsnag";
import {rotate} from "../css_helpers/rotate";

interface IProps {
    children: ReactNode;
}

interface IState {
    hasError: boolean;
}

export class ChartError extends Component<IProps, IState> {
    state = {hasError: false};

    componentDidCatch(error: Error) {
        bugsnagNotify(error, {source: "ChartError"});
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    render() {
        if (this.state.hasError) {
            return (
                <div css={[w100, h100, flexAbsoluteCenter]}>
                    <InfoIcon css={[rotate(), mb(3)]} fill="var(--red)" size="6" />
                </div>
            );
        }

        return this.props.children;
    }
}
