import {useRef} from "react";

import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {Card, ICardProps} from "../../card/Card";
import {RenderProps} from "../../types/render_props";
import {BarChart, IBarChartProps, RatioBarChart} from "../Bar";

interface IBarChartCardProps<D> extends ICardProps, IBarChartProps<D> {
    data: D[];
}

interface IBarChartCardWrapperProps<D> extends IBarChartCardProps<D>, RenderProps {}

export const BarChartCardWrapper = <D extends {}>(props: IBarChartCardWrapperProps<D>) => {
    const chartRef = useRef(null);

    return (
        <Card {...props} area={props.area ?? ""} chartRef={chartRef} chartPlaceholder="bar">
            {props.render({innerRef: chartRef, ...props})}
        </Card>
    );
};

export const BarChartCard = <D extends {}>(props: IBarChartCardProps<D>) => {
    return (
        <BarChartCardWrapper {...props} render={(renderProps) => <BarChart<D> {...renderProps} colorSet="purple" />} />
    );
};

export const RatioBarChartCard = <D extends {}>(props: IBarChartCardProps<D>) => {
    return (
        <BarChartCardWrapper
            {...props}
            render={(renderProps) => <RatioBarChart<D> {...renderProps} colorSet="purple" />}
        />
    );
};
