import {Department, IUserModel, UserType} from "../../../../../utils/shared_types/user_model";
import {inquiryApi} from "./inquiry_api";

export enum InquirySource {
    MONITORING = 1,
    PUBLICATIONS = 2
}

export interface IReportInquiryPayload {
    company: string;
    email: string;
    first_name: string;
    form_source: InquirySource;
    last_name: string;
    message: string;
    offer_address?: string;
    offer_name?: string;
    phone: string;
    property_notification_consent: boolean;
    user_type?: UserType;
    department?: Department;
}

export const add_inquiry = inquiryApi.injectEndpoints({
    endpoints: (build) => ({
        inquiry: build.mutation<IUserModel, IReportInquiryPayload>({
            query: (userData) => {
                return {
                    url: `/`,
                    method: "POST",
                    body: userData
                };
            }
        })
    }),
    overrideExisting: false
});

export const {useInquiryMutation} = add_inquiry;
