import {css, Theme} from "@emotion/react";
import {color, display, flex, pr, w100} from "@pg-design/helpers-css";
import {MapMarkerFillIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {truncate} from "../../css_helpers/truncate";
import {OfferType} from "../mocks/singleOfferMock";

interface IProps {
    offer: {
        region: {
            short_name_reverted: string;
        };
        street_name: string | null;
        street_number: string | null;
        type: OfferType;
    };
    showType?: true;
    color?: string;
    markerSize?: string;
    fontSize?: string;
}

export const OfferAddress = (props: IProps) => {
    const {offer} = props;

    function getOfferTypeNameCapital(offerType: OfferType) {
        switch (offerType) {
            case OfferType.FLAT:
                return "Mieszkanie";
            case OfferType.HOUSE:
                return "Dom";
            case OfferType.COMMERCIAL:
                return "Lokal użytkowy";
            default:
                throw new Error("Unknown offer type");
        }
    }

    return (
        <div css={offerBoxAddressHolder} color={props.color}>
            <div css={[offerBoxIconHolder]}>
                <MapMarkerFillIcon size="2.2" />
            </div>

            <Text as="address" variant="body_copy_2" css={offerBoxAddress}>
                <div css={truncate}>
                    {props.showType && getOfferTypeNameCapital(offer.type)} {offer.region.short_name_reverted}
                </div>

                {offer.street_name && (
                    <div css={truncate}>
                        {offer.street_name} {offer.street_number && offer.street_number}
                    </div>
                )}
            </Text>
        </div>
    );
};

const offerBoxAddressHolder = (theme: Theme) => css`
    ${flex("center")};
    ${color(theme.colors.gray[700])};
`;

const offerBoxIconHolder = css`
    ${pr(1.5)};
`;

const offerBoxAddress = css`
    ${display("table")}
    table-layout: fixed;
    ${w100};
    font-style: normal;
`;
