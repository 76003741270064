import {ReactNode} from "react";
import {css} from "@emotion/react";
import {flex, flexDirection, listUnStyled, m, mh, p} from "@pg-design/helpers-css";
import {HomeIcon} from "@pg-design/icons";

import {rpWebUrl} from "../../common/app/read_charts_web_environment_variables";

export interface IBreadCrumbsProps {
    className?: string;
    children?: ReactNode;
}

export interface IBreadcrumb {
    children: string;
    onClick?: () => void;
    title: string;
    to: string;
}

export const Breadcrumbs = ({children, className}: IBreadCrumbsProps) => {
    return (
        <nav
            css={breadcrumbs}
            className={className}
            id="breadcrumbs"
            itemScope
            itemType="https://schema.org/BreadcrumbList"
        >
            <ol css={breadcrumbsList} id="breadcrumbs-list">
                <li itemProp="itemListElement" itemScope={true} itemType="https://schema.org/ListItem">
                    <a css={mh(0.5)} href={rpWebUrl} title="RynekPierwotny.pl" itemProp="item">
                        <span itemProp="name" css={hideText}>
                            Strona główna
                        </span>

                        <HomeIcon size="2" />
                    </a>
                    <meta itemProp="position" content="1" />
                </li>

                {children}
            </ol>
        </nav>
    );
};

const breadcrumbs = css`
    ${flex()};
    ${flexDirection("row")};
    overflow-x: auto;
    white-space: nowrap;
`;

const breadcrumbsList = css`
    padding: 1rem 0;
    ${m(0)};
    ${listUnStyled};
    ${flex()};
    ${flexDirection("row")};
    width: 100%;

    > li:not(:last-of-type)::after {
        content: ">";
        ${p(0, 0.5)};
    }
`;

const hideText = css`
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
`;
