import {Trans, useTranslation} from "react-i18next";
import {useHistory, useRouteMatch} from "react-router-dom";

import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {
    CitySlug,
    getCityOption,
    mapCitySlugToLocalizedParamSlug,
    mapParamSlugToCitySlug,
    validateOfferType
} from "../../../../../config/cities";
import {getRelatedCitySlug, mapCitySlugToRegionType, RegionType} from "../../../../../config/regions";
import {getRecentQuartersNames, lastFinishedQuarter, originDate} from "../../../../../utils/dates";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {useCityPermission} from "../../../../../utils/hooks/useCityPermission";
import {chartsRoutes, compilePath, ICityParams} from "../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, mapPathnameToOfferType, OfferType} from "../../../common/app/routing/offer_type";
import {RatioBarChartCard} from "../../../components/charts/chart_cards/BarChartCard";
import {RatioColumnStackedChartCard} from "../../../components/charts/chart_cards/ColumnStackedChartCard";
import {DistrictsMapChartCard} from "../../../components/charts/chart_cards/DistrictsMapChartCard";
import {RatioLineColumnChartCard} from "../../../components/charts/chart_cards/LineColumnChartCard";
import {MultilineChartCard, RatioMultilineChartCard} from "../../../components/charts/chart_cards/MultilineChartCard";
import {PieBarChartCard, RatioPieBarChartCard} from "../../../components/charts/chart_cards/PieBarChartCard";
import {Grid} from "../../../components/Grid";
import {withDateGuard} from "../../../components/hoc/withDateGuard";
import {withGTM} from "../../../components/hoc/withGTM";
import {ChartsLayout as Layout} from "../../../components/layout/charts_layout/ChartsLayout";
import {selectAuthStatus} from "../../auth/auth_slice";
import {selectLatestEntry} from "../../ssr/redux/latest_entry_slice";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {pageviewUserComHit} from "../../tracking/user_com/hit_user_com";
import {ViewType} from "../../tracking/view_type";
import {
    useGetMonthCityStatsQuery,
    useGetMonthCityTypeStatsQuery,
    useGetMonthDistrictStatsQuery,
    useGetQuarterCityStatsQuery,
    useGetQuarterCityTypeStatsQuery
} from "../api/analytical_platform_api";
import {mapRtkQueryStatusToRequestStatus} from "../utils/rtq_status_to_request_status";

export const Sold = withDateGuard(
    withGTM(() => {
        const {
            path,
            params: {city}
        } = useRouteMatch<ICityParams>();
        const citySlug = mapParamSlugToCitySlug(city);
        const regionType = mapCitySlugToRegionType(citySlug);
        usePageview(() => {
            pageviewAlgolyticsHit({citySlug, regionType, viewType: ViewType.SOLD_DETAILS});
            pageviewUserComHit();
        });
        const checkOfferType = mapPathnameToOfferType(path);
        const isFlatOfferType = checkOfferType === OfferType.FLAT;
        const isPermitted = useCityPermission(city);
        const history = useHistory();
        const {t, i18n} = useTranslation();
        const {currentDate} = useAppSelector(selectLatestEntry);
        const lastMonth = currentDate ?? "";
        const lastQuarter = lastFinishedQuarter(lastMonth);
        const isAgglomerationView = getCityOption(citySlug).useAgglomerationApi;
        const {isLoggedIn, isAuthorized} = useAppSelector(selectAuthStatus);
        const shouldFetch = isLoggedIn && isAuthorized;
        const requestSlugKey = isAgglomerationView ? "slug_agglomeration" : "slug_city";
        const requestCitySlug = getCityOption(citySlug).requestCitySlug;

        const {
            data = [],
            isError,
            isSuccess
        } = useGetMonthCityStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                scenario: "sold"
            },
            {skip: !shouldFetch}
        );
        const {
            data: typeData = [],
            isError: isTypeDataError,
            isSuccess: isTypeDataSuccess
        } = useGetMonthCityTypeStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                offer_type: "Mieszkania",
                scenario: "sold"
            },
            {skip: !shouldFetch}
        );

        const {
            data: monthDistrictData = [],
            isError: isMonthDistrictDataError,
            isSuccess: isMonthDistrictDataSuccess
        } = useGetMonthDistrictStatsQuery(
            {
                slug_city: citySlug,
                date_start: lastMonth,
                date_end: lastMonth,
                scenario: "sold",
                ...(isFlatOfferType ? {offer_type: "Mieszkania"} : {})
            },
            {skip: !shouldFetch || isAgglomerationView}
        );

        const {
            data: quarterData = [],
            isError: isQuarterDataError,
            isSuccess: isQuarterDataSuccess
        } = useGetQuarterCityStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: lastQuarter,
                date_end: lastQuarter,
                scenario: "sold"
            },
            {skip: !shouldFetch}
        );

        const {
            data: quarterTypeData = [],
            isError: isQuarterTypeDataError,
            isSuccess: isQuarterTypeDataSuccess
        } = useGetQuarterCityTypeStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: lastQuarter,
                date_end: lastQuarter,
                scenario: "sold",
                ...(isFlatOfferType && {offer_type: "Mieszkania"})
            },
            {skip: !shouldFetch}
        );

        const dataStatus = mapRtkQueryStatusToRequestStatus(isError, isSuccess);
        const typeDataStatus = mapRtkQueryStatusToRequestStatus(isTypeDataError, isTypeDataSuccess);
        const monthDistrictStatus = mapRtkQueryStatusToRequestStatus(
            isMonthDistrictDataError,
            isMonthDistrictDataSuccess
        );
        const quarterStatus = mapRtkQueryStatusToRequestStatus(isQuarterDataError, isQuarterDataSuccess);

        const monthData = Array.isArray(data) && data.length > 0 ? [data[data.length - 1]] : [];
        const flatMonthData = Array.isArray(typeData) && typeData.length > 0 ? [typeData[typeData.length - 1]] : [];

        // display districts map only on cities (not agglomerations) with districts enabled
        const districtsMapAvailable = getCityOption(citySlug).districtsMap && !isAgglomerationView;
        const quarterLabels = getRecentQuartersNames(new Date(lastQuarter), 8);
        const pieQuarterLabels = [quarterLabels[0], ...quarterLabels].map(
            (label, i) => `${t("sold.construction_started")} ${i === 0 ? t("common.before") : t("common.in")} ${label}`
        );
        const barQuarterLabels = [quarterLabels[0], ...quarterLabels].map(
            (label, i) => `${i === 0 ? t("common.before") : t("common.in")} ${label}`
        );
        const handleCitySelection = (city: CitySlug) => {
            const validOfferType = validateOfferType(city, checkOfferType);
            const targetPath = mapOfferTypeToValidPath(
                validOfferType,
                chartsRoutes[i18n.language].properties.city.sold,
                i18n
            );
            const localizedCityParam = mapCitySlugToLocalizedParamSlug(city, i18n.language);
            const compiledPath = compilePath(targetPath, {city: localizedCityParam});
            history.push(compiledPath);
        };
        const handleRegionChange = (region: RegionType) => {
            const targetCitySlug = getRelatedCitySlug(citySlug, region);
            const paramCitySlug = mapCitySlugToLocalizedParamSlug(targetCitySlug, i18n.language);
            const compiledPath = compilePath(path, {city: paramCitySlug});
            history.push(compiledPath);
        };

        const finalData = isFlatOfferType
            ? data.map((item, index) => ({
                  ...item,
                  sold: typeData[index] ? typeData[index].sold : item.sold
              }))
            : data;

        return (
            <Layout onCitySelection={handleCitySelection} onRegionChange={handleRegionChange}>
                <Grid type={districtsMapAvailable ? "subpage" : "subpageMapless"}>
                    <RatioLineColumnChartCard<IMonthCityStats>
                        area="chart1"
                        chartId="sold-chart1"
                        title={<Trans>{t("sold.chart1.title")}</Trans>}
                        helper={<Trans>{t("sold.chart1.description")}</Trans>}
                        data={finalData}
                        dataStatus={isFlatOfferType ? typeDataStatus : dataStatus}
                        columnFields={[
                            "sold_studio",
                            "sold_2_rooms",
                            "sold_3_rooms",
                            "sold_4_plus_rooms",
                            ...(isFlatOfferType ? [] : ["sold_house"])
                        ]}
                        lineField="sold"
                        locked={!isPermitted}
                    />

                    <RatioBarChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart2"
                        chartId="sold-chart2"
                        title={<Trans>{t("sold.chart2.title")}</Trans>}
                        helper={<Trans>{t("sold.chart2.description")}</Trans>}
                        data={isFlatOfferType ? flatMonthData : monthData}
                        dataStatus={isFlatOfferType ? typeDataStatus : dataStatus}
                        fields={[
                            "sold_offers_to_6k",
                            "sold_offers_between_6k_and_7k",
                            "sold_offers_between_7k_and_8k",
                            "sold_offers_between_8k_and_9k",
                            "sold_offers_between_9k_and_10k",
                            "sold_offers_between_10k_and_12k",
                            "sold_offers_between_12k_and_15k",
                            "sold_offers_between_15k_and_20k",
                            "sold_offers_above_20k"
                        ]}
                        height={225}
                        locked={!isPermitted}
                    />

                    <RatioPieBarChartCard
                        area="chart3"
                        chartId="sold-chart3"
                        title={<Trans>{t("sold.chart3.title")}</Trans>}
                        helper={t("sold.chart3.description")}
                        pieData={isFlatOfferType ? flatMonthData : monthData}
                        pieStatus={isFlatOfferType ? typeDataStatus : dataStatus}
                        pieFields={[
                            "sold_offers_delivery_deadline_ready",
                            "sold_offers_delivery_deadline_in_3_months",
                            "sold_offers_delivery_deadline_in_6_months",
                            "sold_offers_delivery_deadline_in_1_year",
                            "sold_offers_delivery_deadline_in_2_year",
                            "sold_offers_delivery_deadline_above_2_years"
                        ]}
                        pieInnerTitle={t("sold.sold_offers")}
                        pieTooltipLabel="offer"
                        barData={isFlatOfferType ? flatMonthData : monthData}
                        barStatus={isFlatOfferType ? typeDataStatus : dataStatus}
                        barFields={[
                            "sold_offers_delivery_deadline_ready",
                            "sold_offers_delivery_deadline_in_3_months",
                            "sold_offers_delivery_deadline_in_6_months",
                            "sold_offers_delivery_deadline_in_1_year",
                            "sold_offers_delivery_deadline_in_2_year",
                            "sold_offers_delivery_deadline_above_2_years"
                        ]}
                        barMulticolorSet="alternateLong"
                        locked={!isPermitted}
                    />

                    <MultilineChartCard<IMonthCityStats>
                        area="chart4"
                        chartId="sold-chart4"
                        title={<Trans>{t("sold.chart4.title")}</Trans>}
                        helper={t("sold.chart4.description")}
                        data={data}
                        dataStatus={dataStatus}
                        fields={[
                            "sold_avg_area_studio",
                            "sold_avg_area_2_rooms",
                            "sold_avg_area_3_rooms",
                            "sold_avg_area_4_plus_rooms"
                        ]}
                        unit=" m<sup>2</sup>"
                        locked={!isPermitted}
                    />

                    <RatioMultilineChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart5"
                        chartId="sold-chart5"
                        title={<Trans>{t("sold.chart5.title")}</Trans>}
                        helper={t("sold.chart5.description")}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={isFlatOfferType ? typeDataStatus : dataStatus}
                        fields={[
                            "sold_area_to_30m2",
                            "sold_area_between_30_and_50m2",
                            "sold_area_between_50_and_70m2",
                            "sold_area_between_70_and_100m2",
                            "sold_area_between_100_and_120m2",
                            "sold_area_above_120m2"
                        ]}
                        unit={` ${t("common.sold", {context: "male", count: 5})}`}
                        locked={!isPermitted}
                    />

                    <RatioColumnStackedChartCard
                        area="chart6"
                        chartId="sold-chart6"
                        title={<Trans>{t("sold.chart6.title")}</Trans>}
                        helper={<Trans>{t("sold.chart6.description")}</Trans>}
                        data={monthData}
                        dataStatus={dataStatus}
                        categories={[
                            "investment_type_intimate",
                            "investment_type_small",
                            "investment_type_medium",
                            "investment_type_large",
                            "investment_type_x_large",
                            ...(isFlatOfferType ? [] : ["investment_type_house"])
                        ]}
                        fieldGroups={[
                            [
                                "sold_investment_type_intimate_offer_studio",
                                "sold_investment_type_small_offer_studio",
                                "sold_investment_type_medium_offer_studio",
                                "sold_investment_type_large_offer_studio",
                                "sold_investment_type_x_large_offer_studio",
                                ...(isFlatOfferType ? [] : ["sold_houses_to_100m2"])
                            ],
                            [
                                "sold_investment_type_intimate_offer_2_rooms",
                                "sold_investment_type_small_offer_2_rooms",
                                "sold_investment_type_medium_offer_2_rooms",
                                "sold_investment_type_large_offer_2_rooms",
                                "sold_investment_type_x_large_offer_2_rooms",
                                ...(isFlatOfferType ? [] : ["sold_houses_between_100_and_150m2"])
                            ],
                            [
                                "sold_investment_type_intimate_offer_3_rooms",
                                "sold_investment_type_small_offer_3_rooms",
                                "sold_investment_type_medium_offer_3_rooms",
                                "sold_investment_type_large_offer_3_rooms",
                                "sold_investment_type_x_large_offer_3_rooms",
                                ...(isFlatOfferType ? [] : ["sold_houses_between_150_and_200m2"])
                            ],
                            [
                                "sold_investment_type_intimate_offer_4_plus_rooms",
                                "sold_investment_type_small_offer_4_plus_rooms",
                                "sold_investment_type_medium_offer_4_plus_rooms",
                                "sold_investment_type_large_offer_4_plus_rooms",
                                "sold_investment_type_x_large_offer_4_plus_rooms",
                                ...(isFlatOfferType ? [] : ["sold_houses_between_200_and_250m2"])
                            ],
                            ["", "", "", "", "", ...(isFlatOfferType ? [] : ["sold_houses_above_250m2"])]
                        ]}
                        minValue={1}
                        locked={!isPermitted}
                    />
                    {/*handle one case, we do not have agglomeration data for the type of flat*/}
                    {!(isAgglomerationView && isFlatOfferType) && (
                        <PieBarChartCard
                            area="chart7"
                            chartId="sold-chart7"
                            title={
                                <Trans>
                                    {t(isFlatOfferType ? "sold.chart7.title_flats" : "sold.chart7.title", {
                                        quarter: barQuarterLabels[barQuarterLabels.length - 1]
                                    })}
                                </Trans>
                            }
                            helper={t("sold.chart7.description")}
                            pieData={isFlatOfferType ? quarterTypeData : quarterData}
                            pieStatus={quarterStatus}
                            pieFields={[
                                "above_8q",
                                "between_7_and_8q",
                                "between_6_and_7q",
                                "between_5_and_6q",
                                "between_4_and_5q",
                                "between_3_and_4q",
                                "between_2_and_3q",
                                "between_1_and_2q",
                                "to_1q"
                            ]}
                            pieInnerTitle={t("sold.sold_offers")}
                            pieTooltipLabel={undefined}
                            pieFieldsLabels={pieQuarterLabels}
                            barData={isFlatOfferType ? quarterTypeData : quarterData}
                            barStatus={quarterStatus}
                            barUnit={` ${t("common.pln_m2")}`}
                            barFields={[
                                "avg_price_8qplus",
                                "avg_price_8q",
                                "avg_price_7q",
                                "avg_price_6q",
                                "avg_price_5q",
                                "avg_price_4q",
                                "avg_price_3q",
                                "avg_price_2q",
                                "avg_price_1q"
                            ]}
                            barMulticolorSet="alternateLong"
                            barFieldsLabels={barQuarterLabels}
                            barTitle={` ${t("sold.construction_started")}`}
                            barHeight={225}
                            roundValues
                            locked={!isPermitted}
                        />
                    )}

                    <DistrictsMapChartCard
                        chartId="sold-map"
                        title={<Trans>{t("sold.map.title", {city: citySlug})}</Trans>}
                        helper={t("sold.map.description")}
                        visible={districtsMapAvailable}
                        city={citySlug as string}
                        data={monthDistrictData}
                        dataStatus={monthDistrictStatus}
                        valueField="sold"
                        legendType="quantitative"
                        legendColors={["#FFE8EC", "#FFD4DC", "#FF98B1", "#FF6D99", "#FF4085"]}
                        locked={!isPermitted}
                    />
                </Grid>
            </Layout>
        );
    })
);
