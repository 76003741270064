import {PropsWithChildren} from "react";
import {useTranslation} from "react-i18next";

import {IStaticContext} from "../../../server/routes/charts_route";
import {Error404Icon} from "../components/Error404Icon";
import {ErrorLayout} from "../components/ErrorLayout";

interface IProps {
    staticContext?: IStaticContext;
}

export const Error404 = (props: PropsWithChildren<IProps>) => {
    // server side 404 status response
    if (props.staticContext) {
        props.staticContext.noMatchRoute = true;
    }
    const {t} = useTranslation();
    const icon = <Error404Icon />;

    return (
        <ErrorLayout errorHeadline={t("common.error.404.name")} icon={icon}>
            {t("common.error.404.description")}
        </ErrorLayout>
    );
};
