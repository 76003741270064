import {Options} from "highcharts";

import {RequestStatus} from "../../../common/app/constants/enums";
import {placeholderYearData} from "../../../common/app/constants/placeholder_charts_data";
import {LineColumnChart} from "../LineColumn";

export const LineColumnChartPlaceholder = () => (
    <LineColumnChart
        data={placeholderYearData}
        dataStatus={RequestStatus.SUCCESS}
        height={275}
        colorSet="purple"
        lineField="value1"
        columnFields={["value2", "value3", "value4"]}
        customOptions={options}
    />
);

const options: Options = {
    tooltip: {
        enabled: false
    }
};
