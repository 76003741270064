import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {getPublicationsApi} from "../../../publications/api/get_publications";
import {IActionContext} from "../create_path_to_action";
import {setAuthAndDate} from "./common_action";

export const publicationsLandingAction = async (ctx: IActionContext<ICityParams>): Promise<void> => {
    await setAuthAndDate(ctx);

    ctx.store.dispatch(getPublicationsApi.endpoints.getPublications.initiate());

    await Promise.all(ctx.store.dispatch(getPublicationsApi.util.getRunningQueriesThunk()));
};
