import {lazy, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@emotion/react";
import {Options, SeriesColumnOptions, SeriesLineOptions} from "highcharts";

import {formatDate, humanDateFormatShort} from "../../../../utils/dates";
import {formatNumberGreaterThan1000} from "../../../../utils/format";
import {formatLineColumnTooltip} from "../../../../utils/tooltips";
import {mapFieldToColor} from "../../common/app/constants/colors";
import {IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";
import {calculateRatioForFields} from "./withRatioData";

const Chart = lazy(() => import("./ChartDefaultExport"));

// @TODO any
export interface ILineColumnChartProps extends IChartProps {
    data: any[];
    categoryField?: string;
    columnFields: string[];
    columnAxisTitle?: string;
    lineField: string;
    lineAxisTitle?: string;
    lineAxisMinValue?: number;
    stacked?: boolean;
    lineValueMultiplier?: number;
    columnValueMultiplier?: number;
    roundValues?: boolean;
    nullZeroLineValues?: boolean;
    tooltipUnits?: {
        column?: string;
        line?: string;
    };
}

export const LineColumnChart = (props: ILineColumnChartProps) => {
    const theme = useTheme();

    const {
        categoryField,
        columnFields,
        data,
        lineField,
        stacked,
        columnAxisTitle,
        lineAxisTitle,
        lineAxisMinValue,
        lineValueMultiplier = 1,
        columnValueMultiplier = 1,
        roundValues,
        nullZeroLineValues,
        tooltipUnits
    } = props;

    const {t, i18n} = useTranslation();

    const columnSeries: SeriesColumnOptions[] = useMemo(() => {
        if (data && data.length > 0) {
            return columnFields.map((field) => ({
                type: "column",
                borderWidth: 0,
                borderRadiusTopLeft: 4,
                borderRadiusTopRight: 4,
                pointWidth: 8,
                color: mapFieldToColor(field),
                name: t(`fields.${field}`) as string,
                yAxis: "column-axis",
                data: data.map((el) => ({
                    events: {
                        mouseOver: function () {
                            this.series.chart.axes
                                .find((axis) => axis.userOptions.id === "column-axis")
                                ?.update({
                                    labels: {
                                        style: {
                                            color: "black"
                                        }
                                    },
                                    title: {
                                        style: {
                                            color: "black"
                                        }
                                    }
                                });
                        },
                        mouseOut: function () {
                            this.series.chart.axes
                                .find((axis) => axis.userOptions.id === "column-axis")
                                ?.update({
                                    labels: {
                                        style: {
                                            color: theme.colors.gray[700]
                                        }
                                    },
                                    title: {
                                        style: {
                                            color: theme.colors.gray[700]
                                        }
                                    }
                                });
                        }
                    },
                    y: el[field] * columnValueMultiplier
                }))
            }));
        }

        return [];
    }, [data, i18n.language]);

    const lineSeries: SeriesLineOptions[] = useMemo(() => {
        if (data && data.length > 0) {
            return [
                {
                    type: "line",
                    yAxis: "line-axis",
                    color: mapFieldToColor(lineField),
                    name: t(`fields.${lineField}`) as string,
                    data: data.map((el) => ({
                        events: {
                            mouseOver: function () {
                                this.series.chart.axes
                                    .find((axis) => axis.userOptions.id === "line-axis")
                                    ?.update({
                                        labels: {
                                            style: {
                                                color: "black"
                                            }
                                        },
                                        title: {
                                            style: {
                                                color: "black"
                                            }
                                        }
                                    });
                            },
                            mouseOut: function () {
                                this.series.chart.axes
                                    .find((axis) => axis.userOptions.id === "line-axis")
                                    ?.update({
                                        labels: {
                                            style: {
                                                color: theme.colors.gray[700]
                                            }
                                        },
                                        title: {
                                            style: {
                                                color: theme.colors.gray[700]
                                            }
                                        }
                                    });
                            }
                        },
                        // Replace 0s with nulls to hide corresponding plot lines
                        y:
                            el[lineField] > 0
                                ? el[lineField] * lineValueMultiplier
                                : nullZeroLineValues
                                  ? null
                                  : el[lineField]
                    }))
                }
            ];
        }

        return [];
    }, [data, i18n.language]);

    const series = [...columnSeries, ...lineSeries];

    const categories = categoryField
        ? data.map((month) =>
              categoryField !== "date"
                  ? month[categoryField]
                  : formatDate(month[categoryField], humanDateFormatShort, i18n)
          )
        : [];

    const options: Options = {
        legend: {
            enabled: true
        },
        xAxis: {
            categories,
            visible: true
        },
        plotOptions: {
            column: {
                stacking: stacked ? "normal" : undefined
            }
        },
        tooltip: {
            formatter: function () {
                return formatLineColumnTooltip(this, tooltipUnits, roundValues);
            }
        },
        yAxis: [
            {
                id: "line-axis",
                labels: {
                    formatter: function () {
                        return formatNumberGreaterThan1000(this.value as number);
                    }
                },
                title: {
                    text: lineAxisTitle
                },
                min: lineAxisMinValue
            },
            {
                id: "column-axis",
                labels: {
                    formatter: function () {
                        return formatNumberGreaterThan1000(this.value as number);
                    }
                },
                opposite: true,
                title: {
                    text: columnAxisTitle
                }
            }
        ]
    };

    return (
        <SuspendedChart>
            <Chart {...props} customOptions={options} series={series} />
        </SuspendedChart>
    );
};

// custom component without a decorator due to a more complicated underlying logic
export const RatioLineColumnChart = (props: ILineColumnChartProps) => {
    const mappedRatioData = useMemo(() => calculateRatioForFields(props.data, props.columnFields), [props.data]);

    return (
        <LineColumnChart
            {...props}
            data={mappedRatioData.result}
            tooltipUnits={{column: "%", line: (props.tooltipUnits && props.tooltipUnits.line) || ""}}
        />
    );
};
