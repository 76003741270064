import {IUserModel} from "../../../utils/shared_types/user_model";
import {albacrossId, algolyticsId, userComId, userComScript} from "../common/app/read_charts_web_environment_variables";
import {initAlgolytics} from "../features/tracking/algolytics/init_algolytics";
import {initAlbacross} from "../features/tracking/init_albacross";
import {initUserCom} from "../features/tracking/user_com/init_user_com";

export const initClientScripts = (userData: IUserModel | null) => {
    initAlgolytics(algolyticsId);
    initUserCom(userComId, userComScript, userData);
    initAlbacross(albacrossId);
};
