import {lazy, useEffect, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {Options, SeriesMapOptions} from "highcharts";

import {chartsApiPath} from "../../../../api/src/constants/api_path";
import {mapParamSlugToCitySlug} from "../../../../config/cities";
import {useAppDispatch, useAppSelector} from "../../../../utils/hooks/store_hooks";
import {useAccessDate} from "../../../../utils/hooks/useAccessDate";
import {useAPIRequest} from "../../../../utils/hooks/useAPIRequest";
import {useTypeFilteredData} from "../../../../utils/hooks/useTypeFilteredData";
import {proxyNullValues} from "../../../../utils/misc";
import {formatMapTooltip} from "../../../../utils/tooltips";
import {colorSets, mapMapFieldToColor} from "../../common/app/constants/colors";
import {FilterOfferType} from "../../common/app/constants/enums";
import {maps} from "../../common/app/constants/maps";
import {breakpoints} from "../../common/app/styles/breakpoints";
import {MapLegend as Legend} from "../../features/analytical_platform/components/country_map/MapLegend";
import {selectLatestEntry} from "../../features/ssr/redux/latest_entry_slice";
import {MapStatType, selectSelectedStat, setSelectedStat} from "../map/redux/map_type_slice";
import {ExtendedPoint, IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";

const Chart = lazy(() => import("./ChartDefaultExport"));

interface IProps<D> extends Omit<IChartProps, "dataStatus"> {
    city: string;
    mapWrapperHeight: null | number;
    setMapWrapperHeight: (value: number) => void;
    offerType?: FilterOfferType;
}

const initialDistrictState = {
    available_offers: 0,
    available_investments: 0,
    sold: 0,
    added: 0
};

interface IOfferType {
    offer_type?: "Mieszkania" | "Domy";
}

interface IData extends IOfferType {
    added: number;
    available_investments: number;
    available_offers: number;
    avg_price_m2: number;
    city: string;
    date: string;
    district: string;
    slug_city: string;
    slug_district: string;
    sold: number;
}

interface ISlug {
    slug_district: string;
}

export const DistrictsMap = <D extends ISlug>(props: IProps<D>) => {
    const {city, mapWrapperHeight, setMapWrapperHeight, offerType} = props;

    const {i18n} = useTranslation();
    const selectedStat = useAppSelector(selectSelectedStat);
    const dispatch = useAppDispatch();
    const wrapperEl = useRef<HTMLDivElement>(null);
    const mapHeight = wrapperEl.current?.clientHeight;
    // get current date from initial context for data fetching purposes
    const {currentDate} = useAppSelector(selectLatestEntry);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore withDateGuard makes sure currentDate is not null
    const {upperBound: bound} = useAccessDate(currentDate);
    const citySlug = mapParamSlugToCitySlug(city);
    const endpoint = offerType ? chartsApiPath.monthDistrictTypeStats : chartsApiPath.monthDistrictStats;

    const [dataStatus, data] = useAPIRequest<IData>(
        endpoint,
        {
            slug_city: citySlug,
            date_start: bound,
            date_end: bound,
            scenario: "dashboard"
        },
        true
    );

    const filteredData = useTypeFilteredData(data, offerType);

    useEffect(() => {
        if (!mapWrapperHeight) {
            setMapWrapperHeight(mapHeight ?? 0);
        }
    }, [mapWrapperHeight]);

    const mapData = maps[citySlug as string][0];

    const districtsData = useMemo(() => {
        if (filteredData && filteredData.length > 0) {
            const districtsMapData = mapData.mapData;
            const districts = (districtsMapData as []).map(({id}: any) => {
                return [id, {...initialDistrictState, id}];
            });

            const series = Object.fromEntries(districts);

            for (const month of filteredData) {
                if (month.slug_district in series) {
                    for (const key of Object.keys(initialDistrictState)) {
                        series[month.slug_district][key] += month[key as keyof IData];
                    }
                }
            }

            return proxyNullValues(series);
        }

        return [];
    }, [citySlug, filteredData]);

    const series: SeriesMapOptions[] = [
        {
            ...mapData,
            data: Object.values(districtsData).map((el: any) => ({
                id: el.id,
                value: el[selectedStat]
            }))
        }
    ];

    const options: Options = {
        chart: {
            spacing: [20, 20, 20, 20],
            width: null,
            animation: false
        },
        colorAxis: {
            maxColor: colorSets[mapMapFieldToColor[selectedStat]][0],
            min: 0.5,
            type: "logarithmic"
        },
        tooltip: {
            className: "map-tooltip",
            formatter: function () {
                return formatMapTooltip(this, districtsData, Object.keys(initialDistrictState), i18n);
            },
            shared: true
        },
        plotOptions: {
            map: {
                events: {
                    click: function ({point}) {
                        window.userengage("event.district_click", {
                            city: citySlug as string,
                            district: (point as ExtendedPoint).id
                        });
                    }
                }
            }
        }
    };

    return (
        <StyledDistrictsMap>
            <div className="chart-wrapper" ref={wrapperEl}>
                <SuspendedChart>
                    <Chart
                        {...props}
                        colorSet={mapMapFieldToColor[selectedStat]}
                        customOptions={options}
                        series={series}
                        height={mapWrapperHeight}
                        type="map"
                        dataStatus={dataStatus}
                    />
                </SuspendedChart>
            </div>

            <Legend
                city={citySlug as string}
                data={data}
                dataField={selectedStat}
                fluidLayout
                mapColor={mapMapFieldToColor[selectedStat] as string}
                offerType={offerType}
                onLegendChange={(field: MapStatType) => dispatch(setSelectedStat(field))}
            />
        </StyledDistrictsMap>
    );
};

const StyledDistrictsMap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .chart-wrapper {
        height: 300px;
    }

    @media (min-width: ${breakpoints.md}) {
        height: calc(100vh - 122px);
        align-items: center;
        .chart-wrapper {
            max-width: calc(100% - 300px);
            width: 100% !important;
            flex: 1;
            display: flex;
            align-items: center;
            padding: 20px 0;
        }
    }
    @media (min-aspect-ratio: 16/8) {
        flex-direction: row;
        .chart-wrapper {
            height: calc(100% - var(--desktop-header-height));
        }
    }
`;
