import {OfferType} from "../web/src/common/app/routing/offer_type";

export enum CitySlug {
    BIALYSTOK = "bialystok",
    BYDGOSZCZ = "bydgoszcz",
    TROJMIASTO = "trojmiasto",
    GDANSK = "gdansk",
    KATOWICE = "katowice",
    KATOWICE_AGGLOMERATION = "aglomeracja-katowicka-(gzm)",
    KIELCE = "kielce",
    KRAKOW = "krakow",
    KRAKOW_AGGLOMERATION = "krakow-i-okolice",
    KRAKOW_SUBURBS = "okolice-krakowa",
    LODZ = "lodz",
    LODZ_AGGLOMERATION = "lodz-i-okolice",
    LODZ_SUBURBS = "okolice-lodzi",
    LUBLIN = "lublin",
    OLSZTYN = "olsztyn",
    // Opole = "opole",
    POZNAN = "poznan",
    POZNAN_AGGLOMERATION = "poznan-i-okolice",
    POZNAN_SUBURBS = "okolice-poznania",
    RZESZOW = "rzeszow",
    SZCZECIN = "szczecin",
    WARSZAWA = "warszawa",
    WARSZAWA_AGGLOMERATION = "warszawa-i-okolice",
    WARSZAWA_SUBURBS = "okolice-warszawy",
    WROCLAW = "wroclaw",
    WROCLAW_AGGLOMERATION = "wroclaw-i-okolice",
    WROCLAW_SUBURBS = "okolice-wroclawia",
    ZIELONA_GORA = "zielona-gora"
}

interface ICityOption {
    districtsMap?: boolean;
    propertyTypeFilters?: boolean;
    isCity: boolean;
    includeInCitySelect: boolean;
    fetchFooterData: boolean;
    enableRegionGroup: boolean;
    useAgglomerationApi: boolean;
    requestCitySlug: string;
    requestMapSlug: string; //Katowice agglomeration needs a different slug for its polygon
    availableOfferTypes: OfferType[];
    isMainCity?: boolean;
    enabled: boolean;
}

export const getCityOption = (city: CitySlug | null): ICityOption | never => {
    if (city && city in cityConfig) {
        return cityConfig[city];
    }
    throw new Error(`Invalid city provided to getCityOption(): ${city}`);
};

export const validateOfferType = (city: CitySlug | null, offerType: OfferType): OfferType | never => {
    const {availableOfferTypes} = getCityOption(city);
    if (availableOfferTypes.length === 0) {
        throw new Error("Provided city doesn't have any available offer types");
    }
    if (availableOfferTypes.includes(offerType)) {
        return offerType;
    }
    return availableOfferTypes[0];
};

const defaultCityConfig = (city: CitySlug): ICityOption => ({
    districtsMap: false, // is city's vector map available?
    propertyTypeFilters: false, // is city's data filterable by property type?
    isCity: true, //is this region only a city or does it include suburbs?
    includeInCitySelect: true, //should this region be included in city select component?
    fetchFooterData: true, //should data for this region be fetched in footer
    useAgglomerationApi: false,
    requestCitySlug: city,
    requestMapSlug: city,
    enableRegionGroup: true,
    availableOfferTypes: [], // allowed offer types
    isMainCity: false, // ?
    enabled: true // should the city be displayed in the UI?
});

export const cityConfig: {
    [key in CitySlug]: ICityOption;
} = {
    warszawa: {
        ...defaultCityConfig(CitySlug.WARSZAWA),
        districtsMap: true,
        propertyTypeFilters: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE],
        isMainCity: true
    },
    krakow: {
        ...defaultCityConfig(CitySlug.KRAKOW),
        districtsMap: true,
        propertyTypeFilters: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE],
        isMainCity: true
    },
    wroclaw: {
        ...defaultCityConfig(CitySlug.WROCLAW),
        districtsMap: true,
        propertyTypeFilters: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE],
        isMainCity: true
    },
    //we need this entry because slug for fetching data is "trojmiasto" but we leave "gdansk" in url
    //and also fetch data for "trojmiasto" in landing animated chart so slug with this name must exist
    [CitySlug.TROJMIASTO]: {
        ...defaultCityConfig(CitySlug.TROJMIASTO),
        includeInCitySelect: false
    },
    gdansk: {
        ...defaultCityConfig(CitySlug.GDANSK),
        districtsMap: false,
        propertyTypeFilters: true,
        useAgglomerationApi: true,
        enableRegionGroup: false,
        requestCitySlug: "trojmiasto",
        requestMapSlug: "trojmiasto",
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE],
        isMainCity: true
    },
    poznan: {
        ...defaultCityConfig(CitySlug.POZNAN),
        districtsMap: true,
        propertyTypeFilters: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE],
        isMainCity: true
    },
    lodz: {
        ...defaultCityConfig(CitySlug.LODZ),
        districtsMap: true,
        propertyTypeFilters: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE],
        isMainCity: true
    },
    szczecin: {
        ...defaultCityConfig(CitySlug.SZCZECIN),
        districtsMap: true,
        availableOfferTypes: [OfferType.FLAT]
    },
    olsztyn: {
        ...defaultCityConfig(CitySlug.OLSZTYN),
        availableOfferTypes: [OfferType.FLAT]
    },
    bialystok: {
        ...defaultCityConfig(CitySlug.BIALYSTOK),
        availableOfferTypes: [OfferType.FLAT]
    },
    "zielona-gora": {
        ...defaultCityConfig(CitySlug.ZIELONA_GORA),
        availableOfferTypes: [OfferType.FLAT],
        enabled: false
    },
    bydgoszcz: {
        ...defaultCityConfig(CitySlug.BYDGOSZCZ),
        availableOfferTypes: [OfferType.FLAT]
    },
    katowice: {
        ...defaultCityConfig(CitySlug.KATOWICE),
        districtsMap: false,
        propertyTypeFilters: true,
        useAgglomerationApi: true,
        enableRegionGroup: false,
        requestCitySlug: "aglomeracja-katowicka-(gzm)",
        requestMapSlug: "aglomeracja-katowicka-gzm",
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    //this entry is needed for a type check to pass and should not be used
    [CitySlug.KATOWICE_AGGLOMERATION]: {
        ...defaultCityConfig(CitySlug.KATOWICE_AGGLOMERATION),
        includeInCitySelect: false
    },
    kielce: {
        ...defaultCityConfig(CitySlug.KIELCE),
        availableOfferTypes: [OfferType.FLAT]
    },
    lublin: {
        ...defaultCityConfig(CitySlug.LUBLIN),
        availableOfferTypes: [OfferType.FLAT]
    },
    // opole: {
    //     ...defaultCityConfig,
    //     availableOfferTypes: [OfferType.FLAT]
    // },
    rzeszow: {
        ...defaultCityConfig(CitySlug.RZESZOW),
        availableOfferTypes: [OfferType.FLAT]
    },
    "warszawa-i-okolice": {
        ...defaultCityConfig(CitySlug.WARSZAWA_AGGLOMERATION),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    "okolice-warszawy": {
        ...defaultCityConfig(CitySlug.WARSZAWA_SUBURBS),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    "krakow-i-okolice": {
        ...defaultCityConfig(CitySlug.KRAKOW_AGGLOMERATION),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    "okolice-krakowa": {
        ...defaultCityConfig(CitySlug.KRAKOW_SUBURBS),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    "wroclaw-i-okolice": {
        ...defaultCityConfig(CitySlug.WROCLAW_AGGLOMERATION),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    "okolice-wroclawia": {
        ...defaultCityConfig(CitySlug.WROCLAW_SUBURBS),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    "poznan-i-okolice": {
        ...defaultCityConfig(CitySlug.POZNAN_AGGLOMERATION),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    "okolice-poznania": {
        ...defaultCityConfig(CitySlug.POZNAN_SUBURBS),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    "lodz-i-okolice": {
        ...defaultCityConfig(CitySlug.LODZ_AGGLOMERATION),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    },
    "okolice-lodzi": {
        ...defaultCityConfig(CitySlug.LODZ_SUBURBS),
        propertyTypeFilters: true,
        isCity: false,
        includeInCitySelect: false,
        fetchFooterData: false,
        useAgglomerationApi: true,
        availableOfferTypes: [OfferType.PROPERTY, OfferType.FLAT, OfferType.HOUSE]
    }
};

export const mapParamSlugToCitySlug = (paramSlug: string): CitySlug | null => {
    switch (paramSlug) {
        case "bialystok":
            return CitySlug.BIALYSTOK;
        case "bydgoszcz":
            return CitySlug.BYDGOSZCZ;
        case "gdansk":
            return CitySlug.GDANSK;
        case "katowice":
            return CitySlug.KATOWICE;
        case "kielce":
            return CitySlug.KIELCE;
        case "krakow":
            return CitySlug.KRAKOW;
        case "krakow-i-okolice":
        case "krakow-and-suburbs":
            return CitySlug.KRAKOW_AGGLOMERATION;
        case "okolice-krakowa":
        case "suburbs-of-krakow":
            return CitySlug.KRAKOW_SUBURBS;
        case "lodz":
            return CitySlug.LODZ;
        case "lodz-i-okolice":
        case "lodz-and-suburbs":
            return CitySlug.LODZ_AGGLOMERATION;
        case "okolice-lodzi":
        case "suburbs-of-lodz":
            return CitySlug.LODZ_SUBURBS;
        case "lublin":
            return CitySlug.LUBLIN;
        case "olsztyn":
            return CitySlug.OLSZTYN;
        // case "opole":
        //     return CitySlug.Opole;
        case "poznan":
            return CitySlug.POZNAN;
        case "poznan-i-okolice":
        case "poznan-and-suburbs":
            return CitySlug.POZNAN_AGGLOMERATION;
        case "okolice-poznania":
        case "suburbs-of-poznan":
            return CitySlug.POZNAN_SUBURBS;
        case "rzeszow":
            return CitySlug.RZESZOW;
        case "szczecin":
            return CitySlug.SZCZECIN;
        case "warszawa":
        case "warsaw":
            return CitySlug.WARSZAWA;
        case "warszawa-i-okolice":
        case "warsaw-and-suburbs":
            return CitySlug.WARSZAWA_AGGLOMERATION;
        case "okolice-warszawy":
        case "suburbs-of-warsaw":
            return CitySlug.WARSZAWA_SUBURBS;
        case "wroclaw":
            return CitySlug.WROCLAW;
        case "wroclaw-i-okolice":
        case "wroclaw-and-suburbs":
            return CitySlug.WROCLAW_AGGLOMERATION;
        case "okolice-wroclawia":
        case "suburbs-of-wroclaw":
            return CitySlug.WROCLAW_SUBURBS;
        case "zielona-gora":
            return CitySlug.ZIELONA_GORA;
        default:
            return null;
    }
};

export const mapCitySlugToLocalizedParamSlug = (citySlug: CitySlug | null, lang: string): string => {
    switch (citySlug) {
        case CitySlug.BIALYSTOK:
            return "bialystok";
        case CitySlug.BYDGOSZCZ:
            return "bydgoszcz";
        case CitySlug.GDANSK:
            return "gdansk";
        case CitySlug.TROJMIASTO:
            return "trojmiasto";
        case CitySlug.KATOWICE:
            return "katowice";
        case CitySlug.KATOWICE_AGGLOMERATION:
            return "aglomeracja-katowicka-(gzm)";
        case CitySlug.KIELCE:
            return "kielce";
        case CitySlug.KRAKOW:
            return "krakow";
        case CitySlug.KRAKOW_AGGLOMERATION:
            return lang === "en" ? "krakow-and-suburbs" : "krakow-i-okolice";
        case CitySlug.KRAKOW_SUBURBS:
            return lang === "en" ? "suburbs-of-krakow" : "okolice-krakowa";
        case CitySlug.LODZ:
            return "lodz";
        case CitySlug.LODZ_AGGLOMERATION:
            return lang === "en" ? "lodz-and-suburbs" : "lodz-i-okolice";
        case CitySlug.LODZ_SUBURBS:
            return lang === "en" ? "suburbs-of-lodz" : "okolice-lodzi";
        case CitySlug.LUBLIN:
            return "lublin";
        case CitySlug.OLSZTYN:
            return "olsztyn";
        // case CitySlug.Opole:
        //     return "opole";
        case CitySlug.POZNAN:
            return "poznan";
        case CitySlug.POZNAN_AGGLOMERATION:
            return lang === "en" ? "poznan-and-suburbs" : "poznan-i-okolice";
        case CitySlug.POZNAN_SUBURBS:
            return lang === "en" ? "suburbs-of-poznan" : "okolice-poznania";
        case CitySlug.RZESZOW:
            return "rzeszow";
        case CitySlug.SZCZECIN:
            return "szczecin";
        case CitySlug.WARSZAWA:
            return lang === "en" ? "warsaw" : "warszawa";
        case CitySlug.WARSZAWA_AGGLOMERATION:
            return lang === "en" ? "warsaw-and-suburbs" : "warszawa-i-okolice";
        case CitySlug.WARSZAWA_SUBURBS:
            return lang === "en" ? "suburbs-of-warsaw" : "okolice-warszawy";
        case CitySlug.WROCLAW:
            return "wroclaw";
        case CitySlug.WROCLAW_AGGLOMERATION:
            return lang === "en" ? "wroclaw-and-suburbs" : "wroclaw-i-okolice";
        case CitySlug.WROCLAW_SUBURBS:
            return lang === "en" ? "suburbs-of-wroclaw" : "okolice-wroclawia";
        case CitySlug.ZIELONA_GORA:
            return "zielona-gora";
        default:
            throw new Error(
                `Passed city slug "${citySlug}" and language "${lang}" combination doesn't map to any known param city slug`
            );
    }
};
