import {useTranslation} from "react-i18next";
import {css} from "@emotion/react";
import {FormikForm} from "@pg-design/formik-utils";
import {calculateRemSize, mb, onDesktop, textAlign, w100} from "@pg-design/helpers-css";
import {Select} from "@pg-design/select";
import {Text} from "@pg-design/text";
import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {dataLayer} from "../../../../../../utils/data_layer";
import {Department, UserType} from "../../../../../../utils/shared_types/user_model";
import {handleFormikSubmit} from "../../../../components/form/handle_submit";
import {SubmitFormikButton} from "../../../../components/SubmitFormikButton";
import {departmentSelectOptions} from "../../../../components/types/department_select_options";
import {userTypeSelectOptions} from "../../../../components/types/user_type_select_options";
import {DataProtectionNotice} from "../../../auth/components/auth_modal/DataProtectionNotice";
import {InquirySource, useInquiryMutation} from "../../../publications/api/add_inquiry";

interface IOrderDedicatedReportFormValues {
    email: string;
    property_notification_consent: boolean;
    first_name: string;
    last_name: string;
    company: string;
    offer_name: string;
    offer_address: string;
    message: string;
    user_type: UserType;
    department: Department;
    phone: string;
}

interface IOrderDedicatedReportFormProps {
    source: InquirySource;
    closeModal: () => void;
}

export const OrderDedicatedReportForm = ({source, closeModal}: IOrderDedicatedReportFormProps) => {
    const {t, i18n} = useTranslation();
    const [addInquiry] = useInquiryMutation({fixedCacheKey: "addInquiry"});
    const isMonitoring = source === InquirySource.MONITORING;
    const initialValues: IOrderDedicatedReportFormValues = {
        email: "",
        property_notification_consent: false,
        first_name: "",
        last_name: "",
        company: "",
        offer_name: "",
        offer_address: "",
        message: "",
        user_type: UserType.OTHER,
        department: Department.BROKER_DEPARTMENT,
        phone: ""
    };

    const validationSchema = Yup.object({
        email: Yup.string().required(t("common.form.required_field")).email("Niepoprawny adres e-mail"),
        property_notification_consent: Yup.boolean()
            .required(t("common.form.required_field"))
            .equals([true], "Zgoda jest wymagana"),
        first_name: Yup.string().required(t("common.form.required_field")),
        last_name: Yup.string().required(t("common.form.required_field")),
        company: Yup.string().required(t("common.form.required_field")),
        offer_name:
            source === InquirySource.MONITORING ? Yup.string().required(t("common.form.required_field")) : Yup.string(),
        offer_address:
            source === InquirySource.MONITORING ? Yup.string().required(t("common.form.required_field")) : Yup.string(),
        message: Yup.string().required(t("common.form.required_field")),
        user_type: Yup.number(),
        department: Yup.string(),
        phone: Yup.string()
            .required(t("common.form.required_field"))
            .matches(/^\d+$/, t("common.form.phone"))
            .length(9, t("common.form.phone_length"))
    });

    const onSubmit = (
        values: IOrderDedicatedReportFormValues,
        helpers: FormikHelpers<IOrderDedicatedReportFormValues>
    ) =>
        handleFormikSubmit(
            addInquiry({
                form_source: source,
                email: values.email,
                property_notification_consent: values.property_notification_consent,
                first_name: values.first_name,
                last_name: values.last_name,
                company: values.company,
                offer_name: values.offer_name,
                offer_address: values.offer_address,
                message: values.message,
                user_type: values.user_type,
                phone: values.phone,
                department:
                    values.user_type === UserType.DEVELOPER || values.user_type === UserType.BROKER
                        ? values.department
                        : undefined
            }).unwrap(),
            helpers,
            {
                onSuccess: () => {
                    closeModal();
                    dataLayer({
                        event: isMonitoring ? "custom_report_form_success" : "dedicate_report_form_success"
                    });
                }
            }
        );

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {({values, handleBlur, isSubmitting, isValid, setFieldValue}) => (
                    <Form>
                        <div css={formContent}>
                            <div css={leftInputs}>
                                <FormikForm.Input
                                    onBlur={handleBlur}
                                    css={mb(1.5)}
                                    name="first_name"
                                    placeholder={t("common.name")}
                                    required
                                />
                                <FormikForm.Input
                                    onBlur={handleBlur}
                                    css={mb(1.5)}
                                    name="last_name"
                                    placeholder={t("common.last_name")}
                                    required
                                />
                                <FormikForm.Input
                                    onBlur={handleBlur}
                                    css={mb(1.5)}
                                    name="company"
                                    placeholder={t("inquiry.form.company")}
                                    required
                                />
                                <Select
                                    css={mb(1.5)}
                                    placeholder={t("common.who_are_you")}
                                    onChange={(e) => {
                                        const newUserType = e as {label: string; value: UserType};
                                        setFieldValue("user_type", newUserType.value);
                                    }}
                                    onBlur={handleBlur}
                                    name="user_type"
                                    options={userTypeSelectOptions(i18n)}
                                />
                                {(values.user_type === UserType.DEVELOPER || values.user_type === UserType.BROKER) && (
                                    <Select
                                        css={mb(1.5)}
                                        maxMenuHeight={150}
                                        name="department"
                                        placeholder={t("common.function")}
                                        onChange={(e: unknown) => {
                                            const newDeveloperDepartment = e as {
                                                label: string;
                                                value: Department;
                                            };
                                            setFieldValue("department", newDeveloperDepartment.value);
                                        }}
                                        onBlur={handleBlur}
                                        options={departmentSelectOptions(i18n, values.user_type === UserType.DEVELOPER)}
                                    />
                                )}
                                <FormikForm.Input
                                    onBlur={handleBlur}
                                    css={mb(1.5)}
                                    name="phone"
                                    placeholder={t("common.phone")}
                                />

                                <FormikForm.Input
                                    onBlur={handleBlur}
                                    css={mb(1.5)}
                                    name="email"
                                    placeholder="E-mail"
                                    required
                                />
                            </div>

                            <div css={agreementsWrapper}>
                                <FormikForm.Checkbox
                                    onBlur={handleBlur}
                                    css={checkbox}
                                    labelContent={<Text variant="info_txt_1">{t("auth.marketing_consent")}</Text>}
                                    name="property_notification_consent"
                                    checkedMarkColor="var(--green-checkbox-checked)"
                                    id="property_notification_consent"
                                />

                                <DataProtectionNotice css={dataProtection} />
                            </div>

                            <div css={rightInputs}>
                                {source === InquirySource.MONITORING && (
                                    <>
                                        <FormikForm.Input
                                            onBlur={handleBlur}
                                            css={mb(1.5)}
                                            name="offer_name"
                                            placeholder={t("inquiry.form.offer_name")}
                                            required
                                        />

                                        <FormikForm.Input
                                            onBlur={handleBlur}
                                            css={mb(1.5)}
                                            name="offer_address"
                                            placeholder={t("inquiry.form.offer_address")}
                                            required
                                        />
                                    </>
                                )}

                                <FormikForm.Textarea
                                    onBlur={handleBlur}
                                    css={mb(2)}
                                    name="message"
                                    rows={source === InquirySource.PUBLICATIONS ? 16 : 14}
                                    placeholder={
                                        source === InquirySource.MONITORING
                                            ? t("inquiry.form.description")
                                            : "Jaki rodzaj raportu chciałbyś otrzymać?\n" +
                                              "Jakie dane Cię interesują?\n"
                                    }
                                    required
                                />
                            </div>
                        </div>

                        <div css={textAlign("center")}>
                            <SubmitFormikButton
                                copy={t("inquiry.form.send")}
                                disabled={isSubmitting || !isValid}
                                css={button}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const formContent = css`
    display: grid;
    column-gap: ${calculateRemSize(3)};
    grid-template-columns: auto;
    grid-template-areas: "left" "right" "agreements";

    ${onDesktop(css`
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "left right"
            "agreements right";
    `)};
`;

const leftInputs = css`
    grid-area: left;
`;

const rightInputs = css`
    grid-area: right;
`;

const agreementsWrapper = css`
    grid-area: agreements;
    ${mb(3)};
`;

const checkbox = css`
    align-items: flex-start;
    ${mb(1.5)};

    ${onDesktop(css`
        ${mb(3)};
    `)};
`;

const dataProtection = css`
    margin-left: 2.8rem;
`;

const button = css`
    ${w100};

    ${onDesktop(css`
        width: 44rem;
    `)};
`;
