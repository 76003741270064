import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {borderRadius, calculateRemSize, flex, flexAlignCenter, mr, ph, pl, pr, pv} from "@pg-design/helpers-css";
import {ChevronRightIcon, UserIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {dataLayer} from "../../../../../utils/data_layer";
import {useAppDispatch, useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {useIsMobile} from "../../../../../utils/hooks/useIsMobile";
import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {Content, DropdownLink} from "../../../components/header/navigation/components/dropdown_link/DropdownLink";
import {StyledIconWrapper} from "../../../components/StyledIconWrapper";
import {selectAuthStatus, setAuthModal} from "../../auth/auth_slice";

interface IUserAccountButtonsProps {
    onMobileClick?: () => void;
}

export const UserAccountButtons = ({onMobileClick}: IUserAccountButtonsProps) => {
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const {i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const isMobile = useIsMobile(false, 1365);

    const handleLoginButtonClick = () => {
        dataLayer({
            event: "login_modal_view",
            position: "right_top_button"
        });
        dispatch(setAuthModal("login"));
    };
    const handleRegisterButtonClick = () => {
        dataLayer({
            event: "register_modal_view",
            position: "right_top_button"
        });
        dispatch(setAuthModal("registerUser"));
    };

    if (isLoggedIn) {
        return (
            <div css={loggedInButtonsWrapper} onClick={() => isMobile && onMobileClick && onMobileClick()}>
                <div css={menu}>
                    {!isMobile ? (
                        <DropdownLink to={chartsRoutes[i18n.language].user.edit} content={Content.Account} />
                    ) : (
                        <span css={mobileUserIcon}>
                            <UserIcon css={mr()} size="1.5" />
                            <Text css={label} variant="info_txt_1">
                                {t("nav.account")}
                            </Text>
                        </span>
                    )}
                </div>

                <StyledIconWrapper css={icon}>
                    <ChevronRightIcon size="1.5" />
                </StyledIconWrapper>
            </div>
        );
    }

    if (!isLoggedIn) {
        return (
            <>
                <div css={mobileWrapper}>
                    <Button size="x-small" variant="filled_primary" onClick={handleLoginButtonClick}>
                        <Text variant="button_small">{t("auth.login")}</Text>
                    </Button>

                    <Button size="x-small" variant="outlined_secondary" onClick={handleRegisterButtonClick}>
                        <Text variant="button_medium">{t("auth.register")}</Text>
                    </Button>
                </div>

                <div css={desktopWrapper}>
                    <Button css={button} size="x-small" variant="filled_primary" onClick={handleLoginButtonClick}>
                        <Text variant="button_small">{t("auth.login")}</Text>
                    </Button>

                    <Button
                        css={button}
                        size="x-small"
                        variant="outlined_secondary"
                        onClick={handleRegisterButtonClick}
                    >
                        <Text variant="button_medium">{t("auth.register")}</Text>
                    </Button>
                </div>
            </>
        );
    }

    return null;
};

const icon = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.lg}) {
        display: none;
    }
`;

const menu = (theme: Theme) => css`
    ${pl(2)};
    ${flexAlignCenter};
    gap: 1rem;
    cursor: pointer;
    transition: var(--transition);
    ${borderRadius(0.5)};

    .label {
        display: inline;
        user-select: none;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        ${pv(0.5)};
        ${ph()}

        &:hover {
            background: hsl(0, 0%, 97%);
        }
    }
`;

const mobileUserIcon = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.lg}) {
        display: none;
    }
`;

const label = (theme: Theme) => css`
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
    display: inline;
    user-select: none;
    white-space: nowrap;

    @media (min-width: ${theme.breakpoints.lg}) {
        display: none;
    }
`;

const mobileWrapper = (theme: Theme) => css`
    ${pv(3)};
    ${ph(2)};
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 1.6rem;

    @media (min-width: ${theme.breakpoints.lg}) {
        display: none;
    }
`;

const desktopWrapper = (theme: Theme) => css`
    ${pr(2)};
    display: none;
    gap: 1.6rem;

    @media (min-width: ${theme.breakpoints.lg}) {
        display: flex;
    }
`;

const button = css`
    width: ${calculateRemSize(21)};
`;

const loggedInButtonsWrapper = (theme: Theme) => css`
    width: 100%;
    height: 60px;
    ${flex("center", "space-between")};
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.lg}) {
        ${mr(2)};
        width: initial;
        height: initial;
    }
`;
