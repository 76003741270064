import {useEffect, useState} from "react";
import {debounce} from "lodash-es";

export function useWindowWidth(delay = 400) {
    const [width, setWidth] = useState(0);

    useEffect(() => setWidth(window.innerWidth), []);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        const debouncedHandleResize = debounce(handleResize, delay);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    return width;
}
