import React, {ReactNode} from "react";

interface IProps {
    children: ReactNode;
    fallback?: string | ReactNode;
}

interface IState {
    hasError: boolean;
}

export class Error extends React.Component<IProps, IState> {
    state = {hasError: false};

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    render() {
        if (this.state.hasError) {
            return <>{this.props.fallback}</>;
        }

        return this.props.children;
    }
}
