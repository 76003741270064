import {useEffect} from "react";
import {useParams} from "react-router";
import {Col, Container, Row} from "@pg-design/grid";

import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {useGetPublicationByIdQuery, useGetPublicationsQuery} from "../api/get_publications";
import {Hero} from "../components/details/Hero";
import {FeaturedPublications} from "../components/featured_publications/FeaturedPublications";
import {PublicationsBreadcrumbs} from "../components/PublicationsBreadcrumbs";

export const PublicationDetails = withGTM(() => {
    const {publicationId} = useParams<{publicationId: string}>();
    const {data} = useGetPublicationByIdQuery(publicationId);
    const {data: publications, isLoading, isSuccess, isError, refetch} = useGetPublicationsQuery();

    const featuredPublications = publications?.results.filter((data) => data.id !== +publicationId);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, []);

    return (
        <SimpleContainer>
            <Container>
                <Row>
                    {data && (
                        <>
                            <Col lgOffset={2} lg={14} md={12} sm={4}>
                                <PublicationsBreadcrumbs publicationName={data.name} />
                            </Col>

                            <Hero publication={data} />
                        </>
                    )}
                </Row>

                <Row as={isSuccess ? "ul" : undefined}>
                    <FeaturedPublications
                        data={featuredPublications}
                        isLoading={isLoading}
                        isSuccess={isSuccess}
                        isError={isError}
                        refetch={refetch}
                    />
                </Row>
            </Container>
        </SimpleContainer>
    );
});
