import {useRouteMatch} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {
    backgroundColor,
    calculateRemSize,
    flex,
    flexAlignCenter,
    flexDirection,
    mb,
    onDesktop,
    p,
    resetWidth,
    w100,
    zIndex
} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {CitySlug, getCityOption, mapParamSlugToCitySlug} from "../../../../config/cities";
import {RegionType} from "../../../../config/regions";
import {useAppSelector} from "../../../../utils/hooks/store_hooks";
import {AccessStatus, useAccessStatus} from "../../../../utils/hooks/useAccessStatus";
import {usePageTitle} from "../../../../utils/hooks/usePageTitle";
import {ICityParams} from "../../common/app/routing/charts_routes";
import {selectLatestEntry} from "../../features/ssr/redux/latest_entry_slice";
import {CitySelect} from "./components/CitySelect";
import {LimitedAccessBar, PreliminaryDataBar} from "./components/InfoBar";
import {PropertyTypeFilter} from "./components/PropertyTypeFilter";
import {RegionTypeSwitch} from "./components/RegionTypeSwitch";

interface IPageHeaderProps {
    visible?: boolean; // should PageHeader be displayed in layout?
    onCitySelection?: (city: CitySlug) => void; // city selection event handler
    onRegionChange?: (region: RegionType) => void; // region selection event handler
}

export const PageHeader = ({visible, onCitySelection, onRegionChange}: IPageHeaderProps) => {
    // stick PageHeader to the top of the screen after crossing certain vertical threshold
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();
    const citySlug = mapParamSlugToCitySlug(city);
    const pageTitle = usePageTitle({});
    const accessStatus = useAccessStatus();
    const showRegionTypeSwitch = !city || getCityOption(citySlug).enableRegionGroup;
    const {completeData} = useAppSelector(selectLatestEntry);

    if (visible) {
        return (
            <header css={pageHeader}>
                <Text css={[mb(3), onDesktop(mb(0))]} as="h1" variant="headline_4">
                    {pageTitle}
                </Text>

                <div css={headerElements}>
                    <PropertyTypeFilter />
                    {showRegionTypeSwitch && <RegionTypeSwitch onRegionTypeChange={onRegionChange} />}
                    <CitySelect onCitySelection={onCitySelection} />
                </div>

                {/*(Mikołaj): Do not render bar if data is undefined*/}
                {accessStatus === AccessStatus.FULL && !completeData && <PreliminaryDataBar />}

                {accessStatus !== AccessStatus.FULL && <LimitedAccessBar />}
            </header>
        );
    }

    return null;
};

const pageHeader = (theme: Theme) => css`
    position: sticky;
    top: 0;
    ${zIndex(90)};
    ${mb(2)};
    ${p(2, 1.5)};
    ${flex("center", "space-between")}
    flex-wrap: wrap;
    gap: ${calculateRemSize(0.5)};
    ${backgroundColor(theme.colors.gray[100])};

    ${onDesktop(css`
        ${p(2, 3)};
    `)};
`;

const headerElements = css`
    ${w100};
    ${flex()};
    ${flexDirection("column")}
    gap: ${calculateRemSize(3)};

    ${onDesktop(css`
        ${flexAlignCenter};
        ${flexDirection("row")};
        ${resetWidth};
    `)}
`;
