const ENV = process.env;
// NODE_ENV - defines production build and production code execution
export const isProduction: boolean = ENV.NODE_ENV === "production";
export const isDevelopment: boolean = ENV.NODE_ENV === "development";
if (!isProduction && !isDevelopment) {
    throw new Error("env: NODE_ENV is not properly defined");
}
// EXEC_ENV - defines execution environment (browser or server)
export const isBrowser: boolean = ENV.EXEC_ENV === "browser";
export const isServer: boolean = ENV.EXEC_ENV === "server";
if (!isBrowser && !isServer) {
    throw new Error("env: EXEC_ENV is not properly defined");
}

declare global {
    interface Window {
        __INITIAL_ENV__: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

const UNIVERSAL_ENV = isBrowser ? window.__INITIAL_ENV__ : process.env;

/**
 * Main variables
 */
// CHARTS_API_URL - defines charts-api server used in charts-web SSR
export const chartsApiUrl: string = (UNIVERSAL_ENV.CHARTS_API_URL ?? null) as string;
if (isServer && chartsApiUrl == null) {
    throw new Error("env: CHARTS_API_URL is not defined for server environment");
}
// RP_WEB_URL - defines RP main service
export const rpWebUrl: string = (UNIVERSAL_ENV.RP_WEB_URL ?? null) as string;
if (rpWebUrl == null) {
    throw new Error("env: RP_WEB_URL is not defined");
}

/**
 * Client variables
 */
// ALGOLYTICS_ID - define algolytics ID used to report events to big data. When null algolytics wont be used
export const algolyticsId: string | null = UNIVERSAL_ENV.ALGOLYTICS_ID ?? null;
// GETRESPONSE_LIST_ID - BigData GetResponse newsletter list id
export const getResponseListId: string = UNIVERSAL_ENV.GETRESPONSE_LIST_ID ?? null;
// GOOGLE_MAPS_API_KEY
export const googleMapsApiKey: string = UNIVERSAL_ENV.GOOGLE_MAPS_API_KEY ?? null;
// GTM_ID - Google Tag Manager ID
export const gtmId: string = UNIVERSAL_ENV.GTM_ID ?? null;
// USER_COM_ID and USER_COM_SCRIPT - When any one is null script won't be used
export const userComId: string | null = UNIVERSAL_ENV.USER_COM_ID ?? null;
export const userComScript: string | null = UNIVERSAL_ENV.USER_COM_SCRIPT ?? null;
// ALBACROSS_ID
export const albacrossId: string | null = UNIVERSAL_ENV.ALBACROSS_ID ?? null;
//BUGSNAG_ENV
export const bugsnagEnv: string = (UNIVERSAL_ENV.BUGSNAG_ENV ?? "development") as string;
//BUGSNAG_WEB_KEY
export const bugsnagWebKey: string = (UNIVERSAL_ENV.BUGSNAG_WEB_KEY ?? null) as string;
if (bugsnagWebKey == null) {
    throw new Error("env: BUGSNAG_WEB_KEY is not defined");
}

/**
 * Browser variables
 */
export const browserInitialEnv = {
    RP_WEB_URL: rpWebUrl,
    ALGOLYTICS_ID: algolyticsId,
    GETRESPONSE_LIST_ID: getResponseListId,
    GOOGLE_MAPS_API_KEY: googleMapsApiKey,
    GTM_ID: gtmId,
    USER_COM_ID: userComId,
    USER_COM_SCRIPT: userComScript,
    ALBACROSS_ID: albacrossId,
    BUGSNAG_ENV: bugsnagEnv,
    BUGSNAG_WEB_KEY: bugsnagWebKey
};
