import {useCallback, useRef, useState} from "react";

import {useTimeFilteredData} from "../../../../../utils/hooks/useTimeFilteredData";
import {Card, ICardProps} from "../../card/Card";
import {RenderProps} from "../../types/render_props";
import {IMultilineChartProps, MultilineChart, RatioMultilineChart} from "../Multiline";
import {TimeRangeFilter} from "./TimeRangeFilter";

interface IMultiLineChartCardProps<D> extends ICardProps, IMultilineChartProps {
    data: D[];
}

interface IMultiLineChartCardPropsWrapper<D> extends IMultiLineChartCardProps<D>, RenderProps {}

const MultilineChartChardWrapper = <D extends {date: string}>(props: IMultiLineChartCardPropsWrapper<D>) => {
    const {area, data} = props;

    const chartRef = useRef(null);

    const [timeRange, setTimeRange] = useState<number>(12);

    const {filteredData, availableRanges} = useTimeFilteredData(data, timeRange);

    const handleTimeRangeChange = useCallback((range: number) => setTimeRange(range), []);

    return (
        <Card
            {...props}
            area={area ?? ""}
            chartRef={chartRef}
            chartPlaceholder="multiline"
            footer={
                <TimeRangeFilter
                    availableRanges={availableRanges}
                    currentRange={timeRange}
                    onRangeChange={handleTimeRangeChange}
                />
            }
        >
            {props.render({filteredData, innerRef: chartRef, ...props})}
        </Card>
    );
};

export const MultilineChartCard = <D extends {date: string}>(props: IMultiLineChartCardProps<D>) => {
    return (
        <MultilineChartChardWrapper
            {...props}
            render={({filteredData, ...renderProps}) => (
                <MultilineChart
                    {...renderProps}
                    categoryField="date"
                    colorSet="alternate"
                    data={filteredData}
                    height={275}
                />
            )}
        />
    );
};

export const RatioMultilineChartCard = <D extends {date: string}>(props: IMultiLineChartCardProps<D>) => {
    return (
        <MultilineChartChardWrapper
            {...props}
            render={({filteredData, ...renderProps}) => (
                <RatioMultilineChart
                    {...renderProps}
                    categoryField="date"
                    colorSet="alternate"
                    data={filteredData}
                    height={275}
                />
            )}
        />
    );
};
