export enum ViewType {
    ADDED_DETAILS = "added_details",
    CONTACT = "contact",
    DASHBOARD = "dashboard", // city dashboard
    DEMAND_DETAILS = "demand_details",
    HOME = "home", // summary homepage
    LANDING = "landing", // welcome landing page
    OFFER_DETAILS = "offer_details",
    PRICE_DETAILS = "price_details",
    PRICING = "pricing", // pricing view
    FAQ = "faq",
    SOLD_DETAILS = "sold_details",
    MONITORING = "monitoring",
    PUBLICATIONS = "publication_section"
}
