import React from "react";
import {ICoreModalProps} from "@pg-design/modal";

import {ZIndex} from "../../../../../common/app/constants/z_index";
import {ResponsiveModal} from "../../../../../components/responsive_modal/ResponsiveModal";
import {ResponsiveModalHeader} from "../../../../../components/responsive_modal/ResponsiveModalHeader";

interface IProps extends ICoreModalProps {
    children: React.ReactNode;
    modalHeading: string;
    onModalClose: () => void;
}

export const MobileOptionsModal = (props: IProps) => {
    return (
        <ResponsiveModal
            zIndex={ZIndex.MODAL}
            isOpen={props.isOpen}
            onModalClose={props.onModalClose}
            showCloseButton={false}
            className={props.className}
        >
            <ResponsiveModalHeader onClose={props.onModalClose} mobileTitle={props.modalHeading} />
            {props.children}
        </ResponsiveModal>
    );
};
