import {chartsRoutes, ICityParams} from "../../../common/app/routing/charts_routes";
import {bigDataNewsAction} from "./actions/big_data_news_action";
import {cityAction} from "./actions/city_action";
import {faqAction} from "./actions/faq_action";
import {homepageAction} from "./actions/homepage_action";
import {landingAction} from "./actions/landing_action";
import {monitoringAction} from "./actions/monitoring_action";
import {newPathRedirectAction} from "./actions/new_path_redirect_action";
import {newshubDetailsAction} from "./actions/newshub_details_action";
import {newshubLandingAction} from "./actions/newshub_landing_action";
import {newshubMarketInformationAction} from "./actions/newshub_market_information_action";
import {publicationsDetailsAction} from "./actions/publication_details_action";
import {publicationsLandingAction} from "./actions/publications_landing_action";
import {publicationsListingAction} from "./actions/publications_listing_action";
import {redirectAnnouncementsToMarketInformationAction} from "./actions/redirect_announcements_to_market_information_action";
import {rpFlatPricesRedirectAction} from "./actions/rp_flat_prices_redirect_action";
import {setAuthAndUser} from "./actions/set_auth_and_user";
import {subpageAction} from "./actions/subpage_action";
import {IActionContext, IPathToActionEntry} from "./create_path_to_action";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PATH_TO_ACTION_MAP: IPathToActionEntry<any>[] = [
    {
        path: [
            chartsRoutes.pl.pricing,
            chartsRoutes.en.pricing,
            chartsRoutes.pl.contact,
            chartsRoutes.en.contact,
            chartsRoutes.pl.user.changePassword,
            chartsRoutes.en.user.changePassword,
            chartsRoutes.pl.user.edit,
            chartsRoutes.en.user.edit,
            chartsRoutes.pl.resetPassword,
            chartsRoutes.en.resetPassword,
            chartsRoutes.pl.user.changePassword,
            chartsRoutes.en.user.changePassword,
            chartsRoutes.pl.user.edit,
            chartsRoutes.en.user.edit,
            chartsRoutes.pl.resetPassword,
            chartsRoutes.en.resetPassword
        ],
        action: async (ctx: IActionContext<ICityParams>) => {
            await setAuthAndUser(ctx.store);
        }
    },
    {
        path: [chartsRoutes.pl.root, chartsRoutes.en.root],
        action: landingAction
    },
    {
        path: [chartsRoutes.pl.faq, chartsRoutes.en.faq],
        action: faqAction
    },
    {
        path: [chartsRoutes.pl.monitoring.root, chartsRoutes.en.monitoring.root],
        action: monitoringAction
    },
    {
        path: [chartsRoutes.pl.flatsRedirect.city.root, chartsRoutes.en.flatsRedirect.city.root],
        action: rpFlatPricesRedirectAction
    },
    {
        path: [
            chartsRoutes.pl.propertiesRedirect.root,
            chartsRoutes.en.propertiesRedirect.root,
            chartsRoutes.pl.propertiesRedirect.city.root,
            chartsRoutes.en.propertiesRedirect.city.root,
            chartsRoutes.pl.propertiesRedirect.city.prices,
            chartsRoutes.en.propertiesRedirect.city.prices,
            chartsRoutes.pl.propertiesRedirect.city.offer,
            chartsRoutes.en.propertiesRedirect.city.offer,
            chartsRoutes.pl.propertiesRedirect.city.demand,
            chartsRoutes.en.propertiesRedirect.city.demand,
            chartsRoutes.pl.propertiesRedirect.city.sold,
            chartsRoutes.en.propertiesRedirect.city.sold,
            chartsRoutes.pl.propertiesRedirect.city.added,
            chartsRoutes.en.propertiesRedirect.city.added
        ],
        action: newPathRedirectAction(
            "ceny-nieruchomosci",
            "properties-prices",
            "ceny/nieruchomosci",
            "prices/properties"
        )
    },
    {
        path: [
            chartsRoutes.pl.flatsRedirect.root,
            chartsRoutes.en.flatsRedirect.root,
            chartsRoutes.pl.flatsRedirect.city.root,
            chartsRoutes.en.flatsRedirect.city.root,
            chartsRoutes.pl.flatsRedirect.city.prices,
            chartsRoutes.en.flatsRedirect.city.prices,
            chartsRoutes.pl.flatsRedirect.city.offer,
            chartsRoutes.en.flatsRedirect.city.offer,
            chartsRoutes.pl.flatsRedirect.city.demand,
            chartsRoutes.en.flatsRedirect.city.demand,
            chartsRoutes.pl.flatsRedirect.city.sold,
            chartsRoutes.en.flatsRedirect.city.sold,
            chartsRoutes.pl.flatsRedirect.city.added,
            chartsRoutes.en.flatsRedirect.city.added
        ],
        action: newPathRedirectAction("ceny-mieszkan", "flats-prices", "ceny/mieszkania", "prices/flats")
    },
    {
        path: [
            chartsRoutes.pl.housesRedirect.root,
            chartsRoutes.en.housesRedirect.root,
            chartsRoutes.pl.housesRedirect.city.root,
            chartsRoutes.en.housesRedirect.city.root
        ],
        action: newPathRedirectAction("ceny-domow", "houses-prices", "ceny/domy", "prices/houses")
    },
    {
        path: [
            chartsRoutes.pl.properties.root,
            chartsRoutes.en.properties.root,
            chartsRoutes.pl.flats.root,
            chartsRoutes.en.flats.root,
            chartsRoutes.pl.houses.root,
            chartsRoutes.en.houses.root
        ],
        action: homepageAction
    },
    {
        path: [
            chartsRoutes.pl.properties.city.root,
            chartsRoutes.en.properties.city.root,
            chartsRoutes.pl.flats.city.root,
            chartsRoutes.en.flats.city.root,
            chartsRoutes.pl.houses.city.root,
            chartsRoutes.en.houses.city.root
        ],
        action: cityAction
    },
    {
        path: [
            chartsRoutes.pl.properties.city.prices,
            chartsRoutes.en.properties.city.prices,
            chartsRoutes.pl.properties.city.offer,
            chartsRoutes.en.properties.city.offer,
            chartsRoutes.pl.properties.city.demand,
            chartsRoutes.en.properties.city.demand,
            chartsRoutes.pl.properties.city.sold,
            chartsRoutes.en.properties.city.sold,
            chartsRoutes.pl.properties.city.added,
            chartsRoutes.en.properties.city.added,
            chartsRoutes.pl.flats.city.prices,
            chartsRoutes.en.flats.city.prices,
            chartsRoutes.pl.flats.city.offer,
            chartsRoutes.en.flats.city.offer,
            chartsRoutes.pl.flats.city.demand,
            chartsRoutes.en.flats.city.demand,
            chartsRoutes.pl.flats.city.sold,
            chartsRoutes.en.flats.city.sold,
            chartsRoutes.pl.flats.city.added,
            chartsRoutes.en.flats.city.added
        ],
        action: subpageAction
    },
    {
        path: [chartsRoutes.pl.publications.root, chartsRoutes.en.publications.root],
        action: publicationsLandingAction
    },
    {
        path: [chartsRoutes.pl.publications.listing, chartsRoutes.en.publications.listing],
        action: publicationsListingAction
    },
    {
        path: [chartsRoutes.pl.publications.details, chartsRoutes.en.publications.details],
        action: publicationsDetailsAction
    },
    {
        path: [chartsRoutes.pl.newshub.root, chartsRoutes.en.newshub.root],
        action: newshubLandingAction
    },
    {
        path: [chartsRoutes.pl.newshub.marketInformation.details, chartsRoutes.en.newshub.marketInformation.details],
        action: newshubDetailsAction
    },
    {
        path: [
            chartsRoutes.pl.newshub.marketInformation.root,
            chartsRoutes.pl.newshub.marketInformation.category.root,
            chartsRoutes.pl.newshub.marketInformation.category.subcategory.root,
            chartsRoutes.pl.newshub.marketInformation.category.vendor,
            chartsRoutes.pl.newshub.marketInformation.category.subcategory.vendor
        ],
        action: newshubMarketInformationAction
    },
    {
        path: [
            chartsRoutes.pl.newshub.marketInformation.announcements.root,
            chartsRoutes.pl.newshub.marketInformation.announcements.announcement
        ],
        action: redirectAnnouncementsToMarketInformationAction
    },
    {
        path: [chartsRoutes.pl.newshub.bigDataNews.root, chartsRoutes.en.newshub.bigDataNews.root],
        action: bigDataNewsAction
    },
    {
        path: [chartsRoutes.pl.newshub.bigDataNews.details, chartsRoutes.en.newshub.bigDataNews.details],
        action: newshubDetailsAction
    }
];
