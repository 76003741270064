import {debounce} from "lodash-es";

import {IUserModel} from "../../../../../utils/shared_types/user_model";
import {userComId} from "../../../common/app/read_charts_web_environment_variables";
import {getPayloadFromData} from "./get_payload_for_user_com";
import {isUserComScriptInitialized} from "./init_user_com";

// page view
let firstPageviewMissed = false;
export const pageviewUserComHit = () =>
    firstPageviewMissed ? pageviewUserComInnerHit() : (firstPageviewMissed = true);
const pageviewUserComInnerHit = debounce(() => {
    if (!isUserComScriptInitialized) {
        return;
    }
    window.UE.pageHit({apiKey: userComId!}); // default user-data is provided by initial `civchat` hit or login (pageview) hit
}, 500) as () => void;

// login
export const loginUserComHit = (userData: IUserModel) => {
    if (!isUserComScriptInitialized) {
        return;
    }
    // `client.update` does not update script's context; thus the following pageviews do not receive default user-data
    // instead we use page-hit with full data payload, so next pageviews have default user-data
    // window.userengage("client.update", getPayloadFromData(userData)); // does not update script's context
    window.UE.pageHit({apiKey: userComId!, ...getPayloadFromData(userData)}); // updates script's context for future hits
};

// register
export const registerUserComHit = (userData: IUserModel) => {
    if (!isUserComScriptInitialized) {
        return;
    }
    window.UE.pageHit({apiKey: userComId!, ...getPayloadFromData(userData)}); // updates script's context for future hits
};

// logout
export const logoutUserComHit = () => {
    if (!isUserComScriptInitialized) {
        return;
    }
    window.UE.resetAuth({apiKey: userComId!});
};
