import {createSlice} from "@reduxjs/toolkit";

import {IRootState} from "../../../store/store";

interface IOnboardingSlice {
    isOnboardingOpen: boolean;
}

const initialState: IOnboardingSlice = {
    isOnboardingOpen: false
};

export const onboardingSlice = createSlice({
    name: "onboarding",
    initialState,
    reducers: {
        toggleOnboarding: (state, action) => {
            state.isOnboardingOpen = action.payload;
        }
    }
});

export const {toggleOnboarding} = onboardingSlice.actions;

export const selectOnboardingState = (state: IRootState) => state.onboarding.isOnboardingOpen;
