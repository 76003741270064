import {css} from "@emotion/react";

export enum Languages {
    Polish = "Polski",
    English = "English"
}

interface IFlagProps {
    language: Languages;
    onClick: () => void;
}

export const Flag = ({language, onClick}: IFlagProps) => {
    return (
        <svg css={flagWrapper} onClick={onClick}>
            {language === Languages.English ? (
                <>
                    <rect
                        x="0.25"
                        y="0.25"
                        width="27.5"
                        height="19.5"
                        rx="1.75"
                        fill="white"
                        stroke="#D3D3D5"
                        strokeWidth="0.5"
                    />
                    <mask id="mask0_502_31500" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
                        <rect
                            x="0.25"
                            y="0.25"
                            width="27.5"
                            height="19.5"
                            rx="1.75"
                            fill="white"
                            stroke="white"
                            strokeWidth="0.5"
                        />
                    </mask>
                    <g mask="url(#mask0_502_31500)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 20H28V9.33331H0V20Z" fill="#EB2A50" />
                    </g>
                </>
            ) : (
                <>
                    <rect width="28" height="20" rx="2" fill="white" />
                    <mask id="mask0_502_31487" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
                        <rect width="28" height="20" rx="2" fill="white" />
                    </mask>
                    <g mask="url(#mask0_502_31487)">
                        <rect width="28" height="20" fill="#0A17A7" />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M-1.28226 -1.9165L10.6669 6.14329V-1.33339H17.3335V6.14329L29.2827 -1.9165L30.7739 0.294263L21.3265 6.66661H28.0002V13.3333H21.3265L30.7739 19.7056L29.2827 21.9164L17.3335 13.8566V21.3333H10.6669V13.8566L-1.28226 21.9164L-2.77344 19.7056L6.67395 13.3333H0.000212669V6.66661H6.67395L-2.77344 0.294263L-1.28226 -1.9165Z"
                            fill="white"
                        />
                        <path
                            d="M18.668 6.33219L31.3333 -2"
                            stroke="#DB1F35"
                            strokeWidth="0.666667"
                            strokeLinecap="round"
                        />
                        <path
                            d="M20.0127 13.6975L31.3665 21.3503"
                            stroke="#DB1F35"
                            strokeWidth="0.666667"
                            strokeLinecap="round"
                        />
                        <path
                            d="M8.00562 6.31055L-3.8374 -1.6709"
                            stroke="#DB1F35"
                            strokeWidth="0.666667"
                            strokeLinecap="round"
                        />
                        <path
                            d="M9.29012 13.605L-3.8374 22.3105"
                            stroke="#DB1F35"
                            strokeWidth="0.666667"
                            strokeLinecap="round"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 12H12V20H16V12H28V8H16V0H12V8H0V12Z"
                            fill="#E6273E"
                        />
                    </g>
                </>
            )}
        </svg>
    );
};

const flagWrapper = css`
    width: 30px;
    aspect-ratio: 4/3;
    cursor: pointer;
`;
