import fetch from "cross-fetch";

import {chartsApiPath} from "../../../../../../api/src/constants/api_path";
import {chartsApiUrl} from "../../../../common/app/read_charts_web_environment_variables";

export const fetchLastAvailableEntry = async (): Promise<{currentDate: string; completeData: boolean}> => {
    const apiUrl = chartsApiUrl ?? "";
    const fetchDateUrl = apiUrl + chartsApiPath.currentDate;
    const lastAvailableDate = await fetch(fetchDateUrl);
    const {date, complete_data} = await lastAvailableDate.json();
    if (!date) {
        throw new Error(`fetchLastAvailableDate: cannot find date in URL: ${fetchDateUrl}`);
    }
    return {currentDate: date, completeData: complete_data};
};
