import {useTheme} from "@emotion/react";
import {flex, flexDirection, mb, position, textAlign} from "@pg-design/helpers-css";
import {LockOutlineIcon} from "@pg-design/icons";
import i18n from "i18next";

import {getLockerLabel} from "../../../../../../utils/get_locker_label";
import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {selectAuthStatus} from "../../../auth/auth_slice";
import {selectUserData} from "../../../users/users_slice";

interface IProps {
    isComment?: boolean;
}

export const Lock = ({isComment}: IProps) => {
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const userData = useAppSelector(selectUserData);
    const theme = useTheme();

    return (
        <span css={[flex(), position("absolute"), flexDirection("column"), textAlign("center")]}>
            <div>
                <LockOutlineIcon
                    size="13"
                    wrapperSize="13"
                    fill={isComment ? theme.colors.secondary : "#fff"}
                    css={mb(8)}
                />
            </div>
            <span>{getLockerLabel(i18n, userData, isLoggedIn)}</span>
        </span>
    );
};
