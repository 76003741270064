import {css} from "@emotion/react";
import {prettyScroll} from "@pg-design/helpers-css";

export const showOnHoverScroll = (scrollbarTrackBg?: string, scrollbarThumbBg?: string) => css`
    ${prettyScroll(scrollbarTrackBg, scrollbarThumbBg)};

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Show scrollbar on hover */
    &:hover {
        -ms-overflow-style: none; /* IE and Edge */
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
        }
    }

    /* Fade scrollbar out when leaving */
    &:not(:hover) {
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }
`;
