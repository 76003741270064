import {Trans, useTranslation} from "react-i18next";
import {useHistory, useRouteMatch} from "react-router-dom";

import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {
    CitySlug,
    getCityOption,
    mapCitySlugToLocalizedParamSlug,
    mapParamSlugToCitySlug,
    validateOfferType
} from "../../../../../config/cities";
import {getRelatedCitySlug, mapCitySlugToRegionType, RegionType} from "../../../../../config/regions";
import {getRecentQuartersNames, lastFinishedQuarter, originDate} from "../../../../../utils/dates";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {useCityPermission} from "../../../../../utils/hooks/useCityPermission";
import {chartsRoutes, compilePath, ICityParams} from "../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, mapPathnameToOfferType, OfferType} from "../../../common/app/routing/offer_type";
import {DistrictsMapChartCard} from "../../../components/charts/chart_cards/DistrictsMapChartCard";
import {LineColumnChartCard} from "../../../components/charts/chart_cards/LineColumnChartCard";
import {MultilineChartCard} from "../../../components/charts/chart_cards/MultilineChartCard";
import {PieBarChartCard} from "../../../components/charts/chart_cards/PieBarChartCard";
import {Grid} from "../../../components/Grid";
import {withDateGuard} from "../../../components/hoc/withDateGuard";
import {withGTM} from "../../../components/hoc/withGTM";
import {ChartsLayout as Layout} from "../../../components/layout/charts_layout/ChartsLayout";
import {selectAuthStatus} from "../../auth/auth_slice";
import {selectLatestEntry} from "../../ssr/redux/latest_entry_slice";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {pageviewUserComHit} from "../../tracking/user_com/hit_user_com";
import {ViewType} from "../../tracking/view_type";
import {
    useGetMonthCityStatsQuery,
    useGetMonthCityTypeStatsQuery,
    useGetMonthDistrictStatsQuery,
    useGetQuarterCityStatsQuery,
    useGetQuarterCityTypeStatsQuery
} from "../api/analytical_platform_api";
import {mapRtkQueryStatusToRequestStatus} from "../utils/rtq_status_to_request_status";

export const Prices = withDateGuard(
    withGTM(() => {
        const {
            path,
            params: {city}
        } = useRouteMatch<ICityParams>();
        const citySlug = mapParamSlugToCitySlug(city);
        const regionType = mapCitySlugToRegionType(citySlug);
        usePageview(() => {
            pageviewAlgolyticsHit({citySlug, regionType, viewType: ViewType.PRICE_DETAILS});
            pageviewUserComHit();
        });
        const checkOfferType = mapPathnameToOfferType(path);
        const isFlatOfferType = checkOfferType === OfferType.FLAT;
        const isPermitted = useCityPermission(city);
        const history = useHistory();
        const {t, i18n} = useTranslation();
        const {currentDate} = useAppSelector(selectLatestEntry);
        const lastMonth = currentDate ?? "";
        const lastQuarter = lastFinishedQuarter(lastMonth);
        const isAgglomerationView = getCityOption(citySlug).useAgglomerationApi;
        const {isLoggedIn, isAuthorized} = useAppSelector(selectAuthStatus);
        const shouldFetch = isLoggedIn && isAuthorized;

        const requestSlugKey = isAgglomerationView ? "slug_agglomeration" : "slug_city";
        const requestCitySlug = getCityOption(citySlug).requestCitySlug;

        const {
            data = [],
            isError,
            isSuccess
        } = useGetMonthCityStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                scenario: "prices"
            },
            {skip: !shouldFetch}
        );

        const {
            data: typeData = [],
            isError: isTypeDataError,
            isSuccess: isTypeDataSuccess
        } = useGetMonthCityTypeStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                offer_type: "Mieszkania",
                scenario: "prices"
            },
            {skip: !shouldFetch}
        );

        const {
            data: quarterData = [],
            isError: isQuarterDataError,
            isSuccess: isQuarterDataSuccess
        } = useGetQuarterCityStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: lastQuarter,
                date_end: lastQuarter,
                scenario: "prices"
            },
            {skip: !shouldFetch}
        );

        const {
            data: quarterTypeData = [],
            isError: isQuarterTypeDataError,
            isSuccess: isQuarterTypeDataSuccess
        } = useGetQuarterCityTypeStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: lastQuarter,
                date_end: lastQuarter,
                scenario: "prices",
                ...(isFlatOfferType ? {offer_type: "Mieszkania"} : {})
            },
            {skip: !shouldFetch || isAgglomerationView}
        );

        const {
            data: monthDistrictData = [],
            isError: isMonthDistrictDataError,
            isSuccess: isMonthDistrictDataSuccess
        } = useGetMonthDistrictStatsQuery(
            {
                slug_city: citySlug,
                date_start: lastMonth,
                date_end: lastMonth,
                scenario: "prices",
                ...(isFlatOfferType ? {offer_type: "Mieszkania"} : {})
            },
            {
                skip: !shouldFetch || isAgglomerationView
            }
        );

        const dataStatus = mapRtkQueryStatusToRequestStatus(isError, isSuccess);
        const typeDataStatus = mapRtkQueryStatusToRequestStatus(isTypeDataError, isTypeDataSuccess);
        const monthDistrictStatus = mapRtkQueryStatusToRequestStatus(
            isMonthDistrictDataError,
            isMonthDistrictDataSuccess
        );
        const quarterStatus = mapRtkQueryStatusToRequestStatus(isQuarterDataError, isQuarterDataSuccess);

        const monthData = Array.isArray(data) && data.length > 0 ? [data[data.length - 1]] : [];
        const flatMonthData = Array.isArray(typeData) && typeData.length > 0 ? [typeData[typeData.length - 1]] : [];
        // display districts map only on cities (not agglomerations) with districts enabled
        const districtsMapAvailable = getCityOption(citySlug).districtsMap && !isAgglomerationView;
        const quarterLabels = getRecentQuartersNames(new Date(lastQuarter), 5);
        quarterLabels.unshift(`${t("common.before")} ${quarterLabels[0]}`);
        const handleCitySelection = (city: CitySlug) => {
            const validOfferType = validateOfferType(city, checkOfferType);
            const targetPath = mapOfferTypeToValidPath(
                validOfferType,
                chartsRoutes[i18n.language].properties.city.prices,
                i18n
            );
            const localizedCityParam = mapCitySlugToLocalizedParamSlug(city, i18n.language);
            const compiledPath = compilePath(targetPath, {city: localizedCityParam});
            history.push(compiledPath);
        };
        const handleRegionChange = (region: RegionType) => {
            const targetCitySlug = getRelatedCitySlug(citySlug, region);
            const paramCitySlug = mapCitySlugToLocalizedParamSlug(targetCitySlug, i18n.language);
            const compiledPath = compilePath(path, {city: paramCitySlug});
            history.push(compiledPath);
        };

        return (
            <Layout onCitySelection={handleCitySelection} onRegionChange={handleRegionChange}>
                <Grid type={districtsMapAvailable ? "subpage" : "subpageMapless"}>
                    <LineColumnChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart1"
                        chartId="prices-chart1"
                        title={<Trans>{t("prices.chart1.title")}</Trans>}
                        helper={<Trans>{t("prices.chart1.description")}</Trans>}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={dataStatus}
                        columnFields={["available_offers", "sold", "added"]}
                        lineField="avg_price_m2"
                        tooltipUnits={{
                            column: " ofert",
                            line: ` ${t("common.pln_m2")}`
                        }}
                        colorSet="legendLike"
                        roundValues
                        nullZeroLineValues
                        locked={!isPermitted}
                    />
                    <MultilineChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart2"
                        chartId="prices-chart2"
                        title={<Trans>{t("prices.chart2.title")}</Trans>}
                        helper={<Trans>{t("prices.chart2.description")}</Trans>}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={dataStatus}
                        fields={[
                            "avg_price_m2_studio",
                            "avg_price_m2_2_rooms",
                            "avg_price_m2_3_rooms",
                            "avg_price_m2_4_plus_rooms",
                            ...(isFlatOfferType ? [] : ["avg_price_m2_house"]),
                            "avg_price_m2"
                        ]}
                        unit={` ${t("common.pln_m2")}`}
                        roundValues
                        nullZeroValues
                        locked={!isPermitted}
                    />
                    <MultilineChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart3"
                        chartId="prices-chart3"
                        title={<Trans>{t("prices.chart3.title")}</Trans>}
                        helper={<Trans>{t("prices.chart3.description")}</Trans>}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={dataStatus}
                        fields={[
                            "avg_price",
                            "avg_price_studio",
                            "avg_price_2_rooms",
                            "avg_price_3_rooms",
                            "avg_price_4_plus_rooms",
                            ...(isFlatOfferType ? [] : ["avg_price_house"])
                        ]}
                        unit={` ${t("common.pln")}`}
                        roundValues
                        nullZeroValues
                        locked={!isPermitted}
                    />
                    <PieBarChartCard
                        area="chart4"
                        chartId="prices-chart4"
                        title={<Trans>{t("prices.chart4.title")}</Trans>}
                        helper={<Trans>{t("prices.chart4.description")}</Trans>}
                        pieData={isFlatOfferType ? flatMonthData : monthData}
                        pieStatus={dataStatus}
                        pieFields={[
                            "available_offers_delivery_deadline_ready",
                            "available_offers_delivery_deadline_in_3_months",
                            "available_offers_delivery_deadline_in_6_months",
                            "available_offers_delivery_deadline_in_1_year",
                            "available_offers_delivery_deadline_in_2_year",
                            "available_offers_delivery_deadline_above_2_years"
                        ]}
                        pieInnerTitle={t(isFlatOfferType ? "prices.number_of_flats" : "prices.number_of_properties")}
                        barData={isFlatOfferType ? flatMonthData : monthData}
                        barStatus={dataStatus}
                        barFields={[
                            "avg_price_m2_delivery_deadline_ready",
                            "avg_price_m2_delivery_deadline_in_3_months",
                            "avg_price_m2_delivery_deadline_in_6_months",
                            "avg_price_m2_delivery_deadline_in_1_year",
                            "avg_price_m2_delivery_deadline_in_2_year",
                            "avg_price_m2_delivery_deadline_above_2_years"
                        ]}
                        barMulticolorSet="alternate"
                        barUnit={` ${t("common.pln_m2")}`}
                        roundValues
                        locked={!isPermitted}
                    />
                    <MultilineChartCard<IMonthCityStats>
                        area="chart5"
                        chartId="prices-chart5"
                        title={<Trans>{t("prices.chart5.title")}</Trans>}
                        helper={<Trans>{t("prices.chart5.description")}</Trans>}
                        data={data}
                        dataStatus={dataStatus}
                        fields={[
                            "avg_price_m2_area_apartments_to_30m2",
                            "avg_price_m2_area_apartments_between_30_and_50m2",
                            "avg_price_m2_area_apartments_between_50_and_70m2",
                            "avg_price_m2_area_apartments_between_70_and_100m2",
                            "avg_price_m2_area_apartments_between_100_and_120m2",
                            "avg_price_m2_area_apartments_above_120m2"
                        ]}
                        unit={` ${t("common.pln_m2")}`}
                        roundValues
                        nullZeroValues
                        locked={!isPermitted}
                    />
                    <MultilineChartCard<IMonthCityStats>
                        area="chart6"
                        chartId="prices-chart6"
                        title={<Trans>{t("prices.chart7.title")}</Trans>}
                        helper={<Trans>{t("prices.chart7.description")}</Trans>}
                        data={data}
                        dataStatus={dataStatus}
                        fields={[
                            "avg_price_m2_investment_type_intimate",
                            "avg_price_m2_investment_type_small",
                            "avg_price_m2_investment_type_medium",
                            "avg_price_m2_investment_type_large",
                            "avg_price_m2_investment_type_x_large",
                            ...(isFlatOfferType ? [] : ["avg_price_m2_investment_type_house"])
                        ]}
                        unit={` ${t("common.pln_m2")}`}
                        roundValues
                        nullZeroValues
                        locked={!isPermitted}
                    />
                    {/*handle one case, we do not have agglomeration data for the type of flat*/}
                    {!(isAgglomerationView && isFlatOfferType) && (
                        <PieBarChartCard
                            area="chart7"
                            chartId="prices-chart7"
                            title={<Trans>{t("prices.chart6.title")}</Trans>}
                            helper={<Trans>{t("prices.chart6.description")}</Trans>}
                            pieData={isFlatOfferType ? quarterTypeData : quarterData}
                            pieStatus={quarterStatus}
                            pieFields={[
                                "available_offers_sale_started_above_4_quarters_ago",
                                "available_offers_sale_started_4_quarters_ago",
                                "available_offers_sale_started_3_quarters_ago",
                                "available_offers_sale_started_2_quarters_ago",
                                "available_offers_sale_started_1_quarter_ago",
                                "available_offers_sale_started_now"
                            ]}
                            pieFieldsLabels={quarterLabels}
                            pieInnerTitle={t(
                                isFlatOfferType ? "prices.number_of_flats" : "prices.number_of_properties"
                            )}
                            barData={isFlatOfferType ? quarterTypeData : quarterData}
                            barStatus={quarterStatus}
                            barFields={[
                                "avg_price_m2_sale_started_above_4_quarters_ago",
                                "avg_price_m2_sale_started_4_quarters_ago",
                                "avg_price_m2_sale_started_3_quarters_ago",
                                "avg_price_m2_sale_started_2_quarters_ago",
                                "avg_price_m2_sale_started_1_quarter_ago",
                                "avg_price_m2_sale_started_now"
                            ]}
                            barUnit={` ${t("common.pln_m2")}`}
                            barMulticolorSet="alternate"
                            barFieldsLabels={quarterLabels}
                            roundValues
                            locked={!isPermitted}
                        />
                    )}
                    <DistrictsMapChartCard
                        chartId="prices-map"
                        title={<Trans>{t("prices.map.title", {city: citySlug})}</Trans>}
                        helper={<Trans>{t("prices.map.description")}</Trans>}
                        visible={districtsMapAvailable}
                        city={citySlug as string}
                        data={monthDistrictData}
                        dataStatus={monthDistrictStatus}
                        valueField="avg_price_m2"
                        unit="pln_m2"
                        legendType="pricing"
                        legendColors={["#F7D2FF", "#D0AEFF", "#9E86FF", "#826AF9", "#310AF5"]}
                        hideNullValues
                        roundValues
                        grayNullValues
                        locked={!isPermitted}
                    />
                </Grid>
            </Layout>
        );
    })
);
