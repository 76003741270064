import {Trans, useTranslation} from "react-i18next";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Badge} from "@pg-design/badge";

import {chartsApiPath} from "../../../../../api/src/constants/api_path";
import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {IMonthGusStats} from "../../../../../api/src/db_queries/month_gus_stats_query";
import {
    CitySlug,
    getCityOption,
    mapCitySlugToLocalizedParamSlug,
    mapParamSlugToCitySlug,
    validateOfferType
} from "../../../../../config/cities";
import {getRelatedCitySlug, mapCitySlugToRegionType, RegionType} from "../../../../../config/regions";
import {getNeighbouringYears, originDate, substractOneYear} from "../../../../../utils/dates";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {useAPIRequest} from "../../../../../utils/hooks/useAPIRequest";
import {useCityPermission} from "../../../../../utils/hooks/useCityPermission";
import {useGusDataWarning} from "../../../../../utils/hooks/useGusDataWarning";
import {capitalize} from "../../../../../utils/misc";
import {chartsRoutes, compilePath, ICityParams} from "../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, mapPathnameToOfferType, OfferType} from "../../../common/app/routing/offer_type";
import {RatioBarChartCard} from "../../../components/charts/chart_cards/BarChartCard";
import {RatioColumnStackedChartCard} from "../../../components/charts/chart_cards/ColumnStackedChartCard";
import {DistrictsMapChartCard} from "../../../components/charts/chart_cards/DistrictsMapChartCard";
import {RatioDoublePieChartCard} from "../../../components/charts/chart_cards/double_pie_chart_card/DoublePieChartCard";
import {LineColumnChartCard} from "../../../components/charts/chart_cards/LineColumnChartCard";
import {MultilineChartCard, RatioMultilineChartCard} from "../../../components/charts/chart_cards/MultilineChartCard";
import {MultilineTypeChartCard} from "../../../components/charts/chart_cards/MultilineTypeChartCard";
import {Grid} from "../../../components/Grid";
import {withDateGuard} from "../../../components/hoc/withDateGuard";
import {withGTM} from "../../../components/hoc/withGTM";
import {ChartsLayout as Layout} from "../../../components/layout/charts_layout/ChartsLayout";
import {selectAuthStatus} from "../../auth/auth_slice";
import {selectLatestEntry} from "../../ssr/redux/latest_entry_slice";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {pageviewUserComHit} from "../../tracking/user_com/hit_user_com";
import {ViewType} from "../../tracking/view_type";
import {
    useGetMonthCityStatsQuery,
    useGetMonthCityTypeStatsQuery,
    useGetMonthDistrictStatsQuery
} from "../api/analytical_platform_api";
import {mapRtkQueryStatusToRequestStatus} from "../utils/rtq_status_to_request_status";

export const Offer = withDateGuard(
    withGTM(() => {
        const {
            path,
            params: {city}
        } = useRouteMatch<ICityParams>();
        const citySlug = mapParamSlugToCitySlug(city);
        const regionType = mapCitySlugToRegionType(citySlug);
        usePageview(() => {
            pageviewAlgolyticsHit({citySlug, regionType, viewType: ViewType.OFFER_DETAILS});
            pageviewUserComHit();
        });
        const checkOfferType = mapPathnameToOfferType(path);
        const isFlatOfferType = checkOfferType === OfferType.FLAT;
        const isPermitted = useCityPermission(city);
        const history = useHistory();
        const {t, i18n} = useTranslation();
        const {currentDate} = useAppSelector(selectLatestEntry);
        const lastMonth = currentDate ?? "";
        const lastYear = substractOneYear(lastMonth, i18n);
        const isAgglomerationView = getCityOption(citySlug).useAgglomerationApi;
        const {isLoggedIn, isAuthorized} = useAppSelector(selectAuthStatus);
        const shouldFetch = isLoggedIn && isAuthorized;

        const requestSlugKey = isAgglomerationView ? "slug_agglomeration" : "slug_city";
        const requestCitySlug = getCityOption(citySlug).requestCitySlug;
        const {
            data = [],
            isError,
            isSuccess
        } = useGetMonthCityStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                scenario: "offer"
            },
            {skip: !shouldFetch}
        );

        const {
            data: typeData = [],
            isError: isTypeDataError,
            isSuccess: isTypeDataSuccess
        } = useGetMonthCityTypeStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                scenario: "offer",
                ...(isFlatOfferType && {offer_type: "Mieszkania"})
            },
            {skip: !shouldFetch}
        );

        const {
            data: monthDistrictData = [],
            isError: isMonthDistrictDataError,
            isSuccess: isMonthDistrictDataSuccess
        } = useGetMonthDistrictStatsQuery(
            {
                slug_city: citySlug,
                date_start: lastMonth,
                date_end: lastMonth,
                scenario: "offer",
                ...(isFlatOfferType ? {offer_type: "Mieszkania"} : {})
            },
            {
                skip: !shouldFetch || isAgglomerationView
            }
        );
        const [gusFinishedStatus, gusFinishedData] = useAPIRequest<IMonthGusStats>(
            chartsApiPath.monthGusStats.finished,
            {
                slug_city: citySlug,
                date_start: lastYear,
                date_end: lastMonth,
                scenario: "offer"
            },
            Boolean(shouldFetch)
        );

        const dataStatus = mapRtkQueryStatusToRequestStatus(isError, isSuccess);
        const typeDataStatus = mapRtkQueryStatusToRequestStatus(isTypeDataError, isTypeDataSuccess);
        const monthDistrictStatus = mapRtkQueryStatusToRequestStatus(
            isMonthDistrictDataError,
            isMonthDistrictDataSuccess
        );

        const monthData = Array.isArray(data) && data.length > 0 ? [data[data.length - 1]] : [];
        const flatMonthData = Array.isArray(typeData) && typeData.length > 0 ? [typeData[typeData.length - 1]] : [];

        const gusFinishedWarningDate = useGusDataWarning(gusFinishedData, lastMonth);
        // display districts map only on cities (not agglomerations) with districts enabled
        const districtsMapAvailable = getCityOption(citySlug).districtsMap && !isAgglomerationView;
        const doublePieChartLabels = getNeighbouringYears(new Date(lastMonth), 5);
        const handleCitySelection = (city: CitySlug) => {
            const validOfferType = validateOfferType(city, checkOfferType);
            const targetPath = mapOfferTypeToValidPath(
                validOfferType,
                chartsRoutes[i18n.language].properties.city.offer,
                i18n
            );
            const localizedCityParam = mapCitySlugToLocalizedParamSlug(city, i18n.language);
            const compiledPath = compilePath(targetPath, {city: localizedCityParam});
            history.push(compiledPath);
        };
        const handleRegionChange = (region: RegionType) => {
            const targetCitySlug = getRelatedCitySlug(citySlug, region);
            const paramCitySlug = mapCitySlugToLocalizedParamSlug(targetCitySlug, i18n.language);
            const compiledPath = compilePath(path, {city: paramCitySlug});
            history.push(compiledPath);
        };

        return (
            <Layout onCitySelection={handleCitySelection} onRegionChange={handleRegionChange}>
                <Grid type={districtsMapAvailable ? "subpage" : "subpageMapless"}>
                    <LineColumnChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart1"
                        chartId="offer-chart1"
                        title={
                            <Trans>
                                {t(isFlatOfferType ? "offer.chart1.title_flats" : "offer.chart1.title_properties")}
                            </Trans>
                        }
                        helper={<Trans>{t("offer.chart1.description")}</Trans>}
                        data={isFlatOfferType ? typeData : (data ?? [])}
                        dataStatus={dataStatus}
                        columnFields={["sold", "added"]}
                        lineField="available_offers"
                        lineAxisMinValue={0}
                        locked={!isPermitted}
                    />
                    <RatioMultilineChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart2"
                        chartId="offer-chart2"
                        title={<Trans>{t("offer.chart2.title")}</Trans>}
                        helper={<Trans>{t("offer.chart2.description")}</Trans>}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={dataStatus}
                        fields={[
                            "available_studio",
                            "available_2_rooms",
                            "available_3_rooms",
                            "available_4_plus_rooms",
                            ...(isFlatOfferType ? [] : ["available_house"])
                        ]}
                        unit={` ${t("common.offer", {count: 5})}`}
                        locked={!isPermitted}
                    />
                    <RatioMultilineChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart3"
                        chartId="offer-chart3"
                        title={<Trans>{t("offer.chart3.title")}</Trans>}
                        helper={<Trans>{t("offer.chart3.description")}</Trans>}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={dataStatus}
                        fields={
                            isFlatOfferType
                                ? [
                                      "available_area_to_30m2",
                                      "available_area_between_30_and_50m2",
                                      "available_area_between_50_and_70m2",
                                      "available_area_between_70_and_100m2",
                                      "available_area_between_100_and_120m2",
                                      "available_area_above_120m2"
                                  ]
                                : [
                                      "active_area_to_30m2",
                                      "active_area_between_30_and_50m2",
                                      "active_area_between_50_and_70m2",
                                      "active_area_between_70_and_100m2",
                                      "active_area_between_100_and_120m2",
                                      "active_area_above_120m2"
                                  ]
                        }
                        locked={!isPermitted}
                    />
                    <LineColumnChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart4"
                        chartId="offer-chart4"
                        title={<Trans>{t("offer.chart4.title")}</Trans>}
                        helper={<Trans>{t("offer.chart4.description")}</Trans>}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={dataStatus}
                        columnFields={[]}
                        lineField="percentage_offers_put_into_service"
                        lineAxisMinValue={0}
                        locked={!isPermitted}
                    />
                    {/*  TODO add houses on offertype.estates?*/}
                    <MultilineTypeChartCard
                        area="chart5"
                        chartId="offer-chart5"
                        title={<Trans>{t("offer.chart5.title")}</Trans>}
                        helper={<Trans>{t("offer.chart5.description")}</Trans>}
                        data={typeData}
                        dataStatus={typeDataStatus}
                        fields={["avg_area"]}
                        unit={` ${t("common.m2")}`}
                        locked={!isPermitted}
                        minValue={0}
                        isFlatType={isFlatOfferType}
                    />
                    <RatioDoublePieChartCard<IMonthCityStats>
                        area="chart6"
                        chartId="offer-chart6"
                        title={<Trans>{t("offer.chart6.title")}</Trans>}
                        helper={<Trans>{t("offer.chart6.description")}</Trans>}
                        data={monthData}
                        dataStatus={dataStatus}
                        withSharedLegend
                        chart1InnerTitle={capitalize(t("common.flats.nominative"))}
                        chart1Fields={[
                            "available_apartments_delivery_deadline_before_previous_year",
                            "available_apartments_delivery_deadline_in_previous_year",
                            "available_apartments_delivery_deadline_in_current_year",
                            "available_apartments_delivery_deadline_in_next_year",
                            "available_apartments_delivery_deadline_after_next_year"
                        ]}
                        chart1TooltipLabel="flat"
                        chart2InnerTitle={capitalize(t("common.houses.nominative"))}
                        chart2Fields={[
                            "available_houses_delivery_deadline_before_previous_year",
                            "available_houses_delivery_deadline_in_previous_year",
                            "available_houses_delivery_deadline_in_current_year",
                            "available_houses_delivery_deadline_in_next_year",
                            "available_houses_delivery_deadline_after_next_year"
                        ]}
                        chart2TooltipLabel="house"
                        sharedLegendLabels={doublePieChartLabels}
                        fieldsLabels={doublePieChartLabels}
                        locked={!isPermitted}
                        isFlatOfferType={isFlatOfferType}
                    />
                    <RatioBarChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart7"
                        chartId="offer-chart7"
                        title={<Trans>{t("offer.chart7.title")}</Trans>}
                        helper={<Trans>{t("offer.chart7.description")}</Trans>}
                        data={isFlatOfferType ? flatMonthData : monthData}
                        dataStatus={dataStatus}
                        fields={[
                            "available_offers_to_6k",
                            "available_offers_between_6k_and_7k",
                            "available_offers_between_7k_and_8k",
                            "available_offers_between_8k_and_9k",
                            "available_offers_between_9k_and_10k",
                            "available_offers_between_10k_and_12k",
                            "available_offers_between_12k_and_15k",
                            "available_offers_between_15k_and_20k",
                            "available_offers_above_20k"
                        ]}
                        height={250}
                        locked={!isPermitted}
                    />
                    <RatioMultilineChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart8"
                        chartId="offer-chart8"
                        title={<Trans>{t("offer.chart8.title")}</Trans>}
                        helper={<Trans>{t("offer.chart8.description")}</Trans>}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={dataStatus}
                        fields={[
                            "available_investment_type_intimate",
                            "available_investment_type_small",
                            "available_investment_type_medium",
                            "available_investment_type_large",
                            "available_investment_type_x_large",
                            ...(isFlatOfferType ? [] : ["available_investment_type_house"])
                        ]}
                        unit={` ${t("common.investment", {count: 5})}`}
                        locked={!isPermitted}
                    />
                    <RatioColumnStackedChartCard
                        area="chart9"
                        chartId="offer-chart9"
                        title={<Trans>{t("offer.chart9.title")}</Trans>}
                        helper={<Trans>{t("offer.chart9.description")}</Trans>}
                        data={isFlatOfferType ? flatMonthData : monthData}
                        dataStatus={dataStatus}
                        categories={[
                            "investment_type_intimate",
                            "investment_type_small",
                            "investment_type_medium",
                            "investment_type_large",
                            "investment_type_x_large",
                            ...(isFlatOfferType ? [] : ["investment_type_house"])
                        ]}
                        fieldGroups={[
                            [
                                "available_investment_type_intimate_offer_studio",
                                "available_investment_type_small_offer_studio",
                                "available_investment_type_medium_offer_studio",
                                "available_investment_type_large_offer_studio",
                                "available_investment_type_x_large_offer_studio",
                                ...(isFlatOfferType ? [] : ["available_houses_to_100m2"])
                            ],
                            [
                                "available_investment_type_intimate_offer_2_rooms",
                                "available_investment_type_small_offer_2_rooms",
                                "available_investment_type_medium_offer_2_rooms",
                                "available_investment_type_large_offer_2_rooms",
                                "available_investment_type_x_large_offer_2_rooms",
                                ...(isFlatOfferType ? [] : ["available_houses_between_100_and_150m2"])
                            ],
                            [
                                "available_investment_type_intimate_offer_3_rooms",
                                "available_investment_type_small_offer_3_rooms",
                                "available_investment_type_medium_offer_3_rooms",
                                "available_investment_type_large_offer_3_rooms",
                                "available_investment_type_x_large_offer_3_rooms",
                                ...(isFlatOfferType ? [] : ["available_houses_between_150_and_200m2"])
                            ],
                            [
                                "available_investment_type_intimate_offer_4_plus_rooms",
                                "available_investment_type_small_offer_4_plus_rooms",
                                "available_investment_type_medium_offer_4_plus_rooms",
                                "available_investment_type_large_offer_4_plus_rooms",
                                "available_investment_type_x_large_offer_4_plus_rooms",
                                ...(isFlatOfferType ? [] : ["available_houses_between_200_and_250m2"])
                            ],
                            ["", "", "", "", "", ...(isFlatOfferType ? [] : ["available_houses_above_250m2"])]
                        ]}
                        minValue={1}
                        locked={!isPermitted}
                    />
                    {isFlatOfferType && (
                        <MultilineChartCard<IMonthGusStats>
                            area="chart10"
                            chartId="offer-chart10"
                            title={<Trans>{t("offer.chart10.title")}</Trans>}
                            helper={<Trans>{t("offer.chart10.description")}</Trans>}
                            afterHeaderContent={
                                gusFinishedWarningDate && (
                                    <Badge variant="label_warning">
                                        {t("common.gus_data_update")} {gusFinishedWarningDate}
                                    </Badge>
                                )
                            }
                            data={gusFinishedData}
                            dataStatus={gusFinishedStatus}
                            fields={["flats_finished"]}
                            minValue={0}
                            locked={!isPermitted}
                        />
                    )}

                    {monthDistrictData && (
                        <DistrictsMapChartCard
                            chartId="offer-map"
                            title={<Trans>{t("offer.map.title", {city: citySlug})}</Trans>}
                            helper={<Trans>{t("offer.map.description")}</Trans>}
                            visible={districtsMapAvailable}
                            city={citySlug as string}
                            data={monthDistrictData}
                            dataStatus={monthDistrictStatus}
                            valueField="available_offers"
                            unit="offer"
                            legendType="quantitative"
                            legendColors={["#C0F2DC", "#A4F7CC", "#60F1C8", "#2CD9C5", "#20AC9C"]}
                            locked={!isPermitted}
                        />
                    )}
                </Grid>
            </Layout>
        );
    })
);
