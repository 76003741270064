import {Options} from "highcharts";

import {RequestStatus} from "../../../common/app/constants/enums";
import {placeholderMonthData} from "../../../common/app/constants/placeholder_charts_data";
import {BarChart} from "../Bar";

export const BarChartPlaceholder = () => (
    <BarChart
        data={placeholderMonthData}
        dataStatus={RequestStatus.SUCCESS}
        colorSet="purple"
        height={225}
        fields={["value1", "value2", "value3", "value4", "value5"]}
        customOptions={options}
    />
);

const options: Options = {
    tooltip: {
        enabled: false
    }
};
