import {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {css} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Container} from "@pg-design/grid";
import {mb, onDesktop, ph, textAlign, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {Header} from "../../../components/header/Header";
import {Footer} from "../../landing/components/footer/Footer";

interface IErrorProps {
    errorHeadline: string;
    children: ReactNode | Array<ReactNode>;
    icon: JSX.Element;
}

export const ErrorLayout = ({children, errorHeadline, icon}: IErrorProps) => {
    const {t, i18n} = useTranslation();

    return (
        <Container as="article" fluid>
            <Header hideAuth />
            <main>
                <Container as="section">
                    {icon}

                    <Text align="center" variant="headline_0" as="h1" css={mb(2)}>
                        {errorHeadline}
                    </Text>

                    {children && (
                        <Text align="center" variant="headline_6" as="p" css={[mb(3), onDesktop(mb(6))]}>
                            {children}
                        </Text>
                    )}

                    <div css={[textAlign("center"), mb(6), onDesktop(mb(8))]}>
                        <Link to={chartsRoutes[i18n.language].root}>
                            <Button size="medium" variant="filled_primary" css={[w100, button]}>
                                {t("common.back_to_homepage")}
                            </Button>
                        </Link>
                    </div>
                </Container>
            </main>
            <Footer />
        </Container>
    );
};

const button = css`
    ${onDesktop(css`
        width: initial;
        ${ph(5)};
    `)};
`;
