import React from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "@pg-design/modal";

import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {UserType} from "../../../../../../utils/shared_types/user_model";
import {salesPerson} from "../../../../common/app/constants/sales_person_data";
import {ContactMessage} from "../../../auth/components/auth_modal/modal_thanks/ContactMessage";
import {selectUserData} from "../../../users/users_slice";

import prPerson from "../../../users/assets/mikolaj_ostrowski.jpg";

interface IRejectedAccessRequestModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const RejectedAccessRequestModal = (props: IRejectedAccessRequestModalProps) => {
    const userData = useAppSelector(selectUserData);
    const {t} = useTranslation();

    return (
        <Modal isOpen={props.isOpen} onModalClose={props.onClose}>
            {(!userData || userData?.user_type !== UserType.JOURNALIST) && (
                <ContactMessage
                    title={t("auth.data_access_verification_submission")}
                    message={t("auth.data_access_verification_submission_explainer")}
                    personPicture={salesPerson.picture}
                    personName={salesPerson.name}
                    personRole={t(`${salesPerson.role}`)}
                    personPhone={salesPerson.phone}
                    personEmail={salesPerson.email}
                />
            )}

            {userData?.user_type === UserType.JOURNALIST && (
                <ContactMessage
                    title={t("auth.data_access_verification_submission")}
                    message={t("auth.data_access_verification_submission_explainer")}
                    personPicture={prPerson}
                    personName="Mikołaj Ostrowski"
                    personRole={t("common.pr_manager")}
                    personPhone="+48 530 619 988"
                    personEmail="pr@rynekpierwotny.pl"
                />
            )}
        </Modal>
    );
};
