import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";

interface IProps {
    availableRanges: number[];
    currentRange: number;
    onRangeChange: (range: number) => void;
}

export const TimeRangeFilter = (props: IProps) => {
    const {availableRanges, currentRange, onRangeChange} = props;

    const {t} = useTranslation();

    if (availableRanges.length > 0) {
        return (
            <StyledFilter>
                <div className="label">{t("common.data_time_range")}:</div>

                {availableRanges.map((range) => (
                    <StyledValue key={range} active={currentRange === range} onClick={() => onRangeChange(range)}>
                        {`${range} ${t("common.month_abbrv", {count: range})}`}
                    </StyledValue>
                ))}
            </StyledFilter>
        );
    }

    return <div />;
};

const StyledFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    .label {
        user-select: none;
        font-weight: 600;
    }
`;

interface IStyledValueProps {
    active: boolean;
}

const StyledValue = styled.span<IStyledValueProps>`
    cursor: pointer;
    transition: var(--transition);
    font-weight: ${(props) => (props.active ? 600 : "normal")};
    &:hover {
        color: ${(props) => props.theme.colors.primary};
    }
`;
