import {useId} from "react";
import {useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import {FormikForm} from "@pg-design/formik-utils";
import {calculateRemSize, flex, flexDirection, mb, mt, onDesktop, ph, textAlign} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";
import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {handleFormikSubmit} from "../../../../components/form/handle_submit";
import {SubmitFormikButton} from "../../../../components/SubmitFormikButton";
import {ApiError} from "../../../../store/IApiError";
import {selectAuthStatus} from "../../../auth/auth_slice";
import {selectUserData} from "../../../users/users_slice";
import {useDownloadFreePublicationMutation} from "../../api/download_publication";

interface IDownloadFreePublicationFormValues {
    first_name: string;
    last_name: string;
    email: string;
    bigdata_marketing_consent: boolean;
}

interface IProps {
    id: number;
}

export const DownloadFreePublicationForm = (props: IProps) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [downloadFreePublication] = useDownloadFreePublicationMutation({
        fixedCacheKey: `orderPublication${props.id}`
    });
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const userData = useAppSelector(selectUserData);
    const hasData = isLoggedIn && !!userData;
    const checkboxId = useId();

    const initialValues = {
        first_name: hasData ? userData.first_name : "",
        last_name: hasData ? userData.last_name : "",
        email: hasData ? userData.email : "",
        bigdata_marketing_consent: hasData
    };

    const validationSchema = Yup.object({
        first_name: Yup.string().required(t("common.form.required_field")),
        last_name: Yup.string().required(t("common.form.required_field")),
        email: Yup.string().required(t("common.form.required_field")).email(t("auth.errors.invalid_email")),
        bigdata_marketing_consent: Yup.boolean().equals([true], t("auth.errors.marketing_consent_is_required"))
    });

    const onSubmit = async (
        {first_name, last_name, email, bigdata_marketing_consent}: IDownloadFreePublicationFormValues,
        helpers: FormikHelpers<IDownloadFreePublicationFormValues>
    ) =>
        handleFormikSubmit(
            downloadFreePublication({
                publication: props.id,
                first_name,
                last_name,
                email_form_value: email,
                bigdata_marketing_consent
            }).unwrap(),
            helpers,
            {
                onError: (err: ApiError) =>
                    err.data.publication && helpers.setFieldError("email", err.data.publication[0])
            }
        );

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({errors, touched, isSubmitting, isValid, handleBlur}) => (
                <Form>
                    <div css={evenFieldWrapper}>
                        <FormikForm.Input
                            onBlur={handleBlur}
                            disabled={!!initialValues.first_name}
                            css={input}
                            name="first_name"
                            placeholder={t("publications.download_publication_modal.name")}
                        />
                        <FormikForm.Input
                            onBlur={handleBlur}
                            disabled={!!initialValues.last_name}
                            css={input}
                            name="last_name"
                            placeholder={t("publications.download_publication_modal.surname")}
                        />
                    </div>

                    <FormikForm.Input
                        onBlur={handleBlur}
                        disabled={!!initialValues.email}
                        css={input}
                        type="email"
                        name="email"
                        placeholder={t("publications.download_publication_modal.email")}
                    />

                    <div css={mb(2)}>
                        <FormikForm.Checkbox
                            onBlur={handleBlur}
                            css={[flex("flex-start")]}
                            name="bigdata_marketing_consent"
                            labelContent={<Text variant="info_txt_1">{t("auth.marketing_consent")}</Text>}
                            id={checkboxId}
                            checkedMarkColor="var(--green-checkbox-checked)"
                        />

                        {errors.bigdata_marketing_consent && touched.bigdata_marketing_consent && (
                            <Text variant="info_txt_2" color={theme.colors.danger} css={mt(1)} align="right">
                                {errors.bigdata_marketing_consent &&
                                    touched.bigdata_marketing_consent &&
                                    errors.bigdata_marketing_consent}
                            </Text>
                        )}
                    </div>

                    <div css={[textAlign("center"), mb(2)]}>
                        <SubmitFormikButton
                            css={ph(5)}
                            copy={t("publications.download_publication_modal.order")}
                            disabled={isSubmitting || !isValid}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

// TODO: those should be global styles for forms and forms fields
const input = css`
    ${mb(1.5)};
    width: 100%;
`;

const evenFieldWrapper = css`
    ${onDesktop(css`
        ${flex("flex-start", "center")}
        ${flexDirection("row")};
        gap: ${calculateRemSize(3)};
    `)};
`;
