import {useTranslation} from "react-i18next";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";

import {FilterOfferType} from "../../web/src/common/app/constants/enums";
import {chartsRoutes, compilePath, ICityParams} from "../../web/src/common/app/routing/charts_routes";
import {mapPathnameToOfferType, OfferType} from "../../web/src/common/app/routing/offer_type";

export const usePropertyTypeFilter = () => {
    const history = useHistory();
    const {pathname} = useLocation();
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();

    const {
        i18n: {language}
    } = useTranslation();

    const activeFilter = (() => {
        switch (true) {
            case pathname.includes(chartsRoutes[language].flats.root): {
                return FilterOfferType.FLATS;
            }
            case pathname.includes(chartsRoutes[language].houses.root): {
                return FilterOfferType.HOUSES;
            }
            default:
                return undefined;
        }
    })();

    function transformPath(pathname: string, language: string) {
        const offerType = mapPathnameToOfferType(pathname);
        const isFlatOfferType = offerType === OfferType.FLAT;
        const detailedViewsRouteChunks = [
            {
                chunk: language === "pl" ? "ceny" : "prices",
                route: isFlatOfferType
                    ? chartsRoutes[language].properties.city.prices
                    : chartsRoutes[language].flats.city.prices
            },
            {
                chunk: language === "pl" ? "oferta" : "offer",
                route: isFlatOfferType
                    ? chartsRoutes[language].properties.city.offer
                    : chartsRoutes[language].flats.city.offer
            },
            {
                chunk: language === "pl" ? "popyt" : "demand",
                route: isFlatOfferType
                    ? chartsRoutes[language].properties.city.demand
                    : chartsRoutes[language].flats.city.demand
            },
            {
                chunk: language === "pl" ? "sprzedane" : "sold",
                route: isFlatOfferType
                    ? chartsRoutes[language].properties.city.sold
                    : chartsRoutes[language].flats.city.sold
            },
            {
                chunk: language === "pl" ? "nowe" : "added",
                route: isFlatOfferType
                    ? chartsRoutes[language].properties.city.added
                    : chartsRoutes[language].flats.city.added
            }
        ];

        const defaultRoute = chartsRoutes[language].flats.city.root;
        const pathnameChunks = pathname.split("/").filter(Boolean); // Remove empty chunks

        if (pathnameChunks.length > 0) {
            const lastChunk = pathnameChunks[pathnameChunks.length - 1];
            const matchingRoute = detailedViewsRouteChunks.find(({chunk}) => lastChunk === chunk);
            if (matchingRoute) {
                return matchingRoute.route;
            } else {
                return "";
            }
        }
        return defaultRoute;
    }

    const filter = (clickedFilter?: FilterOfferType) => {
        const targetPath = () => {
            switch (true) {
                case clickedFilter === FilterOfferType.FLATS && !!city: {
                    return pathname.includes(chartsRoutes[language].houses.root)
                        ? chartsRoutes[language].properties.city.root
                        : chartsRoutes[language].houses.city.root;
                }
                case clickedFilter === FilterOfferType.HOUSES && !!city: {
                    return pathname.includes(chartsRoutes[language].flats.root)
                        ? chartsRoutes[language].properties.city.root
                        : chartsRoutes[language].flats.city.root;
                }
                case !clickedFilter && !!city: {
                    return chartsRoutes[language].properties.city.root;
                }
                case clickedFilter === FilterOfferType.FLATS && !city: {
                    return pathname.includes(chartsRoutes[language].houses.root)
                        ? chartsRoutes[language].properties.root
                        : chartsRoutes[language].houses.root;
                }
                case clickedFilter === FilterOfferType.HOUSES && !city: {
                    return pathname.includes(chartsRoutes[language].flats.root)
                        ? chartsRoutes[language].properties.root
                        : chartsRoutes[language].flats.root;
                }
                case !clickedFilter && !city: {
                    return chartsRoutes[language].properties.root;
                }
                default: {
                    return chartsRoutes[language].properties.root;
                }
            }
        };

        const compiledPath = city ? compilePath(targetPath(), {city}) : targetPath();
        const transformedPath = transformPath(pathname || "", language);

        const path = transformedPath ? compilePath(transformedPath, {city}) : compiledPath;
        history.push(path);
    };

    return {
        activeFilter,
        filter
    };
};
