import {i18n} from "i18next";

import {Department} from "../../../../utils/shared_types/user_model";

export const departmentSelectOptions = (i18n: i18n, isDeveloper?: boolean) =>
    isDeveloper
        ? [
              {
                  label: i18n.t("common.department.board"),
                  value: Department.MANAGEMENT_DEPARTMENT
              },
              {
                  label: i18n.t("common.department.director"),
                  value: Department.DIRECTOR_SALES_DEPARTMENT
              },
              {
                  label: i18n.t("common.department.manager"),
                  value: Department.SALES_DEPARTMENT
              },
              {
                  label: i18n.t("common.department.acquisition"),
                  value: Department.LAND_DEPARTMENT
              },
              {
                  label: i18n.t("common.department.implementation"),
                  value: Department.IMPLEMENTATION_DEPARTMENT
              },
              {
                  label: i18n.t("common.department.analytics"),
                  value: Department.ANALYTICS_DEPARTMENT
              }
          ]
        : [
              {
                  label: i18n.t("common.department.owner"),
                  value: Department.OWNER_ESTATE_DEPARTMENT
              },
              {
                  label: i18n.t("common.department.middle_man"),
                  value: Department.BROKER_DEPARTMENT
              }
          ];
