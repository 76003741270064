import {useMemo} from "react";
import {Link} from "react-router-dom";
import {css} from "@emotion/react";
import {elevation} from "@pg-design/elevation";
import {backgroundColor, borderRadius, flexDirection, mb, mh, onDesktop, p} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {Text} from "@pg-design/text";
import i18n from "i18next";
import {compile} from "path-to-regexp";

import {chartsRoutes, getLocalizedUrl} from "../../../../common/app/routing/charts_routes";
import {truncateMultiline} from "../../../../components/css_helpers/truncate";
import {IEntryCategory} from "../../types/Category";
import {EntryType} from "../../types/Entry";
import {InfoTextBlock} from "../InfoTextBlock";

interface IHighlightedArticleListItemProps {
    category: Array<IEntryCategory> | null;
    id: number;
    images: {
        img_600x300: string;
        img_385x193: string;
    } | null;
    lead: string;
    publication_date: string;
    region_name: string | null;
    slug: string;
    title: string;
    type: EntryType;
    className?: string;
}

export const HighlightedArticleListItem = ({
    title,
    images,
    lead,
    publication_date,
    region_name,
    category,
    id,
    slug,
    type,
    className
}: IHighlightedArticleListItemProps) => {
    const toPath = useMemo(() => {
        if (type === EntryType.ARTICLE) {
            return compile(
                getLocalizedUrl(chartsRoutes[i18n.language].newshub.marketInformation.details, i18n.language)
            );
        } else {
            return compile(getLocalizedUrl(chartsRoutes[i18n.language].newshub.bigDataNews.details, i18n.language));
        }
    }, [i18n.language]);

    return (
        <li className={className} css={articleWrapper}>
            {images && (
                <Link
                    css={picture}
                    to={toPath({
                        ...(category && category[0] ? {marketInfoCategorySlug: category[0].slug} : {}),
                        newsId: String(id),
                        slug
                    })}
                >
                    <Picture
                        sources={[
                            {minWidthPX: 0, src: images.img_385x193, width: 385, height: 193},
                            {minWidthPX: 360, src: images.img_600x300, width: 564, height: 312}
                        ]}
                        alt={title}
                        loading="lazy"
                    />
                </Link>
            )}

            <div css={p(4)}>
                <InfoTextBlock
                    css={mb(2)}
                    publication_date={publication_date}
                    region_name={region_name}
                    category={category}
                />

                <Link
                    to={toPath({
                        ...(category && category[0] ? {marketInfoCategorySlug: category[0].slug} : {}),
                        newsId: String(id),
                        slug
                    })}
                >
                    <Text variant="headline_4" as="h2" css={mb(2)}>
                        {title}
                    </Text>
                </Link>

                <Text variant="body_copy_1" css={leadTextWrapper}>
                    {lead}
                </Text>
            </div>
        </li>
    );
};

const picture = css`
    overflow: hidden;
    flex-shrink: 0;
    display: block;
    ${borderRadius(2, 2, 0, 0)};

    ${onDesktop(css`
        ${borderRadius(2, 0, 0, 2)}
    `)};
`;

const articleWrapper = css`
    ${flexDirection("column")};
    max-width: 38.5rem;
    ${elevation(2)};
    ${borderRadius(2)};
    ${backgroundColor("#fff")};
    ${mh("auto")};

    ${onDesktop(css`
        width: 100%;
        display: flex;
        ${flexDirection("row")};
        overflow: hidden;
        max-width: unset;
    `)}
`;

const leadTextWrapper = css`
    display: none;

    ${onDesktop(css`
        display: block;
        ${truncateMultiline(4)};
    `)}
`;
