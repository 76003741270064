import {IUserModel} from "../../../../../utils/shared_types/user_model";
import {authApi} from "./authApi";

interface ILoginPayload {
    email: string;
    password: string;
}

export interface ILoginValidationErrors {
    non_field_errors: string[];
    email: string[];
    password: string[];
}

export const loginApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<IUserModel, ILoginPayload>({
            query: ({email, password}) => ({
                url: `/users/login/`,
                method: "POST",
                body: {
                    email,
                    password
                }
            })
        })
    }),
    overrideExisting: false
});

export const {useLoginMutation} = loginApi;
