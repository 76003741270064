export const formatThousands = (number: string): string => number.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const formatDecimals = (number: number): string => (Math.round(number * 100) / 100).toFixed(2).replace(".", ",");

export const formatNumber = (number: number | null | undefined, withDecimals = true): string => {
    if (number === null || number === undefined || number < 0.0001 || isNaN(number)) {
        number = 0;
    }

    const stringifiedNumber = number.toString();

    if ((stringifiedNumber.includes(",") || stringifiedNumber.includes(".")) && withDecimals) {
        return formatThousands(formatDecimals(number));
    }

    return formatThousands(number.toFixed(0).toString());
};

export const formatNumberGreaterThan1000 = (number: number): string => {
    if (number >= 1000000) {
        return `${number / 1000000}M`.replace(".", ",");
    }
    if (number >= 1000) {
        return `${number / 1000}k`.replace(".", ",");
    }

    return number.toString();
};
