import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {ph, resetWidth, w100} from "@pg-design/helpers-css";
import {Tabs} from "@pg-design/tabs";

import {useIsMobile} from "../../../../../../utils/hooks/useIsMobile";
import {chartsRoutes} from "../../../../common/app/routing/charts_routes";

export const NewshubTypeSwitch = () => {
    const {i18n} = useTranslation();
    const history = useHistory();
    const {pathname} = useLocation();
    const isMobile = useIsMobile(false, 768);

    return (
        <Tabs
            css={tabs}
            fullWidth={isMobile}
            color="gray"
            onChange={(pathName) => {
                history.push(pathName);
            }}
            tabs={[
                {
                    title: "Informacje rynkowe",
                    value: chartsRoutes[i18n.language].newshub.marketInformation.root
                },
                {
                    title: "Aktualności BIG DATA",
                    value: chartsRoutes[i18n.language].newshub.bigDataNews.root
                }
            ]}
            variant="contained"
            value={pathname}
        />
    );
};

const tabs = (theme: Theme) => css`
    ${w100};

    @media (max-width: ${theme.breakpoints.xs}) {
        // there is no other way to style tabs
        & > div > div > div {
            ${ph(0)};
        }
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        ${resetWidth};
    }
`;
