export enum RequestStatus {
    PENDING = "Pending",
    SUCCESS = "Success",
    ERROR = "Error"
}

export enum FilterOfferType {
    FLATS = "Mieszkania",
    HOUSES = "Domy"
}
