//  This function is meant to handle errors coming from rtk-query, to minify boilerplate code in forms

import {toast} from "react-toastify";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

export function castToFetchBaseQueryError<ErrorData extends object | string = {}>(
    error: unknown,
    unknownErrorHandler?: () => void
) {
    const isFetchBaseQueryError = typeof error === "object" && error && "status" in error;

    const fetchError = isFetchBaseQueryError ? (error as FetchBaseQueryError) : null;

    if (fetchError && typeof fetchError.status === "number" && typeof fetchError.data === "object" && fetchError.data) {
        return {
            status: fetchError.status,
            data: fetchError.data as ErrorData
        };
    } else if (unknownErrorHandler) {
        unknownErrorHandler();
    } else {
        toast.error("Something went wrong, please contact you system administrator");
    }
}
