import {RequestStatus} from "../../../common/app/constants/enums";

export const mapRtkQueryStatusToRequestStatus = (isError: boolean, isSuccess: boolean): RequestStatus => {
    if (isError) {
        return RequestStatus.ERROR;
    } else if (isSuccess) {
        return RequestStatus.SUCCESS;
    } else {
        return RequestStatus.PENDING;
    }
};
