import {isDate} from "lodash-es";

import {ProvincialCity} from "../web/src/features/newshub/api/get_entries";

export const validateNewshubParams = (
    page: string | null,
    vendor: string | null,
    city: string | null,
    date_from: string | null,
    date_to: string | null,
    search: string | null
) => ({
    cityParam:
        city && Object.values(ProvincialCity).includes(city as ProvincialCity) ? (city as ProvincialCity) : undefined,
    vendorParam: vendor && !isNaN(Number(vendor)) ? Number(vendor) : undefined,
    pageParam: !isNaN(Number(page)) && Number(page) > 0 ? Number(page) : 1,
    dateFromParam: date_from && isDate(new Date(date_from)) ? date_from : undefined,
    dateToParam: date_to && isDate(new Date(date_to)) ? date_to : undefined,
    searchParam: search ? search.toString() : undefined
});
