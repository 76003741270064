import {i18n} from "i18next";

import {IMonthCityStats} from "../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../api/src/db_queries/month_city_type_stats_query";
import {calculatePercentageDifference} from "../../../../utils/calculate_percentage_difference";
import {substractOneMonth} from "../../../../utils/dates";
import {formatNumber} from "../../../../utils/format";
import {sortByField} from "../../../../utils/misc";
import {cityPronoun} from "../app/constants/constants";
import {FilterOfferType} from "../app/constants/enums";

export const analystCommentPrices = (
    data: IMonthCityStats[] & IMonthCityTypeStats[],
    currentDate: string,
    offerType: FilterOfferType | null,
    i18n: i18n
): string => {
    // gather data for the month before and current month to be able to compare values
    const lastMonthData = data.filter((city) => city.date === substractOneMonth(currentDate));
    const currentMonthData = data.filter((city) => city.date === currentDate);
    // sort current month's data by field that cities should be sorted
    const sortedCurrentMonthData = sortByField(currentMonthData, "avg_price_m2");
    // define data from last month that will be used for values comparisions
    const firstCityInLastMonth = lastMonthData.find((city) => city.slug_city === sortedCurrentMonthData[0].slug_city);
    const secondCityInLastMonth = lastMonthData.find((city) => city.slug_city === sortedCurrentMonthData[1].slug_city);
    const thirdCityInLastMonth = lastMonthData.find((city) => city.slug_city === sortedCurrentMonthData[2].slug_city);
    const fourthCityInLastMonth = lastMonthData.find((city) => city.slug_city === sortedCurrentMonthData[3].slug_city);
    const fifthCityInLastMonth = lastMonthData.find((city) => city.slug_city === sortedCurrentMonthData[4].slug_city);
    const sixthCityInLastMonth = lastMonthData.find((city) => city.slug_city === sortedCurrentMonthData[5].slug_city);
    // calculate percentage differences
    const firstCityMonthlyChange = calculatePercentageDifference(
        sortedCurrentMonthData[0].avg_price_m2,
        firstCityInLastMonth?.avg_price_m2 as number,
        false
    );
    // const firstCityChangeTrend =
    const secondCityMonthlyChange = calculatePercentageDifference(
        sortedCurrentMonthData[1].avg_price_m2,
        secondCityInLastMonth?.avg_price_m2 as number,
        false
    );
    const thirdCityMonthlyChange = calculatePercentageDifference(
        sortedCurrentMonthData[2].avg_price_m2,
        thirdCityInLastMonth?.avg_price_m2 as number,
        false
    );
    const fourthCityMonthlyChange = calculatePercentageDifference(
        sortedCurrentMonthData[3].avg_price_m2,
        fourthCityInLastMonth?.avg_price_m2 as number,
        false
    );
    const fifthCityMonthlyChange = calculatePercentageDifference(
        sortedCurrentMonthData[4].avg_price_m2,
        fifthCityInLastMonth?.avg_price_m2 as number,
        false
    );
    const sixthCityMonthlyChange = calculatePercentageDifference(
        sortedCurrentMonthData[5].avg_price_m2,
        sixthCityInLastMonth?.avg_price_m2 as number,
        true
    );

    const sixthCityPronoun = cityPronoun[sortedCurrentMonthData[5].slug_city];

    const sixthCityPriceChangeTrend =
        sortedCurrentMonthData[5].avg_price_m2 > (sixthCityInLastMonth?.avg_price_m2 as number) ? "risen" : "fallen";

    const variables = {
        firstCity: sortedCurrentMonthData[0].slug_city,
        firstCityCurrentValue: formatNumber(sortedCurrentMonthData[0].avg_price_m2, false),
        firstCityMonthlyChange,
        firstCityChangeTrend: "",
        secondCity: sortedCurrentMonthData[1].slug_city,
        secondCityCurrentValue: formatNumber(sortedCurrentMonthData[1].avg_price_m2, false),
        secondCityMonthlyChange,
        thirdCity: sortedCurrentMonthData[2].slug_city,
        thirdCityCurrentValue: formatNumber(sortedCurrentMonthData[2].avg_price_m2, false),
        thirdCityMonthlyChange,
        fourthCity: sortedCurrentMonthData[3].slug_city,
        fourthCityCurrentValue: formatNumber(sortedCurrentMonthData[3].avg_price_m2, false),
        fourthCityMonthlyChange,
        fifthCity: sortedCurrentMonthData[4].slug_city,
        fifthCityCurrentValue: formatNumber(sortedCurrentMonthData[4].avg_price_m2, false),
        fifthCityMonthlyChange,
        sixthCity: sortedCurrentMonthData[5].slug_city,
        sixthCityCurrentValue: formatNumber(sortedCurrentMonthData[5].avg_price_m2, false),
        sixthCityMonthlyChange,
        sixthCityPronoun,
        sixthCityPriceChangeTrend
    };

    return i18n?.t("home.analyst_comment_prices", variables) ?? "";
};
