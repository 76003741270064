import {ReactElement, ReactNode, useState} from "react";
import {Config, PopperOptions, usePopperTooltip} from "react-popper-tooltip";
import {SerializedStyles} from "@emotion/react";

import "react-popper-tooltip/dist/styles.css";

interface ITooltipProps {
    isServerSideRendered: boolean;
    onVisibleChange?: (visible: boolean) => void;
    //(Mikołaj): config is described in react-popper-tooltip documentation
    // https://www.npmjs.com/package/react-popper-tooltip
    config: Partial<Config>;
    popperOptions?: PopperOptions;
    interactive?: boolean;
    triggerClassName?: SerializedStyles;
    trigger: ReactElement;
    tooltipClassName?: SerializedStyles;
    children: ReactNode | Array<ReactNode>;
}

export const Tooltip = ({
    isServerSideRendered,
    onVisibleChange,
    config,
    popperOptions,
    triggerClassName,
    trigger,
    tooltipClassName,
    children
}: ITooltipProps) => {
    const [mounted, setMounted] = useState(isServerSideRendered);

    const setMountedOnceVisible = (visible: boolean) => {
        if (!mounted && visible) {
            setMounted(true);
        }
    };

    const {getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible} = usePopperTooltip(
        {
            onVisibleChange: onVisibleChange ?? setMountedOnceVisible,
            trigger: config.trigger ?? "click",
            ...config
        },
        popperOptions
    );

    return (
        <>
            <span css={triggerClassName} ref={setTriggerRef}>
                {trigger}
            </span>
            {mounted && (
                <div
                    css={tooltipClassName}
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: "tooltip-container",
                        style: visible ? {visibility: "visible"} : {visibility: "hidden", pointerEvents: "none"}
                    })}
                >
                    <div {...getArrowProps({className: "tooltip-arrow"})} />
                    {children}
                </div>
            )}
        </>
    );
};
