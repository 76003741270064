import styled from "@emotion/styled";
import {Options} from "highcharts";

import {RequestStatus} from "../../../common/app/constants/enums";
import {placeholderMonthData} from "../../../common/app/constants/placeholder_charts_data";
import {PieChart} from "../Pie";

export const DoublePieChartPlaceholder = () => (
    <StyledDoublePieChartPlaceholder>
        <PieChart
            data={placeholderMonthData}
            dataStatus={RequestStatus.SUCCESS}
            colorSet="alternate"
            height={200}
            fields={["value1", "value2", "value3", "value4", "value5"]}
            customOptions={options}
        />

        <PieChart
            data={placeholderMonthData}
            dataStatus={RequestStatus.SUCCESS}
            colorSet="alternate"
            height={200}
            fields={["value1", "value2", "value3", "value4", "value5"]}
            customOptions={options}
        />
    </StyledDoublePieChartPlaceholder>
);

const StyledDoublePieChartPlaceholder = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > div {
        flex-basis: 50%;
    }
`;

const options: Options = {
    tooltip: {
        enabled: false
    }
};
