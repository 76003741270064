import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useRouteMatch} from "react-router-dom";
import {useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {InfoIcon} from "@pg-design/icons";
import {Loader} from "@pg-design/loader";

import {chartsApiPath} from "../../../../../../api/src/constants/api_path";
import {IMonthCityStats} from "../../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../../api/src/db_queries/month_city_type_stats_query";
import {getCityOption, mapParamSlugToCitySlug} from "../../../../../../config/cities";
import {mapCitySlugToRegionType, RegionType} from "../../../../../../config/regions";
import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {useAccessDate} from "../../../../../../utils/hooks/useAccessDate";
import {useAPIRequest} from "../../../../../../utils/hooks/useAPIRequest";
import {useTypeFilteredData} from "../../../../../../utils/hooks/useTypeFilteredData";
import {sortByField} from "../../../../../../utils/misc";
import {FilterOfferType, RequestStatus} from "../../../../common/app/constants/enums";
import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {rotate} from "../../../../components/css_helpers/rotate";
import {withDateGuard} from "../../../../components/hoc/withDateGuard";
import {selectLatestEntry} from "../../../ssr/redux/latest_entry_slice";
import {BarometerScale as Scale} from "./BarometerScale";

interface IProps {
    offerType?: FilterOfferType;
}

type CityStatsType = IMonthCityStats | IMonthCityTypeStats;

interface IBarometerValues {
    [key: string]: number;
}

const initialValues: IBarometerValues = {
    available_offers: 0,
    sold: 0,
    added: 0
};

export const Barometer = withDateGuard(({offerType}: IProps) => {
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();
    const citySlug = mapParamSlugToCitySlug(city);
    const {t} = useTranslation();
    const theme = useTheme();

    // get current date from initial context for data fetching purposes
    const {currentDate} = useAppSelector(selectLatestEntry);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore withDateGuard makes sure currentDate is not null
    const {upperBound: bound} = useAccessDate(currentDate);

    const isAgglomerationView = getCityOption(citySlug).useAgglomerationApi;
    const regionType = mapCitySlugToRegionType(citySlug);
    const regionSlugKey = isAgglomerationView ? "slug_agglomeration" : "slug_city";
    const endpoint = getBarometerEndpoint(offerType, isAgglomerationView);
    const [status, data] = useAPIRequest(
        endpoint,
        {
            [regionSlugKey]: getCityOption(citySlug).requestCitySlug,
            date_start: bound,
            date_end: bound,
            scenario: "dashboard",
            region_type: isAgglomerationView ? (regionType as RegionType) : undefined
        },
        true
    );

    const filteredData = useTypeFilteredData(data as any[], offerType);

    const benchmark: IBarometerValues = useMemo(() => {
        const values = {...initialValues};
        if (filteredData && filteredData.length > 0) {
            for (const field of Object.keys(initialValues)) {
                const sortedFieldData = sortByField<CityStatsType>(filteredData, field);
                values[field] = Math.round((sortedFieldData[0][field as keyof CityStatsType] as number) * 1.1);
            }
        }
        return values;
    }, [filteredData]);

    const offerTypeKey = offerTypeToTranslationKey(offerType);

    const barometerLabels = {
        available_offers: t("dashboard.barometer.available_offers"),
        sold: t("dashboard.barometer.sold", {offerType: offerTypeKey}),
        added: t("dashboard.barometer.added")
    };

    const tooltipContent: IDictionary = {
        available_offers: t("dashboard.barometer.tooltip.available_offers", {offerType: offerTypeKey}),
        sold: t("dashboard.barometer.tooltip.sold", {offerType: offerTypeKey}),
        added: t("dashboard.barometer.tooltip.added", {offerType: offerTypeKey})
    };

    if (status === RequestStatus.SUCCESS) {
        return (
            <StyledBarometer>
                {Object.entries(barometerLabels).map(([field, name]: [keyof IDictionary, string]) => {
                    const value = filteredData.find(
                        (el) => el[regionSlugKey] === getCityOption(citySlug).requestCitySlug
                    )?.[field];
                    return (
                        <Scale
                            average={benchmark[field]}
                            key={field}
                            name={name}
                            tooltipContent={tooltipContent[field]}
                            value={value}
                        />
                    );
                })}
            </StyledBarometer>
        );
    }

    if (status === RequestStatus.PENDING) {
        return (
            <StyledInfo>
                <Loader size="lg" />
            </StyledInfo>
        );
    }

    if (status === RequestStatus.ERROR) {
        return (
            <StyledInfo>
                <InfoIcon css={rotate()} fill={theme.colors.danger} size="6" />
            </StyledInfo>
        );
    }

    return null;
});

const getBarometerEndpoint = (offerType: FilterOfferType | undefined, isAgglomerationView: boolean): string => {
    if (offerType && isAgglomerationView) {
        return chartsApiPath.monthAgglomerationTypeStats;
    }
    if (offerType && !isAgglomerationView) {
        return chartsApiPath.monthCityTypeStats;
    }
    if (!offerType && isAgglomerationView) {
        return chartsApiPath.monthAgglomerationStats;
    }
    return chartsApiPath.monthCityStats;
};

const offerTypeToTranslationKey = (offerType?: FilterOfferType): string => {
    switch (offerType) {
        case FilterOfferType.FLATS: {
            return "flats";
        }
        case FilterOfferType.HOUSES: {
            return "houses";
        }
        default:
            return "properties";
    }
};

const StyledBarometer = styled.div`
    width: 100%;
    padding: 0 2rem 2rem 2rem;
    height: 175px;
`;

const StyledInfo = styled.div`
    width: 100%;
    padding-bottom: 30px;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
