import {ReactElement, ReactNode, Ref} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {elevation} from "@pg-design/elevation";
import {borderRadius, flex, flexDirection, mb, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {ChartError} from "../charts/ChartError";
import {MethodologyTooltip} from "../MethodologyTooltip";
import {CardLock, ChartPlaceholderType} from "./components/card_lock/CardLock";
import {ExportMenu} from "./components/ExportMenu";

export interface ICardProps {
    area?: string;
    afterHeaderContent?: string | ReactNode;
    className?: string;
    footer?: string | ReactNode;
    helper?: string | ReactNode;
    sticky?: boolean;
    title?: ReactElement;
    chartRef?: Ref<HTMLElement>;
    chartPlaceholder?: ChartPlaceholderType;
    withTextBlock?: boolean;
    locked?: boolean;
    chartId?: string;
    children?: ReactNode | Array<ReactNode>;
}

export const Card = (props: ICardProps) => {
    const {
        area,
        afterHeaderContent,
        chartRef,
        chartPlaceholder,
        children,
        className,
        footer,
        helper,
        sticky,
        title,
        withTextBlock,
        locked
    } = props;

    if (locked) {
        return (
            <StyledCard area={area} className={className ?? ""} sticky={sticky} withPlaceholder>
                <CardLock locked={locked} chartPlaceholder={chartPlaceholder} title={title} />
            </StyledCard>
        );
    }

    return (
        <StyledCard area={area} className={className ?? ""} sticky={sticky}>
            {(title || helper) && (
                <div css={[flex("center", "space-between"), mb(2), p(2, 2, 1, 2)]}>
                    <Text variant="body_copy_1" as="h3">
                        {title || ""}
                    </Text>

                    <MethodologyTooltip content={helper} />
                </div>
            )}

            {afterHeaderContent && <div css={p(0, 2, 2, 2)}>{afterHeaderContent}</div>}

            <ChartError>
                <StyledContent className="card-content" withTextBlock={withTextBlock}>
                    {children}
                </StyledContent>
            </ChartError>

            {(footer || chartRef) && (
                <div css={footerWrapper}>
                    {footer ?? <div>&nbsp;</div>}

                    <ExportMenu chartRef={chartRef} />
                </div>
            )}
        </StyledCard>
    );
};

const StyledCard = styled.div<Pick<ICardProps, "area" | "sticky" | "locked"> & {withPlaceholder?: boolean}>`
    ${flex()};
    ${flexDirection("column")};
    ${borderRadius(2)};
    ${elevation(1)};
    ${mb(2)};
    background: #fff;
    width: 100%;
    overflow: hidden;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${(props) => props.area && "grid-area: " + props.area};
        ${(props) => props.sticky && "position: sticky"};
        top: ${(props) => (props.sticky ? "var(--desktop-header-height)" : 0)};
        height: ${(props) => (props.sticky ? "calc(100vh - var(--desktop-header-height))" : "auto")};
    }
`;

const StyledContent = styled.div<Pick<ICardProps, "withTextBlock">>`
    width: 100%;
    height: 100%;

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        ${(props) =>
            props.withTextBlock &&
            `
            display: grid;
            gap: 2rem;
            grid-template-columns: 70% auto;
        `}
        ${(props) =>
            !props.withTextBlock &&
            `
            display: flex;
            flex-wrap: nowrap;
        `}
    }
`;

const footerWrapper = css`
    ${flex("normal", "space-between")};
    ${p(0, 2, 2, 2)};

    a {
        text-decoration: underline;
    }
`;
