import {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {css, keyframes} from "@emotion/react";
import {backgroundColor, calculateRemSize, mb, mh, p, pointer, zIndex} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {ZIndex} from "../common/app/constants/z_index";
import {Tooltip} from "./Tooltip";

import "react-popper-tooltip/dist/styles.css";

interface IProps {
    content?: string | ReactNode;
}

export const MethodologyTooltip = ({content}: IProps) => {
    const {t} = useTranslation();

    if (content) {
        return (
            <Tooltip
                isServerSideRendered
                config={{trigger: "hover", placement: "top"}}
                triggerClassName={iconWrapper}
                trigger={<InfoIcon size="2.4" />}
                tooltipClassName={contentWrapper}
            >
                <Text variant="info_txt_1" css={[mb(2), text]}>
                    {t("common.methodology")}
                </Text>

                <Text variant="info_txt_1" as="span">
                    {content}
                </Text>
            </Tooltip>
        );
    }

    return null;
};

const text = css`
    text-transform: uppercase;
`;

const contentWrapper = css`
    ${p(2)};
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    ${zIndex(ZIndex.HIGHCHARTS_TOOLTIP)};
    width: ${calculateRemSize(34)};
    ${backgroundColor("#fff")};
`;

const showTooltipAnimation = keyframes`
  from {
      opacity: 0;
  } to {
      opacity: 1;
  }
`;

const iconWrapper = css`
    ${pointer};
    ${mh()};

    :hover + div {
        animation: ${showTooltipAnimation} 0.35s ease 1;
    }
`;
