import {PublicationPricingType} from "../web/src/features/publications/types/Publication";

export function getPublicationAccessStatus(pricing_type: PublicationPricingType) {
    const {FREE_BD_USER, FREE, PAID} = PublicationPricingType;
    return {
        isFreeForBD: pricing_type === FREE_BD_USER,
        isFree: pricing_type === FREE,
        isPaid: pricing_type === PAID
    };
}
