import {useCallback, useRef, useState} from "react";

import {useTimeFilteredData} from "../../../../../utils/hooks/useTimeFilteredData";
import {Card, ICardProps} from "../../card/Card";
import {RenderProps} from "../../types/render_props";
import {ILineColumnChartProps, LineColumnChart, RatioLineColumnChart} from "../LineColumn";
import {TimeRangeFilter} from "./TimeRangeFilter";

interface IColumnChartCardProps<D> extends ICardProps, ILineColumnChartProps {
    data: D[];
}

interface IColumnChartCardWrapperProps<D> extends IColumnChartCardProps<D>, RenderProps {}

export const LineColumnChartCardWrapper = <D extends {date: string}>(props: IColumnChartCardWrapperProps<D>) => {
    const {area, data, colorSet} = props;
    const chartRef = useRef(null);
    const [timeRange, setTimeRange] = useState<number>(12);
    const {filteredData, availableRanges} = useTimeFilteredData(data, timeRange);
    const handleTimeRangeChange = useCallback((range: number) => setTimeRange(range), []);

    return (
        <Card
            {...props}
            area={area ?? ""}
            chartRef={chartRef}
            chartPlaceholder="lineColumn"
            footer={
                <TimeRangeFilter
                    availableRanges={availableRanges}
                    currentRange={timeRange}
                    onRangeChange={handleTimeRangeChange}
                />
            }
        >
            {props.render({filteredData, colorSet, innerRef: chartRef, ...props})}
        </Card>
    );
};

export const LineColumnChartCard = <D extends {date: string}>(props: IColumnChartCardProps<D>) => {
    return (
        <LineColumnChartCardWrapper
            {...props}
            render={({filteredData, colorSet, ...props}) => (
                <LineColumnChart
                    {...props}
                    categoryField="date"
                    colorSet={colorSet ?? "purple"}
                    data={filteredData}
                    height={275}
                />
            )}
        />
    );
};

export const RatioLineColumnChartCard = <D extends {date: string}>(props: IColumnChartCardProps<D>) => {
    return (
        <LineColumnChartCardWrapper
            {...props}
            render={({filteredData, colorSet, ...props}) => (
                <RatioLineColumnChart
                    {...props}
                    categoryField="date"
                    colorSet={colorSet ?? "purple"}
                    data={filteredData}
                    height={275}
                />
            )}
        />
    );
};
