export type ColorSetType = keyof typeof colorSets;

export const colorSets: {
    [key: string]: string[];
} = {
    alternate: ["#BD66D2", "#47AAEC", "#87D44D", "#F1D43A", "#F18B3A", "#DF628F"],
    alternateLong: [
        "#BD66D2",
        "#47AAEC",
        "#87D44D",
        "#F1D43A",
        "#F18B3A",
        "#DF628F",
        "#E2C2CE",
        "#9BCCED",
        "#B5D89B",
        "#F5EBB7",
        "#F0D3BC",
        "#FF385C"
    ],
    blue: ["#47AAEC", "#71BCEE", "#9BCCED", "#C1DCED"],
    gray: ["#23232D", "#EDF0F4", "#F3F5F8", "#FAFBFC", "#FFFFFF"],
    green: ["#87D44D", "#9BD470", "#B5D89B", "#CADCBC"],
    mixed: ["#F1D43A", "#87D44D", "#BD66D2", "#47AAEC", "#F3F5F8"],
    orange: ["#F18B3A", "#F1A567", "#F1BD94", "#F0D3BC"],
    purple: ["#BD66D2", "#C485D4", "#CEA8D8", "#D5C3DA"],
    red: ["#DF628F", "#E084A5", "#E1ABBE", "#E2C2CE"],
    yellow: ["#F1D43A", "#F2DB5F", "#F4E38A", "#F5EBB7"],
    honey: ["#F1BE3A", "#EFC65C", "#ECCD7F", "#EAD5A1"],
    legendLike: ["#02D054", "#FF385C", "#F18B3A"]
};

export const mapMapFieldToColor: {
    [key: string]: ColorSetType;
} = {
    available_offers: "purple",
    available_investments: "orange",
    sold: "blue",
    added: "honey"
};

export const mapLegendColors: IDictionary = {
    available_offers: "#CEA8D8",
    available_investments: "#F1BD94",
    sold: "#9BCCED",
    added: "#F4E38A"
};

export const mapHoverColors: IDictionary = {
    green: "#24AE9F",
    purple: "#5F4DBA",
    red: "#C93168",
    yellow: "#CEBA01",
    blue: "#41A3FF",
    honey: "#FFB055"
};

export const cityColors: IDictionary = {
    warszawa: "#F1D43A",
    krakow: "#DF628F",
    wroclaw: "#87D44D",
    "trojmiasto-i-okolice": "#BD66D2",
    poznan: "#47AAEC",
    lodz: "#F18B3A",
    katowice: "#62dfd0"
};

const fieldsColors: IDictionary = {
    // Offers to avg price
    available_offers: "#02D054",
    sold: "#FF385C",
    added: "#F18B3A",
    demand: "#F1BE3A",
    avg_price_m2: "#DF628F",

    // By rooms - studio
    avg_price_m2_studio: "#BD66D2",
    available_studio: "#BD66D2",
    demand_studio: "#BD66D2",
    sold_avg_area_studio: "#BD66D2",
    added_avg_area_studio: "#BD66D2",
    sold_studio: "#BD66D2",
    added_studio: "#BD66D2",

    // By rooms - 2_rooms
    avg_price_m2_2_rooms: "#47AAEC",
    available_2_rooms: "#47AAEC",
    demand_2_rooms: "#47AAEC",
    sold_avg_area_2_rooms: "#47AAEC",
    added_avg_area_2_rooms: "#47AAEC",
    sold_2_rooms: "#47AAEC",
    added_2_rooms: "#47AAEC",

    // By rooms - 3_rooms
    avg_price_m2_3_rooms: "#87D44D",
    available_3_rooms: "#87D44D",
    demand_3_rooms: "#87D44D",
    sold_avg_area_3_rooms: "#87D44D",
    added_avg_area_3_rooms: "#87D44D",
    sold_3_rooms: "#87D44D",
    added_3_rooms: "#87D44D",

    // By rooms - 4_plus_rooms
    avg_price_m2_4_plus_rooms: "#F1D43A",
    available_4_plus_rooms: "#F1D43A",
    demand_4_plus_rooms: "#F1D43A",
    sold_avg_area_4_plus_rooms: "#F1D43A",
    added_avg_area_4_plus_rooms: "#F1D43A",
    sold_4_plus_rooms: "#F1D43A",
    added_4_plus_rooms: "#F1D43A",

    // By rooms - house
    avg_price_m2_house: "#F18B3A",
    available_house: "#F18B3A",
    demand_house: "#F18B3A",
    sold_house: "#F18B3A",
    added_house: "#F18B3A",

    // By investment size - intimate
    avg_price_m2_investment_type_intimate: "#BD66D2",
    available_investment_type_intimate: "#BD66D2",

    // By investment size - small
    avg_price_m2_investment_type_small: "#47AAEC",
    available_investment_type_small: "#47AAEC",

    // By investment size - medium
    avg_price_m2_investment_type_medium: "#87D44D",
    available_investment_type_medium: "#87D44D",

    // By investment size - large
    avg_price_m2_investment_type_large: "#F1D43A",
    available_investment_type_large: "#F1D43A",

    // By investment size - x_large
    avg_price_m2_investment_type_x_large: "#F18B3A",
    available_investment_type_x_large: "#F18B3A",

    // By investment size - house
    avg_price_m2_investment_type_house: "#DF628F",
    available_investment_type_house: "#DF628F",

    // By area - to_30m2
    avg_price_m2_area_apartments_to_30m2: "#BD66D2",
    sold_area_to_30m2: "#BD66D2",
    added_area_to_30m2: "#BD66D2",

    // By area - between_30_and_50m2
    avg_price_m2_area_apartments_between_30_and_50m2: "#47AAEC",
    sold_area_between_30_and_50m2: "#47AAEC",
    added_area_between_30_and_50m2: "#47AAEC",

    // By area - between_50_and_70m2
    avg_price_m2_area_apartments_between_50_and_70m2: "#87D44D",
    sold_area_between_50_and_70m2: "#87D44D",
    added_area_between_50_and_70m2: "#87D44D",

    // By area - between_70_and_100m2
    avg_price_m2_area_apartments_between_70_and_100m2: "#F1D43A",
    sold_area_between_70_and_100m2: "#F1D43A",
    added_area_between_70_and_100m2: "#F1D43A",

    // By area - between_100_and_120m2
    avg_price_m2_area_apartments_between_100_and_120m2: "#F18B3A",
    sold_area_between_100_and_120m2: "#F18B3A",
    added_area_between_100_and_120m2: "#F18B3A",

    // By area - above_120m2
    avg_price_m2_area_apartments_above_120m2: "#DF628F",
    sold_area_above_120m2: "#DF628F",
    added_area_above_120m2: "#DF628F",

    // GUS
    permits_issued: "#24AE9F",
    flats_started: "#F1D43A",
    flats_finished: "#DF628F",

    // miscellanous
    percentage_offers_put_into_service: "#FF385C",
    avg_price: "#DF628F",
    avg_area: "#24AE9F"
};

// alternate: ["#BD66D2", "#47AAEC", "#87D44D", "#F1D43A", "#F18B3A", "#DF628F"],

export const mapFieldToColor = (field: string): string | undefined =>
    fieldsColors[field] ? fieldsColors[field] : undefined;
