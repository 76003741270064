import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import {bugsnagEnv, bugsnagWebKey} from "../web/src/common/app/read_charts_web_environment_variables";

Bugsnag.start({
    apiKey: bugsnagWebKey,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    plugins: [new BugsnagPluginReact()],
    releaseStage: bugsnagEnv,
    enabledReleaseStages: ["production", "stage"]
});

export const BugsnagErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
