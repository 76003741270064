import {useTranslation} from "react-i18next";
import {useLocation, useRouteMatch} from "react-router-dom";
import {onDesktop, p} from "@pg-design/helpers-css";
import {i18n as Ii18n} from "i18next";

import {CitySlug, mapCitySlugToLocalizedParamSlug, mapParamSlugToCitySlug} from "../../../../../config/cities";
import {dataLayer} from "../../../../../utils/data_layer";
import {capitalize} from "../../../../../utils/misc";
import {chartsRoutes, checkIfIsDetailedView, compilePath, ICityParams} from "../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, mapPathnameToOfferType, OfferType} from "../../../common/app/routing/offer_type";
import {Breadcrumbs, IBreadcrumb} from "../../breadcrumbs/Breadcrumbs";
import {BreadcrumbsItem} from "../../breadcrumbs/BreadcrumbsItem";

export const ChartsBreadcrumbs = () => {
    const {t, i18n} = useTranslation();
    const {
        path,
        params: {city}
    } = useRouteMatch<ICityParams>();
    const {pathname} = useLocation();
    const citySlug = city ? mapParamSlugToCitySlug(city) : CitySlug.WARSZAWA;
    const offerType = mapPathnameToOfferType(pathname);
    const paramCitySlug = mapCitySlugToLocalizedParamSlug(citySlug, i18n.language);
    const handleDataLayerEvent = (button: string) => {
        dataLayer({
            event: "top_menu_click",
            button
        });
    };

    const detailedViewTitle = mapPathnameToDetailedViewTitle(path, i18n);
    const isDetailedView = checkIfIsDetailedView(pathname);
    const viewTitle = mapOfferTypeToViewTitle(offerType, i18n);

    const generateChartsBreadcrumb = () => {
        const bigDataBreadcrumb: IBreadcrumb = {
            title: "BIG DATA RynekPierwotny.pl",
            children: "BIG DATA",
            to: chartsRoutes[i18n.language].root,
            onClick: () => handleDataLayerEvent("BIG DATA")
        };
        const chartsBreadcrumbs: IBreadcrumb[] = [bigDataBreadcrumb];

        const priceBreadcrumb: IBreadcrumb = {
            title: viewTitle,
            children: viewTitle,
            to: mapOfferTypeToValidPath(offerType, chartsRoutes[i18n.language].properties.root, i18n),
            onClick: () => handleDataLayerEvent(viewTitle)
        };
        chartsBreadcrumbs.push(priceBreadcrumb);

        if (city) {
            const cityBreadcrumb: IBreadcrumb = {
                title:
                    city !== "katowice"
                        ? capitalize(t(`city.${citySlug}.nominative`))
                        : `Katowice / ${
                              i18n.language === "pl"
                                  ? "Górnośląsko-Zagłębiowska Metropolia"
                                  : "Upper Silesia Metropolis"
                          }`,
                children:
                    city !== "katowice"
                        ? capitalize(t(`city.${citySlug}.nominative`))
                        : `Katowice / ${
                              i18n.language === "pl"
                                  ? "Górnośląsko-Zagłębiowska Metropolia"
                                  : "Upper Silesia Metropolis"
                          }`,
                to: compilePath(
                    mapOfferTypeToValidPath(offerType, chartsRoutes[i18n.language].properties.city.root, i18n),
                    {city: paramCitySlug}
                ),
                onClick: () => handleDataLayerEvent(t(`city.${city}.nominative`))
            };
            chartsBreadcrumbs.push(cityBreadcrumb);
        }

        if (city && isDetailedView && detailedViewTitle) {
            const detailBreadcrumb = {
                title: detailedViewTitle,
                children: detailedViewTitle,
                to: "#",
                onClick: () => handleDataLayerEvent(detailedViewTitle)
            };
            chartsBreadcrumbs.push(detailBreadcrumb as IBreadcrumb);
        }
        return [...chartsBreadcrumbs];
    };

    const breadcrumbsData = generateChartsBreadcrumb();

    return (
        <Breadcrumbs css={[p(2, 1.5, 0, 1.5), onDesktop(p(2, 3, 0, 3))]}>
            {breadcrumbsData.map((data, i) => {
                const isLastItem = breadcrumbsData.length === i + 1;
                return (
                    <BreadcrumbsItem
                        title={data.title}
                        to={data.to}
                        isLinkingToCurrentLocation={isLastItem}
                        onClick={data.onClick}
                        key={i}
                        position={(i + 2).toString()}
                    >
                        {data.children}
                    </BreadcrumbsItem>
                );
            })}
        </Breadcrumbs>
    );
};

const mapOfferTypeToViewTitle = (offerType: OfferType, i18n: Ii18n): string => {
    switch (offerType) {
        case OfferType.PROPERTY:
            return i18n.t("common.properties_prices");
        case OfferType.FLAT:
            return i18n.t("common.flats_prices");
        case OfferType.HOUSE:
            return i18n.t("common.houses_prices");
    }
};

const mapPathnameToDetailedViewTitle = (pathname: string, i18n: Ii18n): string | undefined | null => {
    switch (pathname) {
        case chartsRoutes[i18n.language].properties.city.prices:
        case chartsRoutes[i18n.language].flats.city.prices:
            return i18n.t("nav.prices");
        case chartsRoutes[i18n.language].properties.city.offer:
        case chartsRoutes[i18n.language].flats.city.offer:
            return i18n.t("nav.offer");
        case chartsRoutes[i18n.language].properties.city.demand:
        case chartsRoutes[i18n.language].flats.city.demand:
            return i18n.t("nav.demand");
        case chartsRoutes[i18n.language].properties.city.sold:
        case chartsRoutes[i18n.language].flats.city.sold:
            return i18n.t("nav.sold");
        case chartsRoutes[i18n.language].properties.city.added:
        case chartsRoutes[i18n.language].flats.city.added:
            return i18n.t("nav.added");
        default:
            return undefined;
    }
};
