const ALGOLYTICS_DOMAIN = "//app.scoring.one/";

declare global {
    interface Window {
        sce: ISce;
    }
}

interface ISce {
    invoked: unknown;
    loadScriptFlag: unknown;
    baseUrl: unknown;
    writeKey: unknown;
    CODES_V: string;
    buffer: unknown[];
    event: (eventType: string, arg: unknown) => void;
    sendData: (eventType: string, arg: unknown) => void;
}

let isAlgolyticsInitialized = false;

/**
 * Init
 */
export const initAlgolytics = (algolyticsId: string | null): void => {
    // if there is no ID provided, do not initialize
    if (!algolyticsId) {
        return;
    }
    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }
    // initialize only once
    if (isAlgolyticsInitialized) {
        return;
    }
    // initialize
    (function (base_url: string, write_key: string) {
        window.sce = window.sce || [];
        const sce = window.sce;

        // already invoked
        if (sce.invoked) {
            if (window.console && console.error) {
                console.error("Probably, code already included");
            }
            return;
        }

        // Invoked flag, to make sure the snippet
        // is never invoked twice.
        sce.invoked = true;
        sce.loadScriptFlag = false;
        // ('https:' === document.location.protocol ? 'https://' : 'http://')
        sce.baseUrl = base_url;
        sce.writeKey = write_key;
        sce.CODES_V = "0.3";
        sce.buffer = sce.buffer || [];

        // fetch main script
        const firstScriptElem = document.getElementsByTagName("script")[0];
        const newScriptElem = document.createElement("script");
        newScriptElem.async = true;
        newScriptElem.src = `${sce.baseUrl}exposed/sendData.js`;
        firstScriptElem != null &&
            firstScriptElem.parentNode != null &&
            firstScriptElem.parentNode.insertBefore(newScriptElem, firstScriptElem);

        // public API
        sce.event = function (eventType: string, arg: unknown) {
            if (sce.loadScriptFlag) {
                sce.sendData(eventType, arg);
            } else {
                sce.buffer.push([Date.now(), eventType, arg]);
            }
        };
    })(ALGOLYTICS_DOMAIN, algolyticsId);
    isAlgolyticsInitialized = true;
};

/**
 * Hit
 */

// NOTE: we should debounce or at least delay every `hitAlgolytics` call
export function hitAlgolytics(collection: string, data: object = {}) {
    if (!isAlgolyticsInitialized) {
        return;
    }
    const sessionCookie = getCookie("bd_sessionid");
    const sessionIdEndIdx = sessionCookie ? sessionCookie.indexOf(":") : -1;
    const sessionId = sessionIdEndIdx > 0 ? sessionCookie!.slice(0, sessionIdEndIdx) : sessionCookie;
    const payload = {session_id: sessionId ? sessionId : undefined, ...data};
    window.sce && window.sce.event(collection, payload);
}

/**
 * Helpers
 */

function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length >= 2) {
        const lastElement = parts.pop();
        return lastElement && lastElement.split(";").shift();
    }
    return null;
}
