import {userComApi} from "./user_com_api";

export interface IUserComUserResponse {
    id: number;
}

export const get_user_com_user = userComApi.injectEndpoints({
    endpoints: (build) => ({
        getUserComUser: build.query<IUserComUserResponse, string>({
            query: (slug) => {
                return {
                    url: `?email=${slug}/`,
                    method: "GET"
                };
            }
        })
    }),
    overrideExisting: false
});

export const {useLazyGetUserComUserQuery} = get_user_com_user;
