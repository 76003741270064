import {Link} from "react-router-dom";

interface IProps {
    isDark?: boolean;
    className?: string;
}

export const Brand = ({isDark, className}: IProps) => {
    const height = isDark ? "40px" : "60px";
    const width = isDark ? "191px" : "287px";
    const fill = isDark ? "#23232D" : "#FAFAF5";

    return (
        <Link to="/">
            <svg
                width={width}
                height={height}
                className={className}
                viewBox="0 0 2218 464"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1350.54 341.916C1345.64 341.916 1341.92 345.833 1341.92 350.733C1341.92 355.63 1345.64 359.352 1350.54 359.352C1355.64 359.352 1359.36 355.63 1359.36 350.733C1359.36 345.833 1355.64 341.916 1350.54 341.916Z"
                    fill={fill}
                />
                <path d="M1494.02 357.395H1502.64V220.245H1494.02V357.395Z" fill={fill} />
                <path
                    d="M1433.1 351.516C1410.57 351.516 1393.92 336.232 1391.96 314.484V305.865C1393.92 283.921 1410.57 268.639 1433.1 268.639C1456.22 268.639 1474.05 286.859 1474.05 310.176C1474.05 333.293 1456.22 351.516 1433.1 351.516ZM1434.09 260.605C1413.9 260.605 1398.62 271.185 1391.96 288.819V262.565H1383.34V390.897H1391.96V331.532C1398.62 348.772 1413.9 359.352 1434.09 359.352C1461.12 359.352 1482.48 337.801 1482.48 310.176C1482.48 282.549 1461.12 260.605 1434.09 260.605"
                    fill={fill}
                />
                <path d="M549.751 127.373H582.86V106.604H528.003V202.415H549.751V127.373Z" fill={fill} />
                <path
                    d="M740.587 104.647C728.048 104.647 718.056 110.72 711.983 121.3V106.605H690.039V202.415H711.983V146.772C713.352 133.839 722.367 125.609 735.298 125.609C749.404 125.609 758.612 135.407 758.612 150.493V202.415H780.36V147.752C780.36 121.104 765.274 104.647 740.587 104.647Z"
                    fill={fill}
                />
                <path
                    d="M838.969 123.064C852.095 123.064 862.284 131.88 863.459 143.833H812.321C815.849 130.508 825.841 123.064 838.969 123.064ZM840.339 204.373C863.653 204.373 882.071 191.245 885.401 170.869H863.851C861.892 179.884 852.487 185.761 840.144 185.761C824.275 185.761 813.104 175.374 811.147 159.702H886.188C886.381 157.546 886.579 153.433 886.579 151.474C886.579 124.826 865.809 104.646 839.947 104.646C812.321 104.646 790.573 126.198 790.573 154.216C790.573 182.625 812.321 204.373 840.339 204.373"
                    fill={fill}
                />
                <path
                    d="M920.086 157.155L960.841 202.415H989.051L942.031 152.649L986.113 106.604H958.879L920.086 147.947V65.2639H898.338V202.415H920.086V157.155Z"
                    fill={fill}
                />
                <path
                    d="M579.138 338.585C562.484 338.585 549.946 326.046 549.946 309.586C549.946 292.932 562.484 280.393 579.138 280.393C595.399 280.393 608.136 293.325 608.136 309.586C608.136 325.654 595.399 338.585 579.138 338.585ZM583.84 259.626C568.363 259.626 556.607 266.678 549.751 278.826V261.584H528.003V390.897H549.751V340.152C556.607 352.3 568.363 359.352 583.84 359.352C609.508 359.352 629.686 337.213 629.686 309.586C629.686 281.961 609.508 259.626 583.84 259.626"
                    fill={fill}
                />
                <path d="M640.293 357.395H662.042V261.585H640.293V357.395Z" fill={fill} />
                <path
                    d="M694.293 298.812C697.818 285.488 707.81 278.042 720.938 278.042C734.066 278.042 744.253 286.858 745.428 298.812H694.293ZM721.918 259.626C694.293 259.626 672.545 281.178 672.545 309.196C672.545 337.604 694.293 359.352 722.31 359.352C745.625 359.352 764.042 346.224 767.373 325.849H745.82C743.861 334.862 734.458 340.741 722.113 340.741C706.244 340.741 695.076 330.354 693.118 314.681H768.156C768.35 312.526 768.548 308.412 768.548 306.453C768.548 279.805 747.781 259.626 721.918 259.626"
                    fill={fill}
                />
                <path d="M780.719 357.394H802.467V282.353H835.579V261.585H780.719V357.394Z" fill={fill} />
                <path
                    d="M964.425 261.584L944.441 323.89L921.713 261.584H904.47L881.939 323.89L861.955 261.584H838.443L873.122 357.394H890.365L913.093 295.284L935.818 357.394H953.258L987.937 261.584H964.425Z"
                    fill={fill}
                />
                <path
                    d="M1034.81 338.388C1018.74 338.388 1006.4 325.849 1006.4 309.586C1006.4 293.128 1018.74 280.393 1034.81 280.393C1050.68 280.393 1063.42 293.128 1063.42 309.586C1063.42 325.849 1050.68 338.388 1034.81 338.388ZM1034.81 259.626C1006.79 259.626 985.243 281.178 985.243 309.586C985.243 337.802 1006.79 359.352 1034.81 359.352C1062.83 359.352 1084.58 337.802 1084.58 309.586C1084.58 281.178 1062.83 259.626 1034.81 259.626Z"
                    fill={fill}
                />
                <path
                    d="M1125.27 235.918H1103.33V261.585H1085.11V282.353H1103.33V321.344C1103.33 345.638 1117.44 358.569 1138.6 358.569C1142.12 358.569 1147.02 358.177 1150.35 357.394V337.213C1148.2 337.802 1145.65 337.997 1143.3 337.997C1132.72 337.997 1125.27 333.098 1125.27 320.952V282.353H1150.35V261.585H1125.27V235.918Z"
                    fill={fill}
                />
                <path
                    d="M1211.33 259.626C1198.79 259.626 1188.8 265.699 1182.72 276.279V261.585H1160.78V357.394H1182.72V301.751C1184.1 288.818 1193.11 280.589 1206.04 280.589C1220.15 280.589 1229.35 290.386 1229.35 305.473V357.394H1251.1V302.731C1251.1 276.083 1236.02 259.626 1211.33 259.626Z"
                    fill={fill}
                />
                <path
                    d="M1327.13 261.584L1301.46 326.829L1275.8 261.584H1252.28L1290.29 352.299L1273.14 390.897H1296.64L1350.64 261.584H1327.13Z"
                    fill={fill}
                />
                <path
                    d="M660.568 106.605L634.904 171.85L609.236 106.605H585.724L623.733 197.319L606.581 235.918H630.084L684.08 106.605H660.568Z"
                    fill={fill}
                />
                <path d="M1669.62 390.897H1677.16V65.2641H1669.62V390.897Z" fill={fill} />
                <path
                    d="M1893.9 196.339C1871.37 196.339 1854.72 181.055 1852.76 159.114V150.491C1854.72 128.746 1871.37 113.462 1893.9 113.462C1917.02 113.462 1934.85 131.682 1934.85 154.803C1934.85 178.117 1917.02 196.339 1893.9 196.339ZM1894.88 105.627C1874.7 105.627 1859.42 116.207 1852.76 133.446V65.2646H1844.14V202.411H1852.76V176.158C1859.42 193.793 1874.7 204.373 1894.88 204.373C1921.92 204.373 1943.28 182.427 1943.28 154.803C1943.28 127.177 1921.92 105.627 1894.88 105.627"
                    fill={fill}
                />
                <path d="M1967.72 107.585H1959.1V202.412H1967.72V107.585Z" fill={fill} />
                <path
                    d="M2032.59 113.463C2056.68 113.463 2073.93 130.705 2073.93 154.411C2073.93 178.508 2056.68 195.751 2032.59 195.751C2009.47 195.751 1991.64 177.725 1991.64 154.411C1991.64 131.488 2009.47 113.463 2032.59 113.463ZM2031.61 203.784C2051.98 203.784 2067.27 192.812 2073.93 175.177V200.256C2073.93 221.612 2058.25 235.915 2035.92 235.915C2016.33 235.915 2000.65 225.924 2000.46 210.643H1992.23C1992.62 231.212 2013.39 243.36 2036.11 243.36C2063.35 243.36 2082.35 226.121 2082.35 200.061V107.585H2073.93V133.839C2067.27 116.208 2051.98 105.627 2031.61 105.627C2004.57 105.627 1983.21 126.983 1983.21 154.411C1983.21 182.036 2004.57 203.784 2031.61 203.784"
                    fill={fill}
                />
                <path
                    d="M1887.05 351.319C1863.93 351.319 1846.1 333.096 1846.1 309.783C1846.1 286.661 1863.93 268.443 1887.05 268.443C1911.14 268.443 1928.38 285.879 1928.38 309.783C1928.38 333.883 1911.14 351.319 1887.05 351.319ZM1928.38 289.015C1921.72 271.381 1906.44 260.607 1886.07 260.607C1859.03 260.607 1837.68 282.156 1837.68 309.783C1837.68 337.407 1859.03 359.352 1886.07 359.352C1906.44 359.352 1921.72 348.38 1928.38 330.747V357.391H1937.01V220.244H1928.38V289.015Z"
                    fill={fill}
                />
                <path
                    d="M2002.38 351.319C1979.26 351.319 1961.43 333.096 1961.43 309.783C1961.43 286.661 1979.26 268.443 2002.38 268.443C2026.47 268.443 2043.71 285.879 2043.71 309.783C2043.71 333.883 2026.47 351.319 2002.38 351.319ZM2043.71 289.015C2037.05 271.381 2021.77 260.607 2001.4 260.607C1974.36 260.607 1953 282.156 1953 309.783C1953 337.407 1974.36 359.352 2001.4 359.352C2021.77 359.352 2037.05 348.38 2043.71 330.747V357.391H2052.14V262.564H2043.71V289.015Z"
                    fill={fill}
                />
                <path
                    d="M2090.39 235.722H2081.77V262.565H2062.57V270.401H2081.77V328.59C2081.77 349.357 2092.94 358.174 2110.77 358.174C2113.31 358.174 2116.25 357.98 2118.41 357.392V349.554C2116.45 350.144 2114.1 350.338 2111.94 350.338C2098.62 350.338 2090.39 344.852 2090.39 328.59V270.401H2118.41V262.565H2090.39V235.722Z"
                    fill={fill}
                />
                <path
                    d="M2168.49 351.319C2145.37 351.319 2127.54 333.096 2127.54 309.783C2127.54 286.661 2145.37 268.443 2168.49 268.443C2192.58 268.443 2209.83 285.879 2209.83 309.783C2209.83 333.883 2192.58 351.319 2168.49 351.319ZM2209.83 262.564V289.015C2203.17 271.381 2187.88 260.607 2167.51 260.607C2140.47 260.607 2119.11 282.156 2119.11 309.783C2119.11 337.407 2140.47 359.352 2167.51 359.352C2187.88 359.352 2203.17 348.38 2209.83 330.747V357.391H2218.25V262.564H2209.83Z"
                    fill={fill}
                />
                <path
                    d="M463.972 232C463.972 360.192 360.052 464.112 231.86 464.112C103.668 464.112 -0.251953 360.192 -0.251953 232C-0.251953 103.808 103.668 -0.111952 231.86 -0.111952C360.052 -0.111952 463.972 103.808 463.972 232Z"
                    fill="#EBFF00"
                />
                <path
                    d="M314.568 314.707H149.152V149.292H314.568V314.707ZM106.466 186.644V357.395H277.216L357.255 277.355V106.604H186.504L106.466 186.644Z"
                    fill="#23232D"
                />
            </svg>
        </Link>
    );
};
