import {ReactNode} from "react";
import {css} from "@emotion/react";
import {flex, mb, onDesktop, ph, w100} from "@pg-design/helpers-css";

import {CitySlug} from "../../../../../config/cities";
import {RegionType} from "../../../../../config/regions";
import {AwardsShowcase} from "../../../features/analytical_platform/components/AwardsShowcase";
import {Onboarding} from "../../../features/analytical_platform/components/onboarding/Onboarding";
import {Footer} from "../../../features/landing/components/footer/Footer";
import {EmailConfirmationModal} from "../../../features/users/components/EmailConfirmationModal";
import {Header} from "../../header/Header";
import {SideMenu} from "../../nav/SideMenu";
import {PageHeader} from "../../page_header/PageHeader";
import {ChartsBreadcrumbs} from "./ChartsBreadcrumbs";

interface IProps {
    children?: ReactNode;
    withHeader?: boolean;
    onCitySelection?: (city: CitySlug) => void;
    onRegionChange?: (region: RegionType) => void;
}

export const ChartsLayout = (props: IProps) => {
    const {children, withHeader = true, onCitySelection, onRegionChange} = props;

    return (
        <>
            <Header />

            <div css={flex()}>
                <SideMenu />

                <div css={content}>
                    <ChartsBreadcrumbs />
                    <PageHeader
                        visible={withHeader}
                        onCitySelection={onCitySelection}
                        onRegionChange={onRegionChange}
                    />
                    <main css={cardsWrapper}>{children}</main>
                    <AwardsShowcase />
                    <Footer isAnalyticalPlatform />
                </div>
            </div>

            <Onboarding />
            <EmailConfirmationModal />
        </>
    );
};

const content = css`
    ${w100};
    ${onDesktop(css`
        width: calc(100% - var(--sidebar-width));
    `)};
`;

const cardsWrapper = css`
    ${ph(1.5)};
    ${mb(5)};

    ${onDesktop(css`
        ${ph(3)};
    `)};
`;
