import {Options} from "highcharts";

import {RequestStatus} from "../../../common/app/constants/enums";
import {placeholderYearData} from "../../../common/app/constants/placeholder_charts_data";
import {ColumnStackedChart} from "../ColumnStacked";

export const ColumnStackedChartPlaceholder = () => (
    <ColumnStackedChart
        data={placeholderYearData}
        dataStatus={RequestStatus.SUCCESS}
        height={250}
        categories={[
            "investment_type_intimate",
            "investment_type_small",
            "investment_type_medium",
            "investment_type_large",
            "investment_type_x_large"
        ]}
        fieldGroups={[
            ["value1", "value2", "value3", "value4", "value5"],
            ["value1", "value2", "value3", "value4", "value5"],
            ["value1", "value2", "value3", "value4", "value5"],
            ["value1", "value2", "value3", "value4", "value5"],
            ["value1", "value2", "value3", "value4", "value5"]
        ]}
        customOptions={options}
    />
);

const options: Options = {
    tooltip: {
        enabled: false
    }
};
