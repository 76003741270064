import styled from "@emotion/styled";
import {flex} from "@pg-design/helpers-css";

interface IProps {
    items: ILegendItem[];
    onItemHover: (fieldIndex: number | null) => void;
}

export const PieChartSharedLegend = ({items, onItemHover}: IProps) => {
    if (items && items.length > 0) {
        return (
            <StyledLegend>
                {items.map((item) => (
                    <StyledLegendItem
                        {...item}
                        key={item.fieldIndex}
                        onMouseOver={() => onItemHover(item.fieldIndex)}
                        onMouseOut={() => onItemHover(null)}
                    >
                        {item.label}
                    </StyledLegendItem>
                ))}
            </StyledLegend>
        );
    }

    return null;
};

const StyledLegend = styled.div`
    padding: 2rem 4rem;
    ${flex("normal", "center")}
    flex-wrap: wrap;
    gap: 0 2rem;
    margin: 0 auto;
`;

const StyledLegendItem = styled.div<ILegendItem>`
    position: relative;
    cursor: pointer;
    color: #333;
    font-weight: 600;
    font-size: 1.2rem;
    padding: 0.3rem 0.3rem 0.3rem 1.7rem;

    &::before {
        content: "";
        background: ${(props) => props.color};
        border-radius: 2px;
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover {
        color: #000;
    }
`;
