import {authApi} from "./authApi";

export const logoutApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        logout: build.mutation<void, void>({
            query: () => ({
                url: `/users/logout/`,
                method: "POST"
            })
        })
    }),
    overrideExisting: false
});

export const {useLogoutMutation} = logoutApi;
