import {matchPath} from "react-router";
import {i18n} from "i18next";
import {escape} from "lodash-es";

import {mapParamSlugToCitySlug} from "../../../../config/cities";
import {getRelatedCitySlug, RegionType} from "../../../../config/regions";
import {getBigDataApiPath} from "../../../../utils/get_big_data_api_path";
import {capitalize} from "../../../../utils/misc";
import {chartsRoutes, compilePath, getLocalizedUrl, ICityParams} from "../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, mapPathnameToOfferType, OfferType} from "../../common/app/routing/offer_type";

interface IEntry {
    path?: string | string[];
    callback: (pathname: string, i18n: i18n) => IMetaData | Promise<IMetaData>;
}

interface match<Params extends {[K in keyof Params]?: string} = {}> {
    params: Params;
    isExact: boolean;
    path: string;
    url: string;
}

export interface IMetaData {
    title: string;
    description: string;
    canonical?: string;
    robots: string;
}

/**
 * Meta generators
 */

const appCanonicalUrl = "https://bigdata.rynekpierwotny.pl";
const rpCannonicalUrl = "https://rynekpierwotny.pl";

const defaultMetaTags: IMetaData = {
    title: "BIG DATA RynekPierwotny.pl — Indeks cen",
    description: "Indeks cen pozwoli Ci sprawdzić wyceny nieruchomości w największych miastach Polski",
    canonical: appCanonicalUrl,
    robots: "index, follow"
};

const generateRootMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    return {
        title: i18n.t("landing.seo.title"),
        description: i18n.t("landing.seo.description"),
        canonical: escape(appCanonicalUrl + pathname),
        robots: "index, follow"
    };
};

const generateContactMetaTags = (pathname: string): IMetaData => {
    return {
        title: "Dane kontaktowe - BIG DATA RynekPierwotny.pl",
        description:
            "Napisz lub zadzwoń do naszych specjalistów jeżeli chcesz poznać cenę lub poznać odpowiedź na dodatkowe pytania dotyczące naszej usługi.",
        canonical: escape(appCanonicalUrl + pathname),
        robots: "index, follow"
    };
};

const generateFaqMetaTags = (pathname: string): IMetaData => {
    return {
        title: "Częste pytania - FAQ - BIG DATA RynekPierwotny.pl",
        description:
            "Przeczytaj najczęściej zadawane pytania i dowiedz się jakie dane dotyczące mieszkań, domów i rynku nieruchomości znajdziesz w serwisie BIG DATA.",
        canonical: escape(appCanonicalUrl + pathname),
        robots: "index, follow"
    };
};

const generateMonitoringMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    return {
        title: i18n.t("Monitoring konkurencji na rynku deweloperskim - BIG DATA RynekPierwotny.pl"),
        description: i18n.t(
            "Monitoring konkurencji pozwoli Ci się dowiedzieć, o działaniach konkurencji oraz oszacować potencjał swoich inwestycji. Sprawdź pełny zakres."
        ),
        canonical: escape(appCanonicalUrl + pathname),
        robots: "index, follow"
    };
};

const generatePricingMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    return {
        title: i18n.t("Cennik dostępu do pełnych danych - BIG DATA RynekPierwotny.pl"),
        description: i18n.t(
            "Poznaj ceny dostępu do pełnych danych o cenach mieszkań i domów na rynku pierwotnym w Polsce oraz możliwości monitorowania konkurencji. "
        ),
        canonical: escape(appCanonicalUrl + pathname),
        robots: "index, follow"
    };
};

const generatePublicationsMetaTags = (pathname: string): IMetaData => {
    return {
        title: "Profesjonalne raporty z rynku nieruchomości - BIG DATA RynekPierwotny.pl",
        description: "Dedykowane i profesjonalne raporty dla Twojej inwestycji mieszkaniowej",
        canonical: escape(appCanonicalUrl + pathname),
        robots: "index, follow"
    };
};

const generatePublicationsListingMetaTags = (pathname: string): IMetaData => {
    return {
        title: "Najnowsze publikacje z BIG DATA RynekPierwotny.pl",
        description: "Najważniejsze publikacje w jednym miejscu - BIG DATA RynekPierwotny.pl",
        canonical: escape(appCanonicalUrl + pathname),
        robots: "index, follow"
    };
};

const generatePublicationsDetailsMetaTags = async (pathname: string) => {
    const id = pathname.substring(pathname.lastIndexOf("-")).replace(/\D/g, "");
    try {
        const res = await fetch(`${getBigDataApiPath()}publications/publications/${id}`);
        const data = await res.json();
        return {
            title: `${data.name} - BIG DATA RynekPierwotny.pl`,
            description: data.lead.substring(0, data.lead.lastIndexOf(".")),
            canonical: escape(appCanonicalUrl + pathname),
            robots: "index follow"
        };
    } catch (e) {
        return {
            title: "Najnowsze informacje rynkowe - BIG DATA RynekPierwotny.pl",
            description:
                "Śledzisz oferty nieruchomości z rynku pierwotnego w poszukiwaniu atrakcyjnej inwestycji?" +
                " Przeprowadzimy Cię przez branżowe newsy. Z nami będziesz na bieżąco.",
            canonical: escape(appCanonicalUrl + pathname),
            robots: "index follow"
        };
    }
};

const generateHomeMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    const offerType = mapPathnameToOfferType(pathname);
    const offerTypeTranslationKey = mapOfferTypeToTranslationKey(offerType);
    const title = offerType === OfferType.PROPERTY ? "home.seo.title_no_chart" : "home.seo.title";

    return {
        title: capitalize(
            i18n.t(`${title}`, {
                year: new Date().getFullYear(),
                propertyType1: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5})
            })
        ),
        description: getDescriptionByOfferType(offerType, i18n),
        canonical: escape(appCanonicalUrl + pathname),
        robots: "index, follow"
    };
};

const generateDashboardMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    const offerType = mapPathnameToOfferType(pathname);
    const offerTypeTranslationKey = mapOfferTypeToTranslationKey(offerType);
    const matchedPath = matchPath<ICityParams>(pathname, {
        path: getLocalizedUrl(pathname, i18n.language),
        exact: true
    }) as match<ICityParams>;

    const citySlug = mapParamSlugToCitySlug(matchedPath.params.city);
    const title = offerType === OfferType.HOUSE ? "dashboard.seo.title" : "dashboard.seo.title_no_chart";
    const isFlatOfferType = offerType === OfferType.FLAT;
    const targetCitySlug = getRelatedCitySlug(citySlug, RegionType.CITY);

    const getCompilePathParams = () => {
        if (targetCitySlug) {
            return {city: targetCitySlug};
        }
        if (citySlug) {
            return {city: citySlug};
        }
        return undefined;
    };

    const compiledPath = mapOfferTypeToValidPath(
        offerType,
        compilePath(chartsRoutes[i18n.language].properties.city.root, getCompilePathParams()),
        i18n
    );

    if (citySlug) {
        return {
            title: capitalize(
                i18n.t(`${title}`, {
                    year: new Date().getFullYear(),
                    propertyType1: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                    city1: i18n.t(`city.${citySlug}.nominative`),
                    propertyType2: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 1}),
                    city2: i18n.t(`city.${citySlug}.locative`)
                })
            ),
            description: i18n.t(getDashboardDescriptionByOfferType(offerType, i18n), {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city2: i18n.t(`city.${citySlug}.locative`)
            }),
            canonical: escape(
                isFlatOfferType && i18n.language === "pl" ? rpCannonicalUrl + compiledPath : appCanonicalUrl + pathname
            ),
            robots: "index, follow"
        };
    }
    return defaultMetaTags;
};

const generatePricesMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    const offerType = mapPathnameToOfferType(pathname);
    const offerTypeTranslationKey = mapOfferTypeToTranslationKey(offerType);
    const matchedPath = matchPath<ICityParams>(pathname, {
        path: getLocalizedUrl(pathname, i18n.language),
        exact: true
    }) as match<ICityParams>;
    const citySlug = mapParamSlugToCitySlug(matchedPath.params.city);
    if (citySlug) {
        return {
            title: i18n.t("prices.seo.title", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.nominative`)
            }),
            description: i18n.t("prices.seo.description", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.locative`)
            }),
            canonical: escape(
                appCanonicalUrl +
                    mapOfferTypeToValidPath(
                        offerType,
                        compilePath(chartsRoutes[i18n.language].properties.city.root, {city: citySlug}),
                        i18n
                    )
            ),
            robots: "noindex"
        };
    }
    return defaultMetaTags;
};

const generateOfferMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    const offerType = mapPathnameToOfferType(pathname);
    const offerTypeTranslationKey = mapOfferTypeToTranslationKey(offerType);
    const matchedPath = matchPath<ICityParams>(pathname, {
        path: getLocalizedUrl(pathname, i18n.language),
        exact: true
    }) as match<ICityParams>;
    const citySlug = mapParamSlugToCitySlug(matchedPath.params.city);
    if (citySlug) {
        return {
            title: i18n.t("offer.seo.title", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.nominative`)
            }),
            description: i18n.t("offer.seo.description", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.locative`)
            }),
            canonical: escape(
                appCanonicalUrl +
                    mapOfferTypeToValidPath(
                        offerType,
                        compilePath(chartsRoutes[i18n.language].properties.city.root, {city: citySlug}),
                        i18n
                    )
            ),
            robots: "noindex, nofollow"
        };
    }
    return defaultMetaTags;
};

const generateDemandMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    const offerType = mapPathnameToOfferType(pathname);
    const offerTypeTranslationKey = mapOfferTypeToTranslationKey(offerType);
    const matchedPath = matchPath<ICityParams>(pathname, {
        path: getLocalizedUrl(pathname, i18n.language),
        exact: true
    }) as match<ICityParams>;
    const citySlug = mapParamSlugToCitySlug(matchedPath.params.city);
    if (citySlug) {
        return {
            title: i18n.t("demand.seo.title", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.nominative`)
            }),
            description: i18n.t("demand.seo.description", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.locative`)
            }),
            canonical: escape(
                appCanonicalUrl +
                    mapOfferTypeToValidPath(
                        offerType,
                        compilePath(chartsRoutes[i18n.language].properties.city.root, {city: citySlug}),
                        i18n
                    )
            ),
            robots: "noindex, nofollow"
        };
    }
    return defaultMetaTags;
};

const generateSoldMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    const offerType = mapPathnameToOfferType(pathname);
    const offerTypeTranslationKey = mapOfferTypeToTranslationKey(offerType);
    const matchedPath = matchPath<ICityParams>(pathname, {
        path: getLocalizedUrl(pathname, i18n.language),
        exact: true
    }) as match<ICityParams>;
    const citySlug = mapParamSlugToCitySlug(matchedPath.params.city);
    if (citySlug) {
        return {
            title: i18n.t("sold.seo.title", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.nominative`)
            }),
            description: i18n.t("sold.seo.description", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.locative`)
            }),
            canonical: escape(
                appCanonicalUrl +
                    mapOfferTypeToValidPath(
                        offerType,
                        compilePath(chartsRoutes[i18n.language].properties.city.root, {city: citySlug}),
                        i18n
                    )
            ),
            robots: "noindex, nofollow"
        };
    }
    return defaultMetaTags;
};

const generateAddedMetaTags = (pathname: string, i18n: i18n): IMetaData => {
    const offerType = mapPathnameToOfferType(pathname);
    const offerTypeTranslationKey = mapOfferTypeToTranslationKey(offerType);
    const matchedPath = matchPath<ICityParams>(pathname, {
        path: getLocalizedUrl(pathname, i18n.language),
        exact: true
    }) as match<ICityParams>;
    const citySlug = mapParamSlugToCitySlug(matchedPath.params.city);
    if (citySlug) {
        return {
            title: i18n.t("added.seo.title", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.nominative`)
            }),
            description: i18n.t("added.seo.description", {
                propertyType: i18n.t(`common.${offerTypeTranslationKey}`, {context: "genitive", count: 5}),
                city: i18n.t(`city.${citySlug}.locative`)
            }),
            canonical: escape(
                appCanonicalUrl +
                    mapOfferTypeToValidPath(
                        offerType,
                        compilePath(chartsRoutes[i18n.language].properties.city.root, {city: citySlug}),
                        i18n
                    )
            ),
            robots: "noindex, nofollow"
        };
    }
    return defaultMetaTags;
};

const generateNewshubMetaTags = () => ({
    title: "Wiadomości z rynku nieruchomości - BIG DATA RynekPierwotny.pl",
    description: "Wiadomości profesjonalistów z rynku mieszkaniowego oraz najnowsze informacji branżowe.",
    //same in both languages
    canonical: escape(appCanonicalUrl + chartsRoutes.pl.newshub.root),
    robots: "index follow"
});

const generateMarketInformationMetaTags = () => ({
    title: "Najnowsze informacje rynkowe - BIG DATA RynekPierwotny.pl",
    description:
        "Śledzisz oferty nieruchomości z rynku pierwotnego w poszukiwaniu atrakcyjnej inwestycji?" +
        " Przeprowadzimy Cię przez branżowe newsy. Z nami będziesz na bieżąco.",
    canonical: escape(appCanonicalUrl + chartsRoutes.pl.newshub.marketInformation.root),
    robots: "index follow"
});

const generateBigDataNewsMetaTags = () => ({
    title: "Ekspercka wiedza - aktualności - BIG DATA RynekPierwotny.pl",
    description:
        "Jak prezentuje się sytuacja mieszkaniowa w największych aglomeracjach? Czy ceny mieszkań" +
        " będą spadać? Analizuj profesjonalne raporty z rynku nieruchomości.",
    canonical: escape(appCanonicalUrl + chartsRoutes.pl.newshub.bigDataNews.root),
    robots: "index follow"
});

const generateNewshubArticleDetailsMetaTags = async (pathname: string) => {
    const id = pathname.substring(pathname.lastIndexOf("-")).replace(/\D/g, "");
    try {
        const res = await fetch(`${getBigDataApiPath()}newshub/entries/${id}`);
        const data = await res.json();
        return {
            title: `${data.title} - BIG DATA RynekPierwotny.pl`,
            description: data.lead.substring(0, data.lead.indexOf(".")),
            canonical: escape(appCanonicalUrl + pathname),
            robots: "index follow"
        };
    } catch (e) {
        return {
            title: "Najnowsze informacje rynkowe - BIG DATA RynekPierwotny.pl",
            description:
                "Śledzisz oferty nieruchomości z rynku pierwotnego w poszukiwaniu atrakcyjnej inwestycji?" +
                " Przeprowadzimy Cię przez branżowe newsy. Z nami będziesz na bieżąco.",
            canonical: escape(appCanonicalUrl + pathname),
            robots: "index follow"
        };
    }
};

/**
 * Route handlers
 */

//For future usage in other views with different meta data handlers than generateMetaTags, use new {path: "", callback: () => } in array below.
const CHARTS_ENTRIES: IEntry[] = [
    {
        path: [chartsRoutes.pl.root, chartsRoutes.en.root],
        callback: generateRootMetaTags
    },
    {
        path: [
            chartsRoutes.pl.properties.root,
            chartsRoutes.pl.flats.root,
            chartsRoutes.pl.houses.root,
            chartsRoutes.en.properties.root,
            chartsRoutes.en.flats.root,
            chartsRoutes.en.houses.root
        ],
        callback: generateHomeMetaTags
    },
    {
        path: [
            chartsRoutes.pl.properties.city.root,
            chartsRoutes.en.properties.city.root,
            chartsRoutes.pl.flats.city.root,
            chartsRoutes.en.flats.city.root,
            chartsRoutes.pl.houses.city.root,
            chartsRoutes.en.houses.city.root
        ],
        callback: generateDashboardMetaTags
    },
    {
        path: [
            chartsRoutes.pl.properties.city.prices,
            chartsRoutes.pl.flats.city.prices,
            chartsRoutes.en.properties.city.prices,
            chartsRoutes.en.flats.city.prices
        ],
        callback: generatePricesMetaTags
    },
    {
        path: [
            chartsRoutes.pl.properties.city.offer,
            chartsRoutes.pl.flats.city.offer,
            chartsRoutes.en.properties.city.offer,
            chartsRoutes.en.flats.city.offer
        ],
        callback: generateOfferMetaTags
    },
    {
        path: [
            chartsRoutes.pl.properties.city.demand,
            chartsRoutes.pl.flats.city.demand,
            chartsRoutes.en.properties.city.demand,
            chartsRoutes.en.flats.city.demand
        ],
        callback: generateDemandMetaTags
    },
    {
        path: [
            chartsRoutes.pl.properties.city.sold,
            chartsRoutes.pl.flats.city.sold,
            chartsRoutes.en.properties.city.sold,
            chartsRoutes.en.flats.city.sold
        ],
        callback: generateSoldMetaTags
    },
    {
        path: [
            chartsRoutes.pl.properties.city.added,
            chartsRoutes.pl.flats.city.added,
            chartsRoutes.en.properties.city.added,
            chartsRoutes.en.flats.city.added
        ],
        callback: generateAddedMetaTags
    },
    {
        path: [chartsRoutes.pl.monitoring.root, chartsRoutes.en.monitoring.root],
        callback: generateMonitoringMetaTags
    },
    {
        path: [chartsRoutes.pl.publications.root],
        callback: generatePublicationsMetaTags
    },
    {
        path: [chartsRoutes.pl.publications.listing],
        callback: generatePublicationsListingMetaTags
    },
    {
        path: [chartsRoutes.pl.publications.details],
        callback: generatePublicationsDetailsMetaTags
    },
    {
        path: [chartsRoutes.pl.pricing, chartsRoutes.en.pricing],
        callback: generatePricingMetaTags
    },
    {
        path: [chartsRoutes.pl.faq, chartsRoutes.en.faq],
        callback: generateFaqMetaTags
    },
    {
        path: [chartsRoutes.pl.contact, chartsRoutes.en.contact],
        callback: generateContactMetaTags
    },
    {
        path: [chartsRoutes.pl.newshub.root],
        callback: generateNewshubMetaTags
    },
    {
        path: [chartsRoutes.pl.newshub.marketInformation.root],
        callback: generateMarketInformationMetaTags
    },
    {
        path: [chartsRoutes.pl.newshub.bigDataNews.root],
        callback: generateBigDataNewsMetaTags
    },
    {
        path: [chartsRoutes.pl.newshub.marketInformation.details],
        callback: generateNewshubArticleDetailsMetaTags
    },
    {
        path: [chartsRoutes.pl.newshub.bigDataNews.details],
        callback: generateNewshubArticleDetailsMetaTags
    }
];

/**
 * Base function
 */

export const generateMetaData = async (pathname: string, i18n: i18n): Promise<IMetaData> => {
    for (const {path: paths, callback} of CHARTS_ENTRIES) {
        if (paths && paths.length > 0) {
            for (const path of paths) {
                const match = matchPath(pathname, {path, exact: true, sensitive: true, strict: true});
                if (match) {
                    return callback(pathname, i18n);
                }
            }
        }
    }
    return defaultMetaTags;
};

const mapOfferTypeToTranslationKey = (offerType: OfferType): string => {
    switch (offerType) {
        case OfferType.FLAT:
            return "flat";
        case OfferType.HOUSE:
            return "house";
        case OfferType.PROPERTY:
        default:
            return "property";
    }
};

const getDescriptionByOfferType = (offerType: OfferType, i18n: i18n): string => {
    switch (offerType) {
        case OfferType.FLAT:
            return i18n.t("home.seo.description_flat");
        case OfferType.HOUSE:
            return i18n.t("home.seo.description_house");
        case OfferType.PROPERTY:
        default:
            return i18n.t("home.seo.description_property");
    }
};

const getDashboardDescriptionByOfferType = (offerType: OfferType, i18n: i18n): string => {
    switch (offerType) {
        case OfferType.FLAT:
            return i18n.t("dashboard.seo.description_flat");
        case OfferType.HOUSE:
            return i18n.t("dashboard.seo.description_house");
        case OfferType.PROPERTY:
        default:
            return i18n.t("dashboard.seo.description_property");
    }
};
