// prepare functional (server, browser) path-to-action logic based on entries provided by app
import {match, matchPath} from "react-router";
import {Request, Response} from "express";
import {I18NextRequest} from "i18next-http-middleware";

import {IBDStore} from "../../../store/store";

// main building block of path-to-action map
export interface IPathToActionEntry<TParams extends {[K in keyof TParams]?: string | undefined}> {
    path: string | string[]; // paths to match
    exact?: boolean; // paths matching option, default is true
    action: IAction<TParams>; // action to trigger
}
// every action called by path-to-action logic
export type IAction<TParams extends {[K in keyof TParams]?: string | undefined}> = (
    ctx: IActionContext<TParams>
) => Promise<void>;
// export type IActionOutput = IState;
// context passed to every action called by path-to-actions

export interface IActionContext<TParams extends {[K in keyof TParams]?: string | undefined}> {
    store: IBDStore;
    route: IActionContextRoute; // current route, universal for server and browser
    prevRoute: IActionContextRoute | null; // previous route, passed by browser
    match: match<TParams>; // react-route matchPath's result
    req?: Request & I18NextRequest; // server only
    res?: Response; // server only
}
// route description, universal for server and browser
export interface IActionContextRoute {
    pathname: string; // server request `path` or browser location `pathname`
    // query: Record<string, string | string[]>; // server request `query` or browser location `search` parsed to object
    url: string; // server request `originalUrl` or browser location `pathname` + `search` + `hash`
    hash?: string; // browser location `hash`
}

export const createPathToAction =
    <TParams extends {[K in keyof TParams]?: string | undefined}>(pathToActionEntries: IPathToActionEntry<TParams>[]) =>
    async (
        store: IBDStore,
        route: IActionContextRoute,
        prevRoute: IActionContextRoute | null,
        req?: Request,
        res?: Response
    ): Promise<void> => {
        const matchedEntry = matchFetchEntry<TParams>(pathToActionEntries, route.pathname);
        if (matchedEntry == null) {
            return;
        }
        const ctx: IActionContext<TParams> = {store, route, prevRoute, match: matchedEntry.match, req, res};
        await matchedEntry.entry.action(ctx);
    };

/**
 * Utils
 */

// result of `matchFetchEntry` block
interface IMatchedEntry<TParams extends {[K in keyof TParams]?: string | undefined}> {
    match: match<TParams>; // result of `matchPath` logic
    entry: IPathToActionEntry<TParams>; // path-to-action entry matched by `matchPath`
}
// helper that operates on path-to-action map and matches entries to given `pathname`
const matchFetchEntry = <TParams extends {[K in keyof TParams]?: string | undefined}>(
    pathToActionEntries: IPathToActionEntry<TParams>[],
    pathname: string
): IMatchedEntry<TParams> | null => {
    let matchedEntry: IPathToActionEntry<TParams> | null = null;
    let match: match<TParams> | null = null;
    for (const entry of pathToActionEntries) {
        const paths = Array.isArray(entry.path) ? entry.path : [entry.path];
        for (const path of paths) {
            match = matchPath(pathname, {path, exact: entry.exact !== false, sensitive: true, strict: true}); // default for `exact` is `true`
            if (match) {
                break; // we look for first path-match
            }
        }
        if (match) {
            // path does match
            matchedEntry = entry;
            break; // we look for first entry-match onlyIPathToActionEntry
        }
    }
    // prepare matches
    return match && matchedEntry ? {match, entry: matchedEntry} : null;
};
