import React from "react";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {css} from "@emotion/react";
import {Button} from "@pg-design/button";
import {FormikForm} from "@pg-design/formik-utils";
import {calculateRemSize, flex, flexDirection, mb, onDesktop, ph, w100} from "@pg-design/helpers-css";
import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {chartsRoutes, getLocalizedUrl} from "../../../../common/app/routing/charts_routes";
import {handleFormikSubmit} from "../../../../components/form/handle_submit";
import {SubmitFormikButton} from "../../../../components/SubmitFormikButton";
import {useChangePasswordMutation} from "../../api/changePasswordApi";

interface IChangePasswordFormValues {
    current_password: string;
    new_password: string;
}

export const ChangePasswordForm = () => {
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const [changePassword] = useChangePasswordMutation();

    const initialValues: IChangePasswordFormValues = {
        current_password: "",
        new_password: ""
    };

    const validationSchema = Yup.object({
        current_password: Yup.string()
            .max(254, t("common.form.max254validation"))
            .required(t("common.form.required_field")),
        new_password: Yup.string().required(t("common.form.required_field")).min(8, t("common.form.min8validation"))
    });

    const onSubmit = async (values: IChangePasswordFormValues, helpers: FormikHelpers<IChangePasswordFormValues>) =>
        handleFormikSubmit(
            changePassword({
                password: values.current_password,
                new_password: values.new_password
            }).unwrap(),
            helpers,
            {
                onSuccess: () => {
                    helpers.resetForm();
                    history.push(getLocalizedUrl(chartsRoutes[i18n.language].user.edit, i18n.language));
                }
            }
        );

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {({isSubmitting, isValid, handleBlur}) => (
                    <Form>
                        <FormikForm.Input
                            onBlur={handleBlur}
                            css={mb(1.5)}
                            type="password"
                            name="current_password"
                            placeholder={t("auth.change_password.current_password")}
                        />

                        <FormikForm.Input
                            onBlur={handleBlur}
                            css={[mb(4), onDesktop(mb(6))]}
                            type="password"
                            name="new_password"
                            placeholder={t("auth.change_password.new_password")}
                        />

                        <div css={buttons}>
                            <SubmitFormikButton
                                copy={t("auth.change_password.change_password")}
                                disabled={isSubmitting || !isValid}
                                css={[mb(3), onDesktop(mb(0)), ph(6)]}
                            />

                            <Link to={chartsRoutes[i18n.language].user.edit}>
                                <Button type="button" css={[w100, ph(6)]} variant="none_secondary">
                                    {t("common.cancel")}
                                </Button>
                            </Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const buttons = css`
    ${flex()};
    ${flexDirection("column")};

    ${onDesktop(css`
        ${flexDirection("row")};
        gap: ${calculateRemSize(3)};
        justify-content: center;
    `)}
`;
