import {HTMLAttributeAnchorTarget} from "react";
import {useTranslation} from "react-i18next";
import {useRouteMatch} from "react-router-dom";
import {toast} from "react-toastify";
import {css, Theme, useTheme} from "@emotion/react";
import {Container} from "@pg-design/grid";
import {flexJustifyCenter, onDesktop, pr} from "@pg-design/helpers-css";
import {flex, flexDirection, m, mt, mv, p, pt, pv} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {bugsnagNotify} from "../../../../../../utils/bugsnag";
import {dataLayer} from "../../../../../../utils/data_layer";
import {flatsLinks, housesLinks, rpOffersLinks, staticLinks} from "../../../../common/app/constants/footer";
import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {Brand} from "../../../../components/nav/Brand";
import {FooterMenuElement} from "./FooterMenuElement";
import {FooterPanelExpandable} from "./FooterPanelExpandable";

interface IProps {
    className?: string;
    isAnalyticalPlatform?: boolean;
}

declare global {
    interface Window {
        Cookiebot: {renew: () => void};
    }
}

export const Footer = ({isAnalyticalPlatform, className}: IProps) => {
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();

    const {t, i18n} = useTranslation();

    const handleLinkClick = (linkText: string) => {
        dataLayer({
            event: "footer",
            button: linkText,
            city: city ?? ""
        });
    };

    const openCookieModal = () => {
        try {
            window.Cookiebot.renew();
        } catch (err) {
            toast.error("Nie udało się otworzyć okna ustawień cookies, spróbuj później");
            bugsnagNotify(err as Error, {source: "openCookieModal"});
        }
    };

    const theme = useTheme();

    return (
        <Container fluid css={footerBg} as="section" className={className}>
            <footer css={footerContent}>
                <FooterPanelExpandable title={t("footer.flats_prices_in_poland")}>
                    <ul css={contentWrapper}>
                        {flatsLinks(i18n).map((element) => (
                            <FooterMenuElement
                                external={false}
                                onClick={() => window.scrollTo(0, 0)}
                                key={element.title.toLowerCase()}
                                title={element.title}
                                url={element.href}
                                css={columnLinks}
                            />
                        ))}
                    </ul>
                </FooterPanelExpandable>

                <FooterPanelExpandable title={t("footer.houses_prices_in_poland")}>
                    <ul css={contentWrapper}>
                        {housesLinks(i18n).map((element) => (
                            <FooterMenuElement
                                external={false}
                                onClick={() => window.scrollTo(0, 0)}
                                key={element.title.toLowerCase()}
                                title={element.title}
                                url={element.href}
                                css={columnLinks}
                            />
                        ))}
                    </ul>
                </FooterPanelExpandable>

                {isAnalyticalPlatform && (
                    <>
                        <FooterPanelExpandable title={t("footer.developers_offers")}>
                            <ul css={contentWrapper}>
                                {rpOffersLinks(i18n).map((element) => (
                                    <FooterMenuElement
                                        external
                                        key={element.title.toLowerCase()}
                                        title={element.title}
                                        url={element.href}
                                        css={columnLinks}
                                        target={element.target as string}
                                    />
                                ))}
                            </ul>
                        </FooterPanelExpandable>
                    </>
                )}

                <nav css={logoAndLinksWrapper}>
                    <Brand css={logoWrapper} />

                    <ul css={links}>
                        {staticLinks(i18n).map((link) => (
                            <FooterMenuElement
                                external={!!link.external}
                                key={link.title}
                                url={link.href}
                                title={link.title}
                                onClick={() => {
                                    if (!link.external) {
                                        window.scrollTo(0, 0);
                                    }
                                    handleLinkClick(link.title);
                                }}
                                target={link.target as HTMLAttributeAnchorTarget}
                                rel={link.rel as string}
                            />
                        ))}

                        <FooterMenuElement
                            external={true}
                            key="cookie"
                            title={t("nav.cookies")}
                            onClick={openCookieModal}
                        />
                    </ul>
                </nav>

                <div css={copyrightsWrapper}>
                    <Text variant="info_txt_1" color={theme.colors.gray[500]}>
                        {t("footer.copyrights", {
                            year: new Date().getFullYear(),
                            interpolation: {escapeValue: false}
                        })}
                    </Text>
                </div>
            </footer>
        </Container>
    );
};

const footerBg = (theme: Theme) => css`
    background: ${theme.colors.secondary};
`;

const footerContent = css`
    margin: 0 auto;
    ${p(2, 1.5, 2, 1.5)};
    max-width: 1680px;

    ${onDesktop(css`
        ${pv(4)}
    `)}
`;

const links = css`
    ${flex()};
    ${flexDirection("column")};

    ${onDesktop(css`
        column-gap: 2.8rem;
        ${flexDirection("row")};
    `)}
`;

const logoAndLinksWrapper = css`
    ${flex("start", "space-between")}
    ${flexDirection("column-reverse")};
    ${mv(4)};

    ${onDesktop(css`
        align-items: center;
        ${flexDirection("row")};
        ${m(6, 0, 0, 0)};
    `)}
`;

const contentWrapper = css`
    width: 100%;

    ${onDesktop(css`
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(4, 1fr);
        ${p(1, 0, 6, 0)};
    `)}
`;

const columnLinks = css`
    &:not(:nth-of-type(4n)) {
        ${pr(1)};
    }
`;

const copyrightsWrapper = css`
    ${flex("initial", "flex-start")}

    ${onDesktop(css`
        ${pt(4)};
        ${flexJustifyCenter};
    `)}
`;

const logoWrapper = (theme: Theme) => css`
    ${mt(2)};

    @media (max-width: ${theme.breakpoints.md}) {
        height: 41px;
        width: 191px;
    }

    ${onDesktop(css`
        ${mt(0)}
    `)}
`;
