import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {css, Theme, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {borderRadius, flexAlignCenter, flexDirection, h100, mb, mt, mv, onDesktop, p, ph} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons";
import {getTextVariantStyleFor, Text} from "@pg-design/text";

import {dataLayer} from "../../../../../../utils/data_layer";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {UserAccessStatus} from "../../../../../../utils/shared_types/user_model";
import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {setResponseStatus} from "../../../app_status/app_status_slice";
import {selectAuthStatus, setAuthModal} from "../../../auth/auth_slice";
import {ResponseStatus} from "../../../ssr/path_to_action/state/app_status/IAppStatusState";
import {useGetContentQuery} from "../../../static_content/api/get_content";
import {StaticContent} from "../../../static_content/types/Content";
import {selectUserData} from "../../../users/users_slice";
import {RejectedAccessRequestModal} from "./RejectedAccessRequestModal";

interface IBenefitBoxProps {
    html?: string;
}

const BenefitBox = ({html}: IBenefitBoxProps) => {
    const theme = useTheme();

    return (
        <Col lg={4} md={3} sm={4}>
            <div css={boxWrapper}>
                <BrandCheckboxCheckedIcon size="4" wrapperSize="4" wrapperColor={theme.colors.primary} />
                {html && <div css={htmlWrapper} dangerouslySetInnerHTML={{__html: html}} />}
            </div>
        </Col>
    );
};

const boxWrapper = css`
    ${p(4, 2)};
    ${mb(3)};
    ${flexAlignCenter};
    ${flexDirection("column")};
    ${borderRadius(1)};
    background: #fff;

    ${onDesktop(h100)};
`;

const htmlWrapper = (theme: Theme) => css`
    h3 {
        ${mv(3)};
        ${getTextVariantStyleFor("headline_3")(theme)}
        text-align: center;
    }

    p {
        ${getTextVariantStyleFor("body_copy_1")(theme)};
        text-align: center;
    }
`;

export const Benefits = () => {
    const {t, i18n} = useTranslation();
    const {isLoggedIn, isAuthorized} = useAppSelector(selectAuthStatus);
    const userData = useAppSelector(selectUserData);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [isRejectedModalOpen, setIsRejectedModalOpen] = useState<boolean>(false);
    const {data, isError: isLeadError} = useGetContentQuery(StaticContent.HOMEPAGE_DESCRIPTION);
    const {data: benefit1, isError: isBenefit1Error} = useGetContentQuery(StaticContent.HOMEPAGE_ADV_1);
    const {data: benefit2, isError: isBenefit2Error} = useGetContentQuery(StaticContent.HOMEPAGE_ADV_2);
    const {data: benefit3, isError: isBenefit3Error} = useGetContentQuery(StaticContent.HOMEPAGE_ADV_3);
    const {data: benefit4, isError: isBenefit4Error} = useGetContentQuery(StaticContent.HOMEPAGE_ADV_4);

    const isError = isLeadError || isBenefit1Error || isBenefit2Error || isBenefit3Error || isBenefit4Error;

    if (isError) {
        dispatch(setResponseStatus({responseStatus: ResponseStatus.PAGE_500}));
        return null;
    }

    const handleButtonClick = () => {
        if (!isLoggedIn) {
            dataLayer({
                event: "login_modal_view",
                position: "center_request_button"
            });
            dataLayer({
                event: "get_access",
                position: "center_request_button"
            });
        }

        if (isLoggedIn) {
            dataLayer({
                event: "get_access",
                position: "center_request_button"
            });

            if (isAuthorized) {
                history.push(chartsRoutes[i18n.language].properties.root);
            }
            if (
                (userData && userData.access_status === UserAccessStatus.REJECTED) ||
                (userData && userData.access_status === UserAccessStatus.PENDING)
            ) {
                setIsRejectedModalOpen(true);
            }
        } else {
            dispatch(setAuthModal("login"));
        }
    };

    return (
        <>
            <Container css={[mv(8), onDesktop(mv(9)), flexAlignCenter, flexDirection("column")]} as="section">
                <Row>
                    <Col lg={12} lgOffset={2} md={8} mdOffset={2} sm={4}>
                        {data && data.content_pl && data.content_en && (
                            <div
                                css={leadWrapper}
                                dangerouslySetInnerHTML={{
                                    __html: i18n.language === "pl" ? data.content_pl : data.content_en
                                }}
                            />
                        )}
                    </Col>
                </Row>

                <Text css={[mt(9), mb(6)]} align="center" as="h3" variant="headline_1">
                    {t("landing.benefits.heading")}
                </Text>

                <Row>
                    {[benefit1, benefit2, benefit3, benefit4].map((benefit, index) => (
                        <BenefitBox
                            key={index}
                            html={i18n.language === "pl" ? benefit?.content_pl : benefit?.content_en}
                        />
                    ))}
                </Row>

                <Button css={[mt(8), onDesktop(mt(9))]} variant="filled_primary" onClick={handleButtonClick}>
                    {t("landing.benefits.buttonCTA")}
                </Button>
            </Container>

            <RejectedAccessRequestModal isOpen={isRejectedModalOpen} onClose={() => setIsRejectedModalOpen(false)} />
        </>
    );
};

const leadWrapper = (theme: Theme) => css`
    p {
        ${ph(1)};
        ${getTextVariantStyleFor("headline_3")(theme)};
        text-align: center;
    }
`;
