import fetch from "cross-fetch";

import {chartsApiPath} from "../../../../../../api/src/constants/api_path";
import {substractOneMonth} from "../../../../../../utils/dates";
import {chartsApiUrl, isServer} from "../../../../common/app/read_charts_web_environment_variables";
import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {setHomepageData} from "../../redux/homepage_slice";
import {IActionContext} from "../create_path_to_action";
import {fetchLastAvailableEntry} from "./fetch_last_available_date";
import {setAuthAndUser} from "./set_auth_and_user";
import {setLatestEntryAction} from "./set_latest_entry";

export const homepageAction = async (ctx: IActionContext<Omit<ICityParams, "city">>) => {
    await setAuthAndUser(ctx.store);

    // fetch current date and calculate previous month for data fetching purposes
    const {currentDate} = await fetchLastAvailableEntry();
    const before2Months = substractOneMonth(currentDate);
    const lastMonth = currentDate;
    // prepare fetch required headers with session ID
    const fetchApiHeaders = isServer
        ? {headers: {Cookie: `bd_sessionid=${ctx.req?.cookies["bd_sessionid"] ?? ""}`}}
        : undefined;
    // define api url - empty string for local development, env variable for live environments
    const apiUrl = chartsApiUrl ?? "";
    // fetch data required to generate homepage content - analyst comments
    const dataUrlParams = new URLSearchParams({
        slug_city: "main", // main = magic keyword for fetching 6 main cities
        date_start: before2Months,
        date_end: lastMonth,
        scenario: "home"
    }).toString();
    const cityDataUrl = `${apiUrl}${chartsApiPath.biggestRegionsStats}?${dataUrlParams}`;
    const cityDataRequest = await fetch(cityDataUrl, fetchApiHeaders);
    const cityData = await cityDataRequest.json();
    if (!cityData || cityData.length === 0) {
        throw new Error(`homepageAction: cannot fetch data from URL: ${cityDataUrl}`);
    }
    const cityTypeDataUrl = `${apiUrl}${chartsApiPath.biggestRegionsTypeStats}?${dataUrlParams}`;
    const cityTypeDataRequest = await fetch(cityTypeDataUrl, fetchApiHeaders);
    const cityTypeData = await cityTypeDataRequest.json();
    if (!cityTypeData || cityTypeData.length === 0) {
        throw new Error(`homepageAction: cannot fetch data from URL: ${cityTypeDataUrl}`);
    }

    await setLatestEntryAction(ctx.store);
    ctx.store.dispatch(setHomepageData({cityData, cityTypeData}));
};
