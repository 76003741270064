import {PropsWithChildren, ReactNode, useEffect} from "react";
import {useLocation} from "react-router-dom";

import {bugsnagNotify} from "../../../../../../utils/bugsnag";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {usePrevious} from "../../../../../../utils/hooks/usePrevious";
import {consoleError} from "../../../../../../utils/logger";
import {IBDStore} from "../../../../store/store";
import {selectResponseStatus, setResponseStatus} from "../../../app_status/app_status_slice";
import {chartsPathToAction} from "../charts_path_to_action";
import {IActionContextRoute} from "../create_path_to_action";
import {ResponseStatus} from "./app_status/IAppStatusState";

interface IInitialStateProps {
    children: ReactNode | Array<ReactNode>;
    store: IBDStore;
}

export const InitialState = (props: PropsWithChildren<IInitialStateProps>) => {
    const location = useLocation();
    const previousLocation = usePrevious(location, location);
    const dispatch = useAppDispatch();
    const responseStatus = useAppSelector(selectResponseStatus);

    useEffect(() => {
        (async () => {
            if (previousLocation.key === location.key) {
                return;
            }
            // when location has changed we invalidate response status, so we can navigate out of the error page
            if (responseStatus !== ResponseStatus.DEFAULT) {
                // disable 404, 500, etc.
                dispatch(setResponseStatus({responseStatus: ResponseStatus.DEFAULT}));
            }
            const currentRoute: IActionContextRoute = {
                pathname: location.pathname,
                // query: parseSearch(location.search),
                url: location.pathname + location.search + location.hash,
                hash: location.hash
            };
            const prevRoute: IActionContextRoute = {
                pathname: previousLocation.pathname,
                // query: parseSearch(previousLocation.search),
                url: previousLocation.pathname + previousLocation.search + previousLocation.hash,
                hash: previousLocation.hash
            };
            try {
                await chartsPathToAction(props.store, currentRoute, prevRoute);
            } catch (err) {
                if (err instanceof DOMException && "name" in err && err.name === "AbortError") {
                    // Firefox treats aborting of fetches as errors
                } else {
                    consoleError("InitialState path-to-action: ", err);
                    bugsnagNotify(err as Error, {source: "InitialState.chartsPathToAction", route: currentRoute});
                    dispatch(setResponseStatus({responseStatus: ResponseStatus.PAGE_500}));
                }
            }
        })();
    }, [previousLocation.key, location.key]);

    return <>{props.children}</>;
};
