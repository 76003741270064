import {IPublication} from "../types/Publication";
import {publicationsApi} from "./publications_api";

export const getPublicationsApi = publicationsApi.injectEndpoints({
    endpoints: (build) => ({
        getPublications: build.query<{results: Array<IPublication>}, void>({
            query: () => ({
                url: "publications/",
                method: "GET"
            }),
            providesTags: (result = {results: []}) => [
                "Publication",
                ...result.results.map(({id}) => ({type: "Publication" as const, id}))
            ]
        }),
        getPublicationById: build.query<IPublication, string>({
            query: (publicationId) => ({
                url: `publications/${publicationId}`,
                method: "GET"
            })
        }),
        getPublicationsByPage: build.query<
            {results: Array<IPublication>; count: number; page: number; page_size: number},
            number
        >({
            query: (page = 1, page_size = 12) => ({
                url: `publications/?page=${page}&page_size=${page_size}`,
                method: "GET"
            })
        })
    })
});

export const {useGetPublicationsQuery, useGetPublicationByIdQuery, useGetPublicationsByPageQuery} = getPublicationsApi;
