import * as React from "react";
import ReactGoogleMapsLoader from "react-google-maps-loader";
import {each} from "lodash-es";

import {
    calculateRefMarkersMap,
    MarkerDefinition,
    MarkerGroupDefinition,
    removeAllMarkers,
    updateMarkers
} from "./markers";
import {PolygonDefinition, PolygonGroupDefinition, PolygonOptions, removeAllPolygons, updatePolygons} from "./polygons";
import GoogleMaps = ReactGoogleMapsLoader.GoogleMaps;
import {Position} from "geojson";

type gMapMarker = google.maps.Marker;
type gMapPolygon = google.maps.Polygon;

export interface IGoogleMapOwnProps {
    className?: string;
    config: google.maps.MapOptions;
    markers?: Record<string, MarkerGroupDefinition>;
    polygons?: Record<string, PolygonGroupDefinition>;
    infoWindow?: boolean;
    googleMaps?: GoogleMaps;
    // configuration
    fitBoundsOnUpdate: boolean;
    // callbacks
    onInitSuccess?: () => void;
}

export interface IMapInternalData {
    map: google.maps.Map | undefined;
    markers: Record<string, gMapMarker[]>;
    polygons: Record<string, gMapPolygon[]>;
    refInfoWindows: google.maps.InfoWindow[];
    refMarkersMap: Record<string, gMapMarker>;
}

export default class LazyGoogleMap extends React.PureComponent<IGoogleMapOwnProps, {}> {
    public static defaultProps = {fitBoundsOnUpdate: false};

    private isInitialized = false; // map's component initialized
    private container = React.createRef<HTMLDivElement>(); // map's HTML element
    private data: IMapInternalData; // map's internal storage

    constructor(props: IGoogleMapOwnProps) {
        super(props);
        this.data = {
            map: undefined,
            markers: {},
            polygons: {},
            refInfoWindows: [],
            refMarkersMap: {}
        };
    }

    /**
     * Lifecycle
     */

    public componentDidMount() {
        setTimeout(() => {
            this.onLoad(this.props.googleMaps as GoogleMaps);
        }, 0);
    }

    public componentDidUpdate(prevProps: IGoogleMapOwnProps): void {
        this.update(this.props, prevProps);
        if (this.props.fitBoundsOnUpdate) {
            this.fitBounds();
        }
    }

    public componentWillUnmount(): void {
        // Remove map tracking event listeners.
        const {map} = this.data;
        map && google.maps.event.clearInstanceListeners(map);
        // Destroys map instance carrying about all its internal data and objects.
        removeAllMarkers(this.data.markers);
        this.data.refMarkersMap = {};
        removeAllPolygons(this.data.polygons);
        this.clearInternalData();
    }

    /**
     * Helpers
     */
    private onLoad = (googleMaps: GoogleMaps) => {
        if (!this.isInitialized) {
            this.isInitialized = true;

            this.initializeMap(googleMaps);
            this.update(this.props, null);
            this.fitBounds();
        }
    };

    private initializeMap(googleMaps: GoogleMaps): void {
        this.data.map = new googleMaps.Map(this.container.current!, this.props.config);
        this.props.onInitSuccess && this.props.onInitSuccess();
    }

    // Updates map relying on given props, if component is already initialized.
    private update(props: IGoogleMapOwnProps, prevProps: IGoogleMapOwnProps | null): void {
        if (!this.isInitialized) {
            return;
        }

        const {markers: markersDefinition, polygons: polygonsDefinition} = props;
        if (markersDefinition) {
            this.data.markers = updateMarkers(this.data, markersDefinition);
            this.data.refMarkersMap = calculateRefMarkersMap(markersDefinition, this.data.markers);
        }
        if (polygonsDefinition) {
            this.data.polygons = updatePolygons(this.data, polygonsDefinition);
        }
    }

    public fitBounds(): void {
        const googleAllBounds = new google.maps.LatLngBounds();
        // add all markers to Bounds
        if (this.props.markers) {
            each(this.props.markers, (googleMarkerGroup: MarkerGroupDefinition) => {
                each(googleMarkerGroup.list, (googleMarker: MarkerDefinition) => {
                    googleAllBounds.extend({lat: googleMarker.coords[1], lng: googleMarker.coords[0]});
                });
            });
        }
        // add all points from poligons paths to Bounds
        if (this.props.polygons) {
            each(this.props.polygons, (polygonsGroup: PolygonGroupDefinition) => {
                each(polygonsGroup, (googlePolygonArray?: Array<PolygonDefinition> | PolygonOptions) => {
                    each(googlePolygonArray, (googlePolygon: PolygonDefinition) => {
                        each(googlePolygon.coords, (areasArray: Array<Array<Position>>) => {
                            each(areasArray, (singlePath: Array<Position>) => {
                                each(singlePath, ([lng, lat]: Position) => {
                                    googleAllBounds.extend({lat, lng});
                                });
                            });
                        });
                    });
                });
            });
        }
        // do it only if the map is not empty
        if (!googleAllBounds.isEmpty() && this.data.map) {
            this.data.map.fitBounds(googleAllBounds);
        }
    }

    private clearInternalData(): void {
        this.data = {
            map: undefined,
            markers: {},
            polygons: {},
            refInfoWindows: [],
            refMarkersMap: {}
        };
    }

    /**
     * Render
     */

    // this.onLoad(googleMaps);

    public render() {
        if (this.props.googleMaps) {
            return <div ref={this.container} style={{width: "100%", height: "100%", minHeight: "450px"}} />;
        }
        return null;
    }
}
