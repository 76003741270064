import {css, Theme} from "@emotion/react";
import {elevation} from "@pg-design/elevation";
import {
    backgroundColor,
    borderRadius,
    calculateRemSize,
    color,
    flex,
    flexDirection,
    h100,
    imageResponsive,
    listUnStyled,
    m,
    p,
    pointer,
    position,
    pt,
    pv,
    w50
} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Link} from "@pg-design/link";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";
import {format, isFuture, isValid, parseISO} from "date-fns";
import {pl} from "date-fns/locale";

import {useResponsiveOpenLink} from "../../../../../utils/hooks/useResponsiveOpenLink";
import {rpWebUrl} from "../../../common/app/read_charts_web_environment_variables";
import {truncate} from "../../css_helpers/truncate";
import {IOfferBoxOffer, OfferType} from "../mocks/singleOfferMock";
import {OfferAddress} from "./OfferAddress";

export interface IOfferBoxProps {
    offer: IOfferBoxOffer | null;
    unlinkVendor?: boolean;
}

export const OfferBox = ({offer, unlinkVendor}: IOfferBoxProps) => {
    const offerLink = `${rpWebUrl}/oferty/${offer?.vendor.slug}/${offer?.slug}-${offer?.id}/`;
    const vendorLink = `${rpWebUrl}/deweloperzy/${offer?.vendor.slug}-${offer?.vendor.id}/`;
    const openResponsiveLink = useResponsiveOpenLink(offerLink);

    const formatPL = (date: Date, formatStr: string) => {
        return format(date, formatStr, {
            locale: pl
        });
    };

    const formatFutureDate = (
        dateString: string | null = null,
        dateFormat = "dd MMMM yyyy",
        readyPhrase?: string
    ): string => {
        const date = dateString ? parseISO(dateString) : new Date();
        return isValid(date)
            ? isFuture(date)
                ? formatPL(date, dateFormat)
                : readyPhrase
                  ? readyPhrase
                  : "gotowe"
            : "";
    };

    const getOfferTypeNamePluralWhatCapital = (offerType: OfferType) => {
        switch (offerType) {
            case OfferType.FLAT:
                return "Mieszkań";
            case OfferType.HOUSE:
                return "Domów";
            case OfferType.COMMERCIAL:
                return "Lokali użytkowych";
            default:
                throw new Error("Unknown offer type");
        }
    };

    return (
        <div css={offerBoxBase}>
            {offer ? (
                <>
                    <Link href={offerLink} css={offerBoxLink} title={offer.name} target="_blank">
                        <div>
                            <Image
                                alt={offer.name}
                                src={offer.main_image.m_img_375x211}
                                css={offerImage}
                                width="375"
                                height="211"
                            />

                            {offer.stats.ranges_price_m2_min > 0 ? (
                                <div css={offerBoxPriceHolder}>
                                    <span>od:</span> {offer.stats.ranges_price_m2_min} zł/m<sup>2</sup>
                                </div>
                            ) : null}
                        </div>
                    </Link>

                    <div css={[p(0, 2, 1), pointer]}>
                        <Text as="h2" variant="headline_6" css={[pt(1), truncate]} onClick={openResponsiveLink}>
                            {offer.name}
                        </Text>

                        <Text as="h3" variant="info_txt_1" css={[pv(0.5), truncate]}>
                            {unlinkVendor ? (
                                <span css={[offerBoxVendor]}>{offer.vendor.name}</span>
                            ) : (
                                <Link href={vendorLink} target="_blank" size="small" css={[offerBoxLink, truncate]}>
                                    {offer.vendor.name}
                                </Link>
                            )}
                        </Text>

                        <div onClick={openResponsiveLink}>
                            <OfferAddress offer={offer} showType />

                            <ul css={offerBoxPropertiesInfoList}>
                                <li css={[offerBoxPropertiesInfoListItem, w50]}>
                                    Oddanie:{" "}
                                    <b>{formatFutureDate(offer.construction_date_range.upper, "Q 'kw.' yyyy")}</b>
                                </li>

                                <li css={[offerBoxPropertiesInfoListItem, w50]}>
                                    Metraż:{" "}
                                    <b>
                                        {offer.stats.ranges_area_min === offer.stats.ranges_area_max
                                            ? offer.stats.ranges_area_min
                                            : `${offer.stats.ranges_area_min}-${offer.stats.ranges_area_max}`}{" "}
                                        m<sup>2</sup>
                                    </b>
                                </li>

                                <li css={[offerBoxPropertiesInfoListItem, w50]}>
                                    {getOfferTypeNamePluralWhatCapital(offer.type)}:{" "}
                                    <b>{offer.stats.properties_count_for_sale}</b>
                                </li>

                                {offer.type !== OfferType.COMMERCIAL && (
                                    <li css={[offerBoxPropertiesInfoListItem, w50]}>
                                        Pokoje:{" "}
                                        <b>
                                            {offer.stats.ranges_rooms_min === offer.stats.ranges_rooms_max
                                                ? offer.stats.ranges_rooms_min
                                                : `${offer.stats.ranges_rooms_min}-${offer.stats.ranges_rooms_max}`}
                                        </b>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </>
            ) : (
                <div css={[flex("center", "center"), h100]}>
                    <Loader />
                </div>
            )}
        </div>
    );
};

const offerBoxBase = css`
    ${elevation(2)};
    border-radius: ${calculateRemSize(1)};
    width: 100%;
    max-width: 32.5rem;
    height: 100%;
    overflow: hidden;
    ${m(0, "auto")};
    ${flex()};
    ${flexDirection("column")};
    ${backgroundColor("#fff")};
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
        ${elevation(3)};
    }
`;

const offerImage = css`
    & > img {
        ${imageResponsive};
    }
`;

const offerBoxLink = (theme: Theme) => css`
    display: block;
    color: ${theme.colors.gray[700]};

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
`;

const offerBoxVendor = (theme: Theme) => css`
    color: ${theme.colors.gray[700]};
    ${p(0.5, 0)};
`;

const offerBoxPriceHolder = (theme: Theme) => css`
    ${position("absolute")};
    top: ${calculateRemSize(2)};
    left: ${calculateRemSize(2)};
    ${p(0.5, 1.5)};
    ${borderRadius(2)};
    ${backgroundColor("#fff")};
    ${color(theme.colors.secondary)};
    font-size: ${calculateRemSize(1.5)};
    font-weight: 500;
`;

const offerBoxPropertiesInfoList = css`
    ${pt(1)};
    ${listUnStyled};
    ${flex()};
    flex-wrap: wrap;
`;

const offerBoxPropertiesInfoListItem = css`
    font-size: ${calculateRemSize(1.5)};
    ${p(0.5, 0)};
`;
