import {INewsParams} from "../../../../common/app/routing/charts_routes";
import {getCategoriesApi} from "../../../newshub/api/get_categories";
import {getEntriesApi} from "../../../newshub/api/get_entries";
import {EntryType} from "../../../newshub/types/Entry";
import {IActionContext} from "../create_path_to_action";
import {ResponseStatus} from "../state/app_status/IAppStatusState";
import {setAuthAndDate} from "./common_action";
import {setError} from "./set_error";

export const newshubDetailsAction = async (ctx: IActionContext<INewsParams>): Promise<void> => {
    await setAuthAndDate(ctx);

    const {newsId} = ctx.match.params;

    const {data} = await ctx.store.dispatch(getEntriesApi.endpoints.getEntriesById.initiate(newsId));

    if (!data) {
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }

    ctx.store.dispatch(getCategoriesApi.endpoints.getCategories.initiate());

    const categoryId = data?.category && data.category.length > 0 ? data.category : null;

    ctx.store.dispatch(
        getEntriesApi.endpoints.getArticlesByCategories.initiate({category: categoryId ? categoryId : [], page: 1})
    );
    ctx.store.dispatch(getEntriesApi.endpoints.getEntriesByType.initiate(EntryType.BIG_DATA_NEWS));

    await Promise.all(ctx.store.dispatch(getEntriesApi.util.getRunningQueriesThunk()));
};
