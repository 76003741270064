import {lazy, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@emotion/react";
import {Options, SeriesSplineOptions} from "highcharts";

import {IMonthCityStats} from "../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../api/src/db_queries/month_city_type_stats_query";
import {calculateDifference} from "../../../../utils/calculate_percentage_difference";
import {formatDate} from "../../../../utils/dates";
import {formatNumber} from "../../../../utils/format";
import {AccessStatus, useAccessStatus} from "../../../../utils/hooks/useAccessStatus";
import {useTypeFilteredData} from "../../../../utils/hooks/useTypeFilteredData";
import {FilterOfferType} from "../../common/app/constants/enums";
import {IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";

const Chart = lazy(() => import("./ChartDefaultExport"));

export interface ISplineChartProps extends IChartProps {
    data: any[];
    field: string;
    offerType?: FilterOfferType;
    roundValues?: boolean;
    valuesUnit?: string;
    currentLabelUnit?: string; // used word must exist in "common" key in translations with proper plural forms
    currentLabelUnitContext?: "male" | "female" | "neutral"; // eventual proper form for unit label
    valueMultiplier?: number;
}

export const SplineChart = (props: ISplineChartProps) => {
    const theme = useTheme();
    const {
        data,
        field,
        offerType,
        roundValues,
        valuesUnit,
        currentLabelUnit,
        currentLabelUnitContext,
        valueMultiplier = 1
    } = props;

    const {t, i18n} = useTranslation();

    const filteredData = useTypeFilteredData(data, offerType);

    const [difference, setDifference] = useState<number>(0);
    const [currentValue, setCurrentValue] = useState<number>(0);
    const accessStatus = useAccessStatus();

    useEffect(() => {
        if (filteredData && filteredData.length > 0) {
            setDifference(calculateDifference(filteredData[filteredData.length - 1][field], filteredData[0][field]));
            setCurrentValue(filteredData[filteredData.length - 1][field]);
        }
    }, [filteredData]);

    const shouldDisplayYearlyChange = !filteredData.find((el) => el[field] === 0);

    const series: SeriesSplineOptions[] = useMemo(() => {
        if (filteredData && filteredData.length > 0) {
            const dataWithoutNulls = filteredData.map((month) => {
                if (month[field] === null) {
                    month[field] = 0;
                }
                return month;
            });

            const values = dataWithoutNulls.map((month: any, i) => {
                if (month[field] === null) {
                    for (let n = i; n >= 0; n--) {
                        if (filteredData[n - 1] !== null) {
                            return filteredData[n - 1][field];
                        }
                    }
                }

                return month[field];
            });

            const highestIndex = values.findIndex((value: number) => value === Math.max(...values));
            const lowestIndex = values.findIndex((value: number) => value === Math.min(...values));

            const lowestMonth = formatDate(filteredData[lowestIndex].date, "MMM", i18n);
            const highestMonth = formatDate(filteredData[highestIndex].date, "MMM", i18n);

            return [
                {
                    type: "spline",
                    data: values.map((value, i) => {
                        const enabled = i === highestIndex || i === lowestIndex;

                        const roundedValue = roundValues ? Math.round(value) : value;

                        const multipliedValue = roundedValue * valueMultiplier;

                        return {
                            y: multipliedValue,
                            marker: {enabled},
                            dataLabels: {
                                enabled,
                                formatter: function (_) {
                                    const month = i === lowestIndex ? lowestMonth : highestMonth;

                                    return `${month}: ${formatNumber(this.point.y || 0)} ${valuesUnit ?? ""}`;
                                },
                                y: i === lowestIndex ? 10 : -50
                            }
                        };
                    })
                }
            ];
        }

        return [];
    }, [filteredData, i18n.language]);

    const options: Options = {
        annotations: [
            {
                draggable: "",
                labels: [
                    {
                        ...labelConfig,
                        point: {
                            x: 0,
                            y: -45,
                            xAxis: null,
                            yAxis: null
                        },
                        style: {
                            ...labelConfig.style,
                            color: Math.sign(difference) > 0 ? theme.colors.secondary : theme.colors.danger,
                            display: shouldDisplayYearlyChange ? "block" : "none"
                        },
                        text: `<span style="display: block; color: ${
                            theme.colors.gray[700]
                        }}; font-weight: 400; font-size: 10px;">${t(
                            accessStatus === AccessStatus.FULL ? "common.year_over_year_change" : "common.change"
                        )}</span><br /><span>${Math.sign(difference) > 0 ? "+" : ""} ${difference} %</span>`
                    },
                    {
                        ...labelConfig,
                        point: {
                            x: 1000,
                            y: -45,
                            xAxis: null,
                            yAxis: null
                        },
                        text: `<span style="display: block; color: ${
                            theme.colors.gray[700]
                        }}; font-weight: 400; font-size: 10px;">${t(
                            "common.current_value"
                        )}</span><br /><span style="letter-spacing: 1px;">${formatNumber(
                            roundValues ? Math.round(currentValue * valueMultiplier) : currentValue * valueMultiplier
                        )}</span> ${
                            currentLabelUnit
                                ? '<small style="font-size: 12px">' +
                                  t(`common.${currentLabelUnit}`, {
                                      count: currentValue * valueMultiplier,
                                      context: currentLabelUnitContext
                                  }) +
                                  "</small>"
                                : ""
                        }`
                    }
                ]
            }
        ],
        chart: {
            marginTop: 60
        },
        exporting: {
            chartOptions: {
                annotations: [
                    {
                        draggable: "",
                        labels: [
                            {
                                ...labelConfig,
                                point: {
                                    x: 0,
                                    y: 20,
                                    xAxis: null,
                                    yAxis: null
                                },
                                style: {
                                    ...labelConfig.style,
                                    color: Math.sign(difference) > 0 ? theme.colors.secondary : theme.colors.danger,
                                    display: shouldDisplayYearlyChange ? "block" : "none"
                                },
                                text: `<span>${
                                    Math.sign(difference) > 0 ? "+" : ""
                                } ${difference} %</span><br /><span style="display: block; color: ${
                                    theme.colors.gray[700]
                                }}; font-weight: 400; font-size: 10px;">${t("common.year_over_year_change")}</span>`
                            },
                            {
                                ...labelConfig,
                                point: {
                                    x: 1000,
                                    y: 20,
                                    xAxis: null,
                                    yAxis: null
                                },
                                text: `<span style="display: block; color: ${
                                    theme.colors.gray[700]
                                }; font-weight: 400; font-size: 10px;">${t(
                                    "common.current_value"
                                )}</span><br /><span style="letter-spacing: 1px;">${formatNumber(
                                    roundValues ? Math.round(currentValue) : currentValue
                                )}</span> ${
                                    currentLabelUnit
                                        ? '<small style="font-size: 12px">' +
                                          t(`common.${currentLabelUnit}`, {
                                              count: currentValue * valueMultiplier,
                                              context: currentLabelUnitContext
                                          }) +
                                          "</small>"
                                        : ""
                                }`
                            }
                        ]
                    }
                ]
            }
        },
        yAxis: {
            maxPadding: 0.3,
            minPadding: 0.4
        }
    };

    return (
        <SuspendedChart>
            <Chart {...props} customOptions={options} series={series} />
        </SuspendedChart>
    );
};

const labelConfig = {
    backgroundColor: "transparent",
    borderColor: "transparent",
    padding: 0,
    style: {
        color: "black",
        fontSize: "18px",
        fontWeight: "600"
    }
};
