import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {getBigDataApiPath} from "../../../../../utils/get_big_data_api_path";
import {getClientCookies} from "../../../client/get_client_cookies";
import prepareHeaders from "../../../store/prepare_headers";

export const sendVerificationLinkApi = createApi({
    reducerPath: "verificationCreateApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${getBigDataApiPath()}`,
        prepareHeaders: prepareHeaders
    }),
    endpoints: (build) => ({
        sendVerificationLink: build.mutation<void, void>({
            query: () => ({
                url: `/verification/create/`,
                method: "POST"
            })
        })
    })
});

export const {useSendVerificationLinkMutation} = sendVerificationLinkApi;
