import {lazy, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Options, SeriesMapOptions} from "highcharts";

import {CitySlug, mapCitySlugToLocalizedParamSlug, validateOfferType} from "../../../../../../config/cities";
import {voivodeshipsConfig} from "../../../../../../config/voivodeships";
import {dataLayer} from "../../../../../../utils/data_layer";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {useTypeFilteredData} from "../../../../../../utils/hooks/useTypeFilteredData";
import {useWindowWidth} from "../../../../../../utils/hooks/useWindowWidth";
import {proxyNullValues} from "../../../../../../utils/misc";
import {formatMapTooltip} from "../../../../../../utils/tooltips";
import {colorSets, mapMapFieldToColor} from "../../../../common/app/constants/colors";
import {voivodeshipCapitals, voivodeshipCapitalsByName} from "../../../../common/app/constants/constants";
import {FilterOfferType} from "../../../../common/app/constants/enums";
import {chartsRoutes, compilePath} from "../../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, OfferType} from "../../../../common/app/routing/offer_type";
import {IChartProps} from "../../../../components/charts/Chart";
import {polska} from "../../../../components/charts/maps/polska";
import {SuspendedChart} from "../../../../components/charts/SuspendedChart";
import {MapStatType, selectSelectedStat, setSelectedStat} from "../../../../components/map/redux/map_type_slice";
import {MapLegend} from "./MapLegend";

const Chart = lazy(() => import("../../../../components/charts/ChartDefaultExport"));

interface IProps<D> extends IChartProps {
    data: D[];
    offerType?: FilterOfferType;
}

interface IVoivodeship {
    id: string;
    sold: number;
    available_offers: number;
    available_investments: number;
    added: number;
}

interface IMapData {
    id: string;
    name: string;
    path: string;
}

const initialVoivodeshipState = {
    available_offers: 0,
    available_investments: 0,
    sold: 0,
    added: 0
};

export const CountryMap = <D extends {}>(props: IProps<D>) => {
    const {data, offerType} = props;
    const {t, i18n} = useTranslation();
    const filteredData = useTypeFilteredData(data as any[], offerType);
    const windowWidth = useWindowWidth();
    const history = useHistory();
    const selectedStat = useAppSelector(selectSelectedStat);
    const dispatch = useAppDispatch();
    const mapHeight = windowWidth < 1024 ? 400 : windowWidth < 1440 ? 500 : 800;

    const voivodeshipsData = useMemo(() => {
        if (filteredData && filteredData.length > 0) {
            const polandMapData = polska[0].mapData;
            const voivodeships = (polandMapData as [])
                .filter((voivodeship: IMapData) => voivodeshipsConfig[voivodeship.id].enabled)
                .map(({id}: IMapData) => {
                    return [id, {...initialVoivodeshipState, id}];
                });
            const series = Object.fromEntries(voivodeships);

            for (const month of filteredData) {
                if (voivodeshipCapitals[month.slug_city] in series) {
                    for (const key of Object.keys(initialVoivodeshipState)) {
                        series[voivodeshipCapitals[month.slug_city]][key] += month[key];
                    }
                }
            }
            return proxyNullValues(series);
        }

        return {};
    }, [filteredData]);

    const series: SeriesMapOptions[] = [
        {
            ...polska[0],
            nullColor: "rgb(230,235,245)",
            data: (Object.values(voivodeshipsData) as IVoivodeship[]).map((el: IVoivodeship) => {
                return {
                    id: el.id,
                    value: el[selectedStat] as number | null | undefined
                };
            })
        }
    ];

    const options: Options = {
        chart: {
            animation: false
        },
        colorAxis: {
            maxColor: colorSets[mapMapFieldToColor[selectedStat]][0],
            floor: Number.MIN_VALUE + Number.MIN_VALUE,
            min: Number.MIN_VALUE,
            type: "linear"
        },
        plotOptions: {
            map: {
                events: {
                    click: function ({point}) {
                        try {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore Property 'id' does not exist on type 'Point'
                            const city = voivodeshipCapitalsByName[point.id];
                            const validOfferType = validateOfferType(city as CitySlug, OfferType.PROPERTY);
                            const targetPath = mapOfferTypeToValidPath(
                                validOfferType,
                                chartsRoutes[i18n.language].properties.city.root,
                                i18n
                            );
                            const targetCitySlug = mapCitySlugToLocalizedParamSlug(city as CitySlug, i18n.language);
                            const compiledPath = compilePath(targetPath, {city: targetCitySlug});
                            history.push(compiledPath);

                            dataLayer({
                                event: "map_city",
                                city
                            });
                        } catch {
                            /* empty */
                        }
                    }
                }
            }
        },
        tooltip: {
            className: "map-tooltip",
            formatter: function () {
                return formatMapTooltip(this, voivodeshipsData, Object.keys(initialVoivodeshipState), i18n);
            },
            shared: true
        }
    };

    return (
        <>
            <SuspendedChart>
                <Chart
                    {...props}
                    title={<></>}
                    colorSet={mapMapFieldToColor[selectedStat]}
                    customOptions={options}
                    series={series}
                    height={mapHeight}
                    type="map"
                />
            </SuspendedChart>

            <MapLegend
                city={t("home.7_largest_cities")}
                data={filteredData}
                dataField={selectedStat}
                mapColor={mapMapFieldToColor[selectedStat] as string}
                onLegendChange={(field: MapStatType) => dispatch(setSelectedStat(field))}
            />
        </>
    );
};
