import Cookies from "universal-cookie";

export const getClientCookies = () => {
    const parsedCookies = new Cookies();
    const csrfToken = parsedCookies.get("bd_csrftoken");

    return {
        csrfToken
    };
};
