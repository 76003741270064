import {useTranslation} from "react-i18next";

import {IMonthGusStats} from "../../api/src/db_queries/month_gus_stats_query";
import {formatDate, humanDateFormatLong} from "../dates";

export const useGusDataWarning = (data: IMonthGusStats[], currentDate?: string): null | string => {
    const {i18n} = useTranslation();

    const lastAvailableDate = data[data.length - 1]?.date;

    const lastAvailableDatePretty = lastAvailableDate ? formatDate(lastAvailableDate, humanDateFormatLong, i18n) : null;

    if (currentDate && lastAvailableDate && currentDate !== lastAvailableDate) {
        return lastAvailableDatePretty;
    }

    return null;
};
