interface IGTMEvent {
    publication?: string;
    event?: string;
    "gtm.start"?: string | number;
    pageType?: "Landing" | "Charts" | "Publikacje" | "Newshub" | "Monitoring";
    userLoggedIn?: "tak" | "nie";
    rp_client?: "tak" | "nie";
    userAccess?: "accepted" | "not active" | "brak";
    position?: string;
    position_id?: string;
    position_name?: string;
    button?: string;
    city?: string;
    area?: string;
    user_profession?: string;
    user_id?: number | "";
}

declare global {
    interface Window {
        dataLayer: IGTMEvent[];
    }
}

export const dataLayer = (data: IGTMEvent): void => {
    window.dataLayer ??= [];

    window.dataLayer.push(data);
};

export const getRecentGTMKeyValue = (key: keyof IGTMEvent): string => {
    if (!window.dataLayer) {
        return "";
    }

    return ([...window.dataLayer].reverse().find((event) => key in event)?.[key] ?? "") as string; // TODO: write better typings
};
