import React from "react";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "@pg-design/grid";
import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {withAuthGuard} from "../../../components/hoc/withAuthGuard";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {ChangePasswordForm} from "../components/change_password_form/ChangePasswordForm";
import {Panel} from "../components/Panel";

export const ChangePassword = withAuthGuard(() => {
    const {t} = useTranslation();

    return (
        <SimpleContainer hideFooter>
            <Container>
                <Row>
                    <Col lg={8} lgOffset={4} md={8} mdOffset={2} sm={4}>
                        <Panel>
                            <Text css={mb(6)} align="center" as="p" variant="headline_3">
                                {t("nav.change_password")}
                            </Text>
                            <ChangePasswordForm />
                        </Panel>
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
});
