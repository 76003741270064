import {useState} from "react";
import {useTranslation} from "react-i18next";
import {css, Theme, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {flexAlignCenter, flexJustifyCenter, mb, onDesktop, pb, positionRelative} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {Text} from "@pg-design/text";

import {dataLayer} from "../../../utils/data_layer";
import {
    OrderDedicatedReportModal,
    SuccessModal
} from "../features/monitoring/components/landing/OrderDedicatedReportModal";
import {InquirySource} from "../features/publications/api/add_inquiry";

import largeMonitoring from "../common/app/assets/monitoring_header_desktop.png";
import smallMonitoring from "../common/app/assets/monitoring_header_mobile.png";

interface IOrderReportProps {
    // source: InquirySource;
    // isSpecial: boolean
}

// export const OrderReport = ({source}: IOrderReportProps) => {
export const OrderReport = () => {
    const {t} = useTranslation();
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    // const isMonitoring = source === InquirySource.MONITORING;
    const theme = useTheme();

    const handleClick = () => {
        setIsModalOpened(true);
        dataLayer({
            // event: isMonitoring ? "custom_report_form_view" : "dedicated_report_form_view"
            event: "custom_report_form_view"
        });
    };

    return (
        <Container as="section" fluid css={orderReportWrapper}>
            <Container>
                <Row css={[flexJustifyCenter, positionRelative]}>
                    <Picture
                        css={orderPicture}
                        sources={[
                            {
                                minWidthPX: 0,
                                // src: isMonitoring ? smallMonitoring : coverImgReportSmall,
                                src: smallMonitoring,
                                width: 328,
                                height: 216
                            },
                            {
                                minWidthPX: theme.breakpoints.numeric.md,
                                // src: isMonitoring ? largeMonitoring : coverImgReport,
                                src: largeMonitoring,
                                width: 635,
                                height: 419
                            }
                        ]}
                        // alt={isMonitoring ? "monitoring cover image" : "report cover image"}
                        alt="monitoring cover image"
                        loading="lazy"
                    />

                    <Col sm={4} md={8} lg={8}>
                        <Text variant="headline_0" as="h2" css={mb(3)} align="center">
                            {/*{isMonitoring ? t("monitoring.report.title") : t("publications.landing.order.title")}*/}
                            Zamów Monitoring Konkurencji
                        </Text>

                        <Text variant="body_copy_0" css={mb(4)} align="center" color={theme.colors.gray[800]}>
                            {/*{isMonitoring ? (*/}
                            {/*    <Trans>{t("monitoring.report.lead")}</Trans>*/}
                            {/*) : (*/}
                            {/*    <Trans>{t("publications.landing.order.subtitle")}</Trans>*/}
                            {/*)}*/}
                            Chcesz wiedzieć więcej na temat realizowanych inwestycji w okolicy? Zapytaj o Monitoring
                            Konkurencji dla Twojej inwestycji mieszkaniowej lub analizowanego gruntu. Sprawdź, jakie są
                            średnie ceny i struktura mieszkań w najbliższych inwestycjach.
                        </Text>

                        <Button size="big" variant="filled_primary" css={orderButton} onClick={handleClick}>
                            {/*{isMonitoring ? t("monitoring.report.button") : t("publications.landing.order.title")}*/}
                            Zamów
                        </Button>

                        <OrderDedicatedReportModal
                            onModalClose={() => setIsModalOpened(false)}
                            isOpen={isModalOpened}
                            source={InquirySource.MONITORING}
                        />
                        <SuccessModal />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

const orderPicture = css`
    position: absolute;
    bottom: 100%;
    ${mb(6)};
`;

const orderReportWrapper = (theme: Theme) => css`
    padding-top: 35rem;
    ${pb(4)};
    ${flexAlignCenter};
    flex-direction: column;
    text-align: center;
    background-color: ${theme.colors.gray[200]};

    ${onDesktop(css`
        ${pb(8)};
    `)};
`;

const orderButton = css`
    height: 5.8rem;
    width: 100%;

    ${onDesktop(css`
        height: unset;
        max-width: 28rem;
    `)};
`;
