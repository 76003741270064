import {IUserModel} from "../../../../../utils/shared_types/user_model";
import {IUserComData} from "./init_user_com";

export const getPayloadFromData = (userData: IUserModel | null): Partial<IUserComData> =>
    userData === null
        ? {}
        : {
              email: userData.email,
              status: 2, //hardcoded value
              user_id: userData.id
          };
