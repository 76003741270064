import {SeriesMapOptions} from "highcharts";

import {gdansk} from "../../../components/charts/maps/gdansk";
import {katowice} from "../../../components/charts/maps/katowice";
import {krakow} from "../../../components/charts/maps/krakow";
import {lodz} from "../../../components/charts/maps/lodz";
import {poznan} from "../../../components/charts/maps/poznan";
import {szczecin} from "../../../components/charts/maps/szczecin";
import {warszawa} from "../../../components/charts/maps/warszawa";
import {wroclaw} from "../../../components/charts/maps/wroclaw";

export const maps: {
    [key: string]: SeriesMapOptions[];
} = {
    warszawa,
    krakow,
    wroclaw,
    gdansk,
    poznan,
    lodz,
    katowice,
    szczecin
};
