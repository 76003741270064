import {useState} from "react";
import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {
    flex,
    flexAlignCenter,
    flexDirection,
    flexJustifyCenter,
    mb,
    ml,
    mr,
    onDesktop,
    ph,
    pt,
    resetWidth,
    w75,
    w100
} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons";
import {Highlight, Text} from "@pg-design/text";

import {dataLayer} from "../../../../../../../utils/data_layer";
import {InquirySource} from "../../../../publications/api/add_inquiry";
import {OrderDedicatedReportModal, SuccessModal} from "../OrderDedicatedReportModal";
import {DownloadDemoModal} from "./DownloadDemoModal";

export const Hero = () => {
    const [isOrderMonitoringModalOpen, setIsOrderMonitoringModalOpen] = useState(false);
    const [isDownloadDemoModalOpen, setIsDownloadDemoModalOpen] = useState(false);
    const {t} = useTranslation();

    const texts = [t("monitoring.hero.comparison"), t("monitoring.hero.sales"), t("monitoring.hero.rooms")];

    const handleOrderMonitoringButtonClick = () => {
        setIsOrderMonitoringModalOpen(true);
        dataLayer({
            event: "custom_report_form_view"
        });
    };

    return (
        <Container as="section" fluid css={wrapper}>
            <Container>
                <OrderDedicatedReportModal
                    onModalClose={() => setIsOrderMonitoringModalOpen(false)}
                    isOpen={isOrderMonitoringModalOpen}
                    source={InquirySource.MONITORING}
                />
                <SuccessModal />
                <DownloadDemoModal
                    isOpen={isDownloadDemoModalOpen}
                    onModalClose={() => setIsDownloadDemoModalOpen(false)}
                />

                <Row>
                    <Col sm={4} md={6} lg={8}>
                        <Text as="h1" variant="headline_2" css={mb(5)}>
                            <Highlight>{t("monitoring.hero.title")}</Highlight>
                            <br />
                            {t("monitoring.hero.subtitle")}
                        </Text>

                        <Text variant="headline_5" css={mb(3)}>
                            {t("monitoring.hero.question")}
                        </Text>

                        <ul css={list}>
                            {texts.map((text) => (
                                <li css={listItem} key={text}>
                                    <BrandCheckboxCheckedIcon size="3.2" wrapperColor="transparent" wrapperSize="3.2" />
                                    <Text variant="body_copy_1" css={ml(1.5)}>
                                        {text}
                                    </Text>
                                </li>
                            ))}
                        </ul>

                        <div css={buttonsWrapper}>
                            <Button
                                css={button}
                                size="big"
                                variant="filled_primary"
                                onClick={handleOrderMonitoringButtonClick}
                            >
                                {t("monitoring.hero.contact")}
                            </Button>
                            <Button
                                css={[ph(10), w100, mb(3), onDesktop(resetWidth, mb(0))]}
                                size="big"
                                variant="outlined_secondary"
                                onClick={() => setIsDownloadDemoModalOpen(true)}
                            >
                                {t("monitoring.hero.demo.download")}
                            </Button>
                        </div>
                    </Col>

                    <Col sm={4} md={6} lg={8} css={onDesktop(flexAlignCenter)}>
                        <iframe
                            css={iframeContent}
                            title="Monitoring Konkurencji na rynku deweloperskim - BIG DATA RynekPierwotny.pl"
                            src="https://www.youtube.com/embed/lkrJybSJ1yQ"
                            allowFullScreen
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

const wrapper = css`
    ${pt(4)};
    position: relative;
    overflow: hidden;

    ${onDesktop(css`
        ${pt(6)}
    `)}
`;

const list = css`
    ${flex()};
    ${flexDirection("column")};
    ${mb(3)};
`;

const listItem = css`
    ${mb(2)};
    ${flexAlignCenter};
    ${w75};

    ${onDesktop(css`
        ${mb(3)};
    `)}
`;

const buttonsWrapper = (theme: Theme) => css`
    ${flexJustifyCenter};
    ${flexDirection("column")};

    @media (min-width: ${theme.breakpoints.xs}) {
        ${ph(10)};
    }

    ${onDesktop(css`
        ${ph(0)};
        ${flex()};
        ${flexDirection("row")};
    `)};
`;

const button = css`
    ${mb(3)};
    ${ph(5)};
    ${w100};

    ${onDesktop(css`
        ${mb(0)};
        ${mr(3)};
        ${resetWidth};
    `)}
`;

const iframeContent = css`
    border: none;
    aspect-ratio: 16/9;
    ${w100};
`;
