import {IBDStore} from "../../../../store/store";
import {getCurrentUserApi} from "../../../users/api/getCurrentUser";

export const setAuthAndUser = async (store: IBDStore) => {
    //  Get user (and authentication)
    store.dispatch(getCurrentUserApi.endpoints.getCurrentUser.initiate());

    //  Wait for queries/mutations to finish
    await Promise.all(store.dispatch(getCurrentUserApi.util.getRunningQueriesThunk()));
};
