import {css} from "@emotion/react";
import {calculateRemSize} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IProps {
    title: string;
    text: string;
    onClick: () => void;
}

export const ResultsInfo = (props: IProps) => {
    return (
        <div css={wrapper} onClick={props.onClick}>
            <Text variant="body_copy_1">{props.title}</Text>

            <Text variant="info_txt_2">{props.text}</Text>
        </div>
    );
};

const wrapper = css`
    height: ${calculateRemSize(5)};
`;
