import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {matchPath} from "react-router";
import {useLocation, useRouteMatch} from "react-router-dom";
import {css} from "@emotion/react";
import {onDesktop, order} from "@pg-design/helpers-css";
import {Select} from "@pg-design/select";

import {mapParamSlugToCitySlug} from "../../../../../config/cities";
import {getRelatedCitySlug, mapCitySlugToRegionType, RegionType} from "../../../../../config/regions";
import {dataLayer} from "../../../../../utils/data_layer";
import {chartsRoutes, ICityParams} from "../../../common/app/routing/charts_routes";

interface IProps {
    onRegionTypeChange?: (region: RegionType) => void;
}

export const RegionTypeSwitch = ({onRegionTypeChange}: IProps) => {
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();
    const {pathname} = useLocation();
    const {i18n, t} = useTranslation();
    const citySlug = mapParamSlugToCitySlug(city);
    const regionType = mapCitySlugToRegionType(citySlug);
    const [selectedRegion, setSelectedRegion] = useState<RegionType>(regionType ?? RegionType.CITY);
    const relatedCitySlug = getRelatedCitySlug(citySlug, regionType);
    const selectOptions = useMemo(
        () => [
            {
                id: RegionType.CITY,
                label: t("nav.agglomerations.city")
            },
            {
                id: RegionType.AGGLOMERATION,
                label: t("nav.agglomerations.agglomeration")
            },
            {
                id: RegionType.SUBURBS,
                label: t("nav.agglomerations.suburbs")
            }
        ],
        [i18n.language]
    );

    function mapRegionTypeToString(id: RegionType): string {
        switch (id) {
            case RegionType.CITY:
                return "city";
            case RegionType.AGGLOMERATION:
                return "suburbs";
            case RegionType.SUBURBS:
                return "agglomeration";
        }
    }

    const isViewFilterable =
        getLocalizedFilterablePaths(i18n.language).some((path) => matchPath(pathname, {path, exact: true})) &&
        relatedCitySlug;
    if (isViewFilterable) {
        return (
            <Select
                instanceId="region_type_select"
                css={select}
                // AgglomerationSwitch won't be displayed on views without city, so RegionType will be always set
                placeholder={selectOptions.find((option) => option.id === selectedRegion)?.label}
                value={regionType as unknown as string}
                onChange={(e: unknown) => {
                    const {id} = e as never;
                    if (onRegionTypeChange) {
                        onRegionTypeChange(id);
                    }
                    setSelectedRegion(id);
                    dataLayer({
                        event: "area_select_menu",
                        area: mapRegionTypeToString(id)
                    });
                }}
                options={selectOptions}
            />
        );
    }
    return null;
};

const select = css`
    ${order(1)};

    ${onDesktop(css`
        min-width: 150px;
        ${order(2)}
    `)}
`;

const getLocalizedFilterablePaths = (lang: string): string[] => [
    chartsRoutes[lang].properties.city.root,
    chartsRoutes[lang].flats.city.root,
    chartsRoutes[lang].houses.city.root,
    chartsRoutes[lang].properties.city.prices,
    chartsRoutes[lang].flats.city.prices,
    chartsRoutes[lang].properties.city.offer,
    chartsRoutes[lang].flats.city.offer,
    chartsRoutes[lang].properties.city.demand,
    chartsRoutes[lang].flats.city.demand,
    chartsRoutes[lang].properties.city.sold,
    chartsRoutes[lang].flats.city.sold,
    chartsRoutes[lang].properties.city.added,
    chartsRoutes[lang].flats.city.added
];
