interface IVoivodeshipConfig {
    name: string;
    capitalSlug: string;
    enabled: boolean;
}

const defaultVoivodeshipConfig: IVoivodeshipConfig = {
    name: "", // canonical name
    capitalSlug: "", // capital city slug
    enabled: true // should voivodeship be reflected in the UI?
};

export const voivodeshipsConfig: {
    [key: string]: IVoivodeshipConfig;
} = {
    mazowieckie: {
        ...defaultVoivodeshipConfig,
        name: "mazowieckie",
        capitalSlug: "warszawa"
    },
    malopolskie: {
        ...defaultVoivodeshipConfig,
        name: "małopolskie",
        capitalSlug: "krakow"
    },
    dolnoslaskie: {
        ...defaultVoivodeshipConfig,
        name: "dolnośląskie",
        capitalSlug: "wroclaw"
    },
    pomorskie: {
        ...defaultVoivodeshipConfig,
        name: "pomorskie",
        capitalSlug: "trojmiasto-i-okolice"
    },
    wielkopolskie: {
        ...defaultVoivodeshipConfig,
        name: "wielkopolskie",
        capitalSlug: "poznan"
    },
    lodzkie: {
        ...defaultVoivodeshipConfig,
        name: "łódzkie",
        capitalSlug: "lodz"
    },
    "zachodnio-pomorskie": {
        ...defaultVoivodeshipConfig,
        name: "zachodnio-pomorskie",
        capitalSlug: "szczecin"
    },
    "warminsko-mazurskie": {
        ...defaultVoivodeshipConfig,
        name: "warmińsko-mazurskie",
        capitalSlug: "olsztyn"
    },
    podlaskie: {
        ...defaultVoivodeshipConfig,
        name: "podlaskie",
        capitalSlug: "bialystok"
    },
    lubuskie: {
        ...defaultVoivodeshipConfig,
        name: "lubuskie",
        capitalSlug: "zielona-gora",
        enabled: false
    },
    "kujawsko-pomorskie": {
        ...defaultVoivodeshipConfig,
        name: "kujawsko-pomorskie",
        capitalSlug: "bydgoszcz"
    },
    slaskie: {
        ...defaultVoivodeshipConfig,
        name: "śląskie",
        capitalSlug: "katowice"
    },
    swietokrzyskie: {
        ...defaultVoivodeshipConfig,
        name: "świętokrzyskie",
        capitalSlug: "kielce"
    },
    lubelskie: {
        ...defaultVoivodeshipConfig,
        name: "lubelskie",
        capitalSlug: "lublin"
    },
    opolskie: {
        ...defaultVoivodeshipConfig,
        name: "opolskie",
        capitalSlug: "opole"
    },
    podkarpackie: {
        ...defaultVoivodeshipConfig,
        name: "podkarpackie",
        capitalSlug: "rzeszow"
    }
};
