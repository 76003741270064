import {css, Theme} from "@emotion/react";
import {backgroundColor, flexAbsoluteCenter, onDesktop, position, w100, zIndex} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";

import {useAppSelector} from "../../../../utils/hooks/store_hooks";
import {selectLatestEntry} from "../../features/ssr/redux/latest_entry_slice";

const Loading = () => {
    return (
        <div css={wrapper}>
            <Loader fullWidth size="lg" />
        </div>
    );
};

export const withDateGuard = (Component: any) => {
    return (props: {[key: string]: unknown}) => {
        const {currentDate} = useAppSelector(selectLatestEntry);
        return currentDate ? <Component {...props} /> : <Loading />;
    };
};

const wrapper = (theme: Theme) => css`
    ${position("sticky")};
    ${zIndex(9998)};
    top: 0;
    left: 0;
    height: calc(100vh - var(--mobile-header-height));
    min-height: 100vh;
    ${w100};
    ${flexAbsoluteCenter};
    ${backgroundColor(theme.colors.background[100])};

    ${onDesktop(css`
        height: calc(100vh - var(--desktop-header-height));
    `)}
`;
