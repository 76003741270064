import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {mb, mt, onDesktop, pt} from "@pg-design/helpers-css";
import {getTextVariantStyleFor} from "@pg-design/text";

import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {withDateGuard} from "../../../../components/hoc/withDateGuard";
import {setResponseStatus} from "../../../app_status/app_status_slice";
import {ResponseStatus} from "../../../ssr/path_to_action/state/app_status/IAppStatusState";
import {selectLatestEntry} from "../../../ssr/redux/latest_entry_slice";
import {useGetContentQuery} from "../../../static_content/api/get_content";
import {StaticContent} from "../../../static_content/types/Content";
import {AnimatedChart} from "./animated_chart/AnimatedChart";
import {LandingPageCitySelect} from "./LandingPageCitySelect";

export const Hero = withDateGuard(() => {
    const {
        i18n: {language}
    } = useTranslation();
    const {currentDate} = useAppSelector(selectLatestEntry);
    const {data, isError} = useGetContentQuery(StaticContent.HOMEPAGE_TITLE);
    const dispatch = useAppDispatch();

    if (isError) {
        dispatch(setResponseStatus({responseStatus: ResponseStatus.PAGE_500}));
        return null;
    }

    return (
        <Container css={wrapper} as="section">
            <Row>
                <Col lg={7} md={6} sm={4}>
                    {data && data.content_pl && data.content_en && (
                        <div
                            css={contentWrapper}
                            dangerouslySetInnerHTML={{__html: language === "pl" ? data.content_pl : data.content_en}}
                        />
                    )}

                    <LandingPageCitySelect />
                </Col>

                <Col lg={8} lgOffset={1} md={6} sm={4}>
                    {currentDate && <AnimatedChart />}
                </Col>
            </Row>
        </Container>
    );
});

const contentWrapper = (theme: Theme) => css`
    h1 {
        ${mt(0)};
        ${mb(5)};
        ${getTextVariantStyleFor("headline_1")(theme)};
    }

    h1 > em {
        font-style: normal;
        overflow: hidden;
        background-image: linear-gradient(${theme.colors.primary}, ${theme.colors.primary} 30%, transparent 30%);
        background-repeat: no-repeat;
        background-position: 0 0.8em;

        animation: underline 1.5s linear;
        @keyframes underline {
            from {
                background-position: -100em 0.8em;
            }
            to {
                background-position: 0 0.8em;
            }
        }

        p {
            ${mb(6)};
            ${getTextVariantStyleFor("body_copy_0")(theme)};
        }
`;

const wrapper = (theme: Theme) => css`
    ${pt(3)};
    ${mb(8)};

    ${onDesktop(css`
        ${pt(6)};
        ${mb(9)};
    `)};

    @media (min-width: ${theme.breakpoints.lg}) {
        ${pt(14)}
    }
`;
