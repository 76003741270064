import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {setAppStatus} from "../../../app_status/app_status_slice";
import {IActionContext} from "../create_path_to_action";
import {ResponseStatus} from "../state/app_status/IAppStatusState";

export const redirectAnnouncementsToMarketInformationAction = async (ctx: IActionContext<{}>): Promise<void> => {
    ctx.store.dispatch(
        setAppStatus({responseStatus: ResponseStatus.REDIRECT_301, url: chartsRoutes.pl.newshub.marketInformation.root})
    );
};
