import {useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import {underline} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link";
import {Text} from "@pg-design/text";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";

interface IProps {
    className?: string;
    isCenter?: true;
}

export const DataProtectionNotice = ({className, isCenter}: IProps) => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();

    return (
        <Text
            variant="info_txt_2"
            color={theme.colors.gray[600]}
            className={className}
            align={isCenter ? "center" : "left"}
        >
            {t("auth.data_protection_notice")}
            &nbsp;
            <Link
                underline
                size="x-small"
                css={linkText}
                href={chartsRoutes[i18n.language].privacyPolicy}
                title={t("nav.privacy_policy")}
                target="_blank"
            >
                {t("auth.data_protection_notice_link")}
            </Link>
        </Text>
    );
};

const linkText = css`
    &:any-link {
        ${underline};
    }
    color: inherit;
`;
