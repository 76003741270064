import {useEffect, useState} from "react";

import {useIsMounted} from "./useIsMounted";
/**
 * function can be used to retrieve media query match in the same way that `useIsMobile` does,
 * but can be used instantly in browser, even before react mount
 * @param mobileBreakpoint
 */
export const getIsMobileMatch = (isDesktopFirst = false, mobileBreakpoint?: number) => {
    if (typeof window === "undefined" || typeof window.matchMedia === "undefined") {
        return null;
    }

    const breakpoint = mobileBreakpoint || 1023;
    return window.matchMedia(`(max-width: ${breakpoint}px)`);
};

export const useIsMobile = (isDesktopFirst = false, mobileBreakpoint?: number) => {
    // serverside: by default we are assuming we are on mobile (mobile-first approach)
    // we can change that behaviour by passing isDesktopFirst as true,
    // use that flag ONLY for content irrelevant for SEO
    const mediaQuery = getIsMobileMatch(isDesktopFirst, mobileBreakpoint);
    const initialValue = !isDesktopFirst;
    if (!mediaQuery) {
        return initialValue;
    }

    const [isMobileWidth, setIsMobileWidth] = useState<boolean>(initialValue);
    const isMounted = useIsMounted();

    const handler = () => {
        setIsMobileWidth(mediaQuery.matches);
    };

    useEffect(() => {
        if (mediaQuery.addEventListener) {
            mediaQuery.addEventListener("change", handler);
            return () => mediaQuery.removeEventListener("change", handler);
        } else {
            //<Safari 14, IE, <Edge 16 support only deprecated MediaQueryList' API. https://github.com/microsoft/TypeScript/issues/32210
            mediaQuery.addListener(handler);
            return () => mediaQuery.removeListener(handler);
        }
    }, []);

    useEffect(() => {
        if (isMounted) {
            setIsMobileWidth(mediaQuery.matches);
        }
    }, [isMounted]);
    return isMobileWidth;
};
