import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {borderRadius, fadeInAnimation} from "@pg-design/helpers-css";

interface ISearchDropdown {
    isOpen: boolean;
    isInModal?: boolean;
}

export const SearchDropdown = styled.div<ISearchDropdown>`
    width: 100%;
    max-height: calc(100vh - 17rem);
    display: ${(props) => (props.isOpen || props.isInModal ? "flex" : "none")};
    flex-direction: column;
    ${fadeInAnimation("0.2s")};

    ${(props) =>
        !props.isInModal &&
        css`
            position: absolute;
            padding: 2rem;
            margin: 0;
            z-index: 1001;
            top: 7rem;
            left: 0;
            ${borderRadius(2)}
            background: #fff;
        `};
`;
