import {i18n} from "i18next";

import {UserType} from "../../../../utils/shared_types/user_model";

export const userTypeSelectOptions = (i18n: i18n) => [
    {
        label: `${i18n.t("common.am")} ${i18n.t("common.journalist", {context: "genitive"}).toLowerCase()}`,
        value: UserType.JOURNALIST
    },
    {
        label: `${i18n.t("common.am")} ${i18n.t("common.developer", {context: "genitive"}).toLowerCase()}`,
        value: UserType.DEVELOPER
    },
    {
        label: `${i18n.t("common.am")} ${i18n.t("common.consulting", {context: "genitive"}).toLowerCase()}`,
        value: UserType.RESEARCHER
    },
    {
        label: `${i18n.t("common.am")} ${i18n.t("common.institution", {context: "genitive"}).toLowerCase()}`,
        value: UserType.INSTITUTION
    },
    {
        label: `${i18n.t("common.am")} ${i18n.t("common.broker", {context: "genitive"}).toLowerCase()}`,
        value: UserType.BROKER
    },
    {
        label: i18n.t("common.other"),
        value: UserType.OTHER
    }
];
