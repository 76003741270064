import {ElementType, ReactNode} from "react";
import {Container} from "@pg-design/grid";

import {Footer} from "../../features/landing/components/footer/Footer";
import {Header} from "../header/Header";

interface ISimpleContainerProps {
    fluid?: boolean;
    hideHeader?: boolean;
    hideFooter?: boolean;
    children: ReactNode | Array<ReactNode>;
    as?: ElementType;
}

export const SimpleContainer = ({fluid = true, hideHeader, hideFooter, children, as}: ISimpleContainerProps) => {
    return (
        <Container as={as ?? "article"} fluid={fluid}>
            {!hideHeader && <Header />}
            <main>{children}</main>
            {!hideFooter && <Footer />}
        </Container>
    );
};
