import {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import styled from "@emotion/styled";
import {flex} from "@pg-design/helpers-css";

import {CitySlug, getCityOption, mapParamSlugToCitySlug} from "../../../../../../config/cities";
import {mapCitySlugToRegionType, RegionType} from "../../../../../../config/regions";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {useRpRequest} from "../../../../../../utils/hooks/useRpRequest";
import {FilterOfferType} from "../../../../common/app/constants/enums";
import {rpWebUrl} from "../../../../common/app/read_charts_web_environment_variables";
import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {GoogleMap} from "../../../map/GoogleMap";
import {MapOfferBox} from "../../../map/map_offer_box/MapOfferBox";
import {IOfferBoxOffer} from "../../../map/mocks/singleOfferMock";
import {MapType, selectMapType, setMapType} from "../../../map/redux/map_type_slice";
import {ChartError} from "../../ChartError";
import {DistrictsMap} from "../../DistrictsMap";
import {SwitchableMapFilter as Filters} from "./SwitchableMapFilter";
import {SwitchableMapSwitcher as Switcher} from "./SwitchableMapSwitcher";

interface IProps {
    city: CitySlug | null;
    offerType?: FilterOfferType;
}

export const SwitchableMap = ({offerType}: IProps) => {
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();
    const citySlug = mapParamSlugToCitySlug(city);
    const currentRegionType = mapCitySlugToRegionType(citySlug);
    const mapType = useAppSelector(selectMapType);
    const dispatch = useAppDispatch();
    const [mapWrapperHeight, setWrapperMapHeight] = useState<null | number>(null);
    const [currentOfferId, setCurrentOfferId] = useState<number | null>(null);
    const offerRes = useRpRequest<IOfferBoxOffer>(
        currentOfferId ? `${rpWebUrl}/api/v2/offers/offer/${currentOfferId}/?s=offer-detail-map` : null
    );
    useEffect(() => setCurrentOfferId(null), [citySlug]);
    useEffect(() => {
        // force switch map type to google map for views with disabled districts or on agglomeration view
        if (
            (!getCityOption(citySlug).districtsMap || currentRegionType !== RegionType.CITY) &&
            mapType !== MapType.GOOGLE
        ) {
            dispatch(setMapType(MapType.GOOGLE));
        }
    }, [citySlug, currentRegionType]);

    return (
        <ChartError>
            <StyledSwitchableMap>
                {mapType === MapType.HIGHCHARTS && getCityOption(citySlug).districtsMap && (
                    <DistrictsMap
                        city={citySlug as string}
                        mapWrapperHeight={mapWrapperHeight}
                        setMapWrapperHeight={setWrapperMapHeight}
                        offerType={offerType}
                    />
                )}

                {(mapType === MapType.GOOGLE || !getCityOption(citySlug).districtsMap) && (
                    <StyledGoogleMapWrapper>
                        <GoogleMap city={citySlug} onMarkerClick={setCurrentOfferId} />

                        {!!currentOfferId && (
                            <MapOfferBox offer={offerRes.data} onOfferBoxClose={() => setCurrentOfferId(null)} />
                        )}

                        <Filters city={citySlug} />
                    </StyledGoogleMapWrapper>
                )}

                <Switcher city={citySlug} />
            </StyledSwitchableMap>
        </ChartError>
    );
};

const StyledSwitchableMap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    ${flex("flex-start")};
`;

const StyledGoogleMapWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;
