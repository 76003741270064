import {useEffect, useReducer} from "react";

import {scenarios} from "../../api/src/constants/scenarios";
import {CitySlug} from "../../config/cities";
import {RegionType} from "../../config/regions";
import {RequestStatus} from "../../web/src/common/app/constants/enums";
import {bugsnagNotify} from "../bugsnag";
import {apiDateRegex} from "../dates";

type IState<D> = [RequestStatus, D[], string | null];

type ActionType<D> = {type: "PENDING"} | {type: "SUCCESS"; data: D[]} | {type: "ERROR"; error: string};

export type MagicCityKeyword = "all" | "main" | "capitals";

const reducer = <D>(state: IState<D>, action: ActionType<D>): IState<D> => {
    switch (action.type) {
        case "PENDING":
            return [RequestStatus.PENDING, [], null];
        case "SUCCESS":
            return [RequestStatus.SUCCESS, action.data, null];
        case "ERROR":
            return [RequestStatus.ERROR, [], action.error];
        default:
            throw new Error();
    }
};

const initialState: IState<any> = [RequestStatus.PENDING, [], null];

export const useAPIRequest = <D>(
    endpoint: string,
    params: {
        slug_city?: CitySlug | MagicCityKeyword | null;
        slug_agglomeration?: CitySlug;
        date_start: string; // yyyy-MM-dd date format required
        date_end: string; // yyyy-MM-dd date format required
        scenario: string;
        region_type?: RegionType;
    },
    shouldFetch?: boolean
): IState<D> => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const {date_start, date_end, scenario} = params;

    if (!apiDateRegex.test(date_start) || !apiDateRegex.test(date_end)) {
        throw new Error("Invalid date_start or date_end provided to useAPIRequest()");
    }

    if (!scenario || !scenarios.includes(scenario)) {
        throw new Error("Invalid scenario provided to useAPIRequest()");
    }

    const searchParams = new URLSearchParams({...(params as Record<string, string>)}).toString();

    const url = `${endpoint}?${searchParams}`;

    useEffect(() => {
        let isMounted = true;

        if (isMounted && state[0] !== RequestStatus.PENDING) {
            dispatch({type: "PENDING"});
        }

        if (shouldFetch) {
            (async () => {
                try {
                    const response = await fetch(url);
                    const data = await response.json();
                    if (isMounted) {
                        dispatch({type: "SUCCESS", data});
                    }
                } catch (error) {
                    if (error instanceof DOMException && "name" in error && error.name === "AbortError") {
                        // Firefox treats aborting of fetches as errors
                    } else if (isMounted) {
                        bugsnagNotify(error as Error, {source: "useAPIRequest"});
                        dispatch({type: "ERROR", error: error as string});
                    }
                }
            })();
        }

        return () => {
            isMounted = false;
        };
    }, [url, shouldFetch]);

    return state as IState<D>;
};
