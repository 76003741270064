import Bugsnag from "@bugsnag/js";

import {IActionContextRoute} from "../web/src/features/ssr/path_to_action/create_path_to_action";

interface ErrorMetadata {
    source?: string;
    route?: IActionContextRoute;
}

export const bugsnagNotify = (error: Error, metadata: ErrorMetadata = {}) => {
    Bugsnag.notify(error, (event) => event.addMetadata("metadata", metadata));
};
``;
