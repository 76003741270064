import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {elevation} from "@pg-design/elevation";
import {
    borderRadius,
    calculateRemSize,
    display,
    h100,
    mb,
    onDesktop,
    p,
    truncateMultiline
} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {getTextVariantStyleFor, Text} from "@pg-design/text";
import {compile} from "path-to-regexp";

import {chartsRoutes} from "../../../../../../common/app/routing/charts_routes";
import {flexGrow, flexShrink} from "../../../../../../components/css_helpers/flex";
import {IEntryCategory} from "../../../../types/Category";
import {EntryType} from "../../../../types/Entry";
import {InfoTextBlock} from "../../../InfoTextBlock";

import bdMockImg from "../../../../assets/bd_mock.png";
import bdVideoMockImg from "../../../../assets/bd_video_mock.png";

interface IArticleListItemProps {
    className?: string;
    title: string;
    images: {
        img_332x208: string;
    } | null;
    lead: string;
    publication_date: string;
    region_name: string | null;
    category: Array<IEntryCategory> | null;
    id: number;
    slug: string;
    type: EntryType;
    is_highlighted: boolean;
    is_video_material: boolean;
}

export const ArticleListItem = ({
    className,
    title,
    images,
    lead,
    publication_date,
    region_name,
    category,
    id,
    slug,
    type,
    is_highlighted,
    is_video_material
}: IArticleListItemProps) => {
    const route =
        type === EntryType.ARTICLE
            ? chartsRoutes.pl.newshub.marketInformation.details
            : chartsRoutes.pl.newshub.bigDataNews.details;
    const compiledPath = compile(route);
    const path = compiledPath({newsId: String(id), slug});

    const getArticleImage = () => {
        if (is_highlighted) {
            if (is_video_material) {
                return bdVideoMockImg;
            }
            return bdMockImg;
        }
        return images?.img_332x208;
    };

    return (
        <li className={className}>
            <Link to={path}>
                <div css={articleWrapper}>
                    {(images || is_highlighted) && (
                        <div css={picture}>
                            <Picture
                                css={h100}
                                sources={[
                                    {
                                        minWidthPX: 0,
                                        src: getArticleImage(),
                                        width: 332,
                                        height: 208
                                    }
                                ]}
                                alt={title}
                                loading="lazy"
                            />
                        </div>
                    )}

                    <div css={textWrapper}>
                        <InfoTextBlock
                            css={mb(2)}
                            publication_date={publication_date}
                            region_name={region_name}
                            category={category}
                        />

                        <Text css={articleTitle} variant="headline_6" as="h2">
                            {title}
                        </Text>

                        <Text
                            css={[display("none"), onDesktop(display("block"), truncateMultiline(3))]}
                            variant="body_copy_1"
                        >
                            {lead}
                        </Text>
                    </div>
                </div>
            </Link>
        </li>
    );
};

const articleWrapper = css`
    display: flex;
    overflow: hidden;
    ${elevation(1)};
    ${borderRadius(2)};
    background-color: #fff;

    &:hover {
        ${elevation(4)};
    }

    transition: box-shadow 0.2s ease-in-out;
`;

const textWrapper = css`
    ${flexGrow(1)};
    ${flexShrink(1)};
    ${p(1)};
    min-height: ${calculateRemSize(13)};

    ${onDesktop(css`
        ${p(2)};
    `)}
`;

const picture = css`
    ${flexGrow(0)};
    ${flexShrink(0)};
    max-width: 160px;

    img {
        ${h100};
        object-fit: cover;
    }

    ${onDesktop(css`
        max-width: unset;
    `)}
`;

const articleTitle = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        ${truncateMultiline(3)};
    }

    ${onDesktop(css`
        ${truncateMultiline(2)};
        ${mb(2)};
        ${getTextVariantStyleFor("headline_4")(theme)};
    `)}
`;
