import React, {useMemo} from "react";

import {IChartProps} from "./Chart";

export interface IChartWithDataProps<D> extends IChartProps {
    data: Array<D>;
    fields: Array<string>;
}

export interface IChartWithGroupedDataProps<D> extends IChartProps {
    data: Array<D>;
    categories: Array<string>;
    fieldGroups: Array<Array<string>>;
}

export const calculateRatioForFields = <D extends {}>(data: D[], fields: string[]) => {
    let total = 0;

    const result = data.map((rowData: {[key: string]: number}) => {
        const value = {...rowData};
        const fieldsSum = fields.reduce(
            (acc: number, key: string) => acc + (Object.prototype.hasOwnProperty.call(rowData, key) ? rowData[key] : 0),
            0
        );

        if (fieldsSum > 0) {
            fields.forEach((field) => {
                if (!field) {
                    return;
                }

                total += rowData[field];
                value[field] = (100 * rowData[field]) / fieldsSum;
            });
        } else {
            fields.forEach((field) => {
                if (!field) {
                    return;
                }

                total += rowData[field];
                value[field] = 0;
            });
        }

        return value;
    });

    return {result, total};
};
// sum everything
const calculateRatioForGroups = <D extends {}>(data: D[], groupsOfFields: string[][]) =>
    data.map((entryDatum: {[key: string]: number}) => {
        const finalDatum = {...entryDatum};
        const sum = groupsOfFields.reduce((acc, group) => {
            return acc + group.reduce((acc2, field) => acc2 + (entryDatum[field] ?? 0), 0);
        }, 0);
        groupsOfFields.forEach((group) => {
            group.forEach((field) => {
                const value = entryDatum[field];
                if (value) {
                    // sum is not zero
                    finalDatum[field] = (100 * value) / sum;
                }
            });
        });
        return finalDatum;
    });

export const withRatioData = <P extends IChartWithDataProps<unknown>>(Component: React.ComponentType<P>) => {
    return <D extends {}>({data, fields, ...props}: P & IChartWithDataProps<D>) => {
        const {result, total} = useMemo<{result: Array<{[key: string]: number}>; total: number}>(() => {
            return calculateRatioForFields(data, fields);
        }, [fields.length, data]);

        if (data.length < 1) {
            return null;
        }

        return <Component {...(props as P)} data={result} fields={fields} total={total} unit="%" />;
    };
};

export const withGroupRatioData = <P extends IChartWithGroupedDataProps<unknown>>(
    Component: React.ComponentType<P>
) => {
    return <D extends {}>({data, fieldGroups, categories, ...props}: P & IChartWithGroupedDataProps<D>) => {
        const finalData = useMemo(() => calculateRatioForGroups(data, fieldGroups), [data]);
        if (finalData.length < 1) {
            return null;
        }

        return (
            <Component {...(props as P)} data={finalData} fieldGroups={fieldGroups} categories={categories} unit="%" />
        );
    };
};
