import {ReactNode} from "react";
import {i18n} from "i18next";

import {cityConfig, CitySlug, getCityOption} from "../../../../../config/cities";
import {rpWebUrl} from "../read_charts_web_environment_variables";
import {chartsRoutes, compilePath} from "../routing/charts_routes";
import {OfferType} from "../routing/offer_type";

interface ILink {
    href: string;
    title: string;
    target?: string;
}

export const flatsLinks = (i18n: i18n) =>
    Object.entries(cityConfig)
        .filter(([city, options]) => city !== CitySlug.TROJMIASTO && options.enabled && options.fetchFooterData)
        .map(([city]) => {
            if (getCityOption(city as CitySlug).availableOfferTypes.includes(OfferType.FLAT)) {
                return {
                    title: i18n.t("footer.flats_prices_city", {city}),
                    href: compilePath(chartsRoutes[i18n.language].flats.city.root, {city})
                };
            }
            return null;
        })
        .filter((el) => !!el) as ILink[];

export const housesLinks = (i18n: i18n) =>
    Object.entries(cityConfig)
        .filter(([city, option]) => city !== CitySlug.TROJMIASTO && option.enabled && option.fetchFooterData)
        .map(([city]) => {
            if (getCityOption(city as CitySlug).availableOfferTypes.includes(OfferType.HOUSE)) {
                return {
                    title: i18n.t("footer.houses_prices_city", {city}),
                    href: compilePath(chartsRoutes[i18n.language].houses.city.root, {city})
                };
            }
            return null;
        })
        .filter((el) => !!el) as ILink[];

export const rpOffersLinks = (i18n: i18n): ILink[] => [
    {
        title: i18n.t("footer.new_flats_city", {city: "warszawa"}),
        href: `${rpWebUrl}/s/nowe-mieszkania-warszawa/`,
        target: "_blank"
    },
    {
        title: i18n.t("footer.new_flats_city", {city: "wroclaw"}),
        href: `${rpWebUrl}/s/nowe-mieszkania-wroclaw/`,
        target: "_blank"
    },
    {
        title: i18n.t("footer.new_flats_city", {city: "krakow"}),
        href: `${rpWebUrl}/s/nowe-mieszkania-krakow/`,
        target: "_blank"
    },
    {
        title: i18n.t("footer.new_houses_city", {city: "warszawa"}),
        href: `${rpWebUrl}/s/nowe-domy-warszawa/`,
        target: "_blank"
    },
    {
        title: i18n.t("footer.new_houses_city", {city: "wroclaw"}),
        href: `${rpWebUrl}/s/nowe-domy-wroclaw/`,
        target: "_blank"
    },
    {
        title: i18n.t("footer.new_houses_city", {city: "krakow"}),
        href: `${rpWebUrl}/s/nowe-domy-krakow/`,
        target: "_blank"
    }
];

export const staticLinks = (
    i18n: i18n
): {
    href: string;
    title: string;
    rel?: string;
    children: string | ReactNode;
    target?: string;
    external?: boolean;
}[] => [
    {
        href: chartsRoutes[i18n.language].contact,
        title: i18n.t("nav.contact"),
        children: i18n.t("nav.contact").toString()
    },
    {
        href: chartsRoutes[i18n.language].pricing,
        title: i18n.t("nav.pricing"),
        children: i18n.t("nav.pricing").toString()
    },
    {
        href: chartsRoutes[i18n.language].terms,
        title: i18n.t("nav.terms_of_service"),
        children: i18n.t("nav.terms_of_service").toString(),
        target: "_blank",
        external: true
    },
    {
        href: chartsRoutes[i18n.language].privacyPolicy,
        title: i18n.t("nav.privacy_policy"),
        children: i18n.t("nav.privacy_policy").toString(),
        target: "_blank",
        external: true
    },
    {
        href: chartsRoutes[i18n.language].faq,
        title: i18n.t("nav.faq"),
        children: i18n.t("nav.faq").toString()
    }
];
