import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {borderRadius, calculateRemSize, flex} from "@pg-design/helpers-css";

import {CitySlug, getCityOption} from "../../../../../../config/cities";
import {usePropertyTypeFilter} from "../../../../../../utils/hooks/usePropertyTypeFilter";
import {FilterOfferType} from "../../../../common/app/constants/enums";

interface IProps {
    city: CitySlug | null;
}

export const SwitchableMapFilter = ({city}: IProps) => {
    const {activeFilter, filter} = usePropertyTypeFilter();
    const {t} = useTranslation();

    if (getCityOption(city).propertyTypeFilters) {
        return (
            <StyledFilters>
                <div onClick={() => filter(FilterOfferType.FLATS)}>
                    <span
                        className={`dot mieszkania ${
                            !activeFilter || activeFilter === FilterOfferType.FLATS ? "active" : ""
                        }`}
                    />
                    <span>{t("common.flat", {count: 2})}</span>
                </div>
                <div onClick={() => filter(FilterOfferType.HOUSES)}>
                    <span
                        className={`dot domy ${
                            !activeFilter || activeFilter === FilterOfferType.HOUSES ? "active" : ""
                        }`}
                    />
                    <span>{t("common.house", {count: 2})}</span>
                </div>
            </StyledFilters>
        );
    }
    return null;
};

const StyledFilters = styled.div`
    background: white;
    box-shadow: var(--box-shadow);
    ${borderRadius(1)};
    position: absolute;
    bottom: ${calculateRemSize(3)};
    left: 50%;
    transform: translateX(-50%);
    ${flex("center")}
    flex-wrap: wrap;

    & > div {
        padding: 1rem;
        cursor: pointer;
        text-align: center;
        width: 100%;
        transition: var(--transition);

        .dot {
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            margin-right: 0.6rem;
            background: ${(props) => props.theme.colors.gray[100]};
            transition: var(--transition);

            &.domy.active {
                background: #826af9;
            }

            &.mieszkania.active {
                background: #ee6664;
            }
        }

        &:hover {
            color: #74757a;

            .dot.domy:not(.active) {
                background: #826af9;
            }

            .dot.mieszkania:not(.active) {
                background: #ee6664;
            }
        }
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        & > div {
            width: auto;
            padding: 1rem 2rem;
        }
    }
`;
