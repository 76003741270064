import {css, Theme, useTheme} from "@emotion/react";
import {flexAlignCenter, m, mb, mr, onDesktop} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IProps {
    className?: string;
    heading: string;
    bulletPoints?: string[];
    description?: string;
}

export const HeadingWithDescription = ({className, heading, bulletPoints, description}: IProps) => {
    const theme = useTheme();

    return (
        <div className={className}>
            {heading && (
                <Text variant="headline_1" css={mb(2)}>
                    {heading}
                </Text>
            )}

            {bulletPoints && (
                <ul css={[onDesktop(flexAlignCenter), mb(3)]}>
                    {bulletPoints.map((item, index) => (
                        <Text
                            as="li"
                            variant="headline_6"
                            key={index}
                            css={[listDot(theme), m(0, 0, 1.5, 0), onDesktop(m(0, 4, 0, 0))]}
                        >
                            {item}
                        </Text>
                    ))}
                </ul>
            )}

            {description && (
                <Text as="div" variant="headline_6" css={[mb(4), onDesktop(mb(6))]}>
                    {description}
                </Text>
            )}
        </div>
    );
};

const listDot = (theme: Theme) => css`
    vertical-align: center;

    &::before {
        ${mr(2)};
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        background-color: ${theme.colors.primary};
    }
`;
