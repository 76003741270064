import {useState} from "react";
import {useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {flexAlignCenter, flexDirection, mb, mt, onDesktop, p, pl, w100} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons";
import {Modal} from "@pg-design/modal";
import {Text} from "@pg-design/text";

import {ZIndex} from "../../../../../common/app/constants/z_index";
import {DataProtectionNotice} from "../../../../auth/components/auth_modal/DataProtectionNotice";
import {DownloadDemoForm} from "./DownloadDemoForm";

interface IDownloadDemoModal {
    isOpen: boolean;
    onModalClose: () => void;
}

export const DownloadDemoModal = ({isOpen, onModalClose}: IDownloadDemoModal) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const {t} = useTranslation();
    const theme = useTheme();

    const onClose = () => {
        setIsSuccess(false);
        onModalClose();
    };

    return (
        <Modal zIndex={ZIndex.MODAL} isOpen={isOpen} onModalClose={onClose}>
            <Modal.Header>
                {!isSuccess && (
                    <Text as="p" variant="headline_6" align="center">
                        {t("monitoring.hero.demo.download")}
                    </Text>
                )}
            </Modal.Header>

            <Modal.Content css={modalContent}>
                {!isSuccess ? (
                    <>
                        <Text css={mb(1.5)} variant="body_copy_2" align="center">
                            {t("monitoring.hero.demo.lead")}
                        </Text>
                        <DownloadDemoForm setIsSuccess={setIsSuccess} />
                        <DataProtectionNotice css={[mt(1.5), pl(4)]} />
                    </>
                ) : (
                    <div css={[flexAlignCenter, flexDirection("column")]}>
                        <BrandCheckboxCheckedIcon
                            css={mb(8)}
                            size="12.8"
                            wrapperColor={theme.colors.primary}
                            wrapperSize="12.8"
                        />
                        <Text css={mb(2)} variant="headline_3">
                            {t("monitoring.hero.demo.thanks")}
                        </Text>
                        <Text css={mb(8)} variant="body_copy_1" align="center">
                            {t("monitoring.hero.demo.sent")}
                        </Text>
                        <Button css={w100} variant="filled_primary" onClick={onClose}>
                            {t("common.back")}
                        </Button>
                    </div>
                )}
            </Modal.Content>
        </Modal>
    );
};

const modalContent = css`
    ${p(3, 2, 6, 2)};
    ${onDesktop(css`
        width: var(--modal-width-small);
    `)}
`;
