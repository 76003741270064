interface IRangeQuery<T> {
    lower: T;
    upper: T;
    bounds: string;
}

export enum OfferType {
    FLAT = 1,
    HOUSE = 2,
    COMMERCIAL = 3
}

enum OfferPriceType {
    NETTO = 1,
    BRUTTO = 2
}

export interface IOfferBoxOffer {
    construction_date_range: IRangeQuery<string>;
    description: string | null;
    geo_point: {
        coordinates: [number, number];
    };
    id: number;
    name: string;
    main_image: {
        m_img_375x211: string;
        m_img_155x87: string;
    };
    region: {
        full_name: string;
        id: number;
        short_name_reverted: string;
    };
    slug: string;
    stats: {
        properties_count_for_sale: number;
        properties_with_roi_count: number;
        ranges_area_min: number;
        ranges_area_max: number;
        ranges_price_m2_min: number;
        ranges_rooms_max: number;
        ranges_rooms_min: number;
    };
    street_name: string | null;
    street_number: string | null;
    type: OfferType;
    vendor: {
        id: number;
        name: string;
        slug: string;
    };
    address: string;
    has_required_prices: boolean | null;
    price_type: OfferPriceType | null;
}

export const singleOfferMock: IOfferBoxOffer = {
    address: "Warszawa, Wesoła, Wola Grzybowska, ul. Armii Krajowej 78",
    construction_date_range: {
        lower: "2019-03-30",
        upper: "2021-09-30",
        bounds: "[]"
    },
    description:
        "Lubisz ciszę, spokój i zielone otoczenie. Zgiełk Warszawy męczy Cię i nie pozwala wypocząć po całym dniu pracy. Najważniejsza jest dla Ciebie rodzina. Szukasz miejsca gdzie Twoje dziecko będzie mogło bezpiecznie bawić się na osiedlowym placu zabaw. Przyjaźni sąsiedzi, długie spacery, wygodne życie… Osiedle Wesoła Residence odpowiednie miejsce dla Ciebie i Twojej rodziny.\r\n\r\nWesoła Residence to eleganckie i kameralne osiedle mieszkaniowe (28 mieszkań, po 6 mieszkania na piętrze) o niskiej trzypiętrowej zabudowie wraz z garażami podziemnym.\r\n\r\nWesoła Residence to starannie zaprojektowane wygodne i komfortowe mieszkania 2 i 3 pokojowe o powierzchni od 47m2 do 63,7m2. Wszystkie mieszkania posiadają balkony. Cichobieżna winda obsługiwać będzie poszczególne kondygnacje z podziemną halą garażową. Budynek będzie przystosowany do osób niepełnosprawnych.\r\n\r\nWesoła Residence to osiedle zaprojektowany przez wysoko wykwalifikowanych i doświadczonych architektów.\r\nWażnym elementem infrastruktury osiedla Wesoła Residence będzie plac zabaw dla najmniejszych mieszkańców. A kameralny nastrój budynku podkreślać będzie starannie zaprojektowana zielona architektura z dekoracyjnym oświetleniem i piękną roślinnością wzdłuż alejki prowadzącej do wejścia do budynków.\r\n\r\n\r\nOsiedle będzie zamknięte i strzeżone.",
    geo_point: {
        coordinates: [21.23500896477546, 52.249486942553716]
    },
    has_required_prices: null,
    id: 13153,
    main_image: {
        m_img_155x87:
            "https://thumbs-dev-rp.rynekpierwotny.com/3e79b87d:9ZFBLv6jSrYF2pLA5VF1AHQIp7o/155x87/filters:upscale():format(jpg)/offers/offer/None/main_image/wesola-residence-ii.jpg",

        m_img_375x211:
            "https://thumbs-dev-rp.rynekpierwotny.com/3e79b87d:PpzGlS8bg_JvMmNSmypJpiXcp-A/375x211/filters:upscale():format(jpg)/offers/offer/None/main_image/wesola-residence-ii.jpg"
    },
    name: "Wesoła Residence II",
    price_type: 2,
    region: {
        full_name: "mazowieckie, Warszawa, Wesoła, Wola Grzybowska",
        id: 52748,
        short_name_reverted: "Wola Grzybowska, Wesoła, Warszawa"
    },
    slug: "wesola-residence-ii-warszawa-wesola-wola-grzybowska",
    stats: {
        properties_count_for_sale: 9,
        properties_with_roi_count: 3,
        ranges_area_max: 63,
        ranges_area_min: 47,
        ranges_price_m2_min: 8900,
        ranges_rooms_max: 3,
        ranges_rooms_min: 2
    },
    street_name: "ul. Armii Krajowej",
    street_number: "78",
    type: 1,
    vendor: {
        id: 807,
        name: "Miglack ",
        slug: "miglack"
    }
};
