import {useEffect, useMemo, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {flexAbsoluteCenter, mb, ml, mt, mv, w100} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {appendQueryString} from "../../../../../utils/append_query_string";
import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {Pagination} from "../../../components/Pagination";
import {useGetPublicationsByPageQuery} from "../api/get_publications";
import {FeaturedPublicationItem} from "../components/featured_publications/FeaturedPublicationsItem";
import {PublicationsBreadcrumbs} from "../components/PublicationsBreadcrumbs";

export const PublicationsListing = withGTM(() => {
    const {search} = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
    const {data, isSuccess, isLoading, refetch} = useGetPublicationsByPageQuery(page);
    const {pathname} = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (!searchParams.toString()) {
            setPage(1);
        }
    }, [searchParams]);

    if (!isSuccess) {
        return (
            <SimpleContainer>
                <Container>
                    <Row>
                        <Col lgOffset={2} lg={12} md={12} sm={4}>
                            <div css={[w100, flexAbsoluteCenter, mt(9), mb(25)]}>
                                {isLoading ? (
                                    <Loader size="lg" />
                                ) : (
                                    <>
                                        <Text as="p" variant="headline_2">
                                            Nie udało się pobrać publikacji
                                        </Text>

                                        <Button css={ml(3)} onClick={refetch} variant="filled_primary">
                                            Spróbuj ponownie
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SimpleContainer>
        );
    }

    if (isSuccess && !data.results.length) {
        return (
            <SimpleContainer>
                <Container>
                    <Text css={mv(12)} as="p" variant="headline_1" align="center">
                        Nie znaleziono żadnych treści
                    </Text>
                </Container>
            </SimpleContainer>
        );
    }

    return (
        <SimpleContainer>
            <Container>
                <Row>
                    <Col>
                        <PublicationsBreadcrumbs />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Text css={mb(5)} variant="headline_1">
                            Lista wszystkich publikacji
                        </Text>
                    </Col>
                </Row>

                <Row as="ul">
                    {data.results.map((publication) => (
                        <FeaturedPublicationItem key={publication.id} publication={publication} />
                    ))}
                </Row>

                <Pagination
                    css={mb(6)}
                    list={data}
                    currentPage={page}
                    setCurrentPage={setPage}
                    multiNumbersInside
                    onPageChange={(page) => history.push(appendQueryString(pathname, page > 1 ? {page: page} : {}))}
                />
            </Container>
        </SimpleContainer>
    );
});
