export enum StaticContent {
    HOMEPAGE_TITLE = "homepage-title",
    HOMEPAGE_DESCRIPTION = "homepage-description",
    HOMEPAGE_COMMENT = "homepage-comment",
    HOMEPAGE_ADV_1 = "homepage-adv-1",
    HOMEPAGE_ADV_2 = "homepage-adv-2",
    HOMEPAGE_ADV_3 = "homepage-adv-3",
    HOMEPAGE_ADV_4 = "homepage-adv-4",
    HOMEPAGE_VIDEO_ACCESS = "homepage-video-access",
    HOMEPAGE_VIDEO_NO_ACCESS = "homepage-video-no-access",
    PUBLICATIONS_SUBTITLE = "publications-subtitle",
    FAQ = "faq",
    FAQ_MONITORING = "faq-monitoring"
}
