import {bugsnagNotify} from "../../../../utils/bugsnag";
import {IMetaData} from "../../server/meta_data/generate_meta_data";

export function setClientMetaData(metaData: IMetaData) {
    try {
        // update title
        window.document.title = metaData.title;
        // update meta data
        safelyUpdateMetaTag("description", metaData.description);
        safelyUpdateLinkTag("canonical", metaData.canonical ?? "");
    } catch (e) {
        // serverSide render
        bugsnagNotify(e as Error, {source: "setClientMetadata"});
    }
}

function safelyUpdateMetaTag(name: string, text: string) {
    // prepare tag
    const oldTag = window.document.querySelector(`meta[name=${name}]`);
    const newTag = window.document.createElement("meta");
    newTag.name = name;
    newTag.content = text;
    // update/add tag
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.replaceChild(newTag, oldTag);
    } else {
        const head = window.document.querySelector("head");
        head && head.appendChild(newTag);
    }
}

function safelyUpdateLinkTag(name: string, text: string) {
    // prepare tag
    const oldTag = window.document.querySelector(`link[rel=${name}]`);
    const newTag = window.document.createElement("link");
    newTag.rel = name;
    newTag.href = text;
    // update/add tag
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.replaceChild(newTag, oldTag);
    } else {
        const head = window.document.querySelector("head");
        head && head.appendChild(newTag);
    }
}
