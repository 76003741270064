import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Col} from "@pg-design/grid";
import {
    backgroundColor,
    borderRadius,
    calculateRemSize,
    flex,
    flexAbsoluteCenter,
    flexDirection,
    mb,
    ml,
    mt,
    p,
    w100
} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {IPublication} from "../../types/Publication";
import {FeaturedPublicationItem} from "./FeaturedPublicationsItem";

interface IPublicationsFetchProps {
    data?: Array<IPublication>;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    refetch: () => void;
    className?: string;
}

export const FeaturedPublications = ({data, isLoading, isSuccess, refetch}: IPublicationsFetchProps) => {
    if (!isSuccess) {
        return (
            <Col lgOffset={2} lg={12} md={12} sm={4}>
                <div css={[w100, flexAbsoluteCenter, mt(9), mb(25)]}>
                    {isLoading ? (
                        <Loader size="lg" />
                    ) : (
                        <>
                            <Text as="p" variant="headline_2">
                                Nie udało się pobrać publikacji
                            </Text>
                            <Button css={ml(3)} onClick={refetch} variant="filled_primary">
                                Spróbuj ponownie
                            </Button>
                        </>
                    )}
                </div>
            </Col>
        );
    }

    if (isSuccess && data && data.length) {
        return (
            <>
                {data
                    ?.slice(0, 3)
                    .map((publication) => <FeaturedPublicationItem key={publication.name} publication={publication} />)}

                <Col as="li" lg={4} md={3} sm={4} css={lastItemWrapper}>
                    <Link to={chartsRoutes.pl.publications.listing} css={lastItem}>
                        <div css={numberHolder}>
                            <Text as="p" variant="headline_3">
                                +{data?.length - 3}
                            </Text>
                        </div>

                        <Text css={mt(4)} as="p" variant="headline_4" align="center">
                            Zobacz wszystkie materiały
                        </Text>
                    </Link>
                </Col>
            </>
        );
    }

    return (
        <div css={[w100, flexAbsoluteCenter, mt(9), mb(25)]}>
            <Text as="p" variant="headline_3">
                Brak publikacji do wyświetlenia
            </Text>
        </div>
    );
};

const lastItemWrapper = css`
    ${mb(4)};
    ${flex("flex-start", "center")}
`;

const lastItem = (theme: Theme) => css`
    height: 100%;
    min-height: 40rem;
    width: 100%;
    max-width: 42rem;
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
    ${borderRadius(2)};
    ${backgroundColor(theme.colors.background[200])};
    ${p(2)};
`;

const numberHolder = (theme: Theme) => css`
    width: ${calculateRemSize(12)};
    height: ${calculateRemSize(12)};
    ${flexAbsoluteCenter};
    ${backgroundColor(theme.colors.primary)};
    border-radius: 50%;
`;
