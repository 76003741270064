import {FC} from "react";

import {useAppSelector} from "../../../../utils/hooks/store_hooks";
import {selectAuthStatus} from "../../features/auth/auth_slice";
import {UnauthenticatedLanding} from "../views/UnauthenticatedLanding";

export function withAuthGuard(Component: FC) {
    return () => {
        const {isLoggedIn} = useAppSelector(selectAuthStatus);

        if (isLoggedIn) {
            return <Component />;
        }

        return <UnauthenticatedLanding />;
    };
}
