import {useTranslation} from "react-i18next";
import {css} from "@emotion/react";
import {elevation} from "@pg-design/elevation";
import {calculateRemSize, pointer} from "@pg-design/helpers-css";
import {DownloadIcon} from "@pg-design/icons";
import {i18n} from "i18next";

import {Tooltip} from "../../Tooltip";

interface IProps {
    chartRef: any;
}

export type ChartExportType = "print" | "xls" | "image/png" | "image/jpeg" | "application/pdf" | "image/svg+xml";

export const ExportMenu = ({chartRef}: IProps) => {
    const handleExport = (type: ChartExportType) => {
        chartRef?.current?.exportChart(type);
    };

    if (chartRef) {
        return (
            <Tooltip
                isServerSideRendered={false}
                config={{trigger: "click", interactive: true}}
                triggerClassName={icon}
                trigger={
                    <DownloadIcon
                        css={elevation(1)}
                        wrapperType="circle"
                        wrapperColor="#fff"
                        size="1.6"
                        wrapperSize="2.4"
                    />
                }
            >
                <MenuItems onItemClick={handleExport} />
            </Tooltip>
        );
    }
    return null;
};

interface IMenuItemsProps {
    onItemClick: (action: ChartExportType) => void;
}

const MenuItems = ({onItemClick}: IMenuItemsProps) => {
    const {i18n} = useTranslation();

    return (
        <ul>
            {Object.entries(menuItems(i18n)).map(([key, value]) => (
                <li css={listItem} key={key} onClick={() => onItemClick(key as ChartExportType)}>
                    {value}
                </li>
            ))}
        </ul>
    );
};

const menuItems = (
    i18n: i18n
): {
    [key in ChartExportType]?: string | null;
} => ({
    print: i18n.t("exporting.print"),
    xls: i18n.t("exporting.as_xls"),
    "image/png": i18n.t("exporting.as_png"),
    "image/jpeg": i18n.t("exporting.as_jpg"),
    "image/svg+xml": i18n.t("exporting.as_svg")
});

const listItem = css`
    padding: ${calculateRemSize(0.5)};
    ${pointer};
`;

const icon = css`
    ${pointer};
    vertical-align: middle;
`;
