import {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {css, Theme, useTheme} from "@emotion/react";
import {elevation} from "@pg-design/elevation";
import {Col, Container, Row} from "@pg-design/grid";
import {borderRadius, flexAbsoluteCenter, flexAlignCenter, m, mb, onDesktop, p, pv} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons";
import {Picture} from "@pg-design/picture";
import {Text} from "@pg-design/text";

import background from "../../assets/map_bg.png";
import background_large from "../../assets/map_bg_large.png";
import background_medium from "../../assets/map_bg_medium.png";
import background_mobile from "../../assets/map_bg_mobile.png";
import map_desktop from "../../assets/map_desktop.svg";
import map_mobile from "../../assets/map_mobile.svg";

interface ICardProps {
    children: ReactNode;
}

const Card = ({children}: ICardProps) => {
    const theme = useTheme();

    return (
        <div css={card}>
            <BrandCheckboxCheckedIcon css={mb(3)} size="4" wrapperColor={theme.colors.primary} wrapperSize="4" />
            <Text variant="body_copy_1" align="center">
                {children}
            </Text>
        </div>
    );
};

const card = css`
    ${m(1.5, 0)};
    ${p(3, 2)};
    background-color: #fff;
    ${elevation(4)};
    ${flexAlignCenter};
    ${borderRadius(2)};
    flex-direction: column;

    ${onDesktop(m(1.5))};
`;

export const Reasons = () => {
    const {t} = useTranslation();
    const theme = useTheme();

    const reasons = [
        t("monitoring.reasons.radius"),
        t("monitoring.reasons.strategy"),
        t("monitoring.reasons.marketing"),
        t("monitoring.reasons.rate"),
        t("monitoring.reasons.time"),
        t("monitoring.reasons.enquiries")
    ];

    return (
        <Container as="section" fluid css={wrapper}>
            <Container>
                <Text css={mb(6)} variant="headline_1" align="center" as="h2">
                    {t("monitoring.reasons.question")}
                </Text>
            </Container>

            <div css={content}>
                <Container>
                    <Row>
                        <Col lg={6} md={4} sm={4} css={[flexAbsoluteCenter, reasonsPictureWrapper]}>
                            <Picture
                                sources={[
                                    {minWidthPX: 0, src: map_mobile, height: 360, width: 355},
                                    {
                                        minWidthPX: theme.breakpoints.numeric.lg,
                                        src: map_desktop,
                                        height: 409,
                                        width: 402
                                    }
                                ]}
                                alt="houses"
                                loading="lazy"
                            />
                        </Col>

                        <Col lgOffset={1} lg={9} md={8} sm={4} css={reasonsWrapper}>
                            <div css={cardsWrapper}>
                                {reasons.map((reason) => (
                                    <Card key={reason}>
                                        <span dangerouslySetInnerHTML={{__html: reason}}></span>
                                    </Card>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Container>
    );
};

const wrapper = css`
    ${pv(9)};
    text-align: center;
    overflow: hidden;
`;

const content = (theme: Theme) => css`
    background: url(${background_mobile}) no-repeat;

    @media (min-width: ${theme.breakpoints.xs}) {
        background: url(${background_medium}) no-repeat;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        background: url(${background}) no-repeat;
    }

    @media (min-width: ${theme.breakpoints.xl}) {
        background: url(${background_large}) no-repeat;
    }
`;

const reasonsPictureWrapper = css`
    order: 2;

    ${onDesktop(css`
        order: 1;
    `)}
`;

const reasonsWrapper = css`
    position: relative;
    ${mb(3)};
    order: 1;

    ${onDesktop(css`
        top: 7.5rem;
        ${mb(0)};
        order: 2;
    `)}
`;

const cardsWrapper = css`
    ${mb(9)};
    display: grid;
    grid-template-columns: 1fr;

    ${onDesktop(css`
        grid-template-columns: repeat(2, 1fr);
    `)}
`;
