import React, {ChangeEvent, Dispatch, MutableRefObject, ReactElement, SetStateAction, useRef, useState} from "react";
import {css, Theme} from "@emotion/react";
import {flex, p, pointer} from "@pg-design/helpers-css";
import {ChevronDownIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {debounce, isEmpty} from "lodash-es";

import {useClickOutside} from "../../../../../../../utils/hooks/useClickOutside";
import {rotate} from "../../../../../components/css_helpers/rotate";
import {useGetVendorQuery, useGetVendorsQuery} from "../../../api/get_vendors";
import {inputWrapper} from "./css_helpers/input_wrapper";
import {mobileInputTrigger} from "./css_helpers/mobile_input_trigger";
import {resultsWrapper} from "./css_helpers/results_wrapper";
import {searchDesktopWrapper} from "./css_helpers/search_desktop_wrapper";
import {SearchDropdown} from "./css_helpers/search_dropdown";
import {AutoCompleteInput, iconWrapper} from "./AutoCompleteInput";
import {MobileOptionsModal} from "./MobileOptionsModal";
import {ResultsInfo} from "./ResultsInfo";
import {SearchResultPill} from "./SearchResultPill";

interface IProps {
    className?: string;
    setDesktopSearchBlendActive: Dispatch<SetStateAction<boolean>>;
    selectedVendor?: number;
    setSelectedVendor: (value: string) => void;
    removeVendor: () => void;
}

export const VendorAutoCompleteSearch = ({
    className,
    setDesktopSearchBlendActive,
    selectedVendor,
    setSelectedVendor,
    removeVendor
}: IProps) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isMobileSearchActive, setMobileSearchActive] = useState(false);
    const [inputValue, setInputValue] = useState<string>("");
    const {data} = useGetVendorsQuery(inputValue);
    const {data: currentVendor} = useGetVendorQuery(selectedVendor ?? -1);

    const resetInput = () => {
        if (inputRef.current) {
            (inputRef.current as HTMLInputElement).value = "";
        }
        setInputValue("");
    };

    const handleVendorClick = (vendorId: number) => {
        setSelectedVendor(vendorId.toString());
        setMobileSearchActive(false);
        setDesktopSearchBlendActive(false);
        resetInput();
        closeDropdown();
    };

    const inputRef = useRef<HTMLInputElement | null>(null);

    const defaultPlaceholder = "Wpisz nazwę dewelopera";

    const handleInputChange = debounce((value: string) => {
        setInputValue(value);
        if (value === "") {
            resetInput();
        }
    }, 300);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        handleInputChange(value);
    };

    const openDropdown = () => {
        setDropdownOpen(true);
        setDesktopSearchBlendActive(true);
    };
    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = () => {
        closeDropdown();
        setDesktopSearchBlendActive(false);
    };

    useClickOutside(wrapperRef, handleClickOutside);

    const renderDropdownContent = () => {
        if (data && isEmpty(data.results)) {
            return (
                <ResultsInfo
                    onClick={() => setDropdownOpen(false)}
                    title="Niestety nic nie znaleźliśmy"
                    text="Zmień parametry wyszukiwania"
                />
            );
        }
    };

    const inputIcon = (isInModal: boolean) =>
        isInModal ? null : (
            <div css={iconWrapper}>
                <div css={isDropdownOpen ? rotatedStyle : rotatedStyleReverse}>
                    <ChevronDownIcon size="2" />
                </div>
            </div>
        );

    const handleInputMount = (ref: MutableRefObject<HTMLInputElement | null>) => {
        inputRef.current = ref.current;
    };

    const vendorPillLabel = selectedVendor && selectedVendor > -1 && currentVendor ? currentVendor.name : "";

    const onRemoveVendor = () => {
        removeVendor();
        setInputValue("");
        closeDropdown();
    };

    const renderSearchAutocomplete = (isInModal = false) => {
        return (
            <div css={inputWrapper} className={className} ref={wrapperRef}>
                <AutoCompleteInput
                    openDropdown={openDropdown}
                    closeDropdown={closeDropdown}
                    onChange={handleChange}
                    placeholder={defaultPlaceholder}
                    inputIcon={inputIcon(isInModal) as ReactElement}
                    onInputMount={handleInputMount}
                    pillLabel={vendorPillLabel}
                    onRemoveOption={onRemoveVendor}
                />
                <SearchDropdown isOpen={isDropdownOpen} isInModal={isInModal}>
                    <div css={resultsWrapper}>
                        {vendorPillLabel && (
                            <SearchResultPill pillLabel={vendorPillLabel} onRemoveResult={onRemoveVendor} />
                        )}

                        <ul css={resultsList}>
                            {" "}
                            {data &&
                                data.results.map((vendor) => (
                                    <li key={vendor.id} css={link} onClick={() => handleVendorClick(vendor.id)}>
                                        <Text variant="body_copy_2">{vendor.name}</Text>
                                    </li>
                                ))}
                        </ul>

                        {renderDropdownContent()}
                    </div>
                </SearchDropdown>
            </div>
        );
    };

    return (
        <div css={wrapper}>
            <div onClick={() => setMobileSearchActive(true)} css={mobileInputTrigger}>
                {inputIcon(true)}
                Wpisz nazwę dewelopera
            </div>

            <MobileOptionsModal
                isOpen={isMobileSearchActive}
                modalHeading="Wpisz nazwę dewelopera"
                onModalClose={() => setMobileSearchActive(false)}
            >
                {renderSearchAutocomplete(true)}
            </MobileOptionsModal>

            <div css={searchDesktopWrapper}>{renderSearchAutocomplete(false)}</div>
        </div>
    );
};

const wrapper = css`
    flex: 1;
`;

const link = (theme: Theme) => css`
    ${flex("normal", "space-between")};
    ${p(1)};
    ${pointer};
    color: #000 !important;
    align-items: center;
    height: 6.4rem;
    border-bottom: 1px solid ${theme.colors.gray[200]};

    :hover {
        background: ${theme.colors.gray[200]};
    }
`;

const rotatedStyle = css`
    ${rotate()};
    transition: ease-in-out 0.2s;
`;
const rotatedStyleReverse = css`
    ${rotate("0")};
    transition: ease-in-out 0.2s;
`;

export const resultsList = css`
    list-style: none;
    padding: 0;
    margin: 0;
`;
