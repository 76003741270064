import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {flex, flexDirection, mb} from "@pg-design/helpers-css";
import {getTextVariantStyleFor} from "@pg-design/text";

import {useAppDispatch} from "../../../../utils/hooks/store_hooks";
import {setResponseStatus} from "../../features/app_status/app_status_slice";
import {ResponseStatus} from "../../features/ssr/path_to_action/state/app_status/IAppStatusState";
import {useGetContentQuery} from "../../features/static_content/api/get_content";
import {StaticContent} from "../../features/static_content/types/Content";
import {pageviewAlgolyticsHit} from "../../features/tracking/algolytics/pageview_hits";
import {usePageview} from "../../features/tracking/use_pageview";
import {pageviewUserComHit} from "../../features/tracking/user_com/hit_user_com";
import {ViewType} from "../../features/tracking/view_type";
import {Heading} from "../Heading";
import {SimpleContainer} from "../layout/SimpleContainer";

export const Faq = () => {
    const {t} = useTranslation();
    const {
        i18n: {language}
    } = useTranslation();
    usePageview(() => {
        pageviewAlgolyticsHit({viewType: ViewType.FAQ});
        pageviewUserComHit();
    });
    const {data, isError} = useGetContentQuery(StaticContent.FAQ);
    const dispatch = useAppDispatch();

    if (isError) {
        dispatch(setResponseStatus({responseStatus: ResponseStatus.PAGE_500}));
        return null;
    }

    return (
        <SimpleContainer>
            <Container as="section">
                <Heading text={t("faq_title")} />

                <Row>
                    <Col css={column} mdOffset={1} md={10} lgOffset={2} lg={12}>
                        {data && data.content_pl && data.content_en && (
                            <div
                                css={faqHolder}
                                dangerouslySetInnerHTML={{
                                    __html: language === "pl" ? data.content_pl : data.content_en
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
};

const column = css`
    ${mb(6)}
    ${flex()}
    ${flexDirection("column")};
    gap: 2.4rem;
`;

export const faqHolder = (theme: Theme) => css`
    & > div:not(:last-of-type) {
        ${mb(3)};
    }

    .questions {
        display: none;
    }

    .answers {
        background-color: #fff;
        padding: 0 1.6rem;
        border-radius: 0 0 1.6rem 1.6rem;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: 0.7s ease;
        ${getTextVariantStyleFor("body_copy_2")(theme)};
        color: ${theme.colors.gray[700]};
    }

    .question-holder {
        width: 100%;
        min-height: 5.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        cursor: pointer;
        user-select: none;
        padding: 0.8rem 1.6rem;
        border-radius: 1.6rem;
        transition: 0.7s ease;
    }

    .question {
        text-align: start;
        ${getTextVariantStyleFor("body_copy_0")(theme)};
        font-weight: 500;
    }

    .arrow {
        line-height: 1;
        transition: 0.35s ease;
    }

    .questions:checked ~ .answers {
        height: auto;
        opacity: 1;
        padding: 1.6rem;
    }

    .questions:checked ~ .question-holder {
        border-radius: 1.6rem 1.6rem 0 0;
    }

    .questions:checked ~ .question-holder > .arrow {
        transform: rotate(180deg);
    }
`;
