import React from "react";
import {css, Theme} from "@emotion/react";
import {flex, flexAbsoluteCenter, p, pl} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {useIsMobile} from "../../../../utils/hooks/useIsMobile";

interface IProps {
    desktopTitle?: string;
    mobileTitle?: string;
    onClose: () => void;
}

export const ResponsiveModalHeader = (props: IProps) => {
    const isMobile = useIsMobile(false, 768);

    return isMobile ? (
        <div css={headerMobile}>
            <Text variant="body_copy_2">{props.mobileTitle}</Text>
            <span css={closeWrapperMobile} onClick={props.onClose}>
                <CloseIcon size="1.4" />
            </span>
        </div>
    ) : (
        <div css={[flex("center", "space-between"), p(3, 3, 0, 3)]}>
            <Text variant="headline_6">{props.desktopTitle}</Text>

            <CloseIcon onClick={props.onClose} size="2" />
        </div>
    );
};

const headerMobile = (theme: Theme) => css`
    ${flex("center", "space-between")};
    ${pl(4)};
    background-color: ${theme.colors.gray[100]};
    height: 4.8rem;
    min-height: 4.8rem;
`;

const closeWrapperMobile = (theme: Theme) => css`
    background-color: ${theme.colors.gray[200]};
    width: 4.8rem;
    min-width: 4.8rem;
    height: 100%;
    ${flexAbsoluteCenter};
`;
