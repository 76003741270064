import {css} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {flexAlignCenter, flexDirection, mb, mv} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {Newsletter} from "../../../components/Newsletter";
import {useGetHighlightedEntriesQuery} from "../api/get_entries";
import {HeadingWithDescription} from "../components/header/HeadingWithDescription";
import {HighlightedArticleList} from "../components/landing/HighlightedArticleList";
import {LandingCards} from "../components/landing/LandingCards";
import {NewshubBreadcrumbs} from "../components/NewshubBreadcrumbs";

export const NewshubLanding = withGTM(() => {
    const {data, isLoading, isError} = useGetHighlightedEntriesQuery();

    return (
        <SimpleContainer>
            <Container as="section" css={[flexAlignCenter, flexDirection("column")]}>
                <Row>
                    <Col lg={12} lgOffset={2} md={12} sm={4}>
                        <NewshubBreadcrumbs css={mv(2)} />

                        <HeadingWithDescription
                            heading="BIG DATA News"
                            description="Dołącz do kilkutysięcznej grupy profesjonalistów rynku mieszkaniowego i korzystaj z najnowszych informacji branżowych."
                            css={heading}
                        />

                        <LandingCards />

                        <Text variant="headline_2" css={mb(6)}>
                            Polecamy
                        </Text>

                        <HighlightedArticleList
                            entries={data?.results.slice(0, 6)}
                            isLoading={isLoading}
                            isError={isError}
                        />
                    </Col>
                </Row>
            </Container>

            <Newsletter />
        </SimpleContainer>
    );
});

const heading = css`
    max-width: 78rem;
`;
