import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {getContentApi} from "../../../static_content/api/get_content";
import {StaticContent} from "../../../static_content/types/Content";
import {IActionContext} from "../create_path_to_action";
import {setAuthAndDate} from "./common_action";

export const landingAction = async (ctx: IActionContext<ICityParams>): Promise<void> => {
    await setAuthAndDate(ctx);

    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.HOMEPAGE_TITLE));
    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.HOMEPAGE_DESCRIPTION));
    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.HOMEPAGE_COMMENT));
    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.HOMEPAGE_ADV_1));
    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.HOMEPAGE_ADV_2));
    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.HOMEPAGE_ADV_3));
    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.HOMEPAGE_ADV_4));
    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.HOMEPAGE_VIDEO_ACCESS));
    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.HOMEPAGE_VIDEO_NO_ACCESS));

    await Promise.all(ctx.store.dispatch(getContentApi.util.getRunningQueriesThunk()));
};
