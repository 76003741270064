import {css, Theme} from "@emotion/react";

const fontVariant = "poppins";
const fontPath = `/static-charts/public/fonts/${fontVariant}/`;

export const fontFace = (
    src: string,
    family: string,
    variant: string,
    weight: 300 | 400 | 500 | 600 | 700,
    style = "normal"
) => `
    @font-face {
        font-family: ${family};
        src: url('${src}${variant}.woff2') format('woff2'),
             url('${src}${variant}.woff') format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: swap;
    }
`;

export const scaffolding = (theme: Theme) => css`
    ${fontFace(fontPath, fontVariant, `${fontVariant}_light`, 300)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_regular`, 400)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_medium`, 500)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_semibold`, 600)};
    ${fontFace(fontPath, fontVariant, `${fontVariant}_bold`, 700)};

    html {
        font-size: var(--font-root-size) !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        scroll-behavior: smooth;
    }

    body {
        font-size: var(--font-size);
        font-weight: var(--font-weight);
        line-height: var(--line-height);
        color: ${theme.colors.secondary};
        background-color: ${theme.colors.gray[100]};
    }

    html,
    body {
        font-family: var(--font-family);
    }

    a {
        color: ${theme.colors.secondary};
        text-decoration: none;
        transition: var(--transition);

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &:any-link {
            text-decoration: none;
        }
    }

    input,
    button,
    select,
    textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    [role="button"] {
        cursor: pointer;
    }
`;
