import {css, Theme} from "@emotion/react";
import {Container} from "@pg-design/grid";
import {flexAlignCenter, flexDirection, mb, onDesktop, w75, w100} from "@pg-design/helpers-css";
import {getTextVariantStyleFor} from "@pg-design/text";

import {useAppDispatch, useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {setResponseStatus} from "../../app_status/app_status_slice";
import {selectAuthStatus} from "../../auth/auth_slice";
import {ResponseStatus} from "../../ssr/path_to_action/state/app_status/IAppStatusState";
import {useGetContentQuery} from "../../static_content/api/get_content";
import {StaticContent} from "../../static_content/types/Content";

export const ConferenceVideo = () => {
    const {isLoggedIn, isAuthorized} = useAppSelector(selectAuthStatus);
    const requestSlug =
        isLoggedIn && isAuthorized ? StaticContent.HOMEPAGE_VIDEO_ACCESS : StaticContent.HOMEPAGE_VIDEO_NO_ACCESS;
    const {data, isError} = useGetContentQuery(requestSlug);
    const dispatch = useAppDispatch();

    if (isError) {
        dispatch(setResponseStatus({responseStatus: ResponseStatus.PAGE_500}));
        return null;
    }

    return (
        <Container css={[mb(16), flexAlignCenter, flexDirection("column")]} as="section">
            {data && data.content_pl && (
                <div css={contentWrapper} dangerouslySetInnerHTML={{__html: data.content_pl}} />
            )}
        </Container>
    );
};

const iframe = css`
    border: none;
    aspect-ratio: 16/9;
    ${w100};
`;

const contentWrapper = (theme: Theme) => css`
    ${w100};

    ${onDesktop(w75)};

    h3 {
        ${mb(5)};
        text-align: center;
        ${getTextVariantStyleFor("headline_1")(theme)};
    }

    iframe {
        ${iframe};
    }
`;
