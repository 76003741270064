import {useTranslation} from "react-i18next";
import {css} from "@emotion/react";

import {centeredFlex} from "../../../../components/css_helpers/flex";
import {Lock} from "./Lock";

export const LockedComment = () => {
    const {t} = useTranslation();

    return (
        <div css={centeredFlex}>
            <span css={placeholder}>{t("home.analyst_comment_placeholder")}</span>
            <Lock isComment />
        </div>
    );
};

const placeholder = css`
    filter: blur(5px);
`;
