import {IEntryCategory} from "../types/Category";
import {newshubApi} from "./newshub_api";

interface ICategoryRes {
    results: Array<IEntryCategory>;
}

export const getCategoriesApi = newshubApi.injectEndpoints({
    endpoints: (build) => ({
        getCategories: build.query<ICategoryRes, void>({
            query: () => ({
                url: "/categories/",
                method: "GET"
            })
        })
    })
});

export const {useGetCategoriesQuery} = getCategoriesApi;
