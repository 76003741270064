import {useState} from "react";
import {css, Theme} from "@emotion/react";
import {flexAbsoluteCenter} from "@pg-design/helpers-css";

import {useIsMobile} from "../../../../../utils/hooks/useIsMobile";
import {UserAccountButtons} from "../../../features/users/components/UserAccountButtons";
import {LanguageSwitcher} from "../../language_switcher/LanguageSwitcher";
import {BurgerMenu} from "./components/BurgerMenu";
import {Links} from "./components/Links";

interface INavigationProps {
    hideAuth?: boolean;
}

export const Navigation = ({hideAuth = false}: INavigationProps) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const isMobileDesktop = useIsMobile(true, 1365);
    const isMobile = useIsMobile(false, 1365);

    return (
        <nav css={wrapper}>
            {!isMobileDesktop && <Links />}

            <div css={flexAbsoluteCenter}>
                {!isMobile && !hideAuth && <UserAccountButtons />}
                <LanguageSwitcher />
            </div>

            {isMobile && (
                <BurgerMenu
                    isOpen={isMobileMenuOpen}
                    setOpen={() => setIsMobileMenuOpen(true)}
                    setClose={() => setIsMobileMenuOpen(false)}
                    hideAuth={hideAuth}
                />
            )}
        </nav>
    );
};

const wrapper = (theme: Theme) => css`
    width: 100%;
    height: var(--mobile-header-height);
    display: flex;
    justify-content: flex-end;

    @media (min-width: ${theme.breakpoints.lg}) {
        height: var(--desktop-header-height);
        justify-content: space-between;
    }
`;
