import {useMemo, useReducer, useState} from "react";
import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {
    backgroundColor,
    borderRadius,
    flex,
    flexAbsoluteCenter,
    flexAlignCenter,
    m,
    ml,
    p,
    pl,
    pointer,
    w100,
    zIndex
} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon, CloseIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {compile} from "path-to-regexp";

import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {NavigationText} from "../../../../components/header/navigation/components/NavigationText";
import {StyledIconWrapper} from "../../../../components/StyledIconWrapper";
import {IEntryCategory} from "../../types/Category";

interface ISubmenuState {
    [categoryName: string]: boolean;
}

interface IProps {
    isOpen: boolean;
    setOpen: () => void;
    className?: string;
    categories: Array<IEntryCategory>;
}

type SubmenuAction = {type: "TOGGLE_SUBMENU"; categoryName: string} | {type: "CLOSE_ALL_SUBMENUS"};

export const CategoriesMenuMobile = ({isOpen, setOpen, categories, className}: IProps) => {
    const [currentCategory, setCurrentCategory] = useState("BIG DATA News");

    const toPath = useMemo(() => {
        return compile(chartsRoutes.pl.newshub.marketInformation.category.subcategory.root);
    }, []);

    const toPathCategory = useMemo(() => {
        return compile(chartsRoutes.pl.newshub.marketInformation.category.root);
    }, []);

    const initialSubmenuState = categories.reduce((acc, curr) => {
        return {...acc, [curr.name]: false};
    }, {});

    const submenuReducer = (state: ISubmenuState, action: SubmenuAction) => {
        switch (action.type) {
            case "TOGGLE_SUBMENU":
                return {
                    ...state,
                    [action.categoryName]: !state[action.categoryName]
                };
            case "CLOSE_ALL_SUBMENUS":
                return categories.reduce((acc, curr) => {
                    return {...acc, [curr.name]: false};
                }, {} as ISubmenuState);
        }
    };

    const [submenuState, dispatchSubmenu] = useReducer(submenuReducer, initialSubmenuState);

    const closeAllSubmenus = () => {
        dispatchSubmenu({type: "CLOSE_ALL_SUBMENUS"});
    };

    const isSubmenuOpen = Object.values(submenuState).some(Boolean);

    return (
        <div className={className}>
            {isOpen && (
                <BurgerButtonWrapper
                    onClick={(e) => {
                        e.preventDefault();
                        closeAllSubmenus();
                        setOpen();
                    }}
                    isOpen={isOpen}
                >
                    <CloseIcon size="2" />{" "}
                </BurgerButtonWrapper>
            )}

            <Text variant="mini_header" css={ml(2)}>
                Wybierz kategorię
            </Text>
            <div
                css={currentCategoryWrapper}
                onClick={(e) => {
                    e.preventDefault();
                    closeAllSubmenus();
                    setOpen();
                }}
            >
                <Text variant="headline_6">{currentCategory}</Text>
                <ChevronRightIcon size="2.4" />
            </div>

            <StyledBackground isOpen={isOpen} />

            <StyledMenuHolder isOpen={isOpen}>
                <StyledMainMenu isSubmenuOpen={isSubmenuOpen}>
                    <li css={[label, pl(4)]}>
                        <NavigationText variant="body_copy_2">Menu</NavigationText>
                    </li>
                    {categories.map((category, i) => (
                        <li key={category.slug}>
                            <Link
                                css={link}
                                to={toPathCategory({
                                    marketInfoCategorySlug: category.slug
                                })}
                                onClick={() => {
                                    setCurrentCategory(category.name);
                                    closeAllSubmenus();
                                    setOpen();
                                }}
                            >
                                <NavigationText variant="info_txt_1">{category.name}</NavigationText>

                                <StyledIconWrapper
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        dispatchSubmenu({type: "TOGGLE_SUBMENU", categoryName: category.name});
                                    }}
                                >
                                    <ChevronRightIcon size="1.5" />
                                </StyledIconWrapper>
                            </Link>
                        </li>
                    ))}
                </StyledMainMenu>

                {categories.map((category, i) => (
                    <StyledSubMenu key={category.name} isOpen={submenuState[category.name]}>
                        <li css={label} onClick={() => closeAllSubmenus()}>
                            <ChevronLeftIcon size="1.5" />
                            <NavigationText variant="body_copy_2">{category.name}</NavigationText>
                        </li>

                        {submenuState[category.name] &&
                            category.subcategories.map((subcategory, j) => (
                                <li
                                    key={subcategory.name}
                                    onClick={() => {
                                        setCurrentCategory(subcategory.name);
                                        setOpen();
                                    }}
                                >
                                    <Link
                                        css={link}
                                        to={toPath({
                                            marketInfoCategorySlug: category.slug,
                                            marketInfoSubCategorySlug: subcategory.slug
                                        })}
                                    >
                                        <NavigationText variant="info_txt_1">{subcategory.name}</NavigationText>
                                    </Link>
                                </li>
                            ))}
                    </StyledSubMenu>
                ))}
            </StyledMenuHolder>
        </div>
    );
};

const currentCategoryWrapper = css`
    ${flex("center", "space-between")};
    ${m(1, 0, 3, 0)};
    ${p(2)};
    ${pointer};
    ${w100};
    ${backgroundColor("#fff")};
    ${borderRadius(2)};
`;

const BurgerButtonWrapper = styled.div<{isOpen: boolean}>`
    ${flexAbsoluteCenter};
    height: var(--mobile-header-height);
    width: var(--mobile-header-height);
    background-color: ${(props) => props.isOpen && "var(--nav-close-btn-bg)"};
    ${zIndex(9999)};
    cursor: pointer;

    ${(props) =>
        props.isOpen &&
        css`
            position: fixed;
            top: 0;
            right: 0;
            ${zIndex(10002)};
        `}
`;

const StyledBackground = styled.div<{isOpen: boolean}>`
    display: ${(props) => (props.isOpen ? "block" : "none")};
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: fixed;
    overflow: hidden;
    right: 0;
    top: 0;
    ${zIndex(9999)};
    transition: var(--transition);
    animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.5;
        }
    }
`;

const label = (theme: Theme) => css`
    ${pl(3)};
    ${zIndex(9999)};
    height: var(--mobile-header-height);
    ${flexAlignCenter};
    background-color: ${theme.colors.gray[100]};
    gap: 2.4rem;
`;

const StyledMenuHolder = styled.div<{isOpen: boolean}>`
    position: fixed;
    right: 0;
    top: 0;
    ${zIndex(10000)};
    display: flex;
    width: ${(props) => (props.isOpen ? "100vw" : "0")};
    max-width: 360px;
    height: 100vh;
    overflow: hidden;
    transition: var(--transition);
    background-color: #fff;
`;

const menu = css`
    width: 100vw;
    max-width: 360px;
    height: 100vh;
    overflow: hidden;
    transition: var(--transition);
    background-color: #fff;
`;

const StyledMainMenu = styled.ul<{isSubmenuOpen: boolean}>`
    ${menu};
    transform: ${(props) => (props.isSubmenuOpen ? "translateX(-360px)" : "0")};
`;

const StyledSubMenu = styled.ul<{isOpen: boolean}>`
    position: fixed;
    ${menu};
    transform: ${(props) => (props.isOpen ? "0" : "translateX(360px)")};
`;

const link = css`
    height: 60px;
    ${pl(2)};
    ${flex("center", "space-between")};
    white-space: nowrap;
`;
