import {CookiesProvider} from "react-cookie";
import {hydrateRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import createCache from "@emotion/cache";
import {CacheProvider, ThemeProvider} from "@emotion/react";
import {DSThemeProvider, themeRP} from "@pg-design/themes";

import {BugsnagErrorBoundary} from "../../../config/bugsnag";
import {App} from "../common/app/App";
import {I18nInitialProvider} from "../common/app/I18nInitialProvider";
import {InitialState} from "../features/ssr/path_to_action/state/InitialState";
import {createStore} from "../store/store";
import {initClientScripts} from "./init_client_scripts";

declare global {
    interface Window {
        __INITIAL_STORE__: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

const initialStore = window.__INITIAL_STORE__ ?? null;
delete window.__INITIAL_STORE__;

const emotionKey = "custom";
const emotionCache = createCache({key: emotionKey});
const store = createStore(initialStore);

initClientScripts(store.getState().users.userData);

const container = document.getElementById("root");

hydrateRoot(
    container as Element,
    <BugsnagErrorBoundary>
        <BrowserRouter>
            <Provider store={store}>
                <CookiesProvider>
                    <I18nInitialProvider>
                        <CacheProvider value={emotionCache}>
                            <DSThemeProvider>
                                <ThemeProvider theme={themeRP}>
                                    <InitialState store={store}>
                                        <App />
                                    </InitialState>
                                </ThemeProvider>
                            </DSThemeProvider>
                        </CacheProvider>
                    </I18nInitialProvider>
                </CookiesProvider>
            </Provider>
        </BrowserRouter>
    </BugsnagErrorBoundary>
);
