import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {useHeightObserver} from "../../../../../../../utils/hooks/useHeightObserver";
import {isBrowser} from "../../../../../common/app/read_charts_web_environment_variables";

interface IDesktopBlend {
    children?: React.ReactNode;
    pageHeight?: string;
    isActive: boolean;
    top?: string;
    height?: string;
    isFullScreen?: boolean;
    className?: string;
}

// TODO: `DesktopBlend` component should be moved to separate file. I'm leaving it here to avoid issues between branches during rebranding
export const DesktopBlend = (props: IDesktopBlend) => {
    const {pageHeight, ...restProps} = props;
    const {height: pageHeightLocalState, ref} = useHeightObserver({
        isActive: !props.isFullScreen && props.isActive,
        initialHeight: 0
    });

    if (isBrowser && !ref.current) {
        ref.current = document.body;
    }

    return (
        <DesktopBlendStyled
            {...restProps}
            pageHeight={(pageHeightLocalState ? pageHeightLocalState + "px" : pageHeight) ?? "100vh"}
        >
            {props.children}
        </DesktopBlendStyled>
    );
};

export const resultsList = css`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const fadeInAnimation = (time: string) => css`
    animation: fade-in ${time} cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const DesktopBlendStyled = styled.div<IDesktopBlend>`
    display: none;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        display: ${(props) => (props.isActive ? "block" : "none")};
        position: absolute;
        z-index: 1000;
        left: 0;
        width: 100%;
        background-color: rgba(35, 35, 45, 0.75);
        top: calc(${(props) => (props.top ? props.top : "0")} + 60px); // offset for navigation height
        height: calc(${(props) => props.pageHeight} - ${(props) => (props.height ? props.height : "6rem")});
        ${fadeInAnimation("0.2s")};

        ${(props) =>
            props.isFullScreen &&
            css`
                position: fixed;
                top: 25rem;
                left: 0;
                width: 100%;
                height: 100%;
            `}
    }
`;
