import {usersApi} from "./usersApi";

export interface IChangePasswordPayload {
    password: string;
    new_password: string;
}

export interface IChangePassword400Error {
    password: {password: string};
}

export const changePasswordApi = usersApi.injectEndpoints({
    endpoints: (build) => ({
        changePassword: build.mutation<void, IChangePasswordPayload>({
            query: (changePasswordPayload) => ({
                url: "/password/change/",
                method: "POST",
                body: changePasswordPayload
            })
        })
    })
});

export const {useChangePasswordMutation} = changePasswordApi;
