import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {getBigDataApiPath} from "../../../../../utils/get_big_data_api_path";
import prepare_headers from "../../../store/prepare_headers";

export const staticContentApi = createApi({
    reducerPath: "staticContentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${getBigDataApiPath()}static/`,
        prepareHeaders: prepare_headers,
        credentials: "include"
    }),
    tagTypes: ["Static"],
    endpoints: () => ({})
});
