import {css, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {flexAbsoluteCenter, mb, onDesktop, pv} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {AccessStatus, useAccessStatus} from "../../../../../../utils/hooks/useAccessStatus";

interface IPublicationOrderSuccessProps {
    isPaid: boolean;
    isFreeForBD?: boolean;
    onClick: () => void;
}

export const PublicationOrderSuccess = ({isPaid, isFreeForBD, onClick}: IPublicationOrderSuccessProps) => {
    const accessStatus = useAccessStatus();
    const theme = useTheme();

    return (
        <div css={successModal}>
            <div css={flexAbsoluteCenter}>
                <BrandCheckboxCheckedIcon
                    css={[mb(6), onDesktop(mb(8))]}
                    size="12.8"
                    wrapperColor={theme.colors.primary}
                    wrapperSize="12.8"
                />
            </div>

            <Text variant="headline_3" as="p" align="center" css={[mb(3), onDesktop(mb(2))]}>
                {/*publication free for bd for user without full access, is treated as paid*/}
                {isPaid || (isFreeForBD && accessStatus !== AccessStatus.FULL)
                    ? "Dziękujemy. Twoje zamówienie zostało zarejestrowane."
                    : "Dziękujemy. Twoje zamówienie zostało zrealizowane."}
            </Text>

            <Text variant="body_copy_1" align="center" css={[mb(6), onDesktop(mb(8))]}>
                {isPaid || (isFreeForBD && accessStatus !== AccessStatus.FULL)
                    ? "Skontaktujemy się z Tobą niebawem."
                    : "Publikacja została wysłana na wskazany adres e-mail."}
            </Text>

            <div css={flexAbsoluteCenter}>
                <Button variant="filled_primary" size="medium" css={button} onClick={onClick}>
                    Zamknij
                </Button>
            </div>
        </div>
    );
};

const successModal = css`
    ${pv(10)};

    ${onDesktop(css`
        width: var(--modal-width-small);
    `)};
`;

const button = css`
    width: 100%;

    ${onDesktop(css`
        width: 20rem;
    `)};
`;
