import {IPublicationParams} from "../../../../common/app/routing/charts_routes";
import {getPublicationsApi} from "../../../publications/api/get_publications";
import {IActionContext} from "../create_path_to_action";
import {setAuthAndDate} from "./common_action";

export const publicationsDetailsAction = async (ctx: IActionContext<IPublicationParams>): Promise<void> => {
    await setAuthAndDate(ctx);

    const {publicationId} = ctx.match.params;

    ctx.store.dispatch(getPublicationsApi.endpoints.getPublicationById.initiate(publicationId ?? "-1"));

    await Promise.all(ctx.store.dispatch(getPublicationsApi.util.getRunningQueriesThunk()));
};
