import {Options} from "highcharts";

import {RequestStatus} from "../../../common/app/constants/enums";
import {placeholderYearData} from "../../../common/app/constants/placeholder_charts_data";
import {MultilineChart} from "../Multiline";

export const MultilineChartPlaceholder = () => (
    <MultilineChart
        data={placeholderYearData}
        dataStatus={RequestStatus.SUCCESS}
        categoryField="date"
        colorSet="alternate"
        height={275}
        fields={["value1", "value2", "value3", "value4", "value5"]}
        customOptions={options}
    />
);

const options: Options = {
    tooltip: {
        enabled: false
    }
};
