import {css, Theme} from "@emotion/react";
import {m} from "@pg-design/helpers-css";

interface IDividerProps {
    className?: string;
}

export const Divider = ({className}: IDividerProps) => <hr css={divider} className={className} />;

const divider = (theme: Theme) => css`
    ${m(0)};
    height: 0;
    border: 0;
    border-top: 1px solid ${theme.colors.gray[500]};
`;
