import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {flexAlignCenter, pl} from "@pg-design/helpers-css";

import {NavigationText} from "../NavigationText";

interface IDropdownLinkItemProps {
    name: string;
    route: string;
    onClick?: () => void;
}

export const DropdownLinkItem = ({route, name, onClick}: IDropdownLinkItemProps) => {
    return (
        <li>
            <Link css={link} to={route} onClick={onClick}>
                <NavigationText variant="info_txt_1">{name}</NavigationText>
            </Link>
        </li>
    );
};

const link = (theme: Theme) => css`
    ${pl(2)};
    width: 240px;
    height: 68px;
    ${flexAlignCenter};
    background-color: #fff;

    &:hover {
        background-color: ${theme.colors.gray[100]};
    }
`;
