import {useTranslation} from "react-i18next";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {flexAbsoluteCenter, pl, pt} from "@pg-design/helpers-css";
import {UserIcon} from "@pg-design/icons";

import {CitySlug, mapParamSlugToCitySlug} from "../../../../../../../config/cities";
import {changeLanguage} from "../../../../../../../utils/change_language";
import {useAppDispatch} from "../../../../../../../utils/hooks/store_hooks";
import {useIsPolishOnlyPath} from "../../../../../../../utils/hooks/useIsPolishOnlyPath";
import {chartsRoutes, ICityParams} from "../../../../../common/app/routing/charts_routes";
import {getMenuItems} from "../../../../../common/app/routing/menu_items";
import {OnboardingTrigger} from "../../../../../features/analytical_platform/components/onboarding/OnboardingTrigger";
import {toggleOnboarding} from "../../../../../features/analytical_platform/redux/onboarding_slice";
import {useLogout} from "../../../../../features/auth/hooks/useLogout";
import {Divider} from "../../../../Divider";
import {NavigationText} from "../NavigationText";
import {DropdownLinkItem} from "./DropdownLinkItem";

interface IProps {
    to: string;
    title?: string;
    content: Content;
    onClick?: () => void;
}

export enum Content {
    Platform = 0,
    Newshub = 1,
    Monitoring = 2,
    Account = 3
}
export const DropdownLink = ({to, title, content, onClick}: IProps) => {
    const {t, i18n} = useTranslation();
    const {pathname} = useLocation();
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();
    const dispatch = useAppDispatch();
    const citySlug = mapParamSlugToCitySlug(city) || CitySlug.WARSZAWA;
    const logout = useLogout();
    const isFlagRendered = useIsPolishOnlyPath();

    const userMenuItems = [
        {
            label: i18n.t("nav.profile"),
            route: chartsRoutes[i18n.language].user.edit
        },
        {
            label: i18n.t("nav.change_password"),
            route: chartsRoutes[i18n.language].user.changePassword
        },
        {
            label: i18n.t("nav.logout"),
            route: chartsRoutes[i18n.language].root
        }
    ];

    return (
        <>
            <StyledListItem isAccountDropdown={content === Content.Account}>
                <Link css={link} to={to} onClick={onClick}>
                    {content === Content.Account ? (
                        <UserIcon size="2" />
                    ) : (
                        <NavigationText variant="info_txt_1">{title}</NavigationText>
                    )}
                </Link>

                <StyledDropdown isAccountDropdown={content === Content.Account} isFlagRendered={isFlagRendered}>
                    {content === Content.Platform && (
                        <>
                            {getMenuItems(citySlug, pathname, i18n).map((item) => (
                                <DropdownLinkItem route={item.path} name={item.name} key={item.name} />
                            ))}
                            <Divider />
                            <DropdownLinkItem
                                route={chartsRoutes[i18n.language].pricing}
                                name={i18n.t("nav.pricing")}
                                key="pricing"
                            />
                            <li>
                                <OnboardingTrigger
                                    onTriggerClick={() => dispatch(toggleOnboarding(true))}
                                    button={false}
                                />
                            </li>
                        </>
                    )}
                    {content === Content.Newshub && (
                        <>
                            <DropdownLinkItem
                                onClick={() => changeLanguage()}
                                route={chartsRoutes.pl.newshub.marketInformation.root}
                                name={t("newshub.market_informations")}
                                key="marketingInformations"
                            />
                            <DropdownLinkItem
                                onClick={() => changeLanguage()}
                                route={chartsRoutes.pl.newshub.bigDataNews.root}
                                name={t("newshub.bigdata_news")}
                                key="bigDataNews"
                            />
                        </>
                    )}
                    {content === Content.Monitoring && (
                        <DropdownLinkItem
                            route={chartsRoutes[i18n.language].pricing}
                            name={i18n.t("nav.pricing")}
                            key="pricing"
                        />
                    )}
                    {content === Content.Account &&
                        userMenuItems.map((item) => (
                            <DropdownLinkItem
                                onClick={item.label === i18n.t("nav.logout") ? logout : () => undefined}
                                name={item.label}
                                route={item.route}
                                key={item.label}
                            />
                        ))}
                </StyledDropdown>
            </StyledListItem>

            <div css={background} />
        </>
    );
};

const StyledListItem = styled.li<{isAccountDropdown: boolean}>`
    height: var(--desktop-header-height);
    list-style-type: none;

    & > a {
        ${(props) => !props.isAccountDropdown && pl(2)}
    }

    :hover {
        & > ul {
            display: flex;
        }
    }

    :hover + div {
        height: calc(100vh - var(--desktop-header-height));
        display: block;
    }

    ::marker {
        content: "";
    }
`;

const link = css`
    position: relative;
    min-width: var(--desktop-header-height);
    min-height: var(--desktop-header-height);
    height: 100%;
    ${flexAbsoluteCenter};
`;

const StyledDropdown = styled.ul<{isAccountDropdown: boolean; isFlagRendered: boolean}>`
    position: absolute;
    top: 6.4rem;
    ${(props) => props.isAccountDropdown && "right: 6.4rem"};
    ${(props) => props.isAccountDropdown && props.isFlagRendered && "right: 3.2rem"};
    ${pt(1)};
    display: none;
    flex-flow: column;
    background: transparent;
    z-index: 10000;
`;

const background = css`
    position: fixed;
    top: var(--desktop-header-height);
    right: 0;
    z-index: 9999;
    width: 100vw;
    height: 0;
    display: none;
    overflow: hidden;
    background-color: black;
    animation: fadein 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.5;
        }
    }
`;
