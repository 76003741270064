export const breakpoints = {
    xxs: "425px",
    xs: "576px",
    sm: "768px",
    md: "1024px",
    // lg: "1200px",
    xl: "1366px",
    xxl: "1650px",
    xxxl: "2048px"
};
