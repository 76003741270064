import {useTranslation} from "react-i18next";
import {pl} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IPrice {
    price: string;
}

export const Price = ({price}: IPrice) => {
    const {t} = useTranslation();

    return (
        <div>
            <Text variant="headline_3" as="span">
                {price} {t("common.pln")}
            </Text>
            <Text variant="info_txt_1" as="span" css={pl(1)}>
                {t("common.netto")}
            </Text>
        </div>
    );
};
