import {keyframes} from "@emotion/react";
import styled from "@emotion/styled";
import {calculateRemSize} from "@pg-design/helpers-css";

import {calculatePercentage} from "../../../../../../utils/calculate_percentage_difference";
import {formatNumber} from "../../../../../../utils/format";
import {colorSets} from "../../../../common/app/constants/colors";
import {MethodologyTooltip} from "../../../../components/MethodologyTooltip";

interface IProps {
    average: number;
    name: string;
    tooltipContent?: string;
    value: number;
}

export const BarometerScale = ({average, name, tooltipContent, value}: IProps) => {
    return (
        <StyledScale>
            <StyledHead>
                <div>
                    <MethodologyTooltip content={tooltipContent ?? ""} />
                    <span className="scaleName">{name}</span>
                </div>
                <strong>{formatNumber(value)}</strong>
            </StyledHead>
            <StyledBar>
                <StyledOverlay fillPercentage={calculatePercentage(value, average)} />
            </StyledBar>
        </StyledScale>
    );
};

const StyledScale = styled.div`
    margin-bottom: 20px;
    &:last-of-type {
        margin-bottom: 0;
    }
`;

const StyledHead = styled.div`
    display: flex;
    justify-content: space-between;
    .scaleName {
        margin-left: 5px;
        display: inline-block;
        &:first-letter {
            text-transform: capitalize;
        }
    }
`;

const StyledBar = styled.div`
    height: 7px;
    margin-top: 7px;
    cursor: pointer;
    background-color: ${colorSets.orange[2]};
    border-radius: ${calculateRemSize(0.5)};
`;

const fill = (fillPercentage: number) => keyframes`
    from {
        width: 0;
    }
    to {
        width: ${fillPercentage || 0}%;
    }
`;

const StyledOverlay = styled.div<{fillPercentage: number}>`
    animation: ${(props) => fill(props.fillPercentage)} 1.25s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    width: 0;
    max-width: 100%;
    height: 100%;
    background-color: ${colorSets.orange[0]};
`;
