import {forwardRef} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {onDesktop, pr} from "@pg-design/helpers-css";
import {IInputCoreProps, InputCore, InputWrapper} from "@pg-design/inputs";

type ISearchAutocompleteInputCore = IInputCoreProps & {hasPill: boolean};

export const SearchAutocompleteInputCore = forwardRef<HTMLInputElement, ISearchAutocompleteInputCore>(
    (props, forwardedRef) => {
        return (
            <StyledInputWrapper className={props.className} hasPill={props.hasPill}>
                <InputCore ref={forwardedRef} placeholder={props.placeholder} onChange={props.onChange} />
            </StyledInputWrapper>
        );
    }
);

interface IStyledInputWrapper {
    hasPill: boolean;
}

export const StyledInputWrapper = styled(InputWrapper)<IStyledInputWrapper>`
    flex-basis: 100%;
    width: 100%;
    height: 48px;
    ${pr(2)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        padding: ${(props) =>
            props.hasPill
                ? css`
                      padding-right: 5rem !important;
                      padding-left: 14rem !important;
                  `
                : css`
                      padding-right: 0;
                  `};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    input {
        ${onDesktop(css`
            padding-right: 3rem;
        `)};
    }

    &::placeholder {
        color: ${(props) => props.theme.colors.gray[700]};
    }

    &:focus {
        outline: none;
    }
`;
