import {Options} from "highcharts";

import {RequestStatus} from "../../../common/app/constants/enums";
import {placeholderYearData} from "../../../common/app/constants/placeholder_charts_data";
import {SplineChart} from "../Spline";

export const SplineChartPlaceholder = () => (
    <SplineChart
        data={placeholderYearData}
        dataStatus={RequestStatus.SUCCESS}
        field="value1"
        colorSet="purple"
        height={225}
        customOptions={options}
    />
);

const options: Options = {
    tooltip: {
        enabled: false
    }
};
