import {
    ChangeEvent,
    Dispatch,
    MutableRefObject,
    ReactElement,
    RefObject,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from "react";
import {css, Theme} from "@emotion/react";
import {flex, listUnStyled, p, pointer, w100} from "@pg-design/helpers-css";
import {ChevronDownIcon} from "@pg-design/icons";

import {useClickOutside} from "../../../../../../../utils/hooks/useClickOutside";
import {rotate} from "../../../../../components/css_helpers/rotate";
import {ProvincialCity} from "../../../api/get_entries";
import {inputWrapper} from "./css_helpers/input_wrapper";
import {mobileInputTrigger} from "./css_helpers/mobile_input_trigger";
import {resultsWrapper} from "./css_helpers/results_wrapper";
import {searchDesktopWrapper} from "./css_helpers/search_desktop_wrapper";
import {SearchDropdown} from "./css_helpers/search_dropdown";
import {AutoCompleteInput, iconWrapper} from "./AutoCompleteInput";
import {MobileOptionsModal} from "./MobileOptionsModal";
import {ResultsInfo} from "./ResultsInfo";
import {SearchResultPill} from "./SearchResultPill";

const CITIES = [
    {label: "Warszawa", value: ProvincialCity.WARSZAWA},
    {label: "Kraków", value: ProvincialCity.KRAKOW},
    {label: "Wrocław", value: ProvincialCity.WROCLAW},
    {label: "Gdansk", value: ProvincialCity.GDANSK},
    {label: "Poznań", value: ProvincialCity.POZNAN},
    {label: "Łódź", value: ProvincialCity.LODZ},
    {label: "Szczecin", value: ProvincialCity.SZCZECIN},
    {label: "Olsztyn", value: ProvincialCity.OLSZTYN},
    {label: "Białystok", value: ProvincialCity.BIALYSTOK},
    {label: "Bydgoszcz", value: ProvincialCity.BYDGOSZCZ},
    {label: "Katowice", value: ProvincialCity.KATOWICE},
    {label: "Kielce", value: ProvincialCity.KIELCE},
    {label: "Lublin", value: ProvincialCity.LUBLIN},
    {label: "Rzeszów", value: ProvincialCity.RZESZOW},
    {label: "Opole", value: ProvincialCity.OPOLE},
    {label: "Gorzów Wielkopolski", value: ProvincialCity.GORZOW_WIELKOPOLSKI}
];

interface IRegionAutocompleteSearchProps {
    fullWidth: boolean;
    className?: string;
    setDesktopSearchBlendActive: Dispatch<SetStateAction<boolean>>;
    cityCode: ProvincialCity | undefined;
    setCityCode: (value: ProvincialCity | undefined) => void;
    removeCity: () => void;
}

export const RegionSearch = ({
    className,
    setDesktopSearchBlendActive,
    cityCode,
    setCityCode,
    removeCity
}: IRegionAutocompleteSearchProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileSearchActive, setIsMobileSearchActive] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef<HTMLInputElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const placeholder = "Wpisz nazwę miasta";

    const resetInput = () => {
        if (inputRef.current) {
            (inputRef.current as HTMLInputElement).value = "";
        }
        setInputValue("");
    };

    const handleInputMount = (ref: MutableRefObject<HTMLInputElement | null>) => {
        inputRef.current = ref.current;
    };

    const handleInputChange = ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
        setInputValue(value);

        if (value === "") {
            resetInput();
        }
    };

    const openDropdown = () => {
        setIsDropdownOpen(true);
        setDesktopSearchBlendActive(true);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleClickOutside = () => {
        closeDropdown();
        setDesktopSearchBlendActive(false);
    };

    useClickOutside(wrapperRef as RefObject<HTMLDivElement>, handleClickOutside);

    const onRemoveCity = () => {
        closeDropdown();
        setInputValue("");
        removeCity();
    };

    const inputIcon = (isInModal: boolean) =>
        isInModal ? null : (
            <div css={iconWrapper}>
                <div css={isDropdownOpen ? rotatedStyle : rotatedStyleReverse}>
                    <ChevronDownIcon size="2" />
                </div>
            </div>
        );

    const handleCityCodeClick = (value: ProvincialCity) => {
        setCityCode(value);
        setIsMobileSearchActive(false);
        setDesktopSearchBlendActive(false);
        resetInput();
        closeDropdown();
    };

    const cityPillLabel = CITIES.find(({value}) => value === cityCode)?.label ?? "";

    useEffect(() => {
        return function cleanup() {
            resetInput();
        };
    }, []);

    const renderSearch = (isInModal = false) => {
        return (
            <div css={inputWrapper} className={className} ref={wrapperRef}>
                <AutoCompleteInput
                    openDropdown={openDropdown}
                    onChange={handleInputChange}
                    closeDropdown={closeDropdown}
                    placeholder={placeholder}
                    onRemoveOption={onRemoveCity}
                    inputIcon={inputIcon(isInModal) as ReactElement}
                    pillLabel={cityPillLabel}
                    onInputMount={handleInputMount}
                />
                <SearchDropdown isOpen={isDropdownOpen} isInModal={isInModal}>
                    <div css={resultsWrapper}>
                        {cityPillLabel && <SearchResultPill pillLabel={cityPillLabel} onRemoveResult={onRemoveCity} />}

                        <ul css={listUnStyled}>
                            {!!inputValue
                                ? CITIES.map(({label, value}) => {
                                      if (label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
                                          return (
                                              <li key={value} css={listItem} onClick={() => handleCityCodeClick(value)}>
                                                  {label}
                                              </li>
                                          );
                                      }
                                  })
                                : CITIES.map(({label, value}) => (
                                      <li css={listItem} key={value} onClick={() => handleCityCodeClick(value)}>
                                          {label}
                                      </li>
                                  ))}

                            {CITIES.filter(({label}) => label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0)
                                .length === 0 && (
                                <ResultsInfo
                                    onClick={() => setIsDropdownOpen(false)}
                                    title="Niestety nic nie znaleźliśmy"
                                    text="Zmień parametry wyszukiwania"
                                />
                            )}
                        </ul>
                    </div>
                </SearchDropdown>
            </div>
        );
    };

    return (
        <div css={wrapper}>
            <div onClick={() => setIsMobileSearchActive(true)} css={mobileInputTrigger}>
                {inputIcon(true)} {placeholder}
            </div>

            <MobileOptionsModal
                isOpen={isMobileSearchActive}
                modalHeading="Wpisz nazwę dewelopera"
                onModalClose={() => setIsMobileSearchActive(false)}
            >
                {renderSearch(true)}
            </MobileOptionsModal>

            <div css={searchDesktopWrapper}>{renderSearch(false)}</div>
        </div>
    );
};

const wrapper = css`
    flex: 1;
`;

const rotatedStyle = css`
    ${rotate()};
    transition: ease-in-out 0.2s;
`;
const rotatedStyleReverse = css`
    ${rotate("0")};
    transition: ease-in-out 0.2s;
`;

const listItem = (theme: Theme) => css`
    ${p(1)};
    ${w100};
    height: 6.4rem;
    ${flex("normal", "space-between")};
    ${pointer};
    align-items: center;
    border-bottom: 1px solid ${theme.colors.gray[200]};

    :hover {
        background: ${theme.colors.gray[200]};
    }
`;
