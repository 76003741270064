import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {IRootState} from "../../../store/store";

interface IHomepageState {
    cityData: Array<IMonthCityStats>;
    cityTypeData: Array<IMonthCityTypeStats>;
}

const initialState: IHomepageState = {
    cityData: [],
    cityTypeData: []
};

export const homepageSlice = createSlice({
    name: "homepageState",
    initialState,
    reducers: {
        setHomepageData: (state, action: PayloadAction<IHomepageState>) => {
            state.cityData = action.payload.cityData;
            state.cityTypeData = action.payload.cityTypeData;
        }
    }
});

export const {setHomepageData} = homepageSlice.actions;

const selectHomepageState = (state: IRootState) => state.homepageState;

export const selectHomepageData = createSelector([selectHomepageState], (homepageState) => ({
    cityData: homepageState.cityData,
    cityTypeData: homepageState.cityTypeData
}));
