import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {calculateRemSize} from "@pg-design/helpers-css";

import {CitySlug, getCityOption} from "../../../../../../config/cities";
import {mapCitySlugToRegionType, RegionType} from "../../../../../../config/regions";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {breakpoints} from "../../../../common/app/styles/breakpoints";
import {MapType, selectMapType, setMapType, toggleMapType} from "../../../map/redux/map_type_slice";

const gmap = require("../../../../common/app/assets/gmap.jpg");

// @TODO(Cieszczyk) mapy do dedykowanego configu dla map
const cities: {
    [key: string]: string;
} = {
    warszawa: require("../../../../common/app/assets/warszawa.svg"),
    krakow: require("../../../../common/app/assets/krakow.svg"),
    wroclaw: require("../../../../common/app/assets/wroclaw.svg"),
    gdansk: require("../../../../common/app/assets/gdansk.svg"),
    poznan: require("../../../../common/app/assets/poznan.svg"),
    lodz: require("../../../../common/app/assets/lodz.svg"),
    katowice: require("../../../../common/app/assets/katowice.svg"),
    szczecin: require("../../../../common/app/assets/szczecin.svg")
};

interface IProps {
    city: CitySlug | null;
}

export const SwitchableMapSwitcher = ({city}: IProps) => {
    const mapType = useAppSelector(selectMapType);
    const dispatch = useAppDispatch();
    const currentRegionType = mapCitySlugToRegionType(city);
    const {t} = useTranslation();

    const handleSwitcherClick = () => {
        if (!getCityOption(city).districtsMap) {
            dispatch(setMapType(MapType.GOOGLE));
        } else {
            dispatch(toggleMapType());
        }
    };

    const mapLabel =
        mapType === MapType.HIGHCHARTS
            ? t("dashboard.map_type_switcher_investments")
            : t("dashboard.map_type_switcher_districts");

    if (getCityOption(city).districtsMap && currentRegionType === RegionType.CITY) {
        return (
            <StyledSwitcher
                className={`map-type-switcher ${mapType === MapType.HIGHCHARTS && "gmap"}`}
                onClick={handleSwitcherClick}
                mapType={mapType}
            >
                <img src={cities[city as string]} alt={mapLabel} />
                <span>{mapLabel}</span>
            </StyledSwitcher>
        );
    }

    return null;
};

const StyledSwitcher = styled.div<{mapType: MapType}>`
    width: 60px;
    height: 60px;
    padding: 10px;
    text-align: center;
    position: absolute;
    top: ${(props) => (props.mapType === MapType.HIGHCHARTS ? "-4rem" : "2rem")};
    right: 2rem;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    background-color: white;
    border-radius: ${calculateRemSize(0.5)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &.gmap {
        background: url("${gmap}") no-repeat center/cover;
        &::after {
            content: "";
            height: 50%;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
            z-index: 2;
        }

        img {
            opacity: 0;
        }
    }

    img {
        max-width: 100%;
        height: 80px;
    }

    span {
        font-size: 12px;
        display: none;
        width: 100%;
        position: relative;
        z-index: 3;
        letter-spacing: -0.05em;
    }

    @media (min-width: ${breakpoints.md}) {
        width: 120px;
        height: 120px;

        span {
            display: block;
        }
    }
`;
