import {ReactNode} from "react";
import {useSSR} from "react-i18next";

import {LanguageType} from "../../../../config/i18n";

declare global {
    interface Window {
        initialI18nStore: any;
        initialLanguage: LanguageType;
    }
}

interface IProps {
    children: ReactNode;
}

export const I18nInitialProvider = ({children}: IProps) => {
    useSSR(window.initialI18nStore, window.initialLanguage);

    return <>{children}</>;
};
