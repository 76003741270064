import {useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {mb, mt, onDesktop, pt} from "@pg-design/helpers-css";
import {getTextVariantStyleFor, Text} from "@pg-design/text";

import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {Newsletter} from "../../../components/Newsletter";
import {OrderReport} from "../../../components/OrderReport";
import {useGetContentQuery} from "../../static_content/api/get_content";
import {StaticContent} from "../../static_content/types/Content";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {ViewType} from "../../tracking/view_type";
import {useGetPublicationsQuery} from "../api/get_publications";
import {FeaturedPublications} from "../components/featured_publications/FeaturedPublications";
import {Hero} from "../components/landing/Hero";
import {PublicationsBreadcrumbs} from "../components/PublicationsBreadcrumbs";

export const PublicationsLanding = withGTM(() => {
    const {t} = useTranslation();
    const {data, isLoading, isSuccess, isError, refetch} = useGetPublicationsQuery();
    const {data: staticContent} = useGetContentQuery(StaticContent.PUBLICATIONS_SUBTITLE);
    // First highlighted publication of report type
    const mainPublication = data?.results.find((data) => data.publication_type === 1 && data.is_highlighted);
    const rest = data?.results.filter((data) => data.id !== mainPublication?.id);

    usePageview(() => pageviewAlgolyticsHit({viewType: ViewType.PUBLICATIONS}));

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, []);

    return (
        <SimpleContainer>
            <Container>
                <PublicationsBreadcrumbs />

                {mainPublication && <Hero publication={mainPublication} />}

                <Row>
                    <Col lgOffset={4} lg={8} mdOffset={2} md={8} sm={4}>
                        {staticContent && staticContent.content_pl ? (
                            <div
                                css={contentWrapper}
                                dangerouslySetInnerHTML={{
                                    __html: staticContent.content_pl
                                }}
                            />
                        ) : (
                            <>
                                <Text css={[mt(9), mb(6)]} as="h2" variant="headline_1" align="center">
                                    {t("publications.featured.title")}
                                </Text>

                                <Text variant="body_copy_0" align="center" css={mb(6)}>
                                    <Trans>{t("publications.featured.subtitle")}</Trans>
                                </Text>
                            </>
                        )}
                    </Col>
                </Row>

                <Row as={isSuccess ? "ul" : undefined}>
                    <FeaturedPublications
                        data={rest}
                        isLoading={isLoading}
                        isSuccess={isSuccess}
                        isError={isError}
                        refetch={refetch}
                    />
                </Row>
            </Container>

            <div css={orderReportWrapper}>
                <OrderReport
                // source={InquirySource.MONITORING}
                />
            </div>

            <Newsletter />
        </SimpleContainer>
    );
});

const orderReportWrapper = css`
    ${pt(12)};

    ${onDesktop(css`
        ${pt(25)};
    `)};
`;

const contentWrapper = (theme: Theme) => css`
    text-align: center;

    h2 {
        ${mt(9)};
        ${mb(6)};
        ${getTextVariantStyleFor("headline_2")(theme)};
    }

    p {
        ${getTextVariantStyleFor("body_copy_0")(theme)};
        ${mb(6)};
    }
`;
