import React, {ReactNode} from "react";
import {css} from "@emotion/react";
import {backgroundColor, borderRadius, mb, mt, onDesktop, ph, pv} from "@pg-design/helpers-css";

interface IProps {
    children: ReactNode | Array<ReactNode>;
}

export const Panel = (props: IProps) => {
    return <div css={panelWrapper}>{props.children}</div>;
};

const panelWrapper = css`
    ${borderRadius(2)}
    ${backgroundColor("#fff")} 
    ${ph(2)} 
    ${pv(3)} 
    ${mb(8)} 
    ${mt(4)} 
    
    ${onDesktop(css`
        ${mb(34)}
        ${mt(8)}
    `)}
`;
