import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {backgroundColor, borderRadius, mt, p, position, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {formatDate, humanDateFormatLong} from "../../../../../utils/dates";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {selectLatestEntry} from "../../../features/ssr/redux/latest_entry_slice";

export const LimitedAccessBar = () => {
    const {t, i18n} = useTranslation();
    return (
        <Link to={chartsRoutes[i18n.language].pricing} css={infoBar}>
            <Text as="p" variant="headline_6">
                {t("limited_access")}
            </Text>
        </Link>
    );
};

export const PreliminaryDataBar = () => {
    const {t, i18n} = useTranslation();
    const {currentDate} = useAppSelector(selectLatestEntry);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore withDateGuard makes sure currentDate is not null
    const currentMonthFormatted = formatDate(currentDate, humanDateFormatLong, i18n);
    return (
        <div css={infoBar}>
            <Text as="p" variant="headline_6">
                <Trans>
                    {t("preliminary_data", {
                        currentMonth: currentMonthFormatted
                    })}
                </Trans>
            </Text>
        </div>
    );
};

export const infoBar = (theme: Theme) => css`
    ${position("sticky")};
    top: 0;
    ${mt(3)}
    ${p(2)};
    ${w100};
    ${borderRadius()};
    ${backgroundColor(theme.colors.info)};
    text-transform: uppercase;
`;
