import {ReactNode} from "react";
import {Link} from "react-router-dom";

interface IBreadcrumbsItemProps {
    children?: ReactNode;
    className?: string;
    onClick?: () => void;
    title: string;
    to: string;
    isLinkingToCurrentLocation?: boolean;
    position: string;
}

export const BreadcrumbsItem = ({
    className,
    children,
    title,
    to,
    onClick,
    isLinkingToCurrentLocation,
    position
}: IBreadcrumbsItemProps) => {
    return (
        <>
            {isLinkingToCurrentLocation ? (
                <li>
                    <span>{title}</span>
                </li>
            ) : (
                <li
                    className={className}
                    itemProp="itemListElement"
                    itemScope={true}
                    itemType="http://schema.org/ListItem"
                >
                    <Link to={to} title={title} onClick={onClick} itemProp="item">
                        <span itemProp="name">{children}</span>
                    </Link>
                    <meta itemProp="position" content={position} />
                </li>
            )}
        </>
    );
};
