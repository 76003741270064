import {ReactNode} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import {elevation} from "@pg-design/elevation";
import {borderRadius, flexAlignCenter, h100, onDesktop, p, ph, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IProps {
    header: string;
    subheader: string;
    children: ReactNode;
    className?: string;
    additionalContent?: ReactNode;
}

export const PricingBox = (props: IProps) => {
    const {header, subheader, children, className, additionalContent} = props;
    const theme = useTheme();

    return (
        <div css={content} className={className}>
            <Text css={[p(4, 2, 4, 2), divider(theme)]} variant="headline_4" as="p" color={theme.colors.gray[800]}>
                {header}
            </Text>

            <Text css={[p(3, 2, 3, 2), divider(theme)]} variant="body_copy_1">
                {subheader}
                {additionalContent && additionalContent}
            </Text>

            <div css={[minHeight, ph(2), flexAlignCenter]}>
                <div>{children}</div>
            </div>
        </div>
    );
};

const divider = (theme: Theme) => css`
    border-bottom: 1px solid ${theme.colors.gray[300]};
`;

const minHeight = css`
    min-height: 62px;

    & > div {
        ${w100};
    }

    ${onDesktop(css`
        min-height: 228px;
    `)}
`;

const content = css`
    ${borderRadius(2)};
    ${elevation(2)};
    background: #fff;
    ${h100};
`;
