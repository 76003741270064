import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {flexAbsoluteCenter, mb, mv, w100} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";
import i18n from "i18next";

import {useAppDispatch} from "../../../../../utils/hooks/store_hooks";
import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {setAuthModal} from "../../auth/auth_slice";
import {useVerifyPasswordLinkMutation} from "../api/resetPasswordApi";
import {Panel} from "../components/Panel";
import {SetNewPasswordForm} from "../components/SetNewPasswordForm";

export interface IResetPasswordViewUrlParams {
    uid: string;
    token: string;
}

export const ResetPassword = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {uid, token} = useParams<IResetPasswordViewUrlParams>();
    const [verifyPasswordLink, verifyPasswordLinkMutation] = useVerifyPasswordLinkMutation();

    useEffect(() => {
        verifyPasswordLink({uid, token});
    }, []);

    const handlers = {
        openResetPasswordModal: () => {
            dispatch(setAuthModal("remindPassword"));
        },
        goBackToLogin: () => {
            history.push(chartsRoutes[i18n.language].root);
            dispatch(setAuthModal("login"));
        }
    };

    return (
        <SimpleContainer>
            <Container>
                <Row>
                    <Col lg={8} lgOffset={4} md={8} mdOffset={2} sm={4}>
                        <Panel>
                            {(verifyPasswordLinkMutation.isUninitialized || verifyPasswordLinkMutation.isLoading) && (
                                <div css={[mv(6), flexAbsoluteCenter]}>
                                    <Loader size="lg" />
                                </div>
                            )}

                            {verifyPasswordLinkMutation.isError && (
                                <>
                                    <Text css={mb(3)} as="p" variant="headline_3" align="center">
                                        {t("user.reset_password_view.set_new_password")}
                                    </Text>

                                    <Text css={mb(6)} variant="body_copy_2" align="center">
                                        {t("user.reset_password_view.link_to_change_pwd_is_invalid")}
                                    </Text>

                                    <Button
                                        variant="filled_primary"
                                        onClick={handlers.openResetPasswordModal}
                                        css={w100}
                                    >
                                        {t("user.reset_password_view.try_again")}
                                    </Button>
                                </>
                            )}

                            {verifyPasswordLinkMutation.isSuccess && <SetNewPasswordForm token={token} uid={uid} />}
                        </Panel>
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
};
