import {useState} from "react";
import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {
    calculateRemSize,
    flex,
    flexAlignCenter,
    flexDirection,
    flexJustifyCenter,
    mb,
    mr,
    onDesktop,
    ph,
    pointer,
    pt,
    resetWidth,
    w100
} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {InquirySource} from "../../../publications/api/add_inquiry";
import {DownloadDemoModal} from "./hero/DownloadDemoModal";
import {OrderDedicatedReportModal, SuccessModal} from "./OrderDedicatedReportModal";

import Slide1D from "../../assets/Slide1-D.png";
import Slide1M from "../../assets/Slide1-M.png";
import Slide2D from "../../assets/Slide2-D.png";
import Slide2M from "../../assets/Slide2-M.png";
import Slide3D from "../../assets/Slide3-D.png";
import Slide3M from "../../assets/Slide3-M.png";
import Slide4D from "../../assets/Slide4-D.png";
import Slide4M from "../../assets/Slide4-M.png";
import Slide5D from "../../assets/Slide5-D.png";
import Slide5M from "../../assets/Slide5-M.png";
import Slide6D from "../../assets/Slide6-D.png";
import Slide6M from "../../assets/Slide6-M.png";

export const Slides = () => {
    const {t} = useTranslation();
    const [activeSlide, setActiveSlide] = useState(0);
    const [isOrderMonitoringModalOpen, setIsOrderMonitoringModalOpen] = useState(false);
    const [isDownloadDemoModalOpen, setIsDownloadDemoModalOpen] = useState(false);
    const slides = [
        {
            desktopImage: Slide1D,
            mobileImage: Slide1M,
            title: t("monitoring.slides.slide1.title"),
            text: t("monitoring.slides.slide1.text")
        },
        {
            desktopImage: Slide2D,
            mobileImage: Slide2M,
            title: t("monitoring.slides.slide2.title"),
            text: t("monitoring.slides.slide2.text")
        },
        {
            desktopImage: Slide3D,
            mobileImage: Slide3M,
            title: t("monitoring.slides.slide3.title"),
            text: t("monitoring.slides.slide3.text")
        },
        {
            desktopImage: Slide4D,
            mobileImage: Slide4M,
            title: t("monitoring.slides.slide4.title"),
            text: t("monitoring.slides.slide4.text")
        },
        {
            desktopImage: Slide5D,
            mobileImage: Slide5M,
            title: t("monitoring.slides.slide5.title"),
            text: t("monitoring.slides.slide5.text")
        },
        {
            desktopImage: Slide6D,
            mobileImage: Slide6M,
            title: t("monitoring.slides.slide6.title"),
            text: t("monitoring.slides.slide6.text")
        }
    ];
    return (
        <Container css={[mb(13), onDesktop(mb(18))]} as="section" fluid>
            <Container>
                <OrderDedicatedReportModal
                    onModalClose={() => setIsOrderMonitoringModalOpen(false)}
                    isOpen={isOrderMonitoringModalOpen}
                    source={InquirySource.MONITORING}
                />
                <SuccessModal />
                <DownloadDemoModal
                    isOpen={isDownloadDemoModalOpen}
                    onModalClose={() => setIsDownloadDemoModalOpen(false)}
                />

                <Row>
                    <Col lg={10} lgOffset={3} md={10} mdOffset={1} sm={4}>
                        <div
                            css={[
                                imageContainer,
                                css`
                                    background-image: url(${slides[activeSlide].mobileImage});

                                    ${onDesktop(css`
                                        background-image: url(${slides[activeSlide].desktopImage});
                                    `)}
                                `
                            ]}
                        >
                            <ChevronLeftIcon
                                css={arrowIcon}
                                onClick={() => setActiveSlide((slide) => (slide === 0 ? slides.length - 1 : slide - 1))}
                            />
                            <ChevronRightIcon
                                css={arrowIcon}
                                onClick={() => setActiveSlide((slide) => (slide === slides.length - 1 ? 0 : slide + 1))}
                            />
                        </div>
                    </Col>
                </Row>
                <Row css={pt(7)}>
                    <Col lg={4} lgOffset={6}>
                        <div css={flexJustifyCenter}>
                            {slides.map((_, i) => (
                                <Circle onClick={() => setActiveSlide(i)} active={i === activeSlide} key={i} />
                            ))}
                        </div>
                    </Col>
                </Row>
                <Row css={pt(6)}>
                    <Col lg={12} lgOffset={2}>
                        <div css={[flexAlignCenter, flexDirection("column")]}>
                            <Text css={mb(3)} variant="headline_2" align="center">
                                {slides[activeSlide].title}
                            </Text>
                            <Text css={mb(8)} variant="body_copy_0" align="center">
                                {slides[activeSlide].text}
                            </Text>
                            <div css={buttonsWrapper}>
                                <Button
                                    onClick={() => setIsOrderMonitoringModalOpen(true)}
                                    size="big"
                                    css={[mb(3), ph(5), w100, onDesktop(resetWidth, mb(0), mr(3))]}
                                    variant="filled_primary"
                                >
                                    {t("monitoring.slides.order")}
                                </Button>
                                <Button
                                    onClick={() => setIsDownloadDemoModalOpen(true)}
                                    size="big"
                                    css={[ph(5), w100, onDesktop(resetWidth)]}
                                    variant="outlined_secondary"
                                >
                                    {t("monitoring.hero.demo.download")}
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

const imageContainer = css`
    ${flex("center", "space-between")};
    height: 19.1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${onDesktop(css`
        height: 53rem;
    `)}
`;

const arrowIcon = css`
    ${pointer};
    transform: scale(2.4);

    ${onDesktop(css`
        transform: scale(4.4);
    `)}
`;

const Circle = styled.button<{active: boolean}>`
    ${mr(3)};
    width: ${calculateRemSize(3)};
    height: ${calculateRemSize(3)};
    border: none;
    border-radius: 50%;
    ${pointer};
    background-color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.gray[300])};

    ${onDesktop(mr(1))}
`;

const buttonsWrapper = (theme: Theme) => css`
    ${w100};
    ${flexJustifyCenter};
    ${flexDirection("column")};

    @media (min-width: ${theme.breakpoints.xs}) {
        ${ph(10)};
    }

    ${onDesktop(css`
        ${ph(0)};
        ${flex()};
        ${flexDirection("row")};
        ${resetWidth};
    `)};
`;
