import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {useHistory, useRouteMatch} from "react-router-dom";

import {CitySlug, mapCitySlugToLocalizedParamSlug, mapParamSlugToCitySlug} from "../../../../config/cities";
import {languages} from "../../../../config/i18n";
import {useIsPolishOnlyPath} from "../../../../utils/hooks/useIsPolishOnlyPath";
import {getLocalizedUrl, ICityParams} from "../../common/app/routing/charts_routes";
import {IResetPasswordViewUrlParams} from "../../features/users/views/ResetPassword";
import {Flag, Languages} from "./components/Flag";

//  In case you need to handle additional route params, add their interface you use with useParams
//  TODO discuss this component and such approach in BD front technical group
type IOptionalParams = Partial<IResetPasswordViewUrlParams> & {
    monitoringId?: string;
    slug?: string;
    publicationId?: string;
    publicationName?: string;
};

export const LanguageSwitcher = () => {
    const {
        params: {city},
        url
    } = useRouteMatch<ICityParams>();
    const optionalParams = useParams<IOptionalParams>();
    const citySlug = city ? mapParamSlugToCitySlug(city) : CitySlug.WARSZAWA;
    const history = useHistory();
    const {i18n} = useTranslation();
    const newLanguage = i18n.language === "pl" ? "en" : "pl";
    const shouldRenderSwitcher = useIsPolishOnlyPath();

    const handleLanguageChange = () => {
        const targetCitySlugParam = mapCitySlugToLocalizedParamSlug(citySlug, newLanguage);
        let urlParams: {city: string} & IOptionalParams = {city: targetCitySlugParam};

        if (optionalParams.uid && optionalParams.token) {
            urlParams = {
                ...urlParams,
                uid: optionalParams.uid,
                token: optionalParams.token
            };
        }

        if (optionalParams.monitoringId) {
            urlParams = {
                ...urlParams,
                monitoringId: optionalParams.monitoringId
            };
        }

        if (optionalParams.publicationId && optionalParams.slug) {
            urlParams = {
                ...urlParams,
                publicationId: optionalParams.publicationId,
                slug: optionalParams.slug
            };
        }

        if (optionalParams.publicationName) {
            urlParams = {
                ...urlParams,
                publicationName: optionalParams.publicationName
            };
        }

        const localizedUrl = getLocalizedUrl(url, i18n.language, newLanguage, urlParams);
        history.push(localizedUrl);
        i18n.changeLanguage(newLanguage);
    };

    return shouldRenderSwitcher ? null : (
        <Flag language={languages[newLanguage].label as Languages} onClick={handleLanguageChange} />
    );
};
