import {IEntryCategory} from "./Category";

export enum EntryType {
    ARTICLE = 1,
    BIG_DATA_NEWS = 3
}

export enum ContentItemType {
    TEXT = "text",
    IMAGE = "image",
    LINK = "link",
    BLOCKQUOTE = "blockquote"
}

interface ContentItemText {
    type: ContentItemType.TEXT;
    value: string;
}

interface ContentItemImage {
    type: ContentItemType.IMAGE;
    value: string;
}

interface ContentItemBlockquote {
    type: ContentItemType.BLOCKQUOTE;
    value: {
        text: string;
        header: string;
        cite: string;
    };
}

interface ContentItemLink {
    type: ContentItemType.LINK;
    value: {
        text: string;
        title: string;
        url: string;
        blank: boolean;
    };
}

export type ContentItem = ContentItemText | ContentItemImage | ContentItemBlockquote | ContentItemLink;

export interface IEntry {
    author: string | null;
    is_highlighted: boolean;
    is_video_material: boolean;
    category: Array<IEntryCategory> | null;
    content: Array<ContentItem> | null;
    id: number;
    image: string | null;
    images: {
        img_840x523: string;
        img_744x463: string;
        img_430x268: string;
        img_332x208: string;
        img_385x193: string;
        img_600x300: string;
    } | null;
    lead: string;
    publication_date: string;
    region: string | null;
    region_name: string | null;
    slug: string;
    source: string | null;
    tags: Array<IEntryCategory> | null;
    title: string;
    type: EntryType;
}
