import {Position} from "geojson";
import * as _ from "lodash-es";

import {IMapInternalData} from "./LazyGoogleMap";

type gMapPolygon = google.maps.Polygon;
type gMapPolygonOpts = google.maps.PolygonOptions;
type gMapInfoWindowOpts = google.maps.InfoWindowOptions;
type gMapLatLng = google.maps.LatLng;

export interface PolygonOptions {
    /** Indicates whether this Polygon handles mouse events. Defaults to true. */
    clickable?: boolean;
    /**
     * If set to true, the user can drag this shape over the map.
     * The geodesic property defines the mode of dragging. Defaults to false.
     */
    draggable?: boolean;
    /**
     * If set to true, the user can edit this shape by dragging the control points
     * shown at the vertices and on each segment. Defaults to false.
     */
    editable?: boolean;
    /** The fill color. All CSS3 colors are supported except for extended named colors. */
    fillColor?: string;
    /** The fill opacity between 0.0 and 1.0 */
    fillOpacity?: number;
    /**
     * When true, edges of the polygon are interpreted as geodesic and will follow
     * the curvature of the Earth. When false, edges of the polygon are rendered as
     * straight lines in screen space. Note that the shape of a geodesic polygon may
     * appear to change when dragged, as the dimensions are maintained relative to
     * the surface of the earth. Defaults to false.
     */
    geodesic?: boolean;
    /**
     * The stroke color.
     * All CSS3 colors are supported except for extended named colors.
     */
    strokeColor?: string;
    /** The stroke opacity between 0.0 and 1.0 */
    strokeOpacity?: number;
    /**
     * The stroke position. Defaults to CENTER.
     * This property is not supported on Internet Explorer 8 and earlier.
     */
    strokePosition?: google.maps.StrokePosition;
    /** The stroke width in pixels. */
    strokeWeight?: number;
    visible?: boolean;
    zIndex?: number;
}

export interface PolygonDefinition {
    /** Polygon points coordinates in form of [[[-12.040397656836609,-77.03373871559225, ...]]]  */
    coords: Position[][][];
    infoWindow?: gMapInfoWindowOpts;
    options?: PolygonOptions;
    type?: PolygonType;
    /** Height of a polygon of type PolygonType.BLOCK */
    height?: number;
}

export interface PolygonGroupDefinition {
    /** List of polygon definitions assigned to particular group. */
    list: PolygonDefinition[];
    /** Options for the whole polygon group. */
    options?: PolygonOptions;
}

export enum PolygonType {
    FLAT
}

/**
 * Updates all polygons on the map from given definition.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupsDef {Record<string, PolygonGroupDefinition>} polygon groups definition
 * @returns {Record<string, gMapPolygon[]>} the state of map's polygons after update
 */
export function updatePolygons(
    mapData: IMapInternalData,
    groupsDef: Record<string, PolygonGroupDefinition>
): Record<string, gMapPolygon[]> {
    removeAllPolygons(mapData.polygons);

    const iteratee = (acc: Record<string, gMapPolygon[]>, groupDef: PolygonGroupDefinition, groupName: string) => {
        acc[groupName] = updatePolygonGroup(mapData, groupName, groupDef);
        return acc;
    };

    return _.reduce(groupsDef, iteratee, {} as Record<string, gMapPolygon[]>);
}

/**
 * Updates polygons belonging to particular group, from given group definition.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupName {string} name of the polygon group to update
 * @param groupDef {PolygonGroupDefinition} definition of polygon group to update
 * @returns {gMapPolygon[]} list of updated polygons belonging to particular group
 */
export function updatePolygonGroup(
    mapData: IMapInternalData,
    groupName: string,
    groupDef: PolygonGroupDefinition
): gMapPolygon[] {
    return groupDef.list.reduce<gMapPolygon[]>((acc, polygonDef) => {
        acc.push(...addPolygon(mapData, groupName, groupDef, polygonDef));
        return acc;
    }, []);
}

/**
 * Adds polygon to the map assigning it to particular group. Also handles polygon events.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupName {string} name of the group the polygon should be assigned to
 * @param groupDef {PolygonGroupDefinition} definition of group the polygon should be assigned to
 * @param polygonDef {PolygonDefinition} definition of the particular polygon with its options
 * @returns {gMapPolygon} newly created polygon (or polygons for PolygonType.BLOCK) instance
 */
export function addPolygon(
    mapData: IMapInternalData,
    groupName: string,
    groupDef: PolygonGroupDefinition,
    polygonDef: PolygonDefinition
): gMapPolygon[] {
    const polygonOptions = buildPolygonOptions(mapData, groupName, groupDef, polygonDef);

    const polygons = polygonOptions.map((polygonAreaOptions, index) => {
        const polygon = new google.maps.Polygon(polygonAreaOptions);
        return polygon;
    });

    addPolygonInfoWindow(mapData, polygons, polygonDef);

    return polygons;
}

/**
 * Returns geographical coordinates based on passed latitude and longitude values.
 * @param lat {number} latitude value
 * @param lng {number} longitude value
 * @returns {gMapLatLng} geographical coordinates
 */
const getLatLng = (lat: number, lng: number): gMapLatLng => {
    return new google.maps.LatLng(lat, lng);
};

/**
 * Returns polygon center point based on passed geographical coordinates.
 * @param coords {Position[]} array of geographical coordinates
 * @returns {gMapLatLng} calculated geographical coordinates
 */
const getPolygonCenter = (coords: Position[]): gMapLatLng => {
    const bounds = new google.maps.LatLngBounds();

    coords.forEach((coord) => bounds.extend(getLatLng(coord[1], coord[0])));

    return bounds.getCenter();
};

/**
 * Adds InfoWindow with defined behavior to given polygon.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param polygons {gMapPolygon[]} array od polygon instances to add InfoWindow to
 * @param polygonDef {PolygonDefinition} definition of the particular polygon with its options
 */
export function addPolygonInfoWindow(
    mapData: IMapInternalData,
    polygons: gMapPolygon[],
    polygonDef: PolygonDefinition
): void {
    if (polygonDef.infoWindow && polygonDef.infoWindow.content) {
        const infoWindow = new google.maps.InfoWindow(polygonDef.infoWindow);

        // Attach InfoWindow events
        polygons.forEach((polygon) => {
            polygon.addListener("mouseover", () => {
                infoWindow.setPosition(getPolygonCenter(polygonDef.coords[0][0]));
                infoWindow.open(mapData.map);
            });
            polygon.addListener("mouseout", () => infoWindow.close());
        });
    }
}

/**
 * Builds and returns options for particular polygon. Options can be assigned to single polygon as well as for
 * the whole polygon group. Options for single polygons have bigger precedence.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupName {string} name of the group the polygon is assigned to
 * @param groupDef {PolygonGroupDefinition} definition of group the polygon is assigned to, with group options
 * @param polygonDef {PolygonDefinition} definition of the particular polygon with its options
 * @returns {gMapPolygonOpts} merged polygon options
 */
export function buildPolygonOptions(
    mapData: IMapInternalData,
    groupName: string,
    groupDef: PolygonGroupDefinition,
    polygonDef: PolygonDefinition
): gMapPolygonOpts[] {
    const defaultOptions = getDefaultPolygonOptions(mapData);
    const groupOptions = getGroupPolygonOptions(groupDef.options);

    const areasCoords = _.flatten(polygonDef.coords);

    return areasCoords.map((coords) =>
        _.assign({}, defaultOptions, groupOptions, polygonDef.options, {
            paths: coords.map((position) => ({lat: position[1], lng: position[0]}))
        })
    );
}

/**
 * Returns default polygon options.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @returns {gMapPolygonOpts} default polygon options
 */
export function getDefaultPolygonOptions(mapData: IMapInternalData): gMapPolygonOpts {
    return {
        map: mapData.map,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35
    };
}

/**
 * Returns options to be applied to whole polygon group.
 * @param groupOptions {PolygonOptions|undefined} options assigned to whole polygon group definition
 * @returns {PolygonOptions|{}}
 */
export function getGroupPolygonOptions(groupOptions: PolygonOptions | undefined): PolygonOptions | {} {
    return groupOptions || {};
}

/**
 * Removes all polygons rendered on the map.
 * @param polygons {Record<string, gMapPolygon[]> polygons rendered on the map, divided into groups.
 */
export function removeAllPolygons(polygons: Record<string, gMapPolygon[]>): void {
    _.each(polygons, (_polygon, groupName) => {
        removePolygonGroup(polygons, groupName);
    });
}

/**
 * Removes all polygons rendered on the map, belonging to particular group.
 * @param polygons {Record<string, gMapPolygon[]> polygons rendered on the map, divided into groups.
 * @param groupName {string} name of the group of polygons to remove
 */
export function removePolygonGroup(polygons: Record<string, gMapPolygon[]>, groupName: string): void {
    if (_.isEmpty(polygons[groupName])) {
        return;
    }

    for (const polygon of polygons[groupName]) {
        polygon.setMap(null);
    }

    polygons[groupName] = []; // necessary to remove all references to polygons
}
