import {useCallback, useMemo, useRef} from "react";
import styled from "@emotion/styled";

import {colorSets} from "../../../../common/app/constants/colors";
import {Card, ICardProps} from "../../../card/Card";
import {RenderProps} from "../../../types/render_props";
import {IPieChartProps, PieChart, RatioPieChart} from "../../Pie";
import {PieChartSharedLegend as SharedLegend} from "./PieChartSharedLegend";

interface IDoublePieChartCardProps<D> extends ICardProps, Omit<IPieChartProps<D>, "fields"> {
    data: D[];
    chart1Fields: string[];
    chart2Fields: string[];
    chart1InnerTitle?: string;
    chart2InnerTitle?: string;
    chart1TooltipLabel?: string; // used word must exist in "common" key in translations with proper plural forms
    chart2TooltipLabel?: string; // used word must exist in "common" key in translations with proper plural forms
    withSharedLegend?: boolean;
    sharedLegendLabels?: string[];
    isFlatOfferType: boolean;
}

interface IDoublePieChartCardWrapperProps<D> extends IDoublePieChartCardProps<D>, RenderProps {}

export const DoublePieChartCardWrapper = <D extends {}>(props: IDoublePieChartCardWrapperProps<D>) => {
    const {area, chart1Fields, chart2Fields, withSharedLegend, sharedLegendLabels, isFlatOfferType} = props;

    if (chart1Fields.length !== chart2Fields.length) {
        throw new Error("Number of fields provided to <DoublePieChartCard /> must be even");
    }

    const legendItems: ILegendItem[] | null = useMemo(() => {
        if (withSharedLegend && sharedLegendLabels) {
            return new Array(sharedLegendLabels.length).fill(null).map((_, i) => ({
                label: sharedLegendLabels[i],
                fieldIndex: i,
                color: colorSets.alternateLong[i]
            }));
        }

        return null;
    }, [withSharedLegend, chart1Fields, chart2Fields]);

    const chart1Ref = useRef(null);
    const chart2Ref = useRef(null);

    const handleLegendItemHover = useCallback((fieldIndex: number | null) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        chart1Ref?.current.highlightPoint(fieldIndex);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        chart2Ref?.current.highlightPoint(fieldIndex);
    }, []);

    return (
        <StyledCard {...props} area={area ?? ""} chartPlaceholder="doublePie">
            <StyledChartsWrapper isFlatOfferType={isFlatOfferType}>
                {props.render({chart1Ref, chart2Ref, ...props})}
            </StyledChartsWrapper>

            {withSharedLegend && legendItems && (
                <SharedLegend items={legendItems} onItemHover={handleLegendItemHover} />
            )}
        </StyledCard>
    );
};

export const DoublePieChartCard = <D extends {}>(props: IDoublePieChartCardProps<D>) => {
    return (
        <DoublePieChartCardWrapper
            {...props}
            render={({
                chart1Ref,
                chart2Ref,
                area,
                data,
                chart1Fields,
                chart2Fields,
                chart1InnerTitle,
                chart2InnerTitle,
                chart1TooltipLabel,
                chart2TooltipLabel,
                withSharedLegend,
                sharedLegendLabels,
                ...props
            }) => (
                <>
                    <PieChart<D>
                        {...props}
                        data={data}
                        colorSet="alternateLong"
                        fields={chart1Fields}
                        height={200}
                        innerTitle={chart1InnerTitle}
                        tooltipLabel={chart1TooltipLabel}
                        innerRef={chart1Ref}
                    />

                    <PieChart<D>
                        {...props}
                        data={data}
                        colorSet="alternateLong"
                        fields={chart2Fields}
                        height={200}
                        innerTitle={chart2InnerTitle}
                        tooltipLabel={chart2TooltipLabel}
                        innerRef={chart2Ref}
                    />
                </>
            )}
        />
    );
};

export const RatioDoublePieChartCard = <D extends {}>(props: IDoublePieChartCardProps<D>) => {
    return (
        <DoublePieChartCardWrapper
            {...props}
            render={({
                chart1Ref,
                chart2Ref,
                area,
                data,
                chart1Fields,
                chart2Fields,
                chart1InnerTitle,
                chart2InnerTitle,
                chart1TooltipLabel,
                chart2TooltipLabel,
                withSharedLegend,
                sharedLegendLabels,
                isFlatOfferType,
                ...props
            }) => (
                <>
                    <RatioPieChart<D>
                        {...props}
                        data={data}
                        colorSet="alternateLong"
                        fields={chart1Fields}
                        height={200}
                        innerTitle={chart1InnerTitle}
                        tooltipLabel={undefined}
                        innerRef={chart1Ref}
                    />
                    {!isFlatOfferType && (
                        <RatioPieChart<D>
                            {...props}
                            data={data}
                            colorSet="alternateLong"
                            fields={chart2Fields}
                            height={200}
                            innerTitle={chart2InnerTitle}
                            tooltipLabel={undefined}
                            innerRef={chart2Ref}
                        />
                    )}
                </>
            )}
        />
    );
};

const StyledCard = styled(Card)`
    .card-content {
        display: flex;
        flex-direction: column;
    }
`;

const StyledChartsWrapper = styled.div<{isFlatOfferType?: boolean}>`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    & > div {
        width: ${(props) => (props.isFlatOfferType ? "100%" : "50%")};
    }
`;
