import * as React from "react";
import {ReactNode} from "react";
import {display, mr, onDesktop} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {formatDate, shortDate} from "../../../../../utils/dates";
import {IEntryCategory} from "../types/Category";

interface IProps {
    category: Array<IEntryCategory> | null;
    children?: ReactNode;
    className?: string;
    publication_date: string;
    region_name?: string | null;
}

export const InfoTextBlock = ({className, publication_date, region_name, category}: IProps) => {
    return (
        <div className={className}>
            <Text as="div" variant="info_txt_2">
                <div css={[display("inline-block"), mr(3)]}>{formatDate(publication_date, shortDate)}</div>

                {region_name && (
                    <div css={[display("inline-block"), mr(3)]}>
                        {region_name
                            .split(", ")
                            .filter((s) => !s.match(/^[0-9-]+$/))
                            .slice(0, -1)
                            .join(", ")}
                    </div>
                )}

                {category && (
                    <div css={[display("none"), onDesktop(display("inline-block"))]}>
                        {category.map((category) => (
                            <span key={category.name} css={mr(3)}>
                                {category.name}
                            </span>
                        ))}
                    </div>
                )}
            </Text>
        </div>
    );
};
