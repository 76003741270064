import {Position} from "geojson";
import * as _ from "lodash-es";

import {IMapInternalData} from "./LazyGoogleMap";

type gMap = google.maps.Map;
type gMapMarker = google.maps.Marker;
type gMapMarkerOpts = google.maps.MarkerOptions;
type gMapInfoWindowOpts = google.maps.InfoWindowOptions;

export interface MarkerOptions {
    /**
     * The offset from the marker's position to the tip of an InfoWindow
     * that has been opened with the marker as anchor.
     */
    anchorPoint?: google.maps.Point;
    /**
     * If true, the marker receives mouse and touch events.
     * @default true
     */
    clickable?: boolean;
    /** Mouse cursor to show on hover. */
    cursor?: string;
    /**
     * If true, the marker can be dragged.
     * @default false
     */
    draggable?: boolean;
    /**
     * Icon for the foreground.
     * If a string is provided, it is treated as though it were an Icon with the string as url.
     * @type {(string|Icon|Symbol)}
     */
    icon?: string | google.maps.Icon | google.maps.Symbol;
    /**
     * Adds a label to the marker. The label can either be a string, or a MarkerLabel object.
     * Only the first character of the string will be displayed.
     * @type {(string|MarkerLabel)}
     */
    label?: string | google.maps.MarkerLabel;
    /** The marker's opacity between 0.0 and 1.0. */
    opacity?: number;
    /**
     * Place information, used to identify and describe the place
     * associated with this Marker. In this context, 'place' means a
     * business, point of interest or geographic location. To allow a user
     * to save this place, open an info window anchored on this marker.
     * The info window will contain information about the place and an
     * option for the user to save it. Only one of position or place can
     * be specified.
     */
    place?: google.maps.Place;
    /** Image map region definition used for drag/click. */
    shape?: google.maps.MarkerShape;
    title?: string;
    visible?: boolean;
    zIndex?: number;
}

export interface MarkerDefinition {
    /** Marker coordinates in form of [-12.040397656836609,-77.03373871559225]. */
    id?: number;
    type?: number;
    coords: Position;
    icon?: string | {url: string; scaledSize?: string};
    onOpen?: (marker: gMapMarker) => void;
    infoWindow?: gMapInfoWindowOpts;
    options?: MarkerOptions;
}

export interface MarkerGroupDefinition {
    /** List of marker definitions assigned to particular group. */
    list: MarkerDefinition[];
    /** Options for the whole marker group. */
    options?: MarkerOptions;
}

/**
 * Updates all markers on the map from given definition.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupsDef {Record<string, MarkerGroupDefinition>} marker groups definition
 * @returns {Record<string, gMapMarker[]>} the state of map's markers after update
 */
export function updateMarkers(
    mapData: IMapInternalData,
    groupsDef: Record<string, MarkerGroupDefinition>
): Record<string, gMapMarker[]> {
    removeAllMarkers(mapData.markers);
    const iteratee = (acc: Record<string, gMapMarker[]>, groupDef: MarkerGroupDefinition, groupName: string) => {
        acc[groupName] = updateMarkerGroup(mapData, groupName, groupDef);
        return acc;
    };

    return _.reduce(groupsDef, iteratee, {} as Record<string, gMapMarker[]>);
}

export function calculateRefMarkersMap(
    groupsDef: Record<string, MarkerGroupDefinition>,
    markerGroups: Record<string, gMapMarker[]>
): Record<string, gMapMarker> {
    return _.reduce(
        groupsDef,
        (acc, groupDef, groupName) => {
            // get markers-ref-map for single group definition
            const markerGroup = markerGroups[groupName];
            const refMarkersMap = _.reduce(
                groupDef.list,
                (acc, markerDef, idx) => (markerDef.id == null ? acc : {...acc, [markerDef.id]: markerGroup[idx]}),
                {}
            );

            return {...acc, ...refMarkersMap};
        },
        {}
    );
}

/**
 * Updates markers belonging to particular group, from given group definition.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupName {string} name of the marker group to update
 * @param groupDef {MarkerGroupDefinition} definition of marker group to update
 * @returns {gMapMarker[]} list of updated markers belonging to particular group
 */
export function updateMarkerGroup(
    mapData: IMapInternalData,
    groupName: string,
    groupDef: MarkerGroupDefinition
): gMapMarker[] {
    return _.map(groupDef.list, (markerDef: MarkerDefinition) => addMarker(mapData, groupName, groupDef, markerDef));
}

/**
 * Adds marker to the map assigning it to particular group. Also handles marker events.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupName {string} name of the group the marker should be assigned to
 * @param groupDef {MarkerGroupDefinition} definition of group the marker should be assigned to
 * @param markerDef {MarkerDefinition} definition of the particular marker with its options
 * @returns {gMapMarker} newly created marker instance
 */
export function addMarker(
    mapData: IMapInternalData,
    groupName: string,
    groupDef: MarkerGroupDefinition,
    markerDef: MarkerDefinition
): gMapMarker {
    const markerOptions = buildMarkerOptions(mapData, groupName, groupDef, markerDef);
    const marker = new google.maps.Marker(markerOptions);
    if (markerDef.infoWindow) {
        addMarkerInfoWindow(mapData, marker, markerDef);
    }
    if (markerDef.onOpen) {
        bindInfoWindowEvents(marker, markerDef.onOpen);
    }

    return marker;
}

function bindInfoWindowEvents(marker: gMapMarker, onOpen?: (marker: gMapMarker) => void) {
    onOpen && marker.addListener("click", () => onOpen(marker));
}

/**
 * Adds InfoWindow with defined behavior to given marker.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param marker {gMapMarker} marker instance to add InfoWindow to
 * @param markerDef {MarkerDefinition} definition of the particular marker with its options
 */
export function addMarkerInfoWindow(mapData: IMapInternalData, marker: gMapMarker, markerDef: MarkerDefinition): void {
    if (markerDef.infoWindow && markerDef.infoWindow.content) {
        const infoWindow = new google.maps.InfoWindow(markerDef.infoWindow);
        mapData.refInfoWindows.push(infoWindow);

        // infoWindow events
        marker.addListener("mouseover", () => {
            infoWindow.open(mapData.map, marker);
        });
        marker.addListener("mouseout", () => {
            infoWindow.close();
        });
    }
}

/**
 * Builds and returns options for particular marker. Options can be assigned to single marker as well as for
 * the whole marker group. Options for single markers have bigger precedence.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @param groupName {string} name of the group the marker is assigned to
 * @param groupDef {MarkerGroupDefinition} definition of group the marker is assigned to, with group options
 * @param markerDef {MarkerDefinition} definition of the particular marker with its options
 * @returns {gMapMarkerOpts} merged marker options
 */
export function buildMarkerOptions(
    mapData: IMapInternalData,
    groupName: string,
    groupDef: MarkerGroupDefinition,
    markerDef: MarkerDefinition
): gMapMarkerOpts {
    const defaultOptions = getDefaultMarkerOptions(mapData);
    const groupOptions = getGroupMarkerOptions(groupDef.options);
    const markerOptions = _.assign(
        {},
        {id: markerDef.id, type: markerDef.type},
        defaultOptions,
        groupOptions,
        markerDef.options,
        {
            position: {lat: markerDef.coords[1], lng: markerDef.coords[0]},
            icon: markerDef.icon || ""
        }
    ) as gMapMarkerOpts;

    return markerOptions;
}

/**
 * Returns default marker options.
 * @param mapData {IMapInternalData} internal data of Map instance
 * @returns {{map: (google.maps.Map)}} default marker options
 */
export function getDefaultMarkerOptions(mapData: IMapInternalData): {map: gMap | undefined} {
    const defaultOptions = {
        map: mapData.map
    };

    return defaultOptions;
}

/**
 * Returns options to be applied to whole marker group.
 * @param groupOptions {MarkerOptions|undefined} options assigned to whole marker group definition
 * @returns {MarkerOptions|{}}
 */
export function getGroupMarkerOptions(groupOptions: MarkerOptions | undefined): MarkerOptions | {} {
    return groupOptions || {};
}

/**
 * Removes all markers rendered on the map.
 * @param markers {Record<string, gMapMarker[]> markers renderes on the map, divided into groups.
 */
export function removeAllMarkers(markers: Record<string, gMapMarker[]>): void {
    for (const groupName in markers) {
        // eslint-disable-next-line no-prototype-builtins
        if (markers.hasOwnProperty(groupName)) {
            removeMarkerGroup(markers, groupName);
        }
    }
}

/**
 * Removes all markers rendered on the map, belonging to particular group.
 * @param markers {Record<string, gMapMarker[]> markers renderes on the map, divided into groups.
 * @param groupName {string} name of the group of markers to remove
 */
export function removeMarkerGroup(markers: Record<string, gMapMarker[]>, groupName: string): void {
    if (_.isEmpty(markers[groupName])) {
        return;
    }

    for (const marker of markers[groupName]) {
        marker.setMap(null);
    }

    markers[groupName] = []; // necessary to remove all references to markers
}
