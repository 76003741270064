import {staticContentApi} from "./static_content_api";

interface IStaticContentRes {
    id: number;
    slug: string;
    content_pl: string;
    content_en: string;
}

export const getContentApi = staticContentApi.injectEndpoints({
    endpoints: (build) => ({
        getContent: build.query<IStaticContentRes, string>({
            query: (slug) => ({
                url: `${slug}/`,
                method: "GET"
            })
        })
    })
});

export const {useGetContentQuery} = getContentApi;
