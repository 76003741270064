import {useRef} from "react";

import {Card, ICardProps} from "../../card/Card";
import {RenderProps} from "../../types/render_props";
import {IColumnChartProps, RatioColumnChart} from "../Column";

interface IColumnChartCardProps<D> extends ICardProps, IColumnChartProps<D> {}

interface IColumnChartCardWrapperProps<D> extends IColumnChartCardProps<D>, RenderProps {}

export const ColumnChartCardWrapper = <D extends {}>(props: IColumnChartCardWrapperProps<D>) => {
    const {area} = props;

    const chartRef = useRef(null);

    return (
        <Card {...props} area={area ?? ""} chartRef={chartRef} chartPlaceholder="column">
            {props.render({innerRef: chartRef, ...props})}
        </Card>
    );
};

export const RatioColumnChartCard = <D extends {}>(props: IColumnChartCardProps<D>) => {
    return (
        <ColumnChartCardWrapper
            {...props}
            chartPlaceholder="column"
            render={() => <RatioColumnChart {...props} colorSet="purple" height={250} />}
        />
    );
};
