import {ComponentType} from "react";

import {Contact} from "../../../components/views/Contact";
import {Faq} from "../../../components/views/Faq";
import {Added} from "../../../features/analytical_platform/views/Added";
import {Dashboard} from "../../../features/analytical_platform/views/Dashboard";
import {DashboardFlats} from "../../../features/analytical_platform/views/DashboardFlats";
import {DashboardHouses} from "../../../features/analytical_platform/views/DashboardHouses";
import {Demand} from "../../../features/analytical_platform/views/Demand";
import {Home} from "../../../features/analytical_platform/views/Home";
import {HomeFlats} from "../../../features/analytical_platform/views/HomeFlats";
import {HomeHouses} from "../../../features/analytical_platform/views/HomeHouses";
import {Offer} from "../../../features/analytical_platform/views/Offer";
import {Prices} from "../../../features/analytical_platform/views/Prices";
import {Sold} from "../../../features/analytical_platform/views/Sold";
import {Error404} from "../../../features/errors/views/Error404";
import {Landing} from "../../../features/landing/views/Landing";
import {MonitoringLanding} from "../../../features/monitoring/views/MonitoringLanding";
import {ArticleDetails} from "../../../features/newshub/views/ArticleDetails";
import {BigDataArticleDetails} from "../../../features/newshub/views/BigDataArticleDetails";
import {BigDataNews} from "../../../features/newshub/views/BigDataNews";
import {MarketInformation} from "../../../features/newshub/views/MarketInformation";
import {NewshubLanding} from "../../../features/newshub/views/NewshubLanding";
import {Pricing} from "../../../features/pricing/views/Pricing";
import {PublicationDetails} from "../../../features/publications/views/PublicationDetails";
import {PublicationsLanding} from "../../../features/publications/views/PublicationsLanding";
import {PublicationsListing} from "../../../features/publications/views/PublicationsListing";
import {ChangePassword} from "../../../features/users/views/ChangePassword";
import {ResetPassword} from "../../../features/users/views/ResetPassword";
import {UserEdit} from "../../../features/users/views/UserEdit";
import {chartsRoutes} from "./charts_routes";

export interface IRoute {
    path: string | string[];
    exact: boolean;
    component: ComponentType;
}

export const routes: IRoute[] = [
    {
        path: [chartsRoutes.pl.root, chartsRoutes.en.root],
        exact: true,
        component: Landing
    },
    {
        path: [chartsRoutes.pl.pricing, chartsRoutes.en.pricing],
        exact: true,
        component: Pricing
    },
    {
        path: [chartsRoutes.pl.properties.root, chartsRoutes.en.properties.root],
        exact: true,
        component: Home
    },
    {
        path: [chartsRoutes.pl.flats.root, chartsRoutes.en.flats.root],
        exact: true,
        component: HomeFlats
    },
    {
        path: [chartsRoutes.pl.houses.root, chartsRoutes.en.houses.root],
        exact: true,
        component: HomeHouses
    },
    {
        path: [chartsRoutes.pl.properties.city.root, chartsRoutes.en.properties.city.root],
        exact: true,
        component: Dashboard
    },
    {
        path: [chartsRoutes.pl.flats.city.root, chartsRoutes.en.flats.city.root],
        exact: true,
        component: DashboardFlats
    },
    {
        path: [chartsRoutes.pl.houses.city.root, chartsRoutes.en.houses.city.root],
        exact: true,
        component: DashboardHouses
    },
    {
        path: [
            chartsRoutes.pl.properties.city.prices,
            chartsRoutes.en.properties.city.prices,
            chartsRoutes.pl.flats.city.prices,
            chartsRoutes.en.flats.city.prices
        ],
        exact: true,
        component: Prices
    },
    {
        path: [
            chartsRoutes.pl.properties.city.offer,
            chartsRoutes.en.properties.city.offer,
            chartsRoutes.pl.flats.city.offer,
            chartsRoutes.en.flats.city.offer
        ],
        exact: true,
        component: Offer
    },
    {
        path: [chartsRoutes.pl.properties.city.demand, chartsRoutes.en.properties.city.demand],
        exact: true,
        component: Demand
    },
    {
        path: [chartsRoutes.pl.flats.city.demand, chartsRoutes.en.flats.city.demand],
        exact: true,
        component: Demand
    },
    {
        path: [chartsRoutes.pl.properties.city.sold, chartsRoutes.en.properties.city.sold],
        exact: true,
        component: Sold
    },
    {
        path: [chartsRoutes.pl.flats.city.sold, chartsRoutes.en.flats.city.sold],
        exact: true,
        component: Sold
    },
    {
        path: [chartsRoutes.pl.properties.city.added, chartsRoutes.en.properties.city.added],
        exact: true,
        component: Added
    },
    {
        path: [chartsRoutes.pl.flats.city.added, chartsRoutes.en.flats.city.added],
        exact: true,
        component: Added
    },
    {
        path: [chartsRoutes.pl.contact, chartsRoutes.en.contact],
        exact: true,
        component: Contact
    },
    {
        path: [chartsRoutes.pl.faq, chartsRoutes.en.faq],
        exact: true,
        component: Faq
    },
    {
        path: [chartsRoutes.pl.user.changePassword, chartsRoutes.en.user.changePassword],
        exact: true,
        component: ChangePassword
    },
    {
        path: [chartsRoutes.pl.user.edit, chartsRoutes.en.user.edit],
        exact: true,
        component: UserEdit
    },
    {
        path: [chartsRoutes.pl.resetPassword, chartsRoutes.en.resetPassword],
        exact: false,
        component: ResetPassword
    },
    {
        path: [chartsRoutes.pl.monitoring.root, chartsRoutes.en.monitoring.root],
        exact: true,
        component: MonitoringLanding
    },
    {
        path: [chartsRoutes.pl.publications.root],
        exact: true,
        component: PublicationsLanding
    },
    {
        path: [chartsRoutes.pl.publications.listing],
        exact: true,
        component: PublicationsListing
    },
    {
        path: [chartsRoutes.pl.publications.details],
        exact: true,
        component: PublicationDetails
    },
    {
        path: [chartsRoutes.pl.newshub.root],
        exact: true,
        component: NewshubLanding
    },
    {
        path: [chartsRoutes.pl.newshub.marketInformation.details],
        exact: true,
        component: ArticleDetails
    },
    {
        path: [
            chartsRoutes.pl.newshub.marketInformation.root,
            chartsRoutes.pl.newshub.marketInformation.category.root,
            chartsRoutes.pl.newshub.marketInformation.category.vendor,
            chartsRoutes.pl.newshub.marketInformation.category.subcategory.root,
            chartsRoutes.pl.newshub.marketInformation.category.subcategory.vendor
        ],
        exact: true,
        component: MarketInformation
    },
    {
        path: [chartsRoutes.pl.newshub.bigDataNews.root],
        exact: true,
        component: BigDataNews
    },
    {
        path: [chartsRoutes.pl.newshub.bigDataNews.details],
        exact: true,
        component: BigDataArticleDetails
    },
    {
        path: "*",
        exact: false,
        component: Error404
    }
];
