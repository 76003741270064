import {Link} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {borderRadius, calculateRemSize, mb, onDesktop, p, pt, pv, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";
import i18n from "i18next";
import {compile} from "path-to-regexp";

import {chartsRoutes, getLocalizedUrl} from "../../../../common/app/routing/charts_routes";
import {IEntry} from "../../types/Entry";
import {InfoTextBlock} from "../InfoTextBlock";

interface IMarketInformationAsideProps {
    className?: string;
    title: string;
    articles: Array<IEntry>;
}

export const MarketInformationAside = ({articles, title, className}: IMarketInformationAsideProps) => {
    const toPath = compile(getLocalizedUrl(chartsRoutes[i18n.language].newshub.bigDataNews.details, i18n.language));

    return (
        <aside css={wrapper} className={className}>
            <Text variant="headline_3" css={mb(2)}>
                {title}
            </Text>
            {articles && !!articles.length ? (
                <ul>
                    {articles.map((articles) => (
                        <li key={articles.id} css={item}>
                            <InfoTextBlock
                                css={mb(1)}
                                publication_date={articles.publication_date}
                                region_name={articles.region_name}
                                category={articles.category}
                            />

                            <Link to={toPath({newsId: String(articles.id), slug: articles.slug})}>
                                <Text variant="body_copy_1" strong css={mb(0.5)}>
                                    {articles.title}
                                </Text>
                            </Link>
                        </li>
                    ))}
                </ul>
            ) : (
                "Brak artykułów"
            )}

            <div css={buttonWrapper}>
                <Link to={chartsRoutes.pl.newshub.bigDataNews.root}>
                    <Button css={w100} variant="filled_primary" size="small">
                        Zobacz więcej
                    </Button>
                </Link>
            </div>
        </aside>
    );
};

const wrapper = css`
    ${p(2, 2, 0, 2)};
    ${borderRadius(2)}
    overflow-y: auto;
    background-color: #fff;

    ${onDesktop(css`
        max-height: 85vh;
        position: sticky;
        top: ${calculateRemSize(2)};
    `)};
`;

const buttonWrapper = css`
    position: sticky;
    bottom: 0;
    ${pv(2)};
    background-color: #fff;
`;

const item = (theme: Theme) => css`
    border-bottom: 1px solid ${theme.colors.gray[300]};

    :not(:first-of-type) {
        ${pt(4)};
    }
`;
