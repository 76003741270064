import {useRef} from "react";

import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {Card, ICardProps} from "../../card/Card";
import {RenderProps} from "../../types/render_props";
import {ColumnStackedChart, IColumnStackedChartProps, RatioColumnStackedChart} from "../ColumnStacked";

type ColumnStackedData = IMonthCityStats | IMonthCityTypeStats;

interface IColumnStackedChartCardProps<D> extends ICardProps, IColumnStackedChartProps<ColumnStackedData> {}

interface IColumnStackedChartCardWrapperProps<D> extends IColumnStackedChartCardProps<D>, RenderProps {}

export const ColumnStackedChartCardWrapper = <D extends {}>(props: IColumnStackedChartCardWrapperProps<D>) => {
    const {area} = props;

    const chartRef = useRef(null);
    return (
        <Card {...props} area={area ?? ""} chartRef={chartRef} chartPlaceholder="columnStacked">
            {props.render({innerRef: chartRef, ...props})}
        </Card>
    );
};

export const ColumnStackedChartCard = <D extends {}>(props: IColumnStackedChartCardProps<D>) => {
    return (
        <ColumnStackedChartCardWrapper
            {...props}
            render={(renderProps) => <ColumnStackedChart {...renderProps} height={250} />}
        />
    );
};

export const RatioColumnStackedChartCard = <D extends {}>(props: IColumnStackedChartCardProps<D>) => {
    return (
        <ColumnStackedChartCardWrapper
            {...props}
            render={(renderProps) => <RatioColumnStackedChart {...renderProps} height={250} />}
        />
    );
};
