import {useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {flexAbsoluteCenter, mb, onDesktop, pb, pt} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons";
import {Modal} from "@pg-design/modal";
import {Text} from "@pg-design/text";

import {ZIndex} from "../../../../common/app/constants/z_index";
import {InquirySource, useInquiryMutation} from "../../../publications/api/add_inquiry";
import {OrderDedicatedReportForm} from "../forms/OrderDedicatedReportForm";

interface IOrderMonitoringModalProps {
    isOpen: boolean;
    onModalClose: () => void;
    source: InquirySource;
}

export const OrderDedicatedReportModal = ({isOpen, onModalClose, source}: IOrderMonitoringModalProps) => {
    const {t} = useTranslation();
    const isMonitoring = source === InquirySource.MONITORING;

    return (
        <Modal zIndex={ZIndex.MODAL} isOpen={isOpen} onModalClose={onModalClose}>
            <Modal.Header>
                <Text variant="headline_6" as="p">
                    {isMonitoring ? t("inquiry.order_monitoring") : "Zamów dedykowany raport"}
                </Text>
            </Modal.Header>

            <Modal.Content css={formModalWrapper}>
                <OrderDedicatedReportForm source={source} closeModal={onModalClose} />
            </Modal.Content>
        </Modal>
    );
};

export const SuccessModal = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [, addInquiryMutation] = useInquiryMutation({fixedCacheKey: "addInquiry"});

    return (
        <Modal
            zIndex={ZIndex.MODAL}
            isOpen={addInquiryMutation.isSuccess}
            onModalClose={() => {
                addInquiryMutation.reset();
            }}
        >
            <Modal.Content css={thanksModalWrapper}>
                <div css={flexAbsoluteCenter}>
                    <BrandCheckboxCheckedIcon
                        css={[mb(6), onDesktop(mb(8))]}
                        size="12.8"
                        wrapperColor={theme.colors.primary}
                        wrapperSize="12.8"
                    />
                </div>

                <Text variant="headline_3" as="p" align="center" css={[mb(3), onDesktop(mb(2))]}>
                    {t("inquiry.sent")}
                </Text>

                <Text variant="body_copy_1" align="center" css={[mb(6), onDesktop(mb(8))]}>
                    {t("inquiry.contact")}
                </Text>

                <div css={flexAbsoluteCenter}>
                    <Button
                        variant="filled_primary"
                        size="medium"
                        css={button}
                        onClick={() => {
                            addInquiryMutation.reset();
                        }}
                    >
                        {t("inquiry.return")}
                    </Button>
                </div>
            </Modal.Content>
        </Modal>
    );
};

const thanksModalWrapper = css`
    ${pt(10)};
    ${pb(14)};

    ${onDesktop(css`
        width: var(--modal-width-small);
    `)};
`;

const formModalWrapper = css`
    ${onDesktop(css`
        width: var(--modal-width-larg);
    `)};
`;

const button = css`
    width: 100%;

    ${onDesktop(css`
        width: 20rem;
    `)};
`;
