import {useTranslation} from "react-i18next";
import {css, Theme, useTheme} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {flexAlignCenter, flexDirection, mb, onDesktop, ph, pt} from "@pg-design/helpers-css";
import {BrandQuoteIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

interface IClientProps {
    quote: string;
    person: string;
    company: string;
}

const Client = ({quote, person, company}: IClientProps) => {
    const theme = useTheme();
    return (
        <Col lg={8} md={6} sm={4}>
            <div css={[flexAlignCenter, flexDirection("column"), ph(1.5)]}>
                <BrandQuoteIcon css={mb(3)} size="4" wrapperSize="4" wrapperColor={theme.colors.primary} />

                <Text css={mb(2)} align="center" variant="body_copy_0" color="#fff">
                    <i>{quote}</i>
                </Text>

                <Text css={mb(0.5)} as="p" variant="headline_5" color="#fff">
                    {person}
                </Text>

                <Text css={mb(11)} variant="body_copy_0" strong color="#fff">
                    {company}
                </Text>
            </div>
        </Col>
    );
};

export const Trust = () => {
    const {t} = useTranslation();

    return (
        <Container css={container} as="section" fluid>
            <Row>
                <Col lg={4} lgOffset={6} md={4} mdOffset={4} sm={2} smOffset={1}>
                    <Text css={[pt(6), mb(8)]} align="center" as="h3" variant="headline_1" color="#fff">
                        {t("landing.trust.title")}
                    </Text>
                </Col>
            </Row>

            <Row>
                <Client
                    quote={t("landing.trust.client1.text")}
                    person="Marek Szmidt"
                    company={"Dom" + " Development"}
                />

                <Client
                    quote={t("landing.trust.client2.text")}
                    person="Przemysław Zańko-Gulczyński"
                    company={t("landing.trust.client2.company")}
                />
            </Row>
        </Container>
    );
};

const container = (theme: Theme) => css`
    ${ph(1.5)};
    background-color: ${theme.colors.secondary};

    ${onDesktop(ph(15))};

    @media (min-width: ${theme.breakpoints.lg}) {
        ${ph(25)}
    }
`;
