import {Button} from "@pg-design/button";
import {mb, onDesktop} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IProps {
    className?: string;
    textContent: string;
    linkContent: string;
    linkAction?: () => void;
}

export const ChangeModalTypeLink = (props: IProps) => {
    const {className, textContent, linkContent, linkAction} = props;

    return (
        <>
            <Text css={mb(1.5)} variant="info_txt_1" className={className}>
                {textContent}
            </Text>
            <Button variant="highlight_primary" size="x-small" onClick={linkAction} css={[mb(2), onDesktop(mb(0))]}>
                {linkContent}
            </Button>
        </>
    );
};
