import {useTranslation} from "react-i18next";
import {useRouteMatch} from "react-router-dom";
import {Options} from "highcharts";

import {RequestStatus} from "../../../common/app/constants/enums";
import {placeholderMonthData} from "../../../common/app/constants/placeholder_charts_data";
import {ICityParams} from "../../../common/app/routing/charts_routes";
import {DistrictsMapDetailed} from "../DistrictsMapDetailed";

export const DistrictsMapPlaceholder = () => {
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();

    const {t} = useTranslation();

    return (
        <DistrictsMapDetailed
            city={city}
            data={placeholderMonthData as []}
            dataStatus={RequestStatus.SUCCESS}
            colorSet="purple"
            height={500}
            valueField="value1"
            unit={t("common.pln_m2")}
            legendItems={[
                [0, 8, "<100", "#FFE8EC"],
                [9, 17, "100-200", "#FFD4DC"],
                [18, 26, "200-500", "#FF98B1"],
                [27, 35, "500-1k", "#FF6D99"],
                [36, Infinity, ">1k", "#FF4085"]
            ]}
            customOptions={options}
        />
    );
};

const options: Options = {
    tooltip: {
        enabled: false
    }
};
