import {matchPath} from "react-router";
import {forIn, get, isObject} from "lodash-es";
import {compile} from "path-to-regexp";

import {prefixPath} from "../../../../../utils/prefix_path";

type ChartsRoutesType = typeof routesPl;

export const routesPl = {
    root: "/",
    properties: prefixPath("/ceny/nieruchomosci", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/",
            prices: "/ceny/",
            offer: "/oferta/",
            demand: "/popyt/",
            sold: "/sprzedane/",
            added: "/nowe/"
        })
    }),
    propertiesRedirect: prefixPath("/ceny-nieruchomosci", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/",
            prices: "/ceny/",
            offer: "/oferta/",
            demand: "/popyt/",
            sold: "/sprzedane/",
            added: "/nowe/"
        })
    }),
    flats: prefixPath("/ceny/mieszkania", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/",
            prices: "/ceny/",
            offer: "/oferta/",
            demand: "/popyt/",
            sold: "/sprzedane/",
            added: "/nowe/"
        })
    }),
    flatsRedirect: prefixPath("/ceny-mieszkan", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/",
            prices: "/ceny/",
            offer: "/oferta/",
            demand: "/popyt/",
            sold: "/sprzedane/",
            added: "/nowe/"
        })
    }),
    houses: prefixPath("/ceny/domy", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/"
        })
    }),
    housesRedirect: prefixPath("/ceny-domow", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/"
        })
    }),
    monitoring: prefixPath("/monitoring", {
        root: "/",
        create: prefixPath("/nowy", {
            root: "/",
            offers: "/inwestycje/",
            details: "/szczegoly/"
        }),
        details: prefixPath("/:monitoringId", {
            root: "/",
            edit: "/edit/"
        })
    }),
    terms: "/regulamin/",
    privacyPolicy: "/polityka-prywatnosci/",
    pricing: "/cennik/",
    contact: "/kontakt/",
    faq: "/faq/",
    user: prefixPath("/uzytkownik", {
        root: "/",
        changePassword: "/zmien-haslo/",
        edit: "/edytuj/"
    }),
    resetPassword: "/przypomnij-haslo/:uid/:token",
    publications: prefixPath("/publikacje", {
        root: "/",
        listing: "/lista/",
        details: "/:slug([\\w-]+)-:publicationId(\\d+)/"
    }),
    newshub: prefixPath("/newshub", {
        root: "/",
        marketInformation: prefixPath("/informacje-rynkowe", {
            root: "/",
            details: "/:slug([\\w-]+)-:newsId(\\d+)/",
            category: prefixPath("/:marketInfoCategorySlug([\\w-]+)", {
                root: "/",
                vendor: "/d/:vendorSlug([\\w-]+)-:vendorId(\\d+)/",
                subcategory: prefixPath("/:marketInfoSubCategorySlug([\\w-]+)", {
                    root: "/",
                    vendor: "/d/:vendorSlug([\\w-]+)-:vendorId(\\d+)/"
                })
            }),
            announcements: prefixPath("/komunikaty", {
                root: "/",
                announcement: "/:announcementCategorySlug([\\w-]+)/"
            })
        }),
        bigDataNews: prefixPath("/aktualnosci-bigdata", {
            root: "/",
            details: "/:slug([\\w-]+)-:newsId(\\d+)/"
        })
    })
};

export const routesEn: ChartsRoutesType = prefixPath("/en", {
    root: "/",
    properties: prefixPath("/prices/properties", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/",
            prices: "/prices/",
            offer: "/offer/",
            demand: "/demand/",
            sold: "/sold/",
            added: "/added/"
        })
    }),
    propertiesRedirect: prefixPath("/properties-prices", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/",
            prices: "/prices/",
            offer: "/offer/",
            demand: "/demand/",
            sold: "/sold/",
            added: "/added/"
        })
    }),
    flats: prefixPath("/prices/flats", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/",
            prices: "/prices/",
            offer: "/offer/",
            demand: "/demand/",
            sold: "/sold/",
            added: "/added/"
        })
    }),
    flatsRedirect: prefixPath("/flats-prices", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/",
            prices: "/prices/",
            offer: "/offer/",
            demand: "/demand/",
            sold: "/sold/",
            added: "/added/"
        })
    }),
    houses: prefixPath("/prices/houses", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/"
        })
    }),
    housesRedirect: prefixPath("/houses-prices", {
        root: "/",
        city: prefixPath("/:city([\\w-]+)", {
            root: "/"
        })
    }),
    monitoring: prefixPath("/monitoring", {
        root: "/",
        create: prefixPath("/create", {
            root: "/",
            offers: "/offers/",
            details: "/details/"
        }),
        details: prefixPath("/:monitoringId", {
            root: "/",
            edit: "/edit/"
        })
    }),
    terms: "/terms-of-service/",
    privacyPolicy: "/privacy-policy/",
    pricing: "/pricing/",
    contact: "/contact/",
    faq: "/faq/",
    user: prefixPath("/user", {
        root: "/",
        changePassword: "/change-password/",
        edit: "/edit/"
    }),
    resetPassword: "/remind-password/:uid/:token",
    publications: prefixPath("/publications", {
        root: "/",
        listing: "/list/",
        details: "/:slug([\\w-]+)-:publicationId(\\d+)/"
    }),
    newshub: prefixPath("/newshub", {
        root: "/",
        marketInformation: prefixPath("/informacje-rynkowe", {
            root: "/",
            details: "/:slug([\\w-]+)-:newsId(\\d+)/",
            category: prefixPath("/:marketInfoCategorySlug([\\w-]+)", {
                root: "/",
                vendor: "/d/:vendorSlug([\\w-]+)-:vendorId(\\d+)/",
                subcategory: prefixPath("/:marketInfoSubCategorySlug([\\w-]+)", {
                    root: "/",
                    vendor: "/d/:vendorSlug([\\w-]+)-:vendorId(\\d+)/"
                })
            }),
            announcements: prefixPath("/komunikaty", {
                root: "/",
                announcement: "/:announcementCategorySlug([\\w-]+)/"
            })
        }),
        bigDataNews: prefixPath("/bigdata-news", {
            root: "/",
            details: "/:slug([\\w-]+)-:newsId(\\d+)/"
        })
    })
});

export const chartsRoutes: {
    [key: string]: ChartsRoutesType;
} = {
    pl: routesPl,
    en: routesEn
};

export const getLocalizedUrl = (
    pathname: string,
    currentLang: string,
    newLang?: string,
    params?: ICityParams
): string => {
    const routeKeyString: string | null = findRouteKeyString(pathname, chartsRoutes[currentLang]);
    if (routeKeyString) {
        const localizedPath = findLocalizedPath(newLang ?? currentLang, routeKeyString);
        return compilePath(localizedPath, params);
    }
    return pathname;
};

const findLocalizedPath = (lang: string, keyString: string): string => {
    return get(chartsRoutes[lang], keyString);
};

const findRouteKeyString = (pathToMatch: string, routesObject: object): string => {
    let keyString = "";
    const mapRoute = (pathToMatch: string, object: object, keys: string[] = []) => {
        forIn(object, (value, key) => {
            if (isObject(value) && !keyString) {
                mapRoute(pathToMatch, value, [...keys, key]);
            } else if (typeof value === "string") {
                const match = matchPath(pathToMatch, {
                    path: value,
                    exact: true
                });

                if (match && !keyString) {
                    keyString = [...keys, key].join(".");
                    return false;
                }
            }
        });
    };
    mapRoute(pathToMatch, routesObject);
    return keyString;
};

export type ICityParams = {
    city: string;
};

export interface IAuthParams {
    user_uuid?: string;
    token?: string;
}

export interface IPublicationParams {
    publicationId?: string;
}

export interface INewsParams {
    newsId: string;
    marketInfoSubCategorySlug?: string;
    marketInfoCategorySlug?: string;
    vendorSlug?: string;
    vendorId?: string;
}

export interface ICategoryParams {
    announcementCategorySlug: string;
}

export const compilePath = (path: string, params?: ICityParams) => {
    const toPath = compile(path, {encode: encodeURIComponent});
    if (params) {
        return toPath(params);
    }
    return path;
};

export const checkIfIsDetailedView = (pathname: string): boolean => {
    let isDetailedView = false;
    const detailedViewsRouteChunks: string[] = [
        "/ceny",
        "/prices",
        "/oferta",
        "/offer",
        "/popyt",
        "/demand",
        "/sprzedane",
        "/sold",
        "/nowe",
        "/added"
    ];
    detailedViewsRouteChunks.forEach((chunk) => {
        if (pathname.includes(chunk)) {
            isDetailedView = true;
            return;
        }
    });
    return isDetailedView;
};
