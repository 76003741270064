import styled from "@emotion/styled";
import {Modal} from "@pg-design/modal";

import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {ZIndex} from "../../../../common/app/constants/z_index";
import {PasswordReminderForm} from "../../../users/components/PasswordReminderForm";
import {RegisterForm} from "../../../users/components/register_form/RegisterForm";
import {IAuthModalVariant, selectCurrentAuthModal} from "../../auth_slice";
import {LoginForm} from "../login_form/LoginForm";

interface IAuthModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const AuthModal = (props: IAuthModalProps) => {
    const currentAuthModal = useAppSelector<IAuthModalVariant>(selectCurrentAuthModal);

    if (currentAuthModal) {
        return (
            <StyledModal
                zIndex={ZIndex.MODAL}
                isRegistrationModal={currentAuthModal === "registerUser"}
                isOpen={Boolean(currentAuthModal)}
                onModalClose={props.onClose}
            >
                {currentAuthModal === "login" && <LoginForm />}
                {currentAuthModal === "remindPassword" && <PasswordReminderForm />}
                {currentAuthModal === "registerUser" && <RegisterForm />}
            </StyledModal>
        );
    }

    return null;
};

const StyledModal = styled(Modal)<{isRegistrationModal: boolean}>`
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        width: ${(props) => (props.isRegistrationModal ? "var(--modal-width-larg)" : "var(--modal-width-small)")};
    }
`;
