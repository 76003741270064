import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IRootState} from "../../../store/store";

interface ILatestEntryState {
    currentDate: string | null;
    completeData: boolean;
}

const initialState: ILatestEntryState = {
    currentDate: null,
    completeData: false
};

export const latestEntrySlice = createSlice({
    name: "latestEntry",
    initialState,
    reducers: {
        setLatestEntry: (state, action: PayloadAction<ILatestEntryState>) => {
            state.currentDate = action.payload.currentDate;
            state.completeData = action.payload.completeData;
        }
    }
});

export const {setLatestEntry} = latestEntrySlice.actions;

export const selectLatestEntry = (state: IRootState) => state.latestEntry;
