import {IUserModel} from "../../../../../utils/shared_types/user_model";
import {getPayloadFromData} from "./get_payload_for_user_com";

declare global {
    interface Window {
        civchat: IUserComPayload;
        UE: {
            pageHit: (payload: IUserComPayload) => void;
            resetAuth: (payload: {apiKey: string}) => void;
        };
        // userengage: (type: "client.update", data: Partial<IUserComData>) => void;
        userengage: (
            type: string,
            payload?:
                | {publication_id: number; name: string}
                | {email: string; sector: string; work_function: string; bigdata_marketing_consent: boolean}
                | {city: string; district: string}
        ) => void;
    }
}

interface IUserComPayload extends Partial<IUserComData> {
    apiKey: string;
}

export interface IUserComData {
    email: string;
    status: 2;
    user_id: number;
}

export let isUserComScriptInitialized = false;

/**
 * Init
 */
export const initUserCom = (apiKey: string | null, userComScript: string | null, userData: IUserModel | null): void => {
    // if there is no key provided, do not initialize
    if (!apiKey || !userComScript) {
        return;
    }
    // initialize only once
    if (isUserComScriptInitialized) {
        return;
    }
    // initialize
    (function () {
        window.civchat = {apiKey, ...getPayloadFromData(userData)};
        // fetch main script
        const firstScriptElem = document.getElementsByTagName("script")[0];
        const newScriptElem = document.createElement("script");
        newScriptElem.async = true;
        newScriptElem.src = userComScript;
        firstScriptElem?.parentNode?.insertBefore(newScriptElem, firstScriptElem);
    })();
    isUserComScriptInitialized = true;
};
