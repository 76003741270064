import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {FormikForm} from "@pg-design/formik-utils";
import {mb, mt, w100} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {Text} from "@pg-design/text";
import {Form, Formik} from "formik";

import {useAppDispatch, useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {handleFormikSubmit} from "../../../components/form/handle_submit";
import {SubmitFormikButton} from "../../../components/SubmitFormikButton";
import {selectAuthFormsSharedData, setAuthFormsSharedData, setAuthModal} from "../../auth/auth_slice";
import {useSendPasswordResetLinkMutation} from "../api/resetPasswordApi";

export const PasswordReminderForm = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const authFormsSharedData = useAppSelector(selectAuthFormsSharedData);
    const dispatch = useAppDispatch();
    const [sendResetLink] = useSendPasswordResetLinkMutation();

    const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState<boolean>(false);

    if (hasFormBeenSubmitted) {
        return (
            <>
                <Modal.Header>
                    <Text variant="headline_6" as="span">
                        {t("user.password_reminder_form.remind_password")}
                    </Text>
                </Modal.Header>

                <Modal.Content>
                    <Text css={[mb(6), mt(2)]} variant="body_copy_2" align="center" color={theme.colors.gray[700]}>
                        {t("user.password_reminder_form.email_with_link_sent")}
                    </Text>

                    <Button css={w100} variant="filled_primary" onClick={() => dispatch(setAuthModal("login"))}>
                        {t("user.password_reminder_form.return_to_login")}
                    </Button>
                </Modal.Content>
            </>
        );
    }

    return (
        <>
            <Modal.Header>
                <Text variant="headline_6" as="span">
                    {t("user.password_reminder_form.remind_password")}
                </Text>
            </Modal.Header>

            <Modal.Content>
                <Formik
                    initialValues={{email: authFormsSharedData.email}}
                    onSubmit={async (values, helpers) =>
                        handleFormikSubmit(sendResetLink({email: values.email}).unwrap(), helpers, {
                            onSuccess: () => setHasFormBeenSubmitted(true)
                        })
                    }
                >
                    {({handleBlur, isSubmitting, values}) => (
                        <Form>
                            <FormikForm.Input
                                css={mb(3)}
                                onBlur={(e) => {
                                    handleBlur(e);
                                    dispatch(
                                        setAuthFormsSharedData({
                                            ...authFormsSharedData,
                                            email: values.email
                                        })
                                    );
                                }}
                                name="email"
                                placeholder="E-mail"
                                type="email"
                            />

                            <SubmitFormikButton
                                css={w100}
                                copy={t("user.password_reminder_form.remind")}
                                disabled={isSubmitting}
                            />
                        </Form>
                    )}
                </Formik>

                <Button css={[mt(1.5), w100]} onClick={() => dispatch(setAuthModal("login"))} variant="none_secondary">
                    {t("user.password_reminder_form.go_back")}
                </Button>
            </Modal.Content>
        </>
    );
};
