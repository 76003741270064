import {useTranslation} from "react-i18next";
import {css} from "@emotion/react";
import {Container} from "@pg-design/grid";
import {Col, Row} from "@pg-design/grid";
import {flexAbsoluteCenter, flexDirection, mb, ml, onDesktop, ph, textAlign} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

import logo from "../../../common/app/assets/dea22_logo.png";
import logoUW from "../../../common/app/assets/uewLogotype.svg";

export const AwardsShowcase = () => {
    const {t} = useTranslation();

    const awards = [
        {
            logo: logoUW,
            text: t("landing.awards.uw"),
            alt: "logo UW"
        },
        {
            logo: logo,
            text: t("landing.awards.digital"),
            alt: "logo Digital Excellence Awards 2022"
        }
    ];

    return (
        <Container css={ph(3)} as="section" fluid>
            <Row>
                {awards.map((item, i) => (
                    <Col css={awardCol} key={i}>
                        <Image src={item.logo} alt={item.alt} width="199" height="99" loading="lazy" />

                        <Text variant="body_copy_2" align="center" css={text}>
                            {item.text}
                        </Text>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

const awardCol = css`
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
    ${mb(4)};

    ${onDesktop(css`
        ${flexDirection("row")}
    `)}
`;

const text = css`
    flex: 1;

    ${onDesktop(css`
        ${ml(2)};
        ${textAlign("start")}
    `)}
`;
