import {IBDStore} from "../../../../store/store";
import {setResponseStatus} from "../../../app_status/app_status_slice";
import {ResponseStatus} from "../state/app_status/IAppStatusState";

export const setError = (store: IBDStore, error: ResponseStatus) => {
    if (error !== ResponseStatus.PAGE_404 && error !== ResponseStatus.PAGE_500) {
        return;
    }
    store.dispatch(
        setResponseStatus({
            responseStatus: error
        })
    );
};
