declare global {
    interface Window {
        _nQc: string;
    }
}
let isAlbacrossScriptInitialized = false;

export const initAlbacross = (albacrossId: string | null): void => {
    // if there is no key provided/already initialized, do not initialize
    if (!albacrossId || isAlbacrossScriptInitialized) {
        return;
    }

    // initialize
    (function () {
        window._nQc = albacrossId;
        // fetch main script
        const firstScriptElem = document.getElementsByTagName("script")[0];
        const newScriptElem = document.createElement("script");
        newScriptElem.async = true;
        newScriptElem.src = "https://serve.albacross.com/track.js";
        firstScriptElem?.parentNode?.insertBefore(newScriptElem, firstScriptElem);
    })();
    isAlbacrossScriptInitialized = true;
};
