import {createSlice} from "@reduxjs/toolkit";

import {IRootState} from "../../../store/store";

export const vendorsSlice = createSlice({
    name: "vendors",
    initialState: {
        vendorId: "" // Set the initial state of vendorId
    },
    reducers: {
        setVendorId: (state, action) => {
            state.vendorId = action.payload;
        },
        resetVendorId: (state) => {
            state.vendorId = ""; // Reset the vendorId state to its initial value
        }
    }
});

export const {setVendorId, resetVendorId} = vendorsSlice.actions;

export const selectVendorId = (state: IRootState) => state.vendors.vendorId;
