import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {mb, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {useAppDispatch} from "../../../../utils/hooks/store_hooks";
import {setAuthModal} from "../../features/auth/auth_slice";
import {Panel} from "../../features/users/components/Panel";
import {SimpleContainer} from "../layout/SimpleContainer";

export const UnauthenticatedLanding = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <SimpleContainer>
            <Container>
                <Row>
                    <Col lg={8} lgOffset={4} md={8} mdOffset={2} sm={4}>
                        <Panel>
                            <Text css={mb(9)} as="p" variant="headline_3" align="center">
                                {t("landing.unauthenticated.title")}
                            </Text>

                            <Button
                                css={w100}
                                variant="filled_primary"
                                onClick={() => {
                                    dispatch(setAuthModal("login"));
                                }}
                            >
                                {t("landing.unauthenticated.log_in_btn")}
                            </Button>
                        </Panel>
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
};
