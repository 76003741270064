import {lazy, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Options, SeriesLineOptions} from "highcharts";

import {formatDate, humanDateFormatShort} from "../../../../utils/dates";
import {formatNumberGreaterThan1000} from "../../../../utils/format";
import {formatSharedTooltip} from "../../../../utils/tooltips";
import {mapFieldToColor} from "../../common/app/constants/colors";
import {IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";
import {withRatioData} from "./withRatioData";
const Chart = lazy(() => import("./ChartDefaultExport"));

// @TODO any
export interface IMultilineChartProps extends IChartProps {
    categoryField?: string;
    data: any[];
    fields: string[];
    unit?: string;
    valueMultiplier?: number;
    minValue?: number;
    roundValues?: boolean;
    nullZeroValues?: boolean;
}

export const MultilineChart = (props: IMultilineChartProps) => {
    const {categoryField, data, fields, unit, valueMultiplier = 1, minValue, roundValues, nullZeroValues} = props;

    const {t, i18n} = useTranslation();

    // value used to highlight currently selected plot's field on the tooltip
    const highlightedPoint = useRef<number | undefined>(undefined);

    const series: SeriesLineOptions[] = useMemo(() => {
        if (data && data.length > 0) {
            return fields.map((field) => ({
                // get translation for the field
                name: t(`fields.${field}`),
                // as plot colors are standarized (every field across the app has its dedicated color), we find the right color using field name; if color is not found, provided colorSet property is used
                color: mapFieldToColor(field),
                type: "line",
                data: data.map((month) => {
                    // factor to multiply value by; used by demand related charts to display percents instead of fractions
                    const multipliedValue = month[field] * valueMultiplier;
                    // Replace 0s with nulls to hide corresponding plot lines
                    if (nullZeroValues) {
                        return multipliedValue > 0 ? multipliedValue : null;
                    }
                    return multipliedValue;
                })
            }));
        }
        return [];
    }, [data, i18n.language]);

    // get unique dates for x-axis labels
    const cats = new Set();
    categoryField && data.forEach((chunk) => cats.add(chunk[categoryField]));
    const categories = Array.from(cats) as string[];

    const options: Options = {
        legend: {
            enabled: true
        },
        plotOptions: {
            line: {
                point: {
                    events: {
                        mouseOver: function (this, e) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore Property 'y' does not exist on type 'EventTarget
                            e.target?.y
                                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore Property 'y' does not exist on type 'EventTarget
                                  (highlightedPoint.current = e.target.y)
                                : (highlightedPoint.current = undefined);
                        }
                    }
                }
            }
        },
        tooltip: {
            className: "multiline-tooltip",
            formatter: function () {
                return formatSharedTooltip(this, highlightedPoint.current || 0, roundValues, i18n, unit);
            },
            shared: true
        },
        xAxis: {
            categories,
            labels: {
                formatter: function () {
                    return formatDate(this.value.toString(), humanDateFormatShort, i18n);
                }
            },
            visible: true
        },
        yAxis: {
            visible: true,
            labels: {
                formatter: function () {
                    return formatNumberGreaterThan1000(this.value as number); // @TODO fix number assertion
                }
            },
            min: minValue
        }
    };

    return (
        <SuspendedChart>
            <Chart {...props} customOptions={options} series={series} />
        </SuspendedChart>
    );
};

export const RatioMultilineChart = withRatioData(MultilineChart);
