import {useTranslation} from "react-i18next";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {flex, flexAbsoluteCenter, mv, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";
import {i18n} from "i18next";

import {formatNumber} from "../../../../../../utils/format";
import {colorSets} from "../../../../common/app/constants/colors";

interface IProps {
    colour: string;
    dataField: string;
    maxValue: number;
}

export const MapScale = ({colour, dataField, maxValue}: IProps) => {
    const {i18n} = useTranslation();
    const theme = useTheme();

    return (
        <div css={scaleWrapper}>
            <div css={w100}>
                <div css={flex("normal", "space-between")}>
                    <Text variant="info_txt_2" color={theme.colors.gray[800]}>
                        0
                    </Text>
                    <Text variant="info_txt_2" color={theme.colors.gray[800]}>
                        {formatNumber(maxValue)}
                    </Text>
                </div>

                <StyledBar colour={colour} />

                <Text variant="info_txt_1" align="center" color={theme.colors.gray[800]}>
                    {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    {mapScaleLabels(i18n)[dataField]}
                </Text>
            </div>
        </div>
    );
};

const mapScaleLabels = (i18n: i18n) => ({
    sold: i18n.t("common.map.legend.sold"),
    available_offers: i18n.t("common.map.legend.available_offers"),
    available_investments: i18n.t("common.map.legend.available_investments"),
    added: i18n.t("common.map.legend.added")
});

const scaleWrapper = (theme: Theme) => css`
    ${flexAbsoluteCenter};
    flex-wrap: wrap;

    @media (min-width: ${theme.breakpoints.sm}) {
        min-width: 32.5rem;
    }
`;

const StyledBar = styled.div<Pick<IProps, "colour">>`
    height: 1.1rem;
    ${mv(1)};
    background: linear-gradient(
        to right,
        ${(props) => colorSets[props.colour][colorSets[props.colour].length - 1]},
        ${(props) => colorSets[props.colour][0]}
    );
`;
