import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {getEntriesApi} from "../../../newshub/api/get_entries";
import {IActionContext} from "../create_path_to_action";
import {setAuthAndDate} from "./common_action";

export const bigDataNewsAction = async (ctx: IActionContext<ICityParams>): Promise<void> => {
    await setAuthAndDate(ctx);

    const {url} = ctx.route;

    const [_, paramsString] = url.split("?");

    const params = new URLSearchParams(paramsString);

    const page = params.get("page");

    ctx.store.dispatch(getEntriesApi.endpoints.getBigDataNews.initiate(Number(page ?? 1)));

    await Promise.all(ctx.store.dispatch(getEntriesApi.util.getRunningQueriesThunk()));
};
