import {FilterOfferType} from "../../web/src/common/app/constants/enums";

export const useTypeFilteredData = <D extends {offer_type?: "Mieszkania" | "Domy"}>(
    data: D[],
    offerType?: FilterOfferType
): D[] => {
    if (data && data.length > 0) {
        return offerType ? data.filter((city) => city.offer_type === offerType) : data;
    }
    return data;
};
