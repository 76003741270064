import React from "react";
import styled from "@emotion/styled";
import {flex, p, w100} from "@pg-design/helpers-css";
import {ICoreModalProps, Modal} from "@pg-design/modal";

// exporting interface only because this component can be used as `styled(ResponsiveModal)`
export interface IResponsiveModalProps extends ICoreModalProps {
    children: React.ReactNode;
    zIndex?: number;
    className?: string;
    maxWidth?: string;
}

export const ResponsiveModal: React.FC<IResponsiveModalProps> = (props) => {
    const {children, ...restProps} = props;

    return (
        <Modal {...restProps} className={props.className} isOpen={props.isOpen}>
            <ContentModal maxWidth={props.maxWidth ? props.maxWidth : "810px"}>{children}</ContentModal>
        </Modal>
    );
};

const ContentModal = styled.div<{maxWidth?: string}>`
    position: relative;
    max-height: 100vh;
    height: 100%;
    max-width: 100vw;
    overflow: hidden;
    ${flex()};
    flex-direction: column;
    ${w100};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        min-height: 630px;
        max-width: ${(props) => props.maxWidth};
        ${p(0)};
    }
`;
