import {useTranslation} from "react-i18next";
import {css, Theme, useTheme} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {
    backgroundColor,
    borderRadius,
    calculateRemSize,
    flexAlignCenter,
    flexDirection,
    flexJustifyCenter,
    h100,
    mb,
    onDesktop,
    p,
    pb,
    pl,
    w100
} from "@pg-design/helpers-css";
import {BrandEmailIcon, BrandMapPinIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {pageviewAlgolyticsHit} from "../../features/tracking/algolytics/pageview_hits";
import {usePageview} from "../../features/tracking/use_pageview";
import {pageviewUserComHit} from "../../features/tracking/user_com/hit_user_com";
import {ViewType} from "../../features/tracking/view_type";
import {ContactList} from "../contact/ContactList";
import {Heading} from "../Heading";
import {SimpleContainer} from "../layout/SimpleContainer";
import {RodoTerms} from "../RodoTerms";

export const Contact = () => {
    const {t} = useTranslation();
    usePageview(() => {
        pageviewAlgolyticsHit({viewType: ViewType.CONTACT});
        pageviewUserComHit();
    });
    const theme = useTheme();

    const contactInfo = [
        {
            icon: <BrandMapPinIcon size="4.8" wrapperSize="4.8" wrapperColor={theme.colors.primary} />,
            title: t("contact.company_address"),
            addressLines: (
                <>
                    Property Group Sp. z o.o.
                    <br />
                    02-627 Warszawa
                    <br />
                    ul. Adama Naruszewicza 27 lok. 101
                </>
            )
        },
        {
            icon: <BrandEmailIcon size="4.8" wrapperSize="4.8" wrapperColor={theme.colors.primary} />,
            title: t("contact.contact_media"),
            addressLines: (
                <>
                    Mikołaj Ostrowski
                    <br />
                    Tel.: <a href="tel:+48 530 619 988">+48 530 619 988</a>
                    <br />
                    E-mail: <a href="mailto:pr@rynekpierwotny.pl">pr@rynekpierwotny.pl</a>
                </>
            )
        },
        {
            icon: <BrandEmailIcon size="4.8" wrapperSize="4.8" wrapperColor={theme.colors.primary} />,
            title: t("contact.contact_details"),
            addressLines: (
                <>
                    Tel.: <a href="tel:+48 22 825 60 71">+48 22 825 60 71</a>
                    <br />
                    E-mail: <a href="mailto:bigdata@rynekpierwotny.pl">bigdata@rynekpierwotny.pl</a>
                </>
            )
        }
    ];

    return (
        <SimpleContainer>
            <Container as="section">
                <Heading text={t("contact.title")} />

                <Row css={[contactWrapper, pb(8)]}>
                    <Col lg={12}>
                        <ContactList />
                    </Col>
                </Row>

                <Row css={addressWrapper}>
                    {contactInfo.map((address, index) => (
                        <Col key={index} lg={4} md={4} sm={4} css={addressColumn}>
                            <div>
                                <div css={[flexAlignCenter, mb(3)]}>
                                    {address.icon}
                                    <Text css={pl(2)} variant="headline_3">
                                        {address.title}
                                    </Text>
                                </div>
                                <Text variant="body_copy_0" color={theme.colors.gray[800]} css={addressText}>
                                    {address.addressLines}
                                </Text>
                            </div>
                        </Col>
                    ))}
                </Row>

                <RodoTerms css={pb(2)} />
            </Container>
        </SimpleContainer>
    );
};

const addressText = css`
    font-style: normal;
`;

const addressColumn = css`
    justify-content: start;

    ${onDesktop(css`
        ${flexJustifyCenter}
    `)}
`;

const contactWrapper = css`
    ${flexJustifyCenter};
    column-gap: ${calculateRemSize(1.5)};
    row-gap: ${calculateRemSize(2)};
`;

const addressWrapper = (theme: Theme) => css`
    ${flexJustifyCenter};
    ${pb(10)};

    ${onDesktop(css`
        ${pb(6)};
    `)};

    @media (max-width: ${theme.breakpoints.md}) {
        row-gap: ${calculateRemSize(6)};
    }
`;

export const mediaBox = css`
    ${p(4)};
    ${borderRadius()};
    ${backgroundColor("#fff")};
    ${w100};
    ${flexAlignCenter};
    ${flexDirection("column")};
    ${h100};
`;
