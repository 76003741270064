import {useCallback, useRef, useState} from "react";

import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {useTimeFilteredData} from "../../../../../utils/hooks/useTimeFilteredData";
import {useTypeFilteredData} from "../../../../../utils/hooks/useTypeFilteredData";
import {FilterOfferType} from "../../../common/app/constants/enums";
import {Card, ICardProps} from "../../card/Card";
import {IMultilineTypeChartProps, MultilineTypeChart} from "../MultilineType";
import {TimeRangeFilter} from "./TimeRangeFilter";

interface IProps extends ICardProps, Omit<IMultilineTypeChartProps, "flatsData" | "housesData"> {
    data: IMonthCityTypeStats[];
    isFlatType?: boolean;
}

export const MultilineTypeChartCard = (props: IProps) => {
    const {area, data, isFlatType} = props;

    const chartRef = useRef(null);

    const [timeRange, setTimeRange] = useState<number>(12);

    const flatsData = useTypeFilteredData(data, FilterOfferType.FLATS);
    const housesData = useTypeFilteredData(data, FilterOfferType.HOUSES);

    const {filteredData: filteredFlatsData, availableRanges} = useTimeFilteredData(flatsData, timeRange);
    const {filteredData: filteredHousesData} = useTimeFilteredData(housesData, timeRange);

    const handleTimeRangeChange = useCallback((range: number) => setTimeRange(range), []);

    return (
        <Card
            {...props}
            area={area ?? ""}
            chartRef={chartRef}
            chartPlaceholder="multiline"
            footer={
                <TimeRangeFilter
                    availableRanges={availableRanges}
                    currentRange={timeRange}
                    onRangeChange={handleTimeRangeChange}
                />
            }
        >
            <MultilineTypeChart
                {...props}
                categoryField="date"
                colorSet="alternate"
                flatsData={filteredFlatsData}
                housesData={isFlatType ? [] : filteredHousesData}
                height={275}
                innerRef={chartRef}
            />
        </Card>
    );
};
