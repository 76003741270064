import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {Badge} from "@pg-design/badge";
import {flex, mb, ml, mr, mt, p, pointer} from "@pg-design/helpers-css";
import {BrandSkyscrapersIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {i18n} from "i18next";

import {cityConfig} from "../../../../../../config/cities";
import {formatDate, humanDateFormatLong} from "../../../../../../utils/dates";
import {formatNumber} from "../../../../../../utils/format";
import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {useAccessDate} from "../../../../../../utils/hooks/useAccessDate";
import {useTypeFilteredData} from "../../../../../../utils/hooks/useTypeFilteredData";
import {mapLegendColors} from "../../../../common/app/constants/colors";
import {FilterOfferType} from "../../../../common/app/constants/enums";
import {withDateGuard} from "../../../../components/hoc/withDateGuard";
import {MapStatType} from "../../../../components/map/redux/map_type_slice";
import {selectLatestEntry} from "../../../ssr/redux/latest_entry_slice";
import {MapScale} from "./MapScale";

interface IProps {
    city: string;
    data: {[key: string]: number}[];
    dataField: string;
    fluidLayout?: boolean;
    mapColor: string;
    offerType?: FilterOfferType;
    onLegendChange: (field: MapStatType) => void;
}

export const MapLegend = withDateGuard((props: IProps) => {
    const {city, data, dataField, fluidLayout, mapColor, onLegendChange, offerType} = props;

    const {t, i18n} = useTranslation();

    const {currentDate} = useAppSelector(selectLatestEntry);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore withDateGuard makes sure currentDate is not null
    const {upperBound: date} = useAccessDate(currentDate);
    const currentMonthLabel = formatDate(date, humanDateFormatLong, i18n);

    const maxValue = Math.max(...data.map((el) => el[dataField]));
    const filteredData = useTypeFilteredData(data, offerType);

    const values = useMemo(() => {
        const newValues: {[key: string]: number} = {
            available_offers: 0,
            available_investments: 0,
            sold: 0,
            added: 0
        };

        filteredData.reduce((acc, next) => {
            for (const key of Object.keys(newValues)) {
                acc[key] += next[key];
            }
            return acc;
        }, newValues);

        return newValues;
    }, [filteredData]);

    const legendItems: {
        [field: string]: {
            color: string;
            value: number;
        };
    } = {
        available_offers: {color: "green", value: values.available_offers},
        available_investments: {color: "blue", value: values.available_investments},
        sold: {color: "red", value: values.sold},
        added: {color: "honey", value: values.added}
    };

    return (
        <StyledLegend fluidLayout={fluidLayout}>
            <div>
                <div css={cityName}>
                    <BrandSkyscrapersIcon css={mr()} size="1.6" />

                    <Text variant="info_txt_1" as="span">{`${
                        Object.keys(cityConfig).includes(city) ? t(`city.${city}.nominative`) : city
                    } - ${currentMonthLabel}`}</Text>
                </div>

                {Object.entries(legendItems).map(([field, {value}]) => {
                    return (
                        <StyledLegendItem
                            field={field}
                            isActive={field === dataField}
                            key={field}
                            onClick={() => onLegendChange(field as MapStatType)}
                        >
                            <Text variant="info_txt_1" className="label">
                                {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                                {/*@ts-ignore*/}
                                {mapLegendLabels(i18n)[field]}
                            </Text>

                            <Badge className="value">{formatNumber(value)}</Badge>
                        </StyledLegendItem>
                    );
                })}
            </div>

            <MapScale colour={mapColor} dataField={dataField} maxValue={maxValue > 0 ? maxValue : 0} />
        </StyledLegend>
    );
});

const mapLegendLabels = (i18n: i18n) => ({
    sold: i18n.t("common.map.sold"),
    available_offers: i18n.t("common.map.available_offers"),
    available_investments: i18n.t("common.map.available_investments"),
    added: i18n.t("common.map.added")
});

interface IStyledLegendProps {
    fluidLayout?: boolean;
}

const StyledLegend = styled.div<IStyledLegendProps>`
    ${flex("normal", "space-between")};
    flex-direction: column-reverse;
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 1rem;

    & > div {
        padding: 2rem;
        width: 100%;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        flex-wrap: nowrap;
        flex-direction: row;
        width: 100%;
        align-items: flex-end;

        & > div {
            width: auto;

            & > div {
                min-width: 300px;
            }
        }
    }

    @media (min-aspect-ratio: 16/8) {
        ${(props) =>
            props.fluidLayout &&
            css`
                width: auto;
                flex-direction: column;
                ${flex("center", "flex-end")};
            `}
    }
`;

interface IStyledLegendItemProps {
    field: string;
    isActive: boolean;
}

const StyledLegendItem = styled.div<IStyledLegendItemProps>`
    ${mb()};
    ${flex("center", "space-between")};
    ${pointer};
    transition: var(--transition);
    position: relative;
    min-width: 30rem;

    &:first-of-type {
        ${mt(0)};
    }

    &:last-of-type {
        ${mb(0)};
    }

    .label {
        font-weight: ${(props) => (props.isActive ? 600 : "unset")};
        left: ${(props) => (props.isActive ? 20 : 0)}px;
        position: relative;
        transition: var(--transition);
    }

    .value {
        background: ${(props) => mapLegendColors[props.field]};
        width: 60px;
        transition: all 2s;

        & > p {
            margin: 0 auto;
            font-weight: 700;
        }
    }

    &:hover {
        .label {
            color: #000;
        }
    }

    &::before {
        content: ${(props) => (props.isActive ? "''" : "none")};
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 7px solid #000;
        border-bottom: 5px solid transparent;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const cityName = css`
    ${p(2, 2, 2, 0)};

    & > div {
        ${ml(2)};
        display: inline;
    }
`;
