import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {CenterBox, Col, Container, Row} from "@pg-design/grid";
import {calculateRemSize, flexJustifyCenter, mb, mv, pb, ph} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";

import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {useGetCategoriesQuery} from "../api/get_categories";
import {useGetArticlesByCategoriesQuery, useGetEntriesByIdQuery} from "../api/get_entries";
import {Hero} from "../components/article_details/Hero";
import {EntryCategories} from "../components/market_information/EntryCategories";
import {ArticleList} from "../components/market_information/listing/articles/ArticleList";
import {NewshubBreadcrumbs} from "../components/NewshubBreadcrumbs";

export const ArticleDetails = withGTM(() => {
    const history = useHistory();
    const {newsId} = useParams<{newsId: string}>();
    const {data, isLoading} = useGetEntriesByIdQuery(newsId);
    const {data: categories, isLoading: isLoadingCategories} = useGetCategoriesQuery();
    const categoryId = data?.category && data.category.length > 0 ? data.category : null;
    const [pathChangeCount, setPathChangeCount] = useState<number>(0);
    const {data: articles} = useGetArticlesByCategoriesQuery({category: categoryId ? categoryId : [], page: 1});

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, [newsId, categories, data]);

    useEffect(() => {
        setPathChangeCount((prevCount) => prevCount - 1);
    }, [history.location.pathname]);

    const handleGoBack = () => {
        history.action === "PUSH"
            ? history.go(pathChangeCount)
            : history.push(chartsRoutes.pl.newshub.marketInformation.root);
    };

    return (
        <SimpleContainer>
            <Container>
                <Row>
                    <Col lgOffset={1}>
                        <NewshubBreadcrumbs css={mv(2)} entryTitle={data?.title} />
                    </Col>
                </Row>

                {isLoadingCategories && (
                    <CenterBox height="300px">
                        <Loader size="lg" />
                    </CenterBox>
                )}

                <Row css={mainContentRow}>
                    <Col lgOffset={1} lg={5} md={4} sm={4}>
                        {categories && <EntryCategories categories={categories.results} />}
                    </Col>

                    <Col lg={9} md={8} sm={4} css={heroCol}>
                        {data && articles && (
                            <>
                                <Hero
                                    css={mb(6)}
                                    title={data.title}
                                    category={data.category}
                                    id={data.id}
                                    publication_date={data.publication_date}
                                    images={data.images}
                                    lead={data.lead}
                                    region_name={data.region_name}
                                    content={data.content}
                                    source={data.source}
                                    author={data.author}
                                />

                                <div css={[flexJustifyCenter, pb(5)]}>
                                    <Button
                                        onClick={handleGoBack}
                                        css={button}
                                        variant="outlined_secondary"
                                        size="medium"
                                    >
                                        Wróć do listy
                                    </Button>
                                </div>

                                <div css={articlesWrapper}>
                                    <Text variant="mini_header">Najnowsze z tej kategorii</Text>

                                    <ArticleList
                                        css={[mv(3), pb(5)]}
                                        isLoading={isLoading}
                                        articles={articles?.results.filter((data) => data.id !== +newsId).slice(0, 5)}
                                    />
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
});

const mainContentRow = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
        margin: 0 -1.2rem;
    }
`;

const heroCol = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
        padding: 0;
    }
`;

const articlesWrapper = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
        ${ph(1.5)};
    }
`;

const button = css`
    width: ${calculateRemSize(34)};
`;
