import {useTranslation} from "react-i18next";
import {css, Theme, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {flex, flexDirection, flexJustifyCenter, mb, ml, onDesktop, pb, ph, pt, pv, w100} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

import {dataLayer} from "../../../utils/data_layer";

import envelope from "../common/app/assets/envelope.svg";

export const Newsletter = () => {
    const {t} = useTranslation();
    const theme = useTheme();

    return (
        <Container fluid css={newsletterWrapper} as="section">
            <Container>
                <Row css={newsletterContentWrapper}>
                    <Col lgOffset={2} lg={7}>
                        <Text as="h2" variant="headline_2" css={[mb(2), textWrapper(theme)]}>
                            {t("landing.newsletter.title")}
                        </Text>

                        <Text as="p" variant="body_copy_1" css={[mb(4), textWrapper(theme)]}>
                            {t("landing.newsletter.heading")}
                        </Text>

                        <Button
                            css={button}
                            size="medium"
                            variant="filled_primary"
                            onClick={() => {
                                dataLayer({
                                    event: "newsletter_signup"
                                });
                                window.userengage("event.subscribe_newsletter");
                            }}
                        >
                            {t("common.sign_up")}
                        </Button>
                    </Col>

                    <Col lg={5} css={imgWrapper}>
                        <Image src={envelope} alt="envelope" width="182px" height="205px" loading="lazy" />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

const newsletterWrapper = (theme: Theme) => css`
    ${pv(7)};
    ${w100};
    background-color: ${theme.colors.background[200]};

    ${onDesktop(css`
        ${pt(10)};
        ${pb(18)};
    `)};
`;

const textWrapper = (theme: Theme) => css`
    ${ph(1)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${ph(0)}
    }
`;
const imgWrapper = (theme: Theme) => css`
    ${flexJustifyCenter};

    @media (min-width: ${theme.breakpoints.sm}) {
        justify-content: flex-end;
    }
`;

const newsletterContentWrapper = (theme: Theme) => css`
    ${flexDirection("column")}

    @media (min-width: ${theme.breakpoints.sm}) {
        ${flex("center", "flex-start")}
        ${flexDirection("row")}
    }
`;

const button = (theme: Theme) => css`
    width: 20rem;
    ${ml(1)};
    ${mb(7)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${ml(0)};
        ${mb(0)};
    }
`;
