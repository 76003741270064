import {lazy, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Options, SeriesLineOptions} from "highcharts";

import {IMonthCityTypeStats} from "../../../../api/src/db_queries/month_city_type_stats_query";
import {formatDate, humanDateFormatShort} from "../../../../utils/dates";
import {formatNumberGreaterThan1000} from "../../../../utils/format";
import {formatSharedTooltip} from "../../../../utils/tooltips";
import {IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";

const Chart = lazy(() => import("./ChartDefaultExport"));

export interface IMultilineTypeChartProps extends IChartProps {
    categoryField?: string;
    flatsData: IMonthCityTypeStats[];
    housesData: IMonthCityTypeStats[];
    fields: string[];
    unit?: string;
    valueMultiplier?: number;
    minValue?: number;
    roundValues?: boolean;
    nullZeroValues?: boolean;
}

export const MultilineTypeChart = (props: IMultilineTypeChartProps) => {
    const {
        categoryField,
        flatsData,
        housesData,
        fields,
        unit,
        valueMultiplier = 1,
        minValue,
        roundValues,
        nullZeroValues
    } = props;

    const {t, i18n} = useTranslation();

    const highlightedPoint = useRef<number | undefined>(undefined);

    const flatsSeries: SeriesLineOptions[] = useMemo(() => {
        if (flatsData && flatsData.length > 0) {
            return fields.map((field) => ({
                // get translation for the field
                name: t(`fields.${field}`, {context: "flats"}),
                // color as in Dashboard's investment map filters
                color: "#ee6664",
                type: "line",
                data: flatsData.map((month) => {
                    // factor to multiply value by; used by demand related charts to display percents instead of fractions
                    const multipliedValue = (month[field] as number) * valueMultiplier;
                    // Replace 0s with nulls to hide corresponding plot lines
                    if (nullZeroValues) {
                        return multipliedValue > 0 ? multipliedValue : null;
                    }
                    return multipliedValue;
                })
            }));
        }
        return [];
    }, [flatsData, i18n.language]);

    const housesSeries: SeriesLineOptions[] = useMemo(() => {
        if (housesData && housesData.length > 0) {
            return fields.map((field) => ({
                // get translation for the field
                name: t(`fields.${field}`, {context: "houses"}),
                // color as in Dashboard's investment map filters
                color: "#826af9",
                type: "line",
                data: housesData.map((month) => {
                    // factor to multiply value by; used by demand related charts to display percents instead of fractions
                    const multipliedValue = (month[field] as number) * valueMultiplier;
                    // Replace 0s with nulls to hide corresponding plot lines
                    if (nullZeroValues) {
                        return multipliedValue > 0 ? multipliedValue : null;
                    }
                    return multipliedValue;
                })
            }));
        }
        return [];
    }, [housesData, i18n.language]);

    const series = [...flatsSeries, ...housesSeries];

    // get unique dates for x-axis labels
    const cats = new Set();
    categoryField && flatsData.forEach((chunk) => cats.add(chunk[categoryField]));
    const categories = Array.from(cats) as string[];

    const options: Options = {
        legend: {
            enabled: true
        },
        plotOptions: {
            line: {
                point: {
                    events: {
                        mouseOver: function (this, e) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore Property 'y' does not exist on type 'EventTarget
                            e.target?.y
                                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore Property 'y' does not exist on type 'EventTarget
                                  (highlightedPoint.current = e.target.y)
                                : (highlightedPoint.current = undefined);
                        }
                    }
                }
            }
        },
        tooltip: {
            className: "multiline-tooltip",
            formatter: function () {
                return formatSharedTooltip(this, highlightedPoint.current || 0, roundValues, i18n, unit);
            },
            shared: true
        },
        xAxis: {
            categories,
            labels: {
                formatter: function () {
                    return formatDate(this.value.toString(), humanDateFormatShort, i18n);
                }
            },
            visible: true
        },
        yAxis: {
            visible: true,
            labels: {
                formatter: function () {
                    return formatNumberGreaterThan1000(this.value as number); // @TODO fix number assertion
                }
            },
            min: minValue
        }
    };

    return (
        <SuspendedChart>
            <Chart {...props} customOptions={options} series={series} />
        </SuspendedChart>
    );
};
