import {lazy, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Options, SeriesPieOptions} from "highcharts";

import {formatNumber} from "../../../../utils/format";
import {formatPieTooltip} from "../../../../utils/tooltips";
import {FilterOfferType} from "../../common/app/constants/enums";
import {IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";
import {withRatioData} from "./withRatioData";

const Chart = lazy(() => import("./ChartDefaultExport"));

export interface IPieChartProps<D> extends IChartProps {
    data: D[];
    fields: string[];
    fieldsLabels?: string[];
    offerType?: FilterOfferType;
    innerTitle?: string;
    tooltipLabel?: string; // used word must exist in "common" key in translations with proper plural forms
    withLegend?: boolean;
    unit?: string;
    valueMultiplier?: number;
    roundValues?: boolean;
    total?: number;
}

export const PieChart = <D extends {}>(props: IPieChartProps<D>) => {
    const {
        data,
        fields,
        fieldsLabels,
        offerType,
        innerTitle,
        tooltipLabel,
        withLegend,
        unit,
        valueMultiplier = 1,
        roundValues
    } = props;

    const {t, i18n} = useTranslation();

    const series: SeriesPieOptions[] = useMemo(() => {
        if (data && data.length > 0) {
            // TODO: why?
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const filteredData = !offerType ? data : data.filter((el: D) => el.offer_type === offerType);

            return [
                {
                    type: "pie",
                    data: fields.map((field: string, i) => {
                        return {
                            id: field,
                            name: fieldsLabels ? fieldsLabels[i] : (t(`fields.${field}`) as string),
                            y: filteredData.reduce((acc: number, curr: any) => acc + curr[field], 0) * valueMultiplier
                        };
                    })
                }
            ];
        }

        return [];
    }, [data, i18n.language]);

    const total: number = useMemo(() => {
        if (props.total) {
            return props.total;
        }

        if (series && series.length > 0 && series[0].data) {
            return series[0].data.reduce((acc: number, curr: any) => acc + (isNaN(curr.y) ? 0 : curr.y), 0);
        }
        return 0;
    }, [series]);

    const options: Options = {
        chart: {
            spacingTop: 0,
            spacingBottom: 10,
            width: null
        },
        legend: {
            enabled: withLegend,
            floating: true,
            align: "left"
        },
        plotOptions: {
            pie: {
                showInLegend: true
            }
        },
        title: {
            text: `${
                innerTitle ? innerTitle + "<br/ >" : ""
            } <div style="font-size: 16px; font-weight: 600;">${formatNumber(total)}</div>`,
            align: "center",
            verticalAlign: "middle",
            style: {
                fontSize: "13px"
            },
            y: 18
        },
        tooltip: {
            className: "pie-tooltip",
            formatter: function () {
                return formatPieTooltip(this, i18n, tooltipLabel, offerType);
            }
        }
    };

    return (
        <SuspendedChart>
            <Chart {...props} customOptions={options} series={series} />
        </SuspendedChart>
    );
};

export const RatioPieChart = withRatioData(PieChart);
