import {IUserModel} from "../../../../../utils/shared_types/user_model";
import {usersApi} from "./usersApi";

export const getCurrentUserApi = usersApi.injectEndpoints({
    endpoints: (build) => ({
        getCurrentUser: build.query<IUserModel, void>({
            query: () => ({
                url: `/me/`,
                method: "GET"
            })
        })
    }),
    overrideExisting: false
});

export const {useGetCurrentUserQuery} = getCurrentUserApi;
