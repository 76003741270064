import {css, Theme} from "@emotion/react";
import {borderRadius} from "@pg-design/helpers-css";

import {StyledInputWrapper} from "../SearchAutocompleteInputCore";

export const searchDesktopWrapper = (theme: Theme) => css`
    position: relative;
    display: none;

    ${StyledInputWrapper} {
        ${borderRadius()};
    }

    @media (min-width: ${theme.breakpoints.md}) {
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        flex-wrap: wrap;
        width: 100%;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        flex-wrap: unset;
    }
`;
