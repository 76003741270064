import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {ViewType} from "../../tracking/view_type";
import {CustomerSupport} from "../components/CustomerSupport";
import {Hero} from "../components/landing/hero/Hero";
import {Instructions} from "../components/landing/Instructions";
import {MonitoringFaq} from "../components/landing/MonitoringFaq";
import {Reasons} from "../components/landing/Reasons";
import {Slides} from "../components/landing/Slides";

export const MonitoringLanding = withGTM(() => {
    usePageview(() => pageviewAlgolyticsHit({viewType: ViewType.MONITORING}));
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const isMonitoring = pathname.includes("/monitoring");

    return (
        <SimpleContainer>
            <Hero />
            <Reasons />
            <Instructions />
            <Slides />
            <MonitoringFaq />
            <CustomerSupport isMonitoring={isMonitoring} title={t("inquiry.order_monitoring")} />
        </SimpleContainer>
    );
});
