import {useTranslation} from "react-i18next";
import {useRouteMatch} from "react-router-dom";
import {css} from "@emotion/react";
import {onDesktop, order} from "@pg-design/helpers-css";
import {Select} from "@pg-design/select";

import {cityConfig, CitySlug, getCityOption, mapParamSlugToCitySlug} from "../../../../../config/cities";
import {getRelatedCitySlug, mapCitySlugToRegionType, RegionType} from "../../../../../config/regions";
import {dataLayer} from "../../../../../utils/data_layer";
import {ICityParams} from "../../../common/app/routing/charts_routes";

interface IProps {
    onCitySelection?: (city: CitySlug) => void;
}

interface ICityOption {
    city: string;
    label: string;
}

export const CitySelect = ({onCitySelection}: IProps) => {
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();
    const citySlug = city ? mapParamSlugToCitySlug(city) : CitySlug.WARSZAWA;
    const regionType = mapCitySlugToRegionType(citySlug);
    // if on agglomeration view, get regular citySlug
    const currentCity = regionType === RegionType.CITY ? citySlug : getRelatedCitySlug(citySlug, RegionType.CITY);
    const {t} = useTranslation();

    const handleCitySelection = (selectedCity: CitySlug) => {
        // redirect to selected city but within current region type
        const relatedCitySlug = getRelatedCitySlug(selectedCity, regionType);
        // if selected city doesn't support agglomerations, redirect to regular city view
        const targetCitySlug = relatedCitySlug ?? selectedCity;
        // city is guaranteed to be valid, thus assertion
        onCitySelection && onCitySelection(targetCitySlug as CitySlug);
        dataLayer({
            event: "select_menu",
            city: targetCitySlug as string
        });
    };

    const valueCityLabel = currentCity === CitySlug.KATOWICE ? "Katowice / GZM" : t(`city.${currentCity}.nominative`);

    const selectOptions: Array<ICityOption> = Object.keys(cityConfig)
        .filter((city) => {
            const option = getCityOption(city as CitySlug);
            //we need this entry because slug for fetching data is "trojmiasto" but we leave "gdansk" in url
            //and also fetch data for "trojmiasto" in landing animated chart so slug with this name must exist
            //but it cannot render in city select
            return city !== CitySlug.TROJMIASTO && option.enabled && option.includeInCitySelect;
        })
        .map((city) => ({
            city: mapParamSlugToCitySlug(city) as string,
            label: city === "katowice" ? "Katowice / GZM" : t(`city.${city}.nominative`)
        }));

    return (
        <Select
            css={select}
            instanceId="header_city_select"
            onChange={(e) => {
                const {city} = e as ICityOption;
                handleCitySelection(city as CitySlug);
            }}
            placeholder={
                city && currentCity && getCityOption(currentCity).enabled ? valueCityLabel : t("nav.choose_city")
            }
            value={city && currentCity && getCityOption(currentCity).enabled ? valueCityLabel : t("nav.choose_city")}
            options={selectOptions}
        />
    );
};

const select = css`
    ${order(2)};

    ${onDesktop(css`
        min-width: 150px;
        ${order(3)}
    `)};
`;
