import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {flex, onDesktop, pl} from "@pg-design/helpers-css";
import {InfoIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {useConditionalPlatformRoute} from "../../../../../../utils/hooks/useConditionalPlatformRoute";
import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {NavigationText} from "../../../../components/header/navigation/components/NavigationText";
import {labelName, showLabelAnimation} from "../../../../components/nav/SideMenu";

interface IProps {
    button: boolean;
    onTriggerClick: () => void;
    className?: string;
}

export const OnboardingTrigger = ({button, onTriggerClick, className}: IProps) => {
    const {t, i18n} = useTranslation();
    const {pathname} = useLocation();
    const shouldRoute = useConditionalPlatformRoute(pathname);
    const history = useHistory();

    return button ? (
        <div
            className={className}
            onClick={() => {
                if (shouldRoute) {
                    history.push(chartsRoutes[i18n.language].properties.root);
                }
                onTriggerClick();
            }}
            css={onBoardingTrigger}
        >
            <InfoIcon size="2.4" fill="#fff" />
            <Text className="name-label" variant="body_copy_2">
                {t("onboarding.trigger")}
            </Text>
        </div>
    ) : (
        <div
            onClick={() => {
                if (shouldRoute) {
                    history.push(chartsRoutes[i18n.language].properties.root);
                }
                onTriggerClick();
            }}
        >
            <NavigationText css={guide} variant="info_txt_1">
                {t("onboarding.trigger")}
            </NavigationText>
        </div>
    );
};

const onBoardingTrigger = (theme: Theme) => css`
    ${onDesktop(css`
        cursor: pointer;
        ${flex("center", "center")};
        color: #fff;
        transition:
            opacity 0.2s,
            left 0.2s,
            background 0.2s;

        &:hover {
            & > span {
                color: #fff;
            }

            .name-label {
                animation: ${showLabelAnimation} 0.35s ease 1;
                animation-fill-mode: forwards;
            }
        }

        .name-label {
            background: white;
            color: ${theme.colors.secondary};
            ${labelName};
        }
    `)}
`;

const guide = (theme: Theme) => css`
    ${pl(2)};
    ${flex("center", "flex-start")};
    height: 68px;
    background-color: #fff;
    cursor: pointer;

    @media (min-width: ${theme.breakpoints.lg}) {
        &:hover {
            background-color: ${theme.colors.gray[100]};
        }
    }
`;
