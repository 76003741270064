import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IRootState} from "../../../store/store";

export enum MapType {
    GOOGLE,
    HIGHCHARTS
}

export enum MapStatType {
    AVAILABLE_OFFERS = "available_offers",
    AVAILABLE_INVESTMENTS = "available_investments",
    SOLD = "sold",
    ADDED = "added"
}

export interface IMapTypeState {
    mapType: MapType;
    selectedStat: MapStatType;
}

const initialState: IMapTypeState = {
    mapType: MapType.HIGHCHARTS,
    selectedStat: MapStatType.AVAILABLE_OFFERS
};

export const mapTypeSlice = createSlice({
    name: "mapType",
    initialState,
    reducers: {
        toggleMapType: (state) => {
            state.mapType === MapType.HIGHCHARTS
                ? (state.mapType = MapType.GOOGLE)
                : (state.mapType = MapType.HIGHCHARTS);
        },
        setMapType: (state, action: PayloadAction<MapType>) => {
            state.mapType = action.payload;
        },
        setSelectedStat: (state, action: PayloadAction<MapStatType>) => {
            state.selectedStat = action.payload;
        }
    }
});

export const {toggleMapType, setMapType, setSelectedStat} = mapTypeSlice.actions;

export const selectMapType = (state: IRootState) => state.mapType.mapType;

export const selectSelectedStat = (state: IRootState) => state.mapType.selectedStat;
