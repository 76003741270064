import React, {memo} from "react";
import {Button} from "@pg-design/button";

interface IProps {
    disabled: boolean;
    copy: string;
    className?: string;
}

export const SubmitFormikButton = memo((props: IProps) => {
    return (
        <Button variant="filled_primary" disabled={props.disabled} type="submit" className={props.className}>
            {props.copy}
        </Button>
    );
});
