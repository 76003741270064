import styled from "@emotion/styled";

import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {IQuarterCityStats} from "../../../../../api/src/db_queries/quarter_city_stats_query";
import {IQuarterCityTypeStats} from "../../../../../api/src/db_queries/quarter_city_type_stats_query";
import {RequestStatus} from "../../../common/app/constants/enums";
import {Card, ICardProps} from "../../card/Card";
import {RenderProps} from "../../types/render_props";
import {BarChart, IBarChartProps, RatioBarChart} from "../Bar";
import {IPieChartProps, PieChart, RatioPieChart} from "../Pie";

interface IPieBarChartCardProps<PieData, BarData> extends ICardProps {
    pieData: IMonthCityTypeStats[] | IMonthCityStats[] | IQuarterCityStats[] | IQuarterCityTypeStats[];
    pieStatus: RequestStatus;
    pieFields: IPieChartProps<PieData>["fields"];
    pieInnerTitle: IPieChartProps<PieData>["innerTitle"];
    pieTooltipLabel?: IPieChartProps<PieData>["tooltipLabel"];
    pieFieldsLabels?: IPieChartProps<PieData>["fieldsLabels"];
    barData: IMonthCityTypeStats[] | IMonthCityStats[] | IQuarterCityStats[] | IQuarterCityTypeStats[];
    barStatus: RequestStatus;
    barFields: IBarChartProps<BarData>["fields"];
    barUnit?: IBarChartProps<BarData>["unit"];
    barFieldsLabels?: IBarChartProps<BarData>["fieldsLabels"];
    barColorSet?: string;
    barMulticolorSet?: string;
    barTitle?: IBarChartProps<BarData>["chartTitle"];
    barHeight?: IBarChartProps<BarData>["height"];
    roundValues?: boolean;
}

type BarDataType = IMonthCityTypeStats[] | IMonthCityStats[] | IQuarterCityStats[] | IQuarterCityTypeStats[];
interface IPieBarChartCardWrapperProps<PieData, BarData> extends IPieBarChartCardProps<PieData, BarData>, RenderProps {}

export const PieBarChartCardWrapper = <PieData extends {}, BarData extends {}>(
    props: IPieBarChartCardWrapperProps<PieData, BarData>
) => {
    const {area} = props;

    const {render, ...p} = props;

    return (
        <StyledCard {...props} area={area ?? ""} chartPlaceholder="pieBar">
            {render(p)}
        </StyledCard>
    );
};

export const PieBarChartCard = <PieData extends {}, BarData extends BarDataType>(
    props: IPieBarChartCardProps<PieData, BarData>
) => {
    return (
        <PieBarChartCardWrapper
            {...props}
            render={({
                area,
                pieData,
                pieStatus,
                pieFields,
                pieInnerTitle,
                pieTooltipLabel,
                pieFieldsLabels,
                barData,
                barStatus,
                barFields,
                barUnit,
                barColorSet,
                barMulticolorSet,
                barFieldsLabels,
                barTitle,
                barHeight,
                roundValues,
                ...renderProps
            }) => (
                <>
                    <PieChart<PieData>
                        {...renderProps}
                        colorSet="alternateLong"
                        data={pieData}
                        dataStatus={sharedDataStatus(pieStatus, barStatus)}
                        fields={pieFields}
                        fieldsLabels={pieFieldsLabels}
                        height={200}
                        innerTitle={pieInnerTitle}
                        tooltipLabel={pieTooltipLabel}
                    />

                    <BarChart<BarData>
                        {...renderProps}
                        colorSet={barColorSet ? barColorSet : undefined}
                        multicolorSet={barMulticolorSet ? barMulticolorSet : undefined}
                        data={barData}
                        dataStatus={sharedDataStatus(pieStatus, barStatus)}
                        fields={barFields}
                        fieldsLabels={barFieldsLabels}
                        height={barHeight ?? 200}
                        unit={barUnit}
                        chartTitle={barTitle}
                    />
                </>
            )}
        />
    );
};

export const RatioPieBarChartCard = <PieData extends {}, BarData extends {}>(
    props: IPieBarChartCardProps<PieData, BarData>
) => {
    return (
        <PieBarChartCardWrapper
            {...props}
            render={({
                area,
                pieData,
                pieStatus,
                pieFields,
                pieInnerTitle,
                pieTooltipLabel,
                pieFieldsLabels,
                barData,
                barStatus,
                barFields,
                barUnit,
                barColorSet,
                barMulticolorSet,
                barFieldsLabels,
                barTitle,
                barHeight,
                roundValues,
                ...renderProps
            }) => (
                <>
                    <RatioPieChart<PieData>
                        {...renderProps}
                        colorSet="alternate"
                        data={pieData}
                        dataStatus={sharedDataStatus(pieStatus, barStatus)}
                        fields={pieFields}
                        fieldsLabels={pieFieldsLabels}
                        height={200}
                        innerTitle={pieInnerTitle}
                    />

                    <RatioBarChart<BarData>
                        {...renderProps}
                        colorSet={barColorSet ? barColorSet : undefined}
                        multicolorSet={barMulticolorSet ? barMulticolorSet : undefined}
                        data={barData}
                        dataStatus={sharedDataStatus(pieStatus, barStatus)}
                        fields={barFields}
                        fieldsLabels={barFieldsLabels}
                        height={barHeight ?? 200}
                        unit={barUnit}
                        chartTitle={barTitle}
                    />
                </>
            )}
        />
    );
};

const StyledCard = styled(Card)`
    .card-content {
        align-items: center;
    }
`;

const sharedDataStatus = (pieStatus: RequestStatus, barStatus: RequestStatus) => {
    switch (true) {
        case pieStatus === RequestStatus.SUCCESS && barStatus === RequestStatus.SUCCESS:
            return RequestStatus.SUCCESS;
        case pieStatus === RequestStatus.ERROR && barStatus === RequestStatus.ERROR:
            return RequestStatus.ERROR;
        case pieStatus === RequestStatus.PENDING && barStatus === RequestStatus.PENDING:
        default:
            return RequestStatus.PENDING;
    }
};
