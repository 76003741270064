import {useLocation} from "react-router-dom";
import {mv} from "@pg-design/helpers-css";

import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {Breadcrumbs, IBreadcrumb} from "../../../components/breadcrumbs/Breadcrumbs";
import {BreadcrumbsItem} from "../../../components/breadcrumbs/BreadcrumbsItem";

interface IProps {
    publicationName?: string;
    className?: string;
}

export const PublicationsBreadcrumbs = ({publicationName, className}: IProps) => {
    const {pathname} = useLocation();

    const generatePublicationsBreadcrumbs = () => {
        const publicationBreadcrumb: IBreadcrumb = {
            title: "Raporty i webinary",
            children: "Raporty i webinary",
            to: chartsRoutes.pl.publications.root
        };
        const sectionBreadcrumbs: IBreadcrumb[] = [publicationBreadcrumb];

        if (pathname.includes(chartsRoutes.pl.publications.listing)) {
            const listingBreadcrumb: IBreadcrumb = {
                title: "Lista",
                children: "Lista",
                to: chartsRoutes.pl.publications.listing
            };
            sectionBreadcrumbs.push(listingBreadcrumb);
        }

        if (publicationName) {
            const detailBreadcrumb: IBreadcrumb = {
                title: publicationName,
                children: publicationName,
                to: "#"
            };
            sectionBreadcrumbs.push(detailBreadcrumb);
        }
        return [...sectionBreadcrumbs];
    };
    const sectionBreadcrumbsData = generatePublicationsBreadcrumbs();

    return (
        <Breadcrumbs className={className} css={mv(2)}>
            {sectionBreadcrumbsData.map((data, i) => {
                const isLastItem = sectionBreadcrumbsData.length === i + 1;
                return (
                    <BreadcrumbsItem
                        title={data.title}
                        to={data.to}
                        isLinkingToCurrentLocation={isLastItem}
                        key={i}
                        position={(i + 2).toString()}
                    >
                        {data.children}
                    </BreadcrumbsItem>
                );
            })}
        </Breadcrumbs>
    );
};
