import {i18n} from "i18next";

import {IUserModel, UserAccessStatus} from "./shared_types/user_model";

export const getLockerLabel = (i18n: i18n, userData: IUserModel | null, isLoggedIn?: boolean): string => {
    switch (true) {
        case !isLoggedIn:
            return i18n.t("common.chart_lock.access_paid");
        case isLoggedIn &&
            userData &&
            userData.access_status === UserAccessStatus.ACCEPTED &&
            !userData.bigdata_marketing_consent:
            return i18n.t("common.chart_lock.enable_marketing_consent");
        case userData && !userData.email_verified:
            return i18n.t("common.chart_lock.confirm_email");
        default:
            return i18n.t("common.chart_lock.access_paid_contact");
    }
};
