import {ReactNode} from "react";
import styled from "@emotion/styled";
import {calculateRemSize} from "@pg-design/helpers-css";

import {breakpoints} from "../common/app/styles/breakpoints";

interface IGridProps {
    type?: "home" | "dashboard" | "subpage" | "subpageMapless" | "placeholder";
    children: ReactNode | Array<ReactNode>;
    isFlatOfferType?: boolean;
}

export const Grid = ({type, children, isFlatOfferType}: IGridProps) => {
    switch (type) {
        case "home":
            return <StyledHomeGrid>{children}</StyledHomeGrid>;
        case "dashboard":
            return <StyledDashboardGrid>{children}</StyledDashboardGrid>;
        case "subpage":
            return <StyledSubpageGrid isFlatOfferType={isFlatOfferType}>{children}</StyledSubpageGrid>;
        case "subpageMapless":
            return <StyledSubpageMaplessGrid isFlatOfferType={isFlatOfferType}>{children}</StyledSubpageMaplessGrid>;
        case "placeholder":
            return <StyledPlaceholderGrid>{children}</StyledPlaceholderGrid>;
        default:
            return <StyledGrid>{children}</StyledGrid>;
    }
};

const StyledGrid = styled.div`
    display: grid;
    column-gap: ${calculateRemSize(2)};
    grid-template-columns: 100%;
`;

const StyledHomeGrid = styled(StyledGrid)`
    @media (min-width: ${breakpoints.md}) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "chart1 chart1"
            "map map"
            "chart2 chart3"
            "chart4 chart5";
    }
`;

const StyledDashboardGrid = styled(StyledGrid)`
    @media (min-width: ${breakpoints.md}) {
        grid-template-columns: 30% repeat(3, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
            "chart1 chart2 chart3 chart4"
            "chart5 map map map"
            "chart6 map map map"
            "chart7 map map map"
            "chart8 map map map";
    }
`;

interface IStyledSubpageGrid {
    isFlatOfferType?: boolean;
}

const StyledSubpageGrid = styled(StyledGrid)<IStyledSubpageGrid>`
    @media (min-width: ${breakpoints.md}) {
        grid-template-columns: 60% auto;
        grid-template-rows: auto;
        grid-template-areas:
            "chart1 map"
            "chart2 map"
            ${(props) => (props.isFlatOfferType ? "" : '"chart3 map"')}
            "chart4 ."
            "chart5 ."
            "chart6 ."
            "chart7 ."
            "chart8 ."
            "chart9 ."
            "chart10 .";
    }
    @media (min-width: ${breakpoints.xxl}) {
        grid-template-columns: 50% auto;
    }
`;

const StyledSubpageMaplessGrid = styled(StyledGrid)<IStyledSubpageGrid>`
    @media (min-width: ${breakpoints.md}) {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: auto;
        grid-template-areas: ${(props) =>
            props.isFlatOfferType
                ? `"chart1 chart2"
                "chart4 chart5"
                "chart6 chart7"
                "chart8 chart9"
                "chart10 chart11"`
                : `"chart1 chart2"
                "chart3 chart4"
                "chart5 chart6"
                "chart7 chart8"
                "chart9 chart10"`};
    }
`;

const StyledPlaceholderGrid = styled(StyledGrid)`
    position: relative;
    overflow: hidden;
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 9;
    }
    & > * {
        filter: blur(8px);
    }
    @media (min-width: ${breakpoints.md}) {
        grid-template-columns: 60% auto;
        grid-template-areas:
            "chart1 map"
            "chart2 map"
            "chart3 map";
    }
    @media (min-width: ${breakpoints.xxl}) {
        grid-template-columns: 50% 50%;
    }
`;
