import {
    addYears,
    format,
    getQuarter,
    getYear,
    Locale,
    startOfMonth,
    startOfQuarter,
    sub,
    subQuarters,
    subYears
} from "date-fns";
import {enGB, pl} from "date-fns/locale";
import {i18n} from "i18next";

const getCurrentLocale = (i18n?: i18n): {locale: Locale} => {
    switch (i18n?.language) {
        case "en": {
            return {locale: enGB};
        }
        case "pl":
        default: {
            return {locale: pl};
        }
    }
};

export const originDate = "2019-01-01";
export const apiDateFormat = "yyyy-MM-dd";
export const shortDate = "dd.MM.yyyy";
export const apiDateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
export const humanDateFormatLong = "LLLL yyyy";
export const humanDateFormatShort = "LLL yy";

export const getStartOfMonth = (date: Date) => startOfMonth(new Date(date));

export const substractOneMonth = (date: string, i18n?: i18n): string => {
    // substract one month
    const lastMonth = sub(new Date(date), {months: 1});
    // format the date as string
    return format(lastMonth, apiDateFormat, getCurrentLocale(i18n));
};

export const substractOneYear = (date: string, i18n?: i18n): string => {
    // substract one year
    const lastYear = sub(new Date(date), {months: 11});
    // format the date as string
    return format(lastYear, apiDateFormat, getCurrentLocale(i18n));
};

// subtract TWO months cause we consider the FINISHED month, which is already one month before actual date (so last-finished-quarter is 3 months before)
export const lastFinishedQuarter = (lastFinishedMonth: string): string => {
    const quarterStart = startOfQuarter(sub(new Date(lastFinishedMonth), {months: 2}));
    // format the date as ISO string
    // NOTE: I see no reason to handle other locales than "pl", we should have consistent timeframe regardless of user locales
    return format(quarterStart, apiDateFormat, getCurrentLocale());
};

export const formatDate = (date: string | Date, targetFormat: string = apiDateFormat, i18n?: i18n): string => {
    const dateToFormat = date instanceof Date ? date : new Date(date);
    return format(dateToFormat, targetFormat, getCurrentLocale(i18n));
};

export const getRecentQuartersNames = (date: Date, quartersCount: number): string[] => {
    return new Array(quartersCount)
        .fill(null)
        .map((_: null, i) => {
            const currentQuarter = subQuarters(date, i);
            const currentQuarterIndex = getQuarter(currentQuarter);
            const currentQuarterYear = getYear(currentQuarter);
            return `Q${currentQuarterIndex} ${currentQuarterYear}`;
        })
        .reverse();
};

export const getNeighbouringYears = (date: Date, yearsCount: number): string[] => {
    if (yearsCount % 2 === 0) {
        throw new Error("Number of provided years should be odd");
    }
    const middleYearIndex = parseInt((yearsCount / 2).toPrecision(1));
    const yearsDiff = middleYearIndex - 1;
    const years: string[] = [];
    for (let i = yearsDiff; i > 0; i--) {
        years.push(getYear(subYears(date, i)).toString());
    }
    years.push(getYear(date).toString());
    for (let i = 1; i <= yearsDiff; i++) {
        years.push(getYear(addYears(date, i)).toString());
    }
    return years;
};
