import {css, Theme} from "@emotion/react";
import {Container} from "@pg-design/grid";
import {backgroundColor, flex, p, pb, resetWidth, w100} from "@pg-design/helpers-css";

import {NewshubTypeSwitch} from "./header/NewshubTypeSwitch";
import {NewshubBreadcrumbs} from "./NewshubBreadcrumbs";

export const NewshubHeader = () => {
    return (
        <Container fluid css={backgroundColor("#fff")}>
            <Container css={container}>
                <div css={breadcrumbs}>
                    <NewshubBreadcrumbs />
                </div>

                <NewshubTypeSwitch />
            </Container>
        </Container>
    );
};

const container = css`
    ${flex("center", "space-between")};
    flex-wrap: wrap;
    ${pb(2)};
`;

const breadcrumbs = (theme: Theme) => css`
    ${w100};
    ${p(2, 0, 4, 0)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${resetWidth};
        ${p(2, 0, 2, 0)};
    }
`;
