import {css} from "@emotion/react";
import {onDesktop} from "@pg-design/helpers-css";

export const resultsWrapper = css`
    overflow: auto;
    flex-shrink: 1;

    ${onDesktop(css`
        max-height: 29.8rem;
    `)}
`;
