import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {getEntriesApi} from "../../../newshub/api/get_entries";
import {IActionContext} from "../create_path_to_action";
import {setAuthAndDate} from "./common_action";

export const newshubLandingAction = async (ctx: IActionContext<ICityParams>): Promise<void> => {
    await setAuthAndDate(ctx);

    ctx.store.dispatch(getEntriesApi.endpoints.getHighlightedEntries.initiate());

    await Promise.all(ctx.store.dispatch(getEntriesApi.util.getRunningQueriesThunk()));
};
