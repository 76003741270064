import React from "react";
import {useTranslation} from "react-i18next";

import {ContactMessage} from "../../../auth/components/auth_modal/modal_thanks/ContactMessage";
import {IRegisterFormSubmissionResult} from "./register_form.types";

import prPerson from "../../assets/mikolaj_ostrowski.jpg";
import salesPerson from "../../assets/ola.jpg";

interface IProps {
    registerResult: IRegisterFormSubmissionResult;
}

export const RegisterFormSuccessMessage = (props: IProps) => {
    const {registerResult} = props;
    const {t} = useTranslation();

    return (
        <>
            {registerResult === "nonJournalistUser" ? (
                <ContactMessage
                    title={t("auth.data_access_verification_submission")}
                    message={t("auth.data_access_verification_submission_explainer")}
                    personPicture={salesPerson}
                    personName="Aleksandra Boratyńska"
                    personRole={t("common.customer_consultant")}
                    personPhone="+48 532 758 886"
                    personEmail="a.boratynska@rynekpierwotny.pl"
                />
            ) : (
                <ContactMessage
                    title={t("auth.data_access_verification_submission")}
                    message={t("auth.data_access_verification_submission_explainer")}
                    personPicture={prPerson}
                    personName="Mikołaj Ostrowski"
                    personRole={t("common.pr_manager")}
                    personPhone="+48 530 619 988"
                    personEmail="pr@rynekpierwotny.pl"
                />
            )}
        </>
    );
};
