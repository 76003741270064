import {useTranslation} from "react-i18next";
import {css, Theme} from "@emotion/react";
import {Col} from "@pg-design/grid";
import {calculateRemSize, flexJustifyCenter, mb, pb, pt, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {mediaBox} from "../views/Contact";
import {ContactPerson} from "./ContactPerson";

import ola from "../../features/users/assets/ola.jpg";

interface IContactListProps {
    title?: string;
    className?: string;
    isMonitoring?: boolean;
}

export const ContactList = ({title, isMonitoring, className}: IContactListProps) => {
    const {t} = useTranslation();

    const contactPeople = [
        {
            name: "Aleksandra Boratyńska",
            title: t("common.customer_consultant"),
            pictureUrl: ola,
            telephone: "+48 532 758 886",
            email: "a.boratynska@rynekpierwotny.pl"
        }
    ];

    return (
        <Col css={wrap} noGutters className={className}>
            {title && (
                <Text variant="headline_1" align="center" css={[mb(6), w100]} as="h3">
                    {title}
                </Text>
            )}

            {contactPeople.map((person, i) => (
                <Col sm={4} md={isMonitoring ? 12 : 6} lg={isMonitoring ? 16 : 8} key={i} css={contactPersonWrapper}>
                    <ContactPerson
                        css={mediaBox}
                        email={person.email}
                        pictureUrl={person.pictureUrl}
                        name={person.name}
                        telephone={person.telephone}
                        title={person.title}
                    />
                </Col>
            ))}
        </Col>
    );
};

const wrap = css`
    position: relative;
    flex-wrap: wrap;
    row-gap: ${calculateRemSize(3)};
    ${flexJustifyCenter}
`;

const contactPersonWrapper = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        &:first-of-type > div {
            ${pt(5)};
        }

        &:last-of-type > div {
            ${pb(3)};
        }

        &:not(:first-of-type) > div > p {
            ${mb(0)};
        }
    }
`;
