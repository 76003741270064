import {css} from "@emotion/react";
import {Pill} from "@pg-design/pill";

import {truncate} from "../../../../../components/css_helpers/truncate";

interface IProps {
    pillLabel: string;
    onRemoveResult: () => void;
}

export const SearchResultPill = (props: IProps) => {
    return (
        <div>
            <Pill
                size="large"
                css={regionBadge}
                onCloseClick={() => {
                    props.onRemoveResult();
                }}
            >
                <div css={[titleHolder, truncate]}>{props.pillLabel}</div>
            </Pill>
        </div>
    );
};

const titleHolder = css`
    flex-grow: 1;
    padding-right: 1rem;
    padding-left: 1rem;
`;

const regionBadge = css`
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
