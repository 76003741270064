import {css} from "@emotion/react";

export const truncate = css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const truncateMultiline = (lines: number) => css`
    ${truncate};
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    white-space: normal;
`;
