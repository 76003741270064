import {mb} from "@pg-design/helpers-css";
import {Modal} from "@pg-design/modal";
import {Text} from "@pg-design/text";

import {RodoTerms} from "../../../../../components/RodoTerms";
import {ContactPerson, IContactPersonProps} from "./ContactPerson";

interface IProps {
    title: string;
    personPicture: IContactPersonProps["picture"];
    personName: IContactPersonProps["name"];
    personRole: IContactPersonProps["role"];
    personPhone: IContactPersonProps["phone"];
    personEmail: IContactPersonProps["email"];
    message?: string;
}

export const ContactMessage = (props: IProps) => {
    const {title, message, personPicture, personName, personRole, personPhone, personEmail} = props;

    return (
        <>
            <Modal.Header>
                <Text css={mb(3)} variant="headline_6" as="span" align="center">
                    {title}
                </Text>
            </Modal.Header>

            <Modal.Content>
                {message && (
                    <Text variant="body_copy_1" align="center" css={mb(3)}>
                        {message}
                    </Text>
                )}

                <ContactPerson
                    css={mb(3)}
                    picture={personPicture}
                    name={personName}
                    role={personRole}
                    phone={personPhone}
                    email={personEmail}
                />

                <RodoTerms />
            </Modal.Content>
        </>
    );
};
