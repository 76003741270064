import {lazy, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Options, SeriesLineOptions} from "highcharts";

import {formatDate, humanDateFormatShort} from "../../../../utils/dates";
import {formatNumberGreaterThan1000} from "../../../../utils/format";
import {useTypeFilteredData} from "../../../../utils/hooks/useTypeFilteredData";
import {formatSharedTooltip} from "../../../../utils/tooltips";
import {cityColors} from "../../common/app/constants/colors";
import {FilterOfferType} from "../../common/app/constants/enums";
import {IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";

const Chart = lazy(() => import("./ChartDefaultExport"));

// @TODO any
interface IProps extends IChartProps {
    data: any[];
    dataField: string;
    offerType?: FilterOfferType;
    unit?: string;
    valueMultiplier?: number;
    roundValues?: boolean;
}

export const MultilineSharedChart = (props: IProps) => {
    const {data, dataField, offerType, unit, valueMultiplier = 1, roundValues} = props;
    const {t, i18n} = useTranslation();
    const filteredData = useTypeFilteredData(data, offerType);
    const highlightedPoint = useRef<number | undefined>(undefined);
    const series: SeriesLineOptions[] = useMemo(() => {
        if (filteredData && filteredData.length > 0) {
            const newSeries: SeriesLineOptions[] = [];

            for (const month of filteredData) {
                const newSeriesIndex = newSeries.findIndex((el) => el.id === month.slug_city);
                if (newSeriesIndex >= 0) {
                    const value = month[dataField] * valueMultiplier;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    newSeries[newSeriesIndex].data.push(value);
                } else {
                    newSeries.push({
                        id: month.slug_city,
                        color: cityColors[month.slug_city],
                        data: [month[dataField] * valueMultiplier],
                        name: t(`city.${month.slug_city}.nominative`),
                        type: "line"
                    });
                }
            }
            return newSeries;
        }

        return [];
    }, [filteredData, i18n.language]);

    const cats = new Set();
    filteredData.forEach((chunk) => cats.add(chunk.date));
    const categories = Array.from(cats) as string[];

    const options: Options = {
        legend: {
            enabled: true
        },
        plotOptions: {
            line: {
                point: {
                    events: {
                        mouseOver: function (this, e) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore Property 'y' does not exist on type 'EventTarget
                            e.target?.y
                                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore Property 'y' does not exist on type 'EventTarget
                                  (highlightedPoint.current = e.target.y)
                                : (highlightedPoint.current = undefined);
                        }
                    }
                }
            }
        },
        tooltip: {
            className: "multiline-tooltip",
            formatter: function () {
                return formatSharedTooltip(this, highlightedPoint.current || 0, roundValues, i18n, unit);
            },
            shared: true
        },
        xAxis: {
            categories,
            labels: {
                formatter: function () {
                    return formatDate(this.value.toString(), humanDateFormatShort, i18n);
                }
            },
            visible: true
        },
        yAxis: {
            visible: true,
            labels: {
                formatter: function () {
                    return formatNumberGreaterThan1000(this.value as number); // @TODO fix number assertion
                }
            }
        }
    };

    return (
        <SuspendedChart>
            <Chart {...props} customOptions={options} series={series} />
        </SuspendedChart>
    );
};
