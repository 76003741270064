import {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {
    calculateRemSize,
    flex,
    flexAbsoluteCenter,
    flexDirection,
    flexJustifyCenter,
    h100,
    imageResponsive,
    mb,
    mt,
    mv,
    onDesktop,
    pt
} from "@pg-design/helpers-css";
import {ChevronLeftIcon, ChevronRightIcon, InfoIcon} from "@pg-design/icons";
import {Image as PgImage} from "@pg-design/image";
import {Modal} from "@pg-design/modal";
import {Text} from "@pg-design/text";
import {i18n} from "i18next";

import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {ZIndex} from "../../../../common/app/constants/z_index";
import {flexGap} from "../../../../components/css_helpers/flex";
import {selectAuthStatus} from "../../../auth/auth_slice";
import {useUpdateCurrentUserMutation} from "../../../users/api/updateCurrentUser";
import {selectUserData} from "../../../users/users_slice";
import {selectOnboardingState, toggleOnboarding} from "../../redux/onboarding_slice";

const nawigacjaPL = require("../../../../common/app/assets/onboarding/szybkanawigacja_PL.gif");
const nawigacjaEN = require("../../../../common/app/assets/onboarding/szybkanawigacja_ENG.gif");
const legendaWykresowPL = require("../../../../common/app/assets/onboarding/interaktywnywykres_PL.gif");
const legendaWykresowEN = require("../../../../common/app/assets/onboarding/interaktywnywykres_ENG.gif");
const metodologiaPL = require("../../../../common/app/assets/onboarding/metodologia_PL.gif");
const metodologiaEN = require("../../../../common/app/assets/onboarding/metodologia_ENG.gif");
const eksportPL = require("../../../../common/app/assets/onboarding/pobieranie_PL.gif");
const eksportEN = require("../../../../common/app/assets/onboarding/pobieranie_ENG.gif");
const mapaPL = require("../../../../common/app/assets/onboarding/rodzajeMapy_PL.gif");
const mapaEN = require("../../../../common/app/assets/onboarding/rodzajemapy_ENG.gif");
const legendaMapyPL = require("../../../../common/app/assets/onboarding/legenda_PL.gif");
const legendaMapyEN = require("../../../../common/app/assets/onboarding/legenda_ENG.gif");

const animations = [
    nawigacjaPL,
    nawigacjaEN,
    legendaWykresowPL,
    legendaWykresowEN,
    metodologiaPL,
    metodologiaEN,
    eksportPL,
    eksportEN,
    mapaPL,
    mapaEN,
    legendaMapyPL,
    legendaMapyEN
];

export const Onboarding = () => {
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const [currentStep, setCurrentStep] = useState<number | null>(null);
    const userData = useAppSelector(selectUserData);
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const [cookies, setCookie] = useCookies(["onboarded", "bd_csrftoken", "bd_sessionid"]);
    const isOnboardingOpen = useAppSelector(selectOnboardingState);
    const dispatch = useAppDispatch();
    const [updateCurrentUser, _] = useUpdateCurrentUserMutation();
    const onboarded = cookies.onboarded === "1" || userData?.onboarded === true;
    const steps = getLocalizedSteps(i18n);

    // Display the onboarding modal if relevant cookie or user setting is not set
    useEffect(() => {
        if (!onboarded && !isOnboardingOpen) {
            // setIsModalOpened(true);
            dispatch(toggleOnboarding(true));
        }
    }, [isOnboardingOpen, onboarded]);

    // Preload all the images upfront to prevent modal flickering; disable scrolling while modal is opened
    useEffect(() => {
        const htmlClasses = document.documentElement.classList;
        const scrollingDisabledClassName = "scrolling-disabled";

        if (isOnboardingOpen) {
            animations.forEach((animation: string) => {
                new Image().src = animation;
            });

            htmlClasses.add(scrollingDisabledClassName);
        } else {
            htmlClasses.remove(scrollingDisabledClassName);
        }
    }, [isOnboardingOpen]);

    const handleButtonClick = (targetStep: number) => {
        if (targetStep < 0) {
            setCurrentStep(null);
        } else if (targetStep === steps.length) {
            handleModalClosing();
        } else {
            setCurrentStep(targetStep);
        }
    };

    const handleModalClosing = async () => {
        dispatch(toggleOnboarding(false));
        setCookie("onboarded", "1", {path: "/"});

        if (isLoggedIn && !userData?.onboarded) {
            await updateCurrentUser({onboarded: true}).unwrap();
        }
    };

    return (
        <Modal isOpen={isOnboardingOpen} onModalClose={handleModalClosing} zIndex={ZIndex.MODAL}>
            {currentStep === null && (
                <Modal.Content css={[onboardingModalContentIntro]}>
                    <div css={[introTextWrapper, h100, flexAbsoluteCenter, flexDirection("column")]}>
                        <div css={[iconWrapper, mt(8)]}>
                            <InfoIcon size="12.8" />
                        </div>

                        <Text variant="headline_3" as="p" align="center" css={mb(4)}>
                            {t("onboarding.intro.title", {stepsCount: steps.length})}
                        </Text>

                        <Text variant="body_copy_1" color={theme.colors.gray[700]} align="center" css={mb(8)}>
                            {t("onboarding.intro.description")}
                        </Text>

                        <Button
                            variant="filled_primary"
                            size="medium"
                            css={[mb(8), buttonWidth]}
                            onClick={() => handleButtonClick(0)}
                        >
                            {t("onboarding.button.start")}
                        </Button>
                    </div>
                </Modal.Content>
            )}

            {currentStep !== null && (
                <>
                    <Modal.Header>
                        <Text variant="info_txt_1" as="p" align="center" color={theme.colors.gray[700]}>
                            {`${currentStep + 1} / ${steps.length}`}
                        </Text>
                    </Modal.Header>

                    <Modal.Content css={onboardingModalContent}>
                        <Text variant="headline_3" align="center" css={mt(2)}>
                            {steps[currentStep].title}
                        </Text>

                        <Text variant="body_copy_2" color={theme.colors.gray[700]} align="center" css={[mv(3)]}>
                            {steps[currentStep].description}
                        </Text>

                        <PgImage
                            src={steps[currentStep].image}
                            alt={`${steps[currentStep].title} - ${steps[currentStep].description}`}
                            height={steps[currentStep].imageHeight}
                            width={steps[currentStep].imageWidth}
                            css={onBoardingImage}
                        />

                        <div
                            css={[
                                flex("center", "center"),
                                flexDirection("column"),
                                flexGap(4),
                                mt(4),
                                mb(0.5),
                                onDesktop(flexDirection("row"))
                            ]}
                        >
                            {currentStep < steps.length - 1 ? (
                                <>
                                    <Button
                                        variant="outlined_secondary"
                                        onClick={() => handleButtonClick(currentStep - 1)}
                                        iconLeft={ChevronLeftIcon}
                                        size="small"
                                        css={buttonWidth}
                                    >
                                        {t("common.previous")}
                                    </Button>

                                    <Button
                                        variant="filled_primary"
                                        onClick={() => handleButtonClick(currentStep + 1)}
                                        iconRight={ChevronRightIcon}
                                        size="small"
                                        css={buttonWidth}
                                    >
                                        {t("common.next")}
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    variant="filled_primary"
                                    size="small"
                                    onClick={() => handleButtonClick(steps.length)}
                                    css={buttonWidth}
                                >
                                    {t("common.finish")}
                                </Button>
                            )}
                        </div>
                    </Modal.Content>
                </>
            )}
        </Modal>
    );
};

interface IStep {
    title: string;
    description: string;
    image: string;
    imageHeight: string;
    imageWidth: string;
}

const getLocalizedSteps = (i18n: i18n): IStep[] => [
    {
        title: i18n.t("onboarding.step1.title"),
        description: i18n.t("onboarding.step1.description"),
        image: i18n.language === "pl" ? nawigacjaPL : nawigacjaEN,
        imageWidth: "600",
        imageHeight: "340"
    },
    {
        title: i18n.t("onboarding.step2.title"),
        description: i18n.t("onboarding.step2.description"),
        image: i18n.language === "pl" ? legendaWykresowPL : legendaWykresowEN,
        imageWidth: "600",
        imageHeight: "266"
    },
    {
        title: i18n.t("onboarding.step3.title"),
        description: i18n.t("onboarding.step3.description"),
        image: i18n.language === "pl" ? metodologiaPL : metodologiaEN,
        imageWidth: "600",
        imageHeight: "332"
    },
    {
        title: i18n.t("onboarding.step4.title"),
        description: i18n.t("onboarding.step4.description"),
        image: i18n.language === "pl" ? eksportPL : eksportEN,
        imageWidth: "600",
        imageHeight: "358"
    },
    {
        title: i18n.t("onboarding.step5.title"),
        description: i18n.t("onboarding.step5.description"),
        image: i18n.language === "pl" ? mapaPL : mapaEN,
        imageWidth: "600",
        imageHeight: "422"
    },
    {
        title: i18n.t("onboarding.step6.title"),
        description: i18n.t("onboarding.step6.description"),
        image: i18n.language === "pl" ? legendaMapyPL : legendaMapyEN,
        imageWidth: "600",
        imageHeight: "333"
    }
];

const onboardingModalContent = css`
    ${h100};

    & > div {
        ${h100};
    }

    ${onDesktop(css`
        width: var(--modal-width-medium);
    `)};
`;

const onboardingModalContentIntro = css`
    ${flexJustifyCenter};
    ${pt(3)};
    ${onboardingModalContent};
    flex-direction: row;
`;

const onBoardingImage = css`
    & > img {
        ${imageResponsive};
    }
`;

const iconWrapper = css`
    ${flexAbsoluteCenter};
    ${mb(8)};
    height: ${calculateRemSize(16)};
`;

const introTextWrapper = css`
    max-width: 31.4rem;
`;

const buttonWidth = css`
    min-width: 20rem;
`;
