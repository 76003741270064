import {CitySlug, mapParamSlugToCitySlug} from "../../../../../../config/cities";
import {rpWebUrl} from "../../../../common/app/read_charts_web_environment_variables";
import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {setAppStatus} from "../../../app_status/app_status_slice";
import {IActionContext} from "../create_path_to_action";
import {ResponseStatus} from "../state/app_status/IAppStatusState";
import {setAuthAndUser} from "./set_auth_and_user";

export const rpFlatPricesRedirectAction = async (ctx: IActionContext<ICityParams>) => {
    await setAuthAndUser(ctx.store);

    const {city} = ctx.match.params;
    const citySlug = mapParamSlugToCitySlug(city);
    const pathname = ctx.route.pathname;

    if (city === CitySlug.KATOWICE) {
        ctx.store.dispatch(
            setAppStatus({
                responseStatus: ResponseStatus.REDIRECT_302,
                url: pathname.includes("ceny-mieszkan")
                    ? pathname.replace("ceny-mieszkan", "ceny/mieszkania")
                    : pathname.replace("flats-prices", "prices/flats")
            })
        );
        return;
    }
    ctx.store.dispatch(
        setAppStatus({
            responseStatus: ResponseStatus.REDIRECT_301,
            url: `${rpWebUrl}/ceny/mieszkania/${citySlug}/`
        })
    );
};
