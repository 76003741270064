import {useEffect} from "react";

import {dataLayer} from "../../../../../utils/data_layer";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {logoutUserComHit} from "../../tracking/user_com/hit_user_com";
import {useLogoutMutation} from "../api/logoutApi";
import {setCookies} from "../api/setCookies";
import {selectAuthStatus} from "../auth_slice";

export const useLogout = (onLogout?: () => void) => {
    const [logout, logoutMutation] = useLogoutMutation();
    const {isLoggedIn} = useAppSelector(selectAuthStatus);

    useEffect(() => {
        if (logoutMutation.isSuccess && isLoggedIn) {
            dataLayer({
                event: "logout",
                user_id: ""
            });
            // User.com
            logoutUserComHit();
        }

        if (logoutMutation.isSuccess && isLoggedIn && onLogout) {
            onLogout();
        }

        if (logoutMutation.isSuccess) {
            setCookies();
        }
    }, [logoutMutation.isSuccess]);

    return () => {
        logout();
    };
};
