import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {useHistory, useLocation} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Datepicker} from "@pg-design/datepicker";
import {Col, Container, Row} from "@pg-design/grid";
import {
    backgroundColor,
    borderRadius,
    calculateRemSize,
    flex,
    flexAbsoluteCenter,
    flexDirection,
    h100,
    mb,
    mv,
    onDesktop,
    pl,
    resetWidth,
    w100
} from "@pg-design/helpers-css";
import {Highlight, Text} from "@pg-design/text";
import {compareAsc, format} from "date-fns";
import {pl as localePl} from "date-fns/locale";

import {appendQueryString} from "../../../../../utils/append_query_string";
import {capitalize} from "../../../../../utils/misc";
import {validateNewshubParams} from "../../../../../utils/validate_newshub_params";
import {chartsRoutes} from "../../../common/app/routing/charts_routes";
import {withGTM} from "../../../components/hoc/withGTM";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {Newsletter} from "../../../components/Newsletter";
import {useGetCategoriesQuery} from "../api/get_categories";
import {
    ProvincialCity,
    useGetArticlesByCategoryQuery,
    useGetHighlightedArticlesByCategoryQuery
} from "../api/get_entries";
import {HeadingWithDescription} from "../components/header/HeadingWithDescription";
import {EntryCategories} from "../components/market_information/EntryCategories";
import {PaginatedArticleList} from "../components/market_information/listing/PaginatedArticleList";
import {NewsroomEmailCard} from "../components/market_information/NewsroomEmailCard";
import {DesktopBlend} from "../components/market_information/search/DesktopBlend";
import {RegionSearch} from "../components/market_information/search/RegionSearch";
import {VendorAutoCompleteSearch} from "../components/market_information/search/VendorAutoCompleteSearch";
import {NewshubBreadcrumbs} from "../components/NewshubBreadcrumbs";

export const MarketInformation = withGTM(() => {
    const {search: urlSearch, pathname} = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(urlSearch), [urlSearch]);
    const {data: categories} = useGetCategoriesQuery();
    const [isDesktopSearchBlendActive, setDesktopSearchBlendActive] = useState(false);
    const city = searchParams.get("city");
    const vendor = searchParams.get("vendor");
    const page = searchParams.get("page");
    const dateFrom = searchParams.get("date_from");
    const dateTo = searchParams.get("date_to");
    const search = searchParams.get("search");
    const {pageParam, vendorParam, cityParam, dateFromParam, dateToParam, searchParam} = validateNewshubParams(
        page,
        vendor,
        city,
        dateFrom,
        dateTo,
        search
    );
    const [searchValue, setSearchValue] = useState(searchParam);

    const goTo = ({
        path,
        page,
        vendor,
        city,
        dateFrom,
        dateTo,
        search
    }: {
        path?: string;
        page?: number;
        vendor?: number;
        city?: ProvincialCity;
        dateFrom?: string;
        dateTo?: string;
        search?: string;
    }) => {
        history.push(
            appendQueryString(path || pathname, {
                ...(vendor ? {vendor: vendor} : {vendor: vendorParam}),
                ...(city ? {city: city} : {city: cityParam}),
                ...(page && page > 1 ? {page: page} : {}),
                ...(dateFrom ? {date_from: dateFrom} : {date_from: dateFromParam}),
                ...(dateTo ? {date_to: dateTo} : {date_to: dateToParam}),
                ...(search ? {search: search} : {search: searchParam})
            })
        );
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    };

    const clearParam = (param: string) => () => {
        searchParams.delete(param);
        history.push(`${pathname}?${searchParams.toString()}`);
    };

    const {marketInfoSubCategorySlug, marketInfoCategorySlug} = useParams<{
        marketInfoSubCategorySlug: string;
        marketInfoCategorySlug: string;
    }>();
    const history = useHistory();

    const mainCategoryId =
        categories && categories.results
            ? categories?.results?.find((data) => data.slug === marketInfoCategorySlug)
            : null;

    const subCategoryId =
        mainCategoryId && mainCategoryId.subcategories
            ? mainCategoryId?.subcategories.find((subcategory) => subcategory.slug === marketInfoSubCategorySlug)
            : null;

    const selectedCategoryId = subCategoryId?.id ?? mainCategoryId?.id;

    const {data: articles, isLoading: areArticlesLoading} = useGetArticlesByCategoryQuery({
        category: selectedCategoryId ?? -1,
        page: pageParam,
        vendorId: vendorParam,
        regionId: cityParam,
        dateFrom: dateFromParam,
        dateTo: dateToParam,
        search: searchParam
    });

    const {data: articlesHighlighted} = useGetHighlightedArticlesByCategoryQuery({category: selectedCategoryId ?? -1});

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
        if (marketInfoCategorySlug) {
            setSearchValue("");
        }
    }, [marketInfoCategorySlug, marketInfoSubCategorySlug]);

    useEffect(() => {
        if (marketInfoCategorySlug !== "deweloperzy") {
            goTo({page: pageParam, city: cityParam});
        }

        if (marketInfoCategorySlug !== "deweloperzy" && marketInfoCategorySlug !== "infrastruktura") {
            goTo({page: pageParam, vendor: vendorParam});
        }
    }, [marketInfoCategorySlug]);

    useEffect(() => {
        if (dateFrom && dateTo && compareAsc(new Date(dateFrom), new Date(dateTo)) === 1) {
            goTo({dateFrom: format(new Date(dateTo), "yyyy-MM-dd")});
        }
    }, [dateFrom, dateTo]);

    const onValueSearched = () => {
        if (!searchValue) {
            return;
        }
        //all other params need to be cleared and search value set before routing
        ["vendor", "city", "date_from", "date_to"].forEach((param) => searchParams.delete(param));
        searchParams.set("search", searchValue);
        history.push(`${chartsRoutes.pl.newshub.marketInformation.root}?${searchParams.toString()}`);
    };

    return (
        <SimpleContainer>
            <Container as="article">
                {categories && <NewshubBreadcrumbs css={mv(2)} categories={categories.results} />}

                {searchParam ? (
                    <Text css={mb(5)} variant="headline_1">
                        Wyniki wyszukiwania dla frazy &quot;<Highlight>{searchValue}</Highlight>&quot;
                    </Text>
                ) : (
                    <HeadingWithDescription
                        heading={capitalize(marketInfoCategorySlug?.replace(/-/g, " ") || "Newsy")}
                        description="Dołącz do kilkutysięcznej grupy profesjonalistów rynku mieszkaniowego i korzystaj z najnowszych informacji branżowych."
                    />
                )}

                <Row>
                    {categories && (
                        <>
                            <Col lg={5} md={4} sm={4}>
                                <div css={textSearchWrapper}>
                                    <input
                                        css={textSearchInput}
                                        value={searchValue}
                                        onKeyPress={({code}) => code === "Enter" && onValueSearched()}
                                        onChange={({target: {value}}) => {
                                            if (!value) {
                                                clearParam("search")();
                                                setSearchValue("");
                                                return;
                                            }
                                            setSearchValue(value);
                                        }}
                                        placeholder="Wpisz szukaną frazę"
                                        type="text"
                                    />
                                    <Button css={textSearchButton} onClick={onValueSearched} variant="filled_primary">
                                        Szukaj
                                    </Button>
                                </div>
                                <EntryCategories categories={categories.results} />
                            </Col>

                            <Col lg={11} md={8} sm={4}>
                                <div css={searchWrapper}>
                                    {marketInfoCategorySlug === "deweloperzy" && (
                                        <VendorAutoCompleteSearch
                                            css={mb(3)}
                                            setDesktopSearchBlendActive={setDesktopSearchBlendActive}
                                            selectedVendor={vendorParam ?? -1}
                                            setSelectedVendor={(value) =>
                                                goTo({
                                                    vendor: Number(value),
                                                    page: 1
                                                })
                                            }
                                            removeVendor={clearParam("vendor")}
                                        />
                                    )}
                                    {(marketInfoCategorySlug === "deweloperzy" ||
                                        marketInfoCategorySlug === "infrastruktura") && (
                                        <RegionSearch
                                            fullWidth={marketInfoCategorySlug === "infrastruktura"}
                                            css={mb(3)}
                                            setDesktopSearchBlendActive={setDesktopSearchBlendActive}
                                            cityCode={cityParam}
                                            setCityCode={(value) =>
                                                goTo({
                                                    city: value,
                                                    page: 1
                                                })
                                            }
                                            removeCity={clearParam("city")}
                                        />
                                    )}
                                    <div css={dateRangeWrapper}>
                                        <div>
                                            <Datepicker
                                                locale={localePl}
                                                placeholderText="Od"
                                                css={datepicker}
                                                selected={dateFromParam ? new Date(dateFromParam) : null}
                                                onChange={(date) => {
                                                    if (!date) {
                                                        clearParam("date_from")();
                                                        return;
                                                    }
                                                    goTo({dateFrom: format(date, "yyyy-MM-dd")});
                                                }}
                                                showPopperArrow={false}
                                            />
                                        </div>
                                        <div>
                                            <Datepicker
                                                locale={localePl}
                                                placeholderText="Do"
                                                css={datepicker}
                                                selected={dateToParam ? new Date(dateToParam) : null}
                                                onChange={(date) => {
                                                    if (!date) {
                                                        clearParam("date_to")();
                                                        return;
                                                    }
                                                    goTo({dateTo: format(date, "yyyy-MM-dd")});
                                                }}
                                                showPopperArrow={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <PaginatedArticleList
                                    page={pageParam}
                                    setPage={(value) =>
                                        goTo({
                                            page: value
                                        })
                                    }
                                    areArticlesLoading={areArticlesLoading}
                                    articles={articles}
                                    highlightedArticles={articlesHighlighted}
                                    selectedCategoryId={selectedCategoryId}
                                    vendorId={vendorParam?.toString() ?? ""}
                                    showHighlighted={
                                        !vendorParam && !cityParam && !dateFromParam && !dateToParam && !searchParam
                                    }
                                />
                                <div css={mobile}>
                                    <NewsroomEmailCard />
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
            </Container>
            <Newsletter />

            <DesktopBlend
                isActive={isDesktopSearchBlendActive}
                isFullScreen={false}
                top="19rem"
                height="(19rem + 60px)"
            />
        </SimpleContainer>
    );
});

const mobile = css`
    display: block;

    ${onDesktop(css`
        display: none;
    `)};
`;

const textSearchWrapper = css`
    height: ${calculateRemSize(6)};
    ${w100};
    ${flexAbsoluteCenter};
`;

const textSearchInput = (theme: Theme) => css`
    ${pl(2)};
    width: 70%;
    ${h100};
    ${borderRadius(2, 0, 0, 2)};
    border: none;

    :focus {
        outline: none;
        border: 1px solid ${theme.colors.info};
    }
`;

const textSearchButton = css`
    width: 30%;
    ${h100};
    ${borderRadius(0, 2, 2, 0)};
`;

const searchWrapper = css`
    ${w100};
    ${flex()};
    ${flexDirection("column")};

    ${onDesktop(css`
        ${flexDirection("row")};
        gap: ${calculateRemSize(3)};
    `)};

}
`;

const dateRangeWrapper = css`
    ${mb(3)};
    ${onDesktop(resetWidth)};
    ${flex()};
    gap: ${calculateRemSize(3)};

    .react-datepicker__tab-loop {
        position: absolute;
    }
`;

const datepicker = css`
    height: ${calculateRemSize(6)};
    ${backgroundColor("#fff")};

    ${onDesktop(css`
        width: ${calculateRemSize(18)};
    `)}
`;
