import {i18n} from "i18next";

import {translateBackendValidationString} from "./translate_backend_validation_string";

//  This function is meant to convert errors we receive from api to usable, translated string

export function getPayloadError(errorData: string | string[] | undefined, i18n: i18n) {
    if (errorData && typeof errorData === "string") {
        return translateBackendValidationString(errorData, i18n);
    }

    if (errorData && typeof errorData === "object" && errorData.length > 1) {
        const strings = errorData.map((errorString) => translateBackendValidationString(errorString, i18n));
        return strings.join(", ");
    }

    if (errorData && typeof errorData === "object" && errorData.length === 1) {
        return translateBackendValidationString(errorData[0], i18n);
    }
}

export interface ICommonApiErrorData {
    [index: string]: string | string[] | undefined;
}
