import {FormikHelpers} from "formik";

import {bugsnagNotify} from "../../../../utils/bugsnag";

export const handleFormikSubmit = async <ReturnedValueType, FormFieldsType, ErrorType = Error>(
    submitResult: Promise<ReturnedValueType> & {unwrap?: never}, //force unwrapping of rtk-query returned values
    helpers: FormikHelpers<FormFieldsType>,
    callbacks?: {
        onSuccess?: (response: ReturnedValueType) => unknown;
        onError?: (err: ErrorType) => unknown;
    }
) => {
    submitResult
        .then((res) => callbacks?.onSuccess && callbacks.onSuccess(res))
        .catch((err) => {
            helpers.setSubmitting(false);
            helpers.setErrors(err.data);
            bugsnagNotify(err, {source: "handleFormikSubmit"});
            callbacks?.onError && callbacks.onError(err);
        });
};
