export const capitalize = (sentence: string): string => sentence.charAt(0).toUpperCase() + sentence.slice(1);

export const isLinkExternal = (url: string): boolean => {
    return url.startsWith("https://") || url.startsWith("https://");
};

// @TODO any, rekursywne sprawdzanie obiektów
export const proxyNullValues = (data: any) => {
    return new Proxy(data, {
        get(target, key) {
            if (key in target) {
                const currentTarget = target[key];
                for (const value in currentTarget) {
                    if (typeof currentTarget[value] === "number" && currentTarget[value] <= 0) {
                        currentTarget[value] = Number.MIN_VALUE;
                    }
                }
                return currentTarget;
            }
        }
    });
};

export const sortByField = <D extends {}>(data: D[], field: keyof D): D[] =>
    data.sort((a, b) => {
        if (a[field] < b[field]) {
            return 1;
        }
        if (a[field] > b[field]) {
            return -1;
        }
        return 0;
    });

export const howManyTimesNumberGoesIntoAnotherEvenly = (total: number, partial: number): number =>
    Math.floor(total / partial);

export const findMaxValue = (values?: number[]): number => {
    if (values) {
        const max = Math.max(...values);

        return max >= 0 ? max : 0;
    }
    return 0;
};
export const stringRemoveAllOccurrences = (string: string, char: string): string => string.split(char).join("");
