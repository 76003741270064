import {initReactI18next} from "react-i18next";
import i18n, {InitOptions} from "i18next";
import {I18NextRequest} from "i18next-http-middleware";

import translationsEn from "../web/src/features/translations/en.json";
// import translationsPl from "../translations/pl/bigdata.json";
// import translationsEn from "../translations/en/bigdata.json";
import translationsPl from "../web/src/features/translations/pl.json";
const flagPl: string = require("../web/src/common/app/assets/flag-pl.svg");
const flagEn: string = require("../web/src/common/app/assets/flag-en.svg");

export enum Languages {
    PL = "pl",
    EN = "en"
}

export const availableLanguages = Object.values(Languages) as LanguageType[];

export type LanguageType = keyof typeof languages;

export interface ILanguage {
    label: string;
    switchToLabel: string;
    flag: string;
}

export const languages = {
    pl: {
        label: "Polski",
        switchToLabel: "Zmień na język polski",
        flag: flagEn
    } as ILanguage,
    en: {
        label: "English",
        switchToLabel: "Switch to english",
        flag: flagPl
    } as ILanguage
};

export const i18nextInitOptions: InitOptions = {
    // debug: true,
    fallbackLng: availableLanguages,
    supportedLngs: availableLanguages,
    preload: availableLanguages,
    lowerCaseLng: true,
    load: "languageOnly",
    ns: ["bigdata"],
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: false,
        transKeepBasicHtmlNodesFor: ["br", "strong", "i", "sup", "span", "p", "h1", "h2", "h3", "h4", "h5", "h6"]
    },
    resources: {
        pl: {
            bigdata: translationsPl
        },
        en: {
            bigdata: translationsEn
        }
    }
};

i18n.use(initReactI18next).init({...i18nextInitOptions});

export default i18n;

export const detectLanguageFromPathname = (url: string): LanguageType => {
    return url.startsWith("/en/") ? "en" : "pl";
};

type InitialI18nStoreType = {
    [key in Languages]?: any;
};

export const generateInitialI18nStore = (i18nRequest: I18NextRequest): InitialI18nStoreType => {
    // https://github.com/i18next/react-i18next/blob/master/example/razzle-ssr/src/server.js#L78
    const store: InitialI18nStoreType = {};
    // const usedNamespaces = i18nRequest.i18n.reportNamespaces.getUsedNamespaces();
    const usedNamespaces = i18nextInitOptions.ns as string[];

    i18nRequest.i18n.languages.forEach((language) => {
        store[language as Languages] = {};

        usedNamespaces.forEach((namespace) => {
            store[language as Languages][namespace] = i18nRequest.i18n.services.resourceStore.data[language][namespace];
        });
    });

    return store;
};
