import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {getBigDataApiPath} from "../../../../../utils/get_big_data_api_path";
import prepare_headers from "../../../store/prepare_headers";

export const publicationsApi = createApi({
    reducerPath: "publicationsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${getBigDataApiPath()}publications/`,
        prepareHeaders: prepare_headers,
        credentials: "include"
    }),
    tagTypes: ["Publication"],
    endpoints: () => ({})
});
