import {format, subMonths, subYears} from "date-fns";

import {AccessStatus, useAccessStatus} from "./useAccessStatus";

export const useAccessDate = (date: string) => {
    const accessDate = new Date(date);
    const accessStatus = useAccessStatus();
    if (accessStatus === AccessStatus.FULL) {
        return {
            upperBound: format(accessDate, "yyyy-MM-dd"),
            lowerBound: format(subYears(accessDate, 1), "yyyy-MM-dd")
        };
    }
    if (accessStatus === AccessStatus.LIMITED) {
        return {
            upperBound: format(subMonths(accessDate, 3), "yyyy-MM-dd"),
            lowerBound: format(subMonths(accessDate, 5), "yyyy-MM-dd")
        };
    }
    return {
        upperBound: format(subMonths(accessDate, 3), "yyyy-MM-dd"),
        lowerBound: format(subMonths(accessDate, 4), "yyyy-MM-dd")
    };
};
