import {lazy, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Options, SeriesColumnOptions} from "highcharts";

import {useTypeFilteredData} from "../../../../utils/hooks/useTypeFilteredData";
import {formatColumnStackedTooltip} from "../../../../utils/tooltips";
import {FilterOfferType} from "../../common/app/constants/enums";
import {IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";
import {withGroupRatioData} from "./withRatioData";

const Chart = lazy(() => import("./ChartDefaultExport"));

export interface IColumnStackedChartProps<D> extends IChartProps {
    data: D[];
    categories: string[];
    fieldGroups: string[][];
    offerType?: FilterOfferType;
    minValue?: number;
    logarithmicYAxis?: boolean;
    unit?: string;
    valueMultiplier?: number;
}

export const ColumnStackedChart = <D extends {}>(props: IColumnStackedChartProps<D>) => {
    const {data, categories, fieldGroups, offerType, minValue, logarithmicYAxis, unit, valueMultiplier = 1} = props;
    const {t} = useTranslation();
    const filteredData = useTypeFilteredData(data as any[], offerType);
    const [highlightedPointIndex, setHighlightedPointIndex] = useState<number | null>(null);
    const categoryLabels = categories.map((category) => t(`common.${category}`));
    const series: SeriesColumnOptions[] = useMemo(() => {
        if (filteredData && filteredData.length > 0) {
            return fieldGroups.map((fieldGroup, i) => ({
                name: categoryLabels[i],
                type: "column",
                borderWidth: 0,
                borderRadiusTopLeft: i === 0 ? 6 : 0,
                borderRadiusTopRight: i === 0 ? 6 : 0,
                // borderRadiusBottomLeft: i === fields.length - 1 ? 6 : 0,
                // borderRadiusBottomRight: i === fields.length - 1 ? 6 : 0,
                pointWidth: 14,
                data: fieldGroup.map((field) => ({
                    name: t(`fields.${field}`) as string,
                    y: filteredData[0][field] * valueMultiplier,
                    events: {
                        mouseOver: function () {
                            if (highlightedPointIndex !== this.y && this.y) {
                                setHighlightedPointIndex(this.y as number);
                            }
                        },
                        mouseOut: () => highlightedPointIndex && setHighlightedPointIndex(null)
                    }
                }))
            }));
        }
        return [];
    }, [filteredData]);

    const options: Options = {
        plotOptions: {
            column: {
                stacking: "normal"
            }
        },
        tooltip: {
            className: "shared-tooltip",
            formatter: function () {
                return formatColumnStackedTooltip(this, unit, highlightedPointIndex);
            },
            shared: true
        },
        xAxis: {
            categories: categoryLabels,
            visible: true
        },
        yAxis: {
            labels: {
                enabled: true,
                formatter: function () {
                    if (typeof this.value === "number" && this.isFirst && this.value < 1) {
                        return "0";
                    }
                    return this.value as string;
                }
            },
            visible: true,
            type: logarithmicYAxis ? "logarithmic" : "linear",
            min: minValue
        }
    };

    return (
        <SuspendedChart>
            <Chart {...props} customOptions={options} series={series} />
        </SuspendedChart>
    );
};

export const RatioColumnStackedChart = withGroupRatioData(ColumnStackedChart);
