import {useId} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {css} from "@emotion/react";
import {Button} from "@pg-design/button";
import {FormikForm} from "@pg-design/formik-utils";
import {calculateRemSize, flex, flexDirection, mb, mr, onDesktop, w50, w100} from "@pg-design/helpers-css";
import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";

import {useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {handleFormikSubmit} from "../../../../components/form/handle_submit";
import {SubmitFormikButton} from "../../../../components/SubmitFormikButton";
import {useLogout} from "../../../auth/hooks/useLogout";
import {useUpdateCurrentUserMutation} from "../../api/updateCurrentUser";
import {selectUserData} from "../../users_slice";

export interface IUserEditFormValues {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    bigdata_marketing_consent: boolean;
}

export const UserEditForm = () => {
    const {t, i18n} = useTranslation();
    const [updateCurrentUser] = useUpdateCurrentUserMutation({fixedCacheKey: "editUser"});
    const userData = useAppSelector(selectUserData);
    const logout = useLogout();
    const checkboxId = useId();

    const initialFormValues: IUserEditFormValues = {
        first_name: userData ? userData.first_name : "",
        last_name: userData ? userData.last_name : "",
        email: userData ? userData.email : "",
        phone: userData ? userData.phone : "",
        bigdata_marketing_consent: userData ? userData.bigdata_marketing_consent : false
    };

    const onSubmit = (values: IUserEditFormValues, helpers: FormikHelpers<IUserEditFormValues>) =>
        handleFormikSubmit(
            updateCurrentUser({
                first_name: values.first_name,
                last_name: values.last_name,
                phone: values.phone,
                bigdata_marketing_consent: values.bigdata_marketing_consent
            }).unwrap(),
            helpers,
            {
                onSuccess: () => toast.success(() => t("user.edit_form.account_updated")),
                onError: () => toast.error(() => t("auth.errors.generic"))
            }
        );

    const validationSchema = Yup.object({
        first_name: Yup.string().max(254, t("common.form.max254validation")).required(t("common.form.required_field")),
        last_name: Yup.string().max(254, t("common.form.max254validation")).required(t("common.form.required_field")),
        email: Yup.string().email(t("user.edit_form.email_validation")).required(t("common.form.required_field")),
        phone: Yup.string()
            .max(20, t("common.form.max20validation"))
            .required(t("common.form.required_field"))
            .matches(/^[0-9\s]+$/, t("common.form.phone")),
        bigdata_marketing_consent: Yup.boolean().required(t("common.form.required_field"))
    });

    return (
        <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({isSubmitting, isValid, handleBlur}) => (
                <Form>
                    <div css={nameWrapper}>
                        <FormikForm.Input
                            onBlur={handleBlur}
                            css={[w100, onDesktop(w50)]}
                            name="first_name"
                            placeholder={t("user.edit_form.first_name")}
                        />

                        <FormikForm.Input
                            onBlur={handleBlur}
                            css={[w100, onDesktop(w50)]}
                            name="last_name"
                            placeholder={t("user.edit_form.last_name")}
                        />
                    </div>

                    <FormikForm.Input
                        onBlur={handleBlur}
                        css={mb(1.5)}
                        name="email"
                        placeholder={t("user.edit_form.email")}
                        readOnly
                    />

                    <FormikForm.Input onBlur={handleBlur} css={mb(1.5)} name="phone" placeholder={t("common.phone")} />

                    <FormikForm.Checkbox
                        onBlur={handleBlur}
                        css={checkbox}
                        name="bigdata_marketing_consent"
                        labelContent={t("auth.marketing_consent")}
                        id={checkboxId}
                        checkedMarkColor="var(--green-checkbox-checked)"
                    />

                    <div css={buttons}>
                        <div css={leftButtons}>
                            <SubmitFormikButton
                                copy={t("user.edit_form.update_data")}
                                disabled={isSubmitting || !isValid}
                                css={button}
                            />

                            <Link to={chartsRoutes[i18n.language].user.changePassword}>
                                <Button
                                    type="button"
                                    css={[w100, mb(7), onDesktop(mb(0))]}
                                    variant="outlined_secondary"
                                >
                                    {t("nav.change_password")}
                                </Button>
                            </Link>
                        </div>

                        <Button type="button" onClick={logout} variant="none_secondary">
                            {t("nav.logout")}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const nameWrapper = css`
    ${mb(1.5)};
    ${flex()};
    ${flexDirection("column")};
    gap: ${calculateRemSize(1.5)};

    ${onDesktop(flexDirection("row"))};
`;

const checkbox = css`
    ${mb(6)};
    align-items: flex-start;
`;

const buttons = css`
    ${flex()};
    ${flexDirection("column")};

    ${onDesktop(css`
        ${flexDirection("row")};
        justify-content: space-between;
    `)}
`;

const leftButtons = css`
    ${flex()};
    ${flexDirection("column")};

    ${onDesktop(css`
        ${flexDirection("row")}
    `)}
`;

const button = css`
    ${mb(2)};
    ${onDesktop(css`
        ${mb(0)};
        ${mr(2)}
    `)}
`;
