import {useState} from "react";
import {useTranslation} from "react-i18next";
import {css} from "@emotion/react";
import {FormikForm} from "@pg-design/formik-utils";
import {mb, mv, w100} from "@pg-design/helpers-css";
import {Select} from "@pg-design/select";
import {Text} from "@pg-design/text";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import {useAppSelector} from "../../../../../../../utils/hooks/store_hooks";
import {consoleError} from "../../../../../../../utils/logger";
import {selectAuthStatus} from "../../../../auth/auth_slice";
import {selectUserData} from "../../../../users/users_slice";
import {useAddUserComUserMutation} from "../../../api/add_user_com_user";
import {useLazyGetUserComUserQuery} from "../../../api/get_user_com_user";
import {useSendUserComEventMutation} from "../../../api/send_user_com_event";

interface IDownloadDemoFormProps {
    setIsSuccess: (value: boolean) => void;
}

export enum UserComUserTypeIds {
    OTHER = 0,
    DEVELOPER = 2,
    RESEARCHER = 3,
    BROKER = 5
}

export enum UserComDepartmentIds {
    MANAGEMENT = 1,
    SALES = 2,
    LAND = 3,
    IMPLEMENTATION = 4,
    ANALYTICS = 5,
    DIRECTOR_OF_SALES = 6,
    OWNER_ESTATE = 7,
    BROKER = 8
}

interface IDownloadDemoFormValues {
    sector?: UserComUserTypeIds;
    work_function?: UserComDepartmentIds;
    email: string;
    bigdata_marketing_consent: boolean;
}

export const DownloadDemoForm = ({setIsSuccess}: IDownloadDemoFormProps) => {
    const {t} = useTranslation();
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const userData = useAppSelector(selectUserData);
    const hasData = isLoggedIn && !!userData;
    const [sendUserComEvent] = useSendUserComEventMutation();
    const [addUserComUser] = useAddUserComUserMutation();
    const [trigger] = useLazyGetUserComUserQuery();
    const [renderSelect, setRenderSelect] = useState(true);
    const initialValues: IDownloadDemoFormValues = {
        sector: undefined,
        work_function: undefined,
        email: hasData ? userData.email : "",
        bigdata_marketing_consent: false
    };

    const validationSchema = Yup.object({
        sector: Yup.mixed().required(t("common.form.required_field")).oneOf(Object.values(UserComUserTypeIds)),
        work_function: Yup.mixed().oneOf(Object.values(UserComDepartmentIds)),
        email: Yup.string().required(t("common.form.required_field")).email(t("auth.errors.invalid_email")),
        bigdata_marketing_consent: Yup.boolean()
            .required(t("common.form.required_field"))
            .equals([true], t("auth.errors.marketing_consent_is_required"))
    });

    const getFunctionOptions = (isDeveloper: boolean) =>
        isDeveloper
            ? [
                  {
                      label: t("monitoring.hero.demo.functions.developer.board"),
                      value: UserComDepartmentIds.MANAGEMENT
                  },
                  {
                      label: t("monitoring.hero.demo.functions.developer.director"),
                      value: UserComDepartmentIds.DIRECTOR_OF_SALES
                  },
                  {
                      label: t("monitoring.hero.demo.functions.developer.manager"),
                      value: UserComDepartmentIds.SALES
                  },
                  {
                      label: t("monitoring.hero.demo.functions.developer.acquisition"),
                      value: UserComDepartmentIds.LAND
                  },
                  {
                      label: t("monitoring.hero.demo.functions.developer.implementation"),
                      value: UserComDepartmentIds.IMPLEMENTATION
                  },
                  {
                      label: t("monitoring.hero.demo.functions.developer.analytics"),
                      value: UserComDepartmentIds.ANALYTICS
                  }
              ]
            : [
                  {
                      label: t("monitoring.hero.demo.functions.middle_man.owner"),
                      value: UserComDepartmentIds.OWNER_ESTATE
                  },
                  {
                      label: t("monitoring.hero.demo.functions.middle_man.middle_man"),
                      value: UserComDepartmentIds.BROKER
                  }
              ];

    //window.userengage is not a promise and this fact interferes with formik functioning (for example isSubmitting
    // helper never changes to false after submit) so function below had to be written
    const isButtonDisabled = (values: IDownloadDemoFormValues) =>
        values.sector === undefined || // Check if sector is not selected
        ((values.sector === UserComUserTypeIds.DEVELOPER || values.sector === UserComUserTypeIds.BROKER) &&
            !values.work_function);

    const onSubmit = ({sector, work_function, email, bigdata_marketing_consent}: IDownloadDemoFormValues) => {
        try {
            //check if user with sent email exists in user.com, if no, create user and send an event with his id, if
            // yes, send an event with his id
            trigger(email)
                .unwrap()
                .then((res) => {
                    if (res && res.id === null) {
                        addUserComUser({
                            email,
                            data: {
                                email,
                                department: work_function,
                                user_type: sector,
                                bigdata_marketing_consent
                            }
                        })
                            .unwrap()
                            .then((res) => {
                                sendUserComEvent({
                                    user_id: res.id,
                                    name: "monitoring_demo_order",
                                    data: {sector, work_function, email, bigdata_marketing_consent}
                                });
                            });
                    }
                    if (res.id) {
                        sendUserComEvent({
                            user_id: res.id,
                            name: "monitoring_demo_order",
                            data: {sector, work_function, email, bigdata_marketing_consent}
                        });
                    }
                });
            setIsSuccess(true);
        } catch (e) {
            consoleError("Failed to send monitoring demo order to user.com");
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({values, isValid, setFieldValue, handleBlur}) => (
                <Form>
                    <Select
                        css={mb(1.5)}
                        placeholder={t("monitoring.hero.demo.sector")}
                        onChange={(e) => {
                            const newValue = e as {value: UserComUserTypeIds; label: string};
                            setFieldValue("sector", newValue.value);
                            setRenderSelect(
                                newValue.value === UserComUserTypeIds.DEVELOPER ||
                                    newValue.value === UserComUserTypeIds.BROKER
                            );
                        }}
                        options={[
                            {
                                label: t("monitoring.hero.demo.sectors.developer"),
                                value: UserComUserTypeIds.DEVELOPER
                            },
                            {
                                label: t("monitoring.hero.demo.sectors.middle_man"),
                                value: UserComUserTypeIds.BROKER
                            },
                            {
                                label: t("monitoring.hero.demo.sectors.advisor"),
                                value: UserComUserTypeIds.RESEARCHER
                            },
                            {
                                label: t("monitoring.hero.demo.sectors.others"),
                                value: UserComUserTypeIds.OTHER
                            }
                        ]}
                    />
                    {renderSelect && (
                        <Select
                            css={workFunctionsSelect}
                            placeholder={t("monitoring.hero.demo.function")}
                            onChange={(e) => {
                                const newValue = e as {value: string; label: string};
                                setFieldValue("work_function", newValue.value);
                            }}
                            options={getFunctionOptions(values.sector === UserComUserTypeIds.DEVELOPER)}
                            isDisabled={!values.sector}
                        />
                    )}
                    <FormikForm.Input
                        onBlur={handleBlur}
                        css={mv(1.5)}
                        type="email"
                        name="email"
                        placeholder="E-mail"
                    />
                    <FormikForm.Button
                        disabled={isButtonDisabled(values) || !isValid}
                        css={[w100, mb(1.5)]}
                        variant="filled_primary"
                    >
                        {t("monitoring.hero.demo.send")}
                    </FormikForm.Button>
                    <FormikForm.Checkbox
                        onBlur={handleBlur}
                        name="bigdata_marketing_consent"
                        checkedMarkColor="var(--green-checkbox-checked)"
                        css={checkbox}
                        labelContent={<Text variant="info_txt_1">{t("auth.marketing_consent")}</Text>}
                        id="demo_marketing_consent"
                    />
                </Form>
            )}
        </Formik>
    );
};

const checkbox = css`
    align-items: flex-start;
`;

//for some reason options in polish were behaving like they were overflowing
const workFunctionsSelect = css`
    div {
        max-height: unset !important;
    }
`;
