import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {css} from "@emotion/react";
import {Button} from "@pg-design/button";
import {centerAbsolute, flexAbsoluteCenter, flexDirection, mb, mt, zIndex} from "@pg-design/helpers-css";
import {LockOutlineIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {dataLayer} from "../../../../../../utils/data_layer";
import {getLockerLabel} from "../../../../../../utils/get_locker_label";
import {useAppDispatch, useAppSelector} from "../../../../../../utils/hooks/store_hooks";
import {AccessStatus, useAccessStatus} from "../../../../../../utils/hooks/useAccessStatus";
import {ZIndex} from "../../../../common/app/constants/z_index";
import {chartsRoutes} from "../../../../common/app/routing/charts_routes";
import {selectAuthStatus, setAuthModal} from "../../../../features/auth/auth_slice";
import {selectUserData} from "../../../../features/users/users_slice";

interface ILockerProps {
    locked?: boolean;
}

export const Locker = ({locked}: ILockerProps) => {
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const {isLoggedIn} = useAppSelector(selectAuthStatus);
    const dispatch = useAppDispatch();
    const userData = useAppSelector(selectUserData);
    const accessStatus = useAccessStatus();

    const handleRegister = () => {
        dataLayer({
            event: "register_modal_view",
            position: "chart"
        });
        dispatch(setAuthModal("registerUser"));
    };

    const handleConsentButtonClick = () => {
        history.push(chartsRoutes[i18n.language].user.edit);
    };

    return (
        <div css={lockerWrapper}>
            <LockOutlineIcon size="13" wrapperSize="13" fill="#fff" />

            <Text variant="body_copy_1" color="#fff" css={[mb(4), mt(8)]}>
                {getLockerLabel(i18n, userData, isLoggedIn)}
            </Text>

            {!isLoggedIn && (
                <Button variant="filled_primary" size="medium" onClick={handleRegister}>
                    {t("auth.register")}
                </Button>
            )}

            {userData && accessStatus === AccessStatus.FULL && !userData.bigdata_marketing_consent && (
                <Button variant="filled_primary" size="medium" onClick={handleConsentButtonClick}>
                    {t("common.chart_lock.unlock")}
                </Button>
            )}

            {userData && accessStatus === AccessStatus.FULL && userData.bigdata_marketing_consent && locked && (
                <Link to={chartsRoutes[i18n.language].contact}>
                    <Button variant="filled_primary" size="medium">
                        {t("common.chart_lock.contact_us")}
                    </Button>
                </Link>
            )}

            {userData && accessStatus !== AccessStatus.FULL && (
                <Link to={chartsRoutes[i18n.language].contact}>
                    <Button variant="filled_primary" size="medium">
                        {t("common.chart_lock.contact_us")}
                    </Button>
                </Link>
            )}
        </div>
    );
};

const lockerWrapper = css`
    ${centerAbsolute("horizontal")};
    top: 40%;
    ${flexAbsoluteCenter};
    ${flexDirection("column")};
    ${zIndex(ZIndex.LOCKED_CHART)};
`;
