import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "@pg-design/grid";
import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {useAppDispatch} from "../../../../../../utils/hooks/store_hooks";
import {faqHolder} from "../../../../components/views/Faq";
import {setResponseStatus} from "../../../app_status/app_status_slice";
import {ResponseStatus} from "../../../ssr/path_to_action/state/app_status/IAppStatusState";
import {useGetContentQuery} from "../../../static_content/api/get_content";
import {StaticContent} from "../../../static_content/types/Content";

export const MonitoringFaq = () => {
    const {t} = useTranslation();
    const {
        i18n: {language}
    } = useTranslation();
    const {data, isError} = useGetContentQuery(StaticContent.FAQ_MONITORING);
    const dispatch = useAppDispatch();

    if (isError) {
        dispatch(setResponseStatus({responseStatus: ResponseStatus.PAGE_500}));
        return null;
    }

    return (
        <Container as="section" fluid>
            <Container>
                <Row>
                    <Col lg={4} lgOffset={6} md={4} mdOffset={4} sm={4}>
                        <Text css={mb(4)} variant="headline_3" align="center">
                            {t("monitoring.faq.title")}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} lgOffset={2} md={12} sm={4}>
                        {data && data.content_pl && data.content_en && (
                            <div
                                css={faqHolder}
                                dangerouslySetInnerHTML={{
                                    __html: language === "pl" ? data.content_pl : data.content_en
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};
