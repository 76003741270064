import {getClientCookies} from "../client/get_client_cookies";

export default (headers: Headers) => {
    const {csrfToken} = getClientCookies();
    headers.set("X-CSRFToken", csrfToken);

    return headers;
};

//TODO this file should probably be elsewhere
