import {css, Theme} from "@emotion/react";
import {backgroundColor, borderRadius, flexAlignCenter, mb, onDesktop, p} from "@pg-design/helpers-css";

export const mobileInputTrigger = (theme: Theme) => css`
    ${p(0, 2)};
    ${mb(3)};
    position: relative;
    height: 5rem;
    width: 100%;
    ${flexAlignCenter};
    cursor: text;
    flex-basis: 100%;
    font-size: 1.4rem;
    ${borderRadius()};
    border: 1px solid ${theme.colors.gray[600]};
    color: ${theme.colors.secondary};
    ${backgroundColor("#fff")};

    ${onDesktop(css`
        display: none;
    `)}
`;
