import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IProps {
    className?: string;
}

export const NoContent = ({className}: IProps) => {
    return (
        <div className={className}>
            <Text variant="headline_1" as="p" align="center" css={mb(2)}>
                Nie znaleziono żadnych treści
            </Text>
            <Text variant="body_copy_1" align="center">
                Zmień kryteria filtrowania
            </Text>
        </div>
    );
};
