import {Trans, useTranslation} from "react-i18next";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Badge} from "@pg-design/badge";

import {chartsApiPath} from "../../../../../api/src/constants/api_path";
import {IMonthCityStats} from "../../../../../api/src/db_queries/month_city_stats_query";
import {IMonthCityTypeStats} from "../../../../../api/src/db_queries/month_city_type_stats_query";
import {IMonthGusStats} from "../../../../../api/src/db_queries/month_gus_stats_query";
import {
    CitySlug,
    getCityOption,
    mapCitySlugToLocalizedParamSlug,
    mapParamSlugToCitySlug,
    validateOfferType
} from "../../../../../config/cities";
import {getRelatedCitySlug, mapCitySlugToRegionType, RegionType} from "../../../../../config/regions";
import {originDate, substractOneYear} from "../../../../../utils/dates";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {useAPIRequest} from "../../../../../utils/hooks/useAPIRequest";
import {useCityPermission} from "../../../../../utils/hooks/useCityPermission";
import {useGusDataWarning} from "../../../../../utils/hooks/useGusDataWarning";
import {chartsRoutes, compilePath, ICityParams} from "../../../common/app/routing/charts_routes";
import {
    mapOfferTypeToValidPath,
    mapPathnameToOfferType,
    OfferType,
    offerTypeToText
} from "../../../common/app/routing/offer_type";
import {RatioBarChartCard} from "../../../components/charts/chart_cards/BarChartCard";
import {RatioColumnChartCard} from "../../../components/charts/chart_cards/ColumnChartCard";
import {RatioColumnStackedChartCard} from "../../../components/charts/chart_cards/ColumnStackedChartCard";
import {DistrictsMapChartCard} from "../../../components/charts/chart_cards/DistrictsMapChartCard";
import {RatioLineColumnChartCard} from "../../../components/charts/chart_cards/LineColumnChartCard";
import {MultilineChartCard, RatioMultilineChartCard} from "../../../components/charts/chart_cards/MultilineChartCard";
import {Grid} from "../../../components/Grid";
import {withDateGuard} from "../../../components/hoc/withDateGuard";
import {withGTM} from "../../../components/hoc/withGTM";
import {ChartsLayout as Layout} from "../../../components/layout/charts_layout/ChartsLayout";
import {selectAuthStatus} from "../../auth/auth_slice";
import {selectLatestEntry} from "../../ssr/redux/latest_entry_slice";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {pageviewUserComHit} from "../../tracking/user_com/hit_user_com";
import {ViewType} from "../../tracking/view_type";
import {
    useGetMonthCityStatsQuery,
    useGetMonthCityTypeStatsQuery,
    useGetMonthDistrictStatsQuery
} from "../api/analytical_platform_api";
import {mapRtkQueryStatusToRequestStatus} from "../utils/rtq_status_to_request_status";

export const Added = withDateGuard(
    withGTM(() => {
        const {
            path,
            params: {city}
        } = useRouteMatch<ICityParams>();
        const citySlug = mapParamSlugToCitySlug(city);
        const regionType = mapCitySlugToRegionType(citySlug);
        usePageview(() => {
            pageviewAlgolyticsHit({citySlug, regionType, viewType: ViewType.ADDED_DETAILS});
            pageviewUserComHit();
        });
        const checkOfferType = mapPathnameToOfferType(path);
        const isFlatOfferType = checkOfferType === OfferType.FLAT;
        const isPermitted = useCityPermission(city);
        const history = useHistory();
        const {t, i18n} = useTranslation();
        const {currentDate} = useAppSelector(selectLatestEntry);
        const lastMonth = currentDate ?? "";
        const lastYear = substractOneYear(lastMonth, i18n);
        const isAgglomerationView = getCityOption(citySlug).useAgglomerationApi;
        const {isLoggedIn, isAuthorized} = useAppSelector(selectAuthStatus);
        const shouldFetch = isLoggedIn && isAuthorized;

        const requestSlugKey = isAgglomerationView ? "slug_agglomeration" : "slug_city";
        const requestCitySlug = getCityOption(citySlug).requestCitySlug;

        const {
            data = [],
            isError,
            isSuccess
        } = useGetMonthCityStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                scenario: "added"
            },
            {skip: !shouldFetch}
        );
        const {
            data: typeData = [],
            isError: isTypeDataError,
            isSuccess: isTypeDataSuccess
        } = useGetMonthCityTypeStatsQuery(
            {
                [requestSlugKey]: requestCitySlug,
                date_start: originDate,
                date_end: lastMonth,
                offer_type: "Mieszkania",
                scenario: "added"
            },
            {skip: !shouldFetch}
        );

        const {
            data: monthDistrictData = [],
            isError: isMonthDistrictDataError,
            isSuccess: isMonthDistrictDataSuccess
        } = useGetMonthDistrictStatsQuery(
            {
                slug_city: citySlug,
                date_start: lastMonth,
                date_end: lastMonth,
                scenario: "added",
                ...(isFlatOfferType ? {offer_type: "Mieszkania"} : {})
            },
            {skip: !shouldFetch || isAgglomerationView}
        );

        const [gusPermitsStatus, gusPermitsData] = useAPIRequest<IMonthGusStats>(
            chartsApiPath.monthGusStats.permits,
            {
                slug_city: citySlug,
                date_start: lastYear,
                date_end: lastMonth,
                scenario: "added"
            },
            Boolean(shouldFetch)
        );
        const [gusStartedStatus, gusStartedData] = useAPIRequest<IMonthGusStats>(
            chartsApiPath.monthGusStats.started,
            {
                slug_city: citySlug,
                date_start: lastYear,
                date_end: lastMonth,
                scenario: "added"
            },
            Boolean(shouldFetch)
        );

        const dataStatus = mapRtkQueryStatusToRequestStatus(isError, isSuccess);
        const typeDataStatus = mapRtkQueryStatusToRequestStatus(isTypeDataError, isTypeDataSuccess);
        const monthDistrictStatus = mapRtkQueryStatusToRequestStatus(
            isMonthDistrictDataError,
            isMonthDistrictDataSuccess
        );

        const monthData = Array.isArray(data) && data.length > 0 ? [data[data.length - 1]] : [];
        const flatMonthData = Array.isArray(typeData) && typeData.length > 0 ? [typeData[typeData.length - 1]] : [];
        const gusPermitsWarningDate = useGusDataWarning(gusPermitsData, lastMonth);
        const gusStartedWarningDate = useGusDataWarning(gusStartedData, lastMonth);
        // display districts map only on cities (not agglomerations) with districts enabled
        const districtsMapAvailable = getCityOption(citySlug).districtsMap && !isAgglomerationView;
        const handleCitySelection = (city: CitySlug) => {
            const validOfferType = validateOfferType(city, checkOfferType);
            const targetPath = mapOfferTypeToValidPath(
                validOfferType,
                chartsRoutes[i18n.language].flats.city.added,
                i18n
            );

            const localizedCityParam = mapCitySlugToLocalizedParamSlug(city, i18n.language);
            const compiledPath = compilePath(targetPath, {city: localizedCityParam});
            console.log(targetPath, localizedCityParam);
            history.push(compiledPath);
        };
        const handleRegionChange = (region: RegionType) => {
            const targetCitySlug = getRelatedCitySlug(citySlug, region);
            const paramCitySlug = mapCitySlugToLocalizedParamSlug(targetCitySlug, i18n.language);
            const compiledPath = compilePath(path, {city: paramCitySlug});
            history.push(compiledPath);
        };

        return (
            <Layout onCitySelection={handleCitySelection} onRegionChange={handleRegionChange}>
                <Grid type={districtsMapAvailable ? "subpage" : "subpageMapless"} isFlatOfferType={isFlatOfferType}>
                    <RatioLineColumnChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart1"
                        chartId="added-chart1"
                        title={<Trans>{t("added.chart1.title")}</Trans>}
                        helper={<Trans>{t("added.chart1.description")}</Trans>}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={isFlatOfferType ? typeDataStatus : dataStatus}
                        columnFields={[
                            "added_studio",
                            "added_2_rooms",
                            "added_3_rooms",
                            "added_4_plus_rooms",
                            ...(isFlatOfferType ? [] : ["added_house"])
                        ]}
                        lineField="added"
                        locked={!isPermitted}
                    />

                    <RatioBarChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart2"
                        chartId="added-chart2"
                        title={<Trans>{t("added.chart2.title")}</Trans>}
                        helper={<Trans>{t("added.chart2.description")}</Trans>}
                        data={isFlatOfferType ? flatMonthData : monthData}
                        dataStatus={isFlatOfferType ? typeDataStatus : dataStatus}
                        fields={[
                            "added_offers_to_6k",
                            "added_offers_between_6k_and_7k",
                            "added_offers_between_7k_and_8k",
                            "added_offers_between_8k_and_9k",
                            "added_offers_between_9k_and_10k",
                            "added_offers_between_10k_and_12k",
                            "added_offers_between_12k_and_15k",
                            "added_offers_between_15k_and_20k",
                            "added_offers_above_20k"
                        ]}
                        height={225}
                        locked={!isPermitted}
                    />

                    {!isFlatOfferType && (
                        <MultilineChartCard<IMonthCityStats>
                            area="chart3"
                            chartId="added-chart3"
                            title={<Trans>{t("added.chart3.title")}</Trans>}
                            helper={<Trans>{t("added.chart3.description")}</Trans>}
                            data={data}
                            dataStatus={dataStatus}
                            fields={[
                                "added_avg_area_studio",
                                "added_avg_area_2_rooms",
                                "added_avg_area_3_rooms",
                                "added_avg_area_4_plus_rooms"
                            ]}
                            unit=" m<sup>2</sup>"
                            locked={!isPermitted}
                        />
                    )}

                    <RatioMultilineChartCard<IMonthCityStats | IMonthCityTypeStats>
                        area="chart4"
                        chartId="added-chart4"
                        title={<Trans>{t("added.chart4.title")}</Trans>}
                        helper={<Trans>{t("added.chart4.description")}</Trans>}
                        data={isFlatOfferType ? typeData : data}
                        dataStatus={isFlatOfferType ? typeDataStatus : dataStatus}
                        fields={[
                            "added_area_to_30m2",
                            "added_area_between_30_and_50m2",
                            "added_area_between_50_and_70m2",
                            "added_area_between_70_and_100m2",
                            "added_area_between_100_and_120m2",
                            "added_area_above_120m2"
                        ]}
                        locked={!isPermitted}
                    />

                    <RatioColumnChartCard<IMonthCityStats>
                        area="chart5"
                        chartId="added-chart5"
                        title={<Trans>{t("added.chart5.title")}</Trans>}
                        helper={<Trans>{t("added.chart5.description")}</Trans>}
                        data={monthData}
                        dataStatus={dataStatus}
                        fields={[
                            "added_investment_type_intimate",
                            "added_investment_type_small",
                            "added_investment_type_medium",
                            "added_investment_type_large",
                            "added_investment_type_x_large",
                            ...(isFlatOfferType ? [] : ["added_investment_type_house"])
                        ]}
                        locked={!isPermitted}
                    />

                    <RatioColumnStackedChartCard
                        area="chart6"
                        chartId="added-chart6"
                        title={<Trans>{t("added.chart6.title")}</Trans>}
                        helper={<Trans>{t("added.chart6.description")}</Trans>}
                        data={monthData}
                        dataStatus={dataStatus}
                        categories={[
                            "investment_type_intimate",
                            "investment_type_small",
                            "investment_type_medium",
                            "investment_type_large",
                            "investment_type_x_large",
                            ...(isFlatOfferType ? [] : ["investment_type_house"])
                        ]}
                        fieldGroups={[
                            [
                                "added_investment_type_intimate_offer_studio",
                                "added_investment_type_small_offer_studio",
                                "added_investment_type_medium_offer_studio",
                                "added_investment_type_large_offer_studio",
                                "added_investment_type_x_large_offer_studio",
                                ...(isFlatOfferType ? [] : ["added_houses_to_100m2"])
                            ],
                            [
                                "added_investment_type_intimate_offer_2_rooms",
                                "added_investment_type_small_offer_2_rooms",
                                "added_investment_type_medium_offer_2_rooms",
                                "added_investment_type_large_offer_2_rooms",
                                "added_investment_type_x_large_offer_2_rooms",
                                ...(isFlatOfferType ? [] : ["added_houses_between_100_and_150m2"])
                            ],
                            [
                                "added_investment_type_intimate_offer_3_rooms",
                                "added_investment_type_small_offer_3_rooms",
                                "added_investment_type_medium_offer_3_rooms",
                                "added_investment_type_large_offer_3_rooms",
                                "added_investment_type_x_large_offer_3_rooms",
                                ...(isFlatOfferType ? [] : ["added_houses_between_150_and_200m2"])
                            ],
                            [
                                "added_investment_type_intimate_offer_4_plus_rooms",
                                "added_investment_type_small_offer_4_plus_rooms",
                                "added_investment_type_medium_offer_4_plus_rooms",
                                "added_investment_type_large_offer_4_plus_rooms",
                                "added_investment_type_x_large_offer_4_plus_rooms",
                                ...(isFlatOfferType ? [] : ["added_houses_between_200_and_250m2"])
                            ],
                            ["", "", "", "", "", ...(isFlatOfferType ? [] : ["added_houses_above_250m2"])]
                        ]}
                        minValue={1}
                        locked={!isPermitted}
                    />
                    {isFlatOfferType && (
                        <>
                            {" "}
                            <MultilineChartCard<IMonthGusStats>
                                area="chart7"
                                chartId="added-chart7"
                                title={<Trans>{t("added.chart7.title")}</Trans>}
                                helper={t("added.chart7.description")}
                                afterHeaderContent={
                                    gusPermitsWarningDate && (
                                        <Badge variant="label_warning">
                                            {t("common.gus_data_update")} {gusPermitsWarningDate}
                                        </Badge>
                                    )
                                }
                                data={gusPermitsData}
                                dataStatus={gusPermitsStatus}
                                fields={["permits_issued"]}
                                minValue={0}
                                locked={!isPermitted}
                            />
                            <MultilineChartCard<IMonthGusStats>
                                area="chart8"
                                chartId="added-chart8"
                                title={<Trans>{t("added.chart8.title")}</Trans>}
                                helper={t("added.chart8.description")}
                                afterHeaderContent={
                                    gusStartedWarningDate && (
                                        <Badge variant="label_warning">
                                            {t("common.gus_data_update")} {gusStartedWarningDate}
                                        </Badge>
                                    )
                                }
                                data={gusStartedData}
                                dataStatus={gusStartedStatus}
                                fields={["flats_started"]}
                                minValue={0}
                                locked={!isPermitted}
                            />
                        </>
                    )}

                    <DistrictsMapChartCard
                        chartId="added-map"
                        title={<Trans>{t("added.map.title", {city: citySlug})}</Trans>}
                        helper={t("added.map.description")}
                        visible={districtsMapAvailable}
                        city={citySlug as string}
                        data={monthDistrictData}
                        dataStatus={monthDistrictStatus}
                        valueField="added"
                        legendType="quantitative"
                        legendColors={["#FFF2D4", "#FFBD98", "#FF8F6D", "#FF6C40", "#E63600"]}
                        locked={!isPermitted}
                    />
                </Grid>
            </Layout>
        );
    })
);
