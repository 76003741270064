import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {IRootState} from "../../store/store";
import {IAppStatus, ResponseStatus} from "../ssr/path_to_action/state/app_status/IAppStatusState";

interface IAppStatusState extends IAppStatus {
    serverSessionId: string | null;
    serverCsrfToken: string | null;
}

const initialState: IAppStatusState = {
    serverSessionId: null,
    serverCsrfToken: null,
    responseStatus: ResponseStatus.DEFAULT
};

export const appStatusSlice = createSlice({
    name: "appStatus",
    initialState,
    reducers: {
        setServerSessionId: (state, action: PayloadAction<string>) => {
            state.serverSessionId = action.payload;
        },
        setServerCsrfToken: (state, action: PayloadAction<string>) => {
            state.serverCsrfToken = action.payload;
        },
        deleteSessionId: (state) => {
            state.serverSessionId = null;
        },
        deleteCsrfToken: (state) => {
            state.serverCsrfToken = null;
        },
        setAppStatus: (state, action: PayloadAction<IAppStatus>) => {
            state.responseStatus = action.payload.responseStatus;
            state.url = action.payload.url;
        },
        setResponseStatus: (state, action: PayloadAction<Pick<IAppStatus, "responseStatus">>) => {
            state.responseStatus = action.payload.responseStatus;
        },
        setUrl: (state, action: PayloadAction<Pick<IAppStatus, "url">>) => {
            state.url = action.payload.url;
        }
    }
});

//  Actions
export const {
    setServerSessionId,
    setServerCsrfToken,
    deleteSessionId,
    deleteCsrfToken,
    setAppStatus,
    setResponseStatus,
    setUrl
} = appStatusSlice.actions;

//  Selectors
export const selectServerSessionId = (state: IRootState) => state.appStatus.serverSessionId;
export const selectServerCsrfToken = (state: IRootState) => state.appStatus.serverCsrfToken;
export const selectResponseStatus = (state: IRootState) => state.appStatus.responseStatus;
export const selectUrl = (state: IRootState) => state.appStatus.url;
