import {validateNewshubParams} from "../../../../../../utils/validate_newshub_params";
import {INewsParams} from "../../../../common/app/routing/charts_routes";
import {getCategoriesApi} from "../../../newshub/api/get_categories";
import {getEntriesApi, ProvincialCity} from "../../../newshub/api/get_entries";
import {IActionContext} from "../create_path_to_action";
import {ResponseStatus} from "../state/app_status/IAppStatusState";
import {setAuthAndUser} from "./set_auth_and_user";
import {setError} from "./set_error";

export const newshubMarketInformationAction = async (ctx: IActionContext<INewsParams>): Promise<void> => {
    await setAuthAndUser(ctx.store);
    const {marketInfoSubCategorySlug, marketInfoCategorySlug} = ctx.match.params;
    const {url} = ctx.route;
    const {data} = await ctx.store.dispatch(getCategoriesApi.endpoints.getCategories.initiate());

    const getParams = (): {
        page: number;
        vendor: number | undefined;
        city: ProvincialCity | undefined;
        dateFrom: string | undefined;
        dateTo: string | undefined;
        search: string | undefined;
    } => {
        const city = params.get("city");
        const page = params.get("page");
        const vendor = params.get("vendor");
        const dateFrom = params.get("date_from");
        const dateTo = params.get("date_to");
        const search = params.get("search");

        const {pageParam, vendorParam, cityParam, dateFromParam, dateToParam, searchParam} = validateNewshubParams(
            page,
            vendor,
            city,
            dateFrom,
            dateTo,
            search
        );

        return {
            city: cityParam,
            vendor: vendorParam,
            page: pageParam,
            dateFrom: dateFromParam,
            dateTo: dateToParam,
            search: searchParam
        };
    };

    const [_, paramsString] = url.split("?");
    const params = new URLSearchParams(paramsString);
    const {page, vendor, city, dateFrom, dateTo, search} = getParams();
    const mainCategoryId = data?.results.find((data) => data.slug === marketInfoCategorySlug);

    const subCategory = mainCategoryId?.subcategories.find(
        (subcategory) => subcategory.slug === marketInfoSubCategorySlug
    );

    const selectedCategoryId = subCategory?.id ?? mainCategoryId?.id;

    if (
        url !== "/newshub/informacje-rynkowe/" &&
        (marketInfoCategorySlug !== mainCategoryId?.slug || marketInfoSubCategorySlug !== subCategory?.slug)
    ) {
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }

    const promises = [
        ctx.store.dispatch(
            getEntriesApi.endpoints.getHighlightedArticlesByCategory.initiate({category: selectedCategoryId ?? -1})
        ),
        ctx.store.dispatch(
            getEntriesApi.endpoints.getArticlesByCategory.initiate({
                category: selectedCategoryId ?? -1,
                page: page,
                vendorId: vendor,
                regionId: city,
                dateFrom,
                dateTo,
                search
            })
        ),

        ctx.store.dispatch(getEntriesApi.endpoints.getArticles.initiate(Number(page ?? 1)))
    ];

    try {
        await Promise.all(promises);
    } catch (error) {
        setError(ctx.store, ResponseStatus.PAGE_500);
        return;
    }

    await Promise.all(ctx.store.dispatch(getEntriesApi.util.getRunningQueriesThunk()));
};
