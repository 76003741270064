import {vendorsApi} from "./vendors_api";

export interface IVendor {
    id: number;
    name: string;
    slug: string;
}

interface IVendorRes {
    results: Array<IVendor>;
    count: number;
    page_size: number;
}

export const getVendorsApi = vendorsApi.injectEndpoints({
    endpoints: (build) => ({
        getVendors: build.query<IVendorRes, string>({
            query: (searchValue) => ({
                url: `/?search=${searchValue}`,
                method: "GET"
            })
        }),
        getVendor: build.query<IVendor, number>({
            query: (id) => ({
                url: `${id}/`,
                method: "GET"
            })
        })
    })
});

export const {useGetVendorsQuery, useGetVendorQuery} = getVendorsApi;
