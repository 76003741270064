import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {css, Theme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {flexAlignCenter, flexDirection, mb, mr, onDesktop, w100} from "@pg-design/helpers-css";
import {Select} from "@pg-design/select";
import {Text} from "@pg-design/text";

import {
    cityConfig,
    CitySlug,
    getCityOption,
    mapCitySlugToLocalizedParamSlug,
    validateOfferType
} from "../../../../../../config/cities";
import {dataLayer} from "../../../../../../utils/data_layer";
import {capitalize} from "../../../../../../utils/misc";
import {chartsRoutes, compilePath} from "../../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, OfferType} from "../../../../common/app/routing/offer_type";

interface ICityOption {
    value: string;
    label: string;
}

export const LandingPageCitySelect = () => {
    const [selectedCity, setSelectedCity] = useState<CitySlug>(CitySlug.WARSZAWA);
    const {t, i18n} = useTranslation();
    const options: Array<ICityOption> = useMemo(() => {
        return Object.keys(cityConfig)
            .filter((city) => {
                const option = getCityOption(city as CitySlug);
                return city !== CitySlug.TROJMIASTO && option.enabled && option.includeInCitySelect;
            })
            .map((city) => ({
                value: mapCitySlugToLocalizedParamSlug(city as CitySlug, i18n.language),
                label: city === "katowice" ? "Katowice / GZM" : t(`city.${city}.nominative`)
            }));
    }, [i18n.language]);

    const history = useHistory();

    const handleButtonClick = () => {
        dataLayer({
            event: "button_data_view",
            city: selectedCity
        });

        const validOfferType = validateOfferType(selectedCity, OfferType.FLAT);
        const targetPath = mapOfferTypeToValidPath(validOfferType, chartsRoutes[i18n.language].flats.city.root, i18n);
        const localizedCityParam = mapCitySlugToLocalizedParamSlug(selectedCity, i18n.language);
        const compiledPath = compilePath(targetPath, {city: localizedCityParam});
        history.push(compiledPath);
    };

    return (
        <div css={wrapper}>
            <Select
                css={select}
                placeholder={capitalize(selectedCity)}
                instanceId="landing-city-select"
                name="landing-city-select"
                onChange={(e: unknown) => {
                    const {value} = e as ICityOption;
                    setSelectedCity(value as CitySlug);
                }}
                options={options}
            />

            <Button css={button} variant="filled_primary" size="small" onClick={handleButtonClick}>
                <Text variant="button_small">{t("landing.hero.button")}</Text>
            </Button>
        </div>
    );
};

const wrapper = (theme: Theme) => css`
    ${flexAlignCenter};
    ${flexDirection("column")};

    @media (min-width: ${theme.breakpoints.md}) {
        flex-direction: row;
    }
`;

const select = css`
    ${mb(3)};
    ${w100};

    ${onDesktop(css`
        ${mb(0)};
        ${mr(3)};
        width: 25.6rem;
        flex-basis: initial;
    `)};
`;

const button = css`
    ${mb(12)};
    ${w100};

    ${onDesktop(css`
        ${mb(0)};
        width: 20.8rem;
        flex-basis: initial;
    `)}
`;
