import {debounce} from "lodash-es";

import {CitySlug} from "../../../../../config/cities";
import {RegionType} from "../../../../../config/regions";
import {OfferType} from "../../../common/app/routing/offer_type";
import {ViewType} from "../view_type";
import {hitAlgolytics} from "./init_algolytics";

interface IPageViewPayload {
    citySlug?: CitySlug | null; // defined by dashboard and details
    offerType?: OfferType; // defined by homepage and dashboard
    regionType?: RegionType | null; // defined by dashboard and details
    viewType: ViewType; // defined by all views
}

export const pageviewAlgolyticsHit = debounce((payload: IPageViewPayload) => {
    const hitPayload = {
        offer_type: payload.offerType,
        view_type: payload.viewType,
        region_slug: payload.citySlug, // TODO: we need to rename `citySlug` to `regionSlug` in the app
        region_type: payload.regionType
    };
    hitAlgolytics("bigdata_views", payload);
}, 500);
