export type IAppStatus = {
    responseStatus: ResponseStatus;
    url?: string;
};

export enum ResponseStatus {
    DEFAULT = "default",
    PAGE_500 = "page_500",
    PAGE_404 = "page_404",
    REDIRECT_301 = "redirect_301",
    REDIRECT_302 = "redirect_302"
}

export const getInitialAppState = (): IAppStatus => ({
    responseStatus: ResponseStatus.DEFAULT
});
