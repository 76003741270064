import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {css, keyframes, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";
import {
    borderRadius,
    calculateRemSize,
    color,
    display,
    fadeInAnimation,
    flexAlignCenter,
    flexDirection,
    mb,
    mt,
    onDesktop,
    pointer
} from "@pg-design/helpers-css";

import {CitySlug, mapCitySlugToLocalizedParamSlug, validateOfferType} from "../../../../../../../config/cities";
import {calculatePercentage} from "../../../../../../../utils/calculate_percentage_difference";
import {formatNumber} from "../../../../../../../utils/format";
import {chartsRoutes, compilePath} from "../../../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, OfferType} from "../../../../../common/app/routing/offer_type";

export interface IAnimatedChartBarProps {
    name: string;
    citySlug: CitySlug;
    hsl: [number, number, number];
    value: number;
    total: number;
    animationDelay: number;
}

export const AnimatedChartBar = (props: IAnimatedChartBarProps) => {
    const {name, citySlug, hsl, value, total, animationDelay} = props;

    const {t, i18n} = useTranslation();
    const theme = useTheme();

    const validOfferType = validateOfferType(citySlug, OfferType.FLAT);
    const validPath = mapOfferTypeToValidPath(validOfferType, chartsRoutes[i18n.language].flats.city.root, i18n);
    const targetCityParamSlug = mapCitySlugToLocalizedParamSlug(citySlug, i18n.language);
    const cityPath = compilePath(validPath, {city: targetCityParamSlug});

    const height = value > 0 && total > 0 ? calculatePercentage(value, total) : 12;

    return (
        <div css={wrapper}>
            <StyledBarValue color={theme.colors.gray[800]} animationDelay={animationDelay}>
                <strong>{formatNumber(Math.round(value), false)}</strong>

                <Trans>{t("common.pln_m2")}</Trans>
            </StyledBarValue>

            <StyledBar hsl={hsl} barHeight={height} animationDelay={animationDelay} />

            <Link css={barTitle} to={cityPath} title={`Ceny mieszkań ${name}`}>
                {name}
            </Link>
        </div>
    );
};

const wrapper = css`
    ${flexAlignCenter};
    ${flexDirection("column")};
`;

const growBar = (targetHeight: number) => keyframes`
    from {
        height: ${calculateRemSize(5)};
    }
    to {
        height: ${targetHeight}px;
    }
`;

interface IStyledBarProps {
    hsl: IAnimatedChartBarProps["hsl"];
    barHeight: number;
    animationDelay: number;
}

const StyledBar = styled.div<IStyledBarProps>`
    background-color: hsl(${({hsl}) => hsl[0]}, ${({hsl}) => hsl[1]}%, ${({hsl}) => hsl[2]}%);
    width: ${calculateRemSize(4)};
    ${borderRadius(2)};
    ${mb(3)};
    animation: ${(props) => growBar(props.barHeight * 3.5)} 1s ease;
    animation-delay: ${({animationDelay}) => animationDelay}s;
    animation-fill-mode: forwards;
    height: ${calculateRemSize(5)};

    ${onDesktop(css`
        width: ${calculateRemSize(5)};
    `)};

    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        width: ${calculateRemSize(6)};
    }
`;

const StyledBarValue = styled.span<{animationDelay: number}>`
    width: max-content;
    ${display("inline block")};
    ${mb(4)};
    opacity: 0;
    ${fadeInAnimation("0.6s")};
    animation-delay: ${({animationDelay}) => animationDelay + 1}s;
    animation-fill-mode: forwards;
    transform: rotate(-90deg);
    font-size: 1rem;

    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
        transform: none;
        font-size: 1.5rem;
    }
`;

const barTitle = (theme: Theme) => css`
    ${mt(3)};
    ${display("inline block")};
    font-size: 1rem;
    transform: rotate(-90deg);
    text-transform: uppercase;
    ${color(theme.colors.gray[700])};
    ${pointer};
    font-weight: 600;
    text-decoration: none !important;

    @media (min-width: ${theme.breakpoints.sm}) {
        transform: none;
        font-size: 1rem;
        ${mt(0)};
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        font-size: 1.3rem;
    }
`;
