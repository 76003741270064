export const calculatePercentage = (partialValue: number, totalValue: number): number =>
    Math.round((100 * partialValue) / totalValue);

export const calculateDifference = (a: number, b: number): number => Math.round((a / b - 1) * 100);

export function calculatePercentageDifference(a: number, b: number, returnAbsoluteValue = false): string {
    const percentage = 100 * ((a - b) / ((a + b) / 2));
    const percentageString = returnAbsoluteValue ? Math.abs(percentage) : percentage;
    return percentageString.toFixed(2).replace(".", ",");
}
