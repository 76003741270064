import React, {HTMLAttributeAnchorTarget} from "react";
import {Link} from "react-router-dom";
import {css} from "@emotion/react";
import {mb, onDesktop, underline} from "@pg-design/helpers-css";
import {Link as ExternalLink} from "@pg-design/link";
import {Text} from "@pg-design/text";

export interface IFooterMenuElement {
    external: boolean;
    title: string;
    url?: string;
    rel?: string;
    target?: HTMLAttributeAnchorTarget;
    titleAttribute?: string;
    onClick?: () => void;
    className?: string;
}

export const FooterMenuElement = (props: IFooterMenuElement) => {
    return (
        <Text as="li" css={footerMenuElement} className={props.className} variant="info_txt_1">
            {props.external ? (
                <ExternalLink
                    size="small"
                    css={link}
                    href={props.url}
                    rel={props.rel}
                    target={props.target}
                    title={props.titleAttribute}
                    onClick={props.onClick}
                >
                    {props.title}
                </ExternalLink>
            ) : (
                <Link
                    css={link}
                    to={props.url || ""}
                    rel={props.rel}
                    target={props.target}
                    title={props.titleAttribute}
                    onClick={props.onClick}
                >
                    {props.title}
                </Link>
            )}
        </Text>
    );
};

const footerMenuElement = css`
    list-style-type: none;
`;

const link = css`
    display: inline-block;
    color: #fff;
    ${mb(3)};

    &:visited,
    &:hover,
    &:focus,
    &:active {
        color: #fff;
    }

    &:hover {
        ${underline};
    }

    ${onDesktop(css`
        ${mb(1)};
    `)}
`;
