import {useTranslation} from "react-i18next";
import {matchPath} from "react-router";
import {useLocation, useRouteMatch} from "react-router-dom";
import {css} from "@emotion/react";
import {Checkbox} from "@pg-design/checkbox";
import {flex, mr, onDesktop, order} from "@pg-design/helpers-css";

import {CitySlug, getCityOption, mapParamSlugToCitySlug} from "../../../../../config/cities";
import {usePropertyTypeFilter} from "../../../../../utils/hooks/usePropertyTypeFilter";
import {capitalize} from "../../../../../utils/misc";
import {FilterOfferType} from "../../../common/app/constants/enums";
import {chartsRoutes, ICityParams} from "../../../common/app/routing/charts_routes";

export const PropertyTypeFilter = () => {
    const {
        params: {city}
    } = useRouteMatch<ICityParams>();
    const citySlug = city ? mapParamSlugToCitySlug(city) : CitySlug.WARSZAWA;
    const {pathname} = useLocation();
    const {t, i18n} = useTranslation();
    const {activeFilter, filter} = usePropertyTypeFilter();

    const isViewFilterable = getCityOption(citySlug).propertyTypeFilters;
    const isHousesAvailable =
        getLocalizedFilterablePaths(i18n.language).some((path) => matchPath(pathname, {path, exact: true})) &&
        (city ? getCityOption(citySlug).propertyTypeFilters : true);

    if (isViewFilterable) {
        return (
            <div css={filters}>
                <Checkbox
                    css={[mr(8), onDesktop(mr(2))]}
                    name="flats_checkbox"
                    id="flats_checkbox"
                    labelContent={capitalize(t("common.flats.nominative"))}
                    onChange={() => filter(FilterOfferType.FLATS)}
                    checked={!activeFilter || activeFilter === FilterOfferType.FLATS}
                    checkedMarkColor="var(--green-checkbox-checked)"
                    readOnly={
                        !isHousesAvailable
                            ? activeFilter === undefined || activeFilter === FilterOfferType.FLATS
                            : false
                    }
                />

                <Checkbox
                    css={mr(4)}
                    name="houses_checkbox"
                    id="houses_checkbox"
                    labelContent={capitalize(t("common.houses.nominative"))}
                    onChange={() => filter(FilterOfferType.HOUSES)}
                    checked={!activeFilter || activeFilter === FilterOfferType.HOUSES}
                    checkedMarkColor="var(--green-checkbox-checked)"
                />
            </div>
        );
    }

    return null;
};

const getLocalizedFilterablePaths = (lang: string): string[] => [
    chartsRoutes[lang].root,
    chartsRoutes[lang].properties.root,
    chartsRoutes[lang].flats.root,
    chartsRoutes[lang].houses.root,
    chartsRoutes[lang].properties.city.root,
    chartsRoutes[lang].flats.city.root,
    chartsRoutes[lang].houses.city.root
];

const filters = css`
    ${flex()};
    ${order(3)}

    ${onDesktop(order(1))}
`;
