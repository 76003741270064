import {CitySlug, getCityOption, mapParamSlugToCitySlug} from "../../../../../../config/cities";
import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {mapPathnameToOfferType} from "../../../../common/app/routing/offer_type";
import {setAppStatus} from "../../../app_status/app_status_slice";
import {IActionContext} from "../create_path_to_action";
import {ResponseStatus} from "../state/app_status/IAppStatusState";
import {setAuthAndUser} from "./set_auth_and_user";
import {setError} from "./set_error";
import {setLatestEntryAction} from "./set_latest_entry";

export const subpageAction = async (ctx: IActionContext<ICityParams>) => {
    await setAuthAndUser(ctx.store);

    const {city} = ctx.match.params;
    const citySlug = mapParamSlugToCitySlug(city);
    const pathname = ctx.route.pathname;

    // if city doesn't exist, show 404 page
    if (!citySlug) {
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }
    // if provided offer type doesn't exist for the given city, show 404 page
    const routeOfferType = mapPathnameToOfferType(ctx.route.pathname);
    if (!getCityOption(citySlug).availableOfferTypes.includes(routeOfferType)) {
        setError(ctx.store, ResponseStatus.PAGE_404);
        return;
    }

    await setLatestEntryAction(ctx.store);
};
