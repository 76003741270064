import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {getBigDataApiPath} from "../../../../../utils/get_big_data_api_path";
import prepareHeaders from "../../../store/prepare_headers";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: getBigDataApiPath(),
        prepareHeaders: prepareHeaders,
        credentials: "include"
    }),
    endpoints: () => ({})
});
