import {Options} from "highcharts";

import {RequestStatus} from "../../../common/app/constants/enums";
import {placeholderMonthData} from "../../../common/app/constants/placeholder_charts_data";
import {PieChart} from "../Pie";

export const PieChartPlaceholder = () => (
    <PieChart
        data={placeholderMonthData}
        dataStatus={RequestStatus.SUCCESS}
        colorSet="alternate"
        height={225}
        fields={["value1", "value2", "value3", "value4", "value5"]}
        customOptions={options}
    />
);

const options: Options = {
    tooltip: {
        enabled: false
    }
};
