export const placeholderMonthData = [
    {
        date: "2020-01-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 34,
        value2: 25,
        value3: 19,
        value4: 7,
        value5: 13
    }
];

export const placeholderYearData = [
    {
        date: "2020-01-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 8,
        value2: 25,
        value3: 17,
        value4: 23,
        value5: 19
    },
    {
        date: "2020-02-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 28,
        value2: 20,
        value3: 7,
        value4: 21,
        value5: 27
    },
    {
        date: "2020-03-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 19,
        value2: 16,
        value3: 16,
        value4: 14,
        value5: 9
    },
    {
        date: "2020-04-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 22,
        value2: 23,
        value3: 8,
        value4: 23,
        value5: 11
    },
    {
        date: "2020-05-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 27,
        value2: 5,
        value3: 30,
        value4: 25,
        value5: 14
    },
    {
        date: "2020-06-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 19,
        value2: 22,
        value3: 25,
        value4: 16,
        value5: 19
    },
    {
        date: "2020-07-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 19,
        value2: 15,
        value3: 11,
        value4: 15,
        value5: 23
    },
    {
        date: "2020-08-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 7,
        value2: 28,
        value3: 11,
        value4: 13,
        value5: 10
    },
    {
        date: "2020-09-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 28,
        value2: 29,
        value3: 24,
        value4: 23,
        value5: 15
    },
    {
        date: "2020-10-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 18,
        value2: 7,
        value3: 18,
        value4: 8,
        value5: 22
    },
    {
        date: "2020-11-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 11,
        value2: 22,
        value3: 13,
        value4: 18,
        value5: 29
    },
    {
        date: "2020-12-01",
        slug_city: "warszawa",
        city: "Warszawa",
        value1: 30,
        value2: 27,
        value3: 29,
        value4: 12,
        value5: 14
    }
];
