import {useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {css, useTheme} from "@emotion/react";
import {Button} from "@pg-design/button";
import {Col, Container, Row} from "@pg-design/grid";
import {
    borderRadius,
    calculateRemSize,
    flexAlignCenter,
    flexDirection,
    h100,
    mb,
    mt,
    mv,
    onDesktop,
    p,
    ph,
    w100
} from "@pg-design/helpers-css";
import {BrandChartIcon, BrandGuideIcon, BrandListIcon, BrandSearchIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {isLinkExternal} from "../../../../../utils/misc";
import {chartsRoutes} from "../../../common/app/routing/charts_routes";

interface IProductProps {
    icon: typeof BrandSearchIcon;
    iconSize: string;
    heading: string;
    content: string;
    route: string;
}

const Product = ({icon, iconSize, heading, content, route}: IProductProps) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Col lg={4} md={3} sm={4}>
            <div css={product}>
                {icon({size: iconSize, wrapperSize: iconSize, wrapperColor: theme.colors.primary})}

                <Text css={mt(3)} align="center" variant="headline_4">
                    <Trans>{heading}</Trans>
                </Text>

                <Text css={mv(3)} align="center" variant="body_copy_1">
                    {content}
                </Text>

                {isLinkExternal(route) ? (
                    <Button variant="filled_primary" href={route} target="_blank" css={button}>
                        {t("publications.landing.findout")}
                    </Button>
                ) : (
                    <Link to={route} css={button}>
                        <Button variant="filled_primary">{t("publications.landing.findout")}</Button>
                    </Link>
                )}
            </div>
        </Col>
    );
};

const product = css`
    ${p(3, 2, 3, 2)};
    ${w100};
    ${flexAlignCenter};
    ${flexDirection("column")};
    ${borderRadius(2)};
    background-color: #fff;
    ${onDesktop(h100)};
`;

const button = css`
    margin-top: auto;
`;

export const Products = () => {
    const {
        t,
        i18n: {language}
    } = useTranslation();

    const products = useMemo(
        () => [
            {
                icon: BrandSearchIcon,
                heading: t("landing.products.product1.title"),
                content: t("landing.products.product1.text"),
                route: chartsRoutes[language].monitoring.root
            },
            {
                icon: BrandGuideIcon,
                heading: t("landing.products.product2.title"),
                content: t("landing.products.product2.text"),
                route: "https://skomponuj-raport.bigdata.rynekpierwotny.pl/"
            },
            {
                icon: BrandListIcon,
                heading: t("landing.products.product3.title"),
                content: t("landing.products.product3.text"),
                route: "https://raport.bigdata.rynekpierwotny.pl/"
            },
            {
                icon: BrandChartIcon,
                heading: t("landing.products.product4.title"),
                content: t("landing.products.product4.text"),
                route: "https://skomponuj-raport.bigdata.rynekpierwotny.pl/"
            }
        ],
        [language]
    );

    return (
        <Container as="section">
            <Row>
                <Col lg={8} lgOffset={4} md={8} mdOffset={2} sm={4}>
                    <Text css={[ph(), mb(6)]} align="center" as="h3" variant="headline_1">
                        {t("landing.products.title")}
                    </Text>
                </Col>
            </Row>

            <Row css={productsWrapper}>
                {products.map((product) => (
                    <Product
                        key={product.heading}
                        icon={product.icon}
                        iconSize="4.8"
                        heading={product.heading}
                        content={product.content}
                        route={product.route}
                    />
                ))}
            </Row>
        </Container>
    );
};

const productsWrapper = css`
    row-gap: ${calculateRemSize(3)};
`;
