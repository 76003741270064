import {useMemo, useState} from "react";

import {mapParamSlugToCitySlug} from "../../config/cities";
import {getRelatedCitySlug, RegionType} from "../../config/regions";
import {isUserAuthorized} from "../../web/src/features/auth/utils/user_is_authorized";
import {selectUserData} from "../../web/src/features/users/users_slice";
import {CityPermissionStatus} from "../shared_types/user_model";
import {useAppSelector} from "./store_hooks";

export function useCityPermission(city: string) {
    const [isPermitted, setIsPermitted] = useState<boolean>(false);
    const userData = useAppSelector(selectUserData);
    const citySlug = mapParamSlugToCitySlug(city);
    const relatedCitySlug = getRelatedCitySlug(citySlug, RegionType.CITY);
    const isAuthorized = isUserAuthorized(userData);

    useMemo(() => {
        if (!userData || !userData.permissions) {
            setIsPermitted(false);
        } else if (userData && userData.permissions && isAuthorized) {
            setIsPermitted(
                userData?.permissions.some(
                    (data) =>
                        (data.slug === relatedCitySlug || data.slug === city) &&
                        data.status === CityPermissionStatus.ACCEPTED
                )
            );
        }
    }, [city, relatedCitySlug, userData]);

    return isPermitted;
}
