import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {backendApiPath} from "../../../../../api/src/constants/api_path";
import prepare_headers from "../../../store/prepare_headers";

export const inquiryApi = createApi({
    reducerPath: "inquiryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: backendApiPath.inquiry,
        prepareHeaders: prepare_headers,
        credentials: "include"
    }),
    endpoints: () => ({})
});
