import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {getContentApi} from "../../../static_content/api/get_content";
import {StaticContent} from "../../../static_content/types/Content";
import {IActionContext} from "../create_path_to_action";
import {setAuthAndDate} from "./common_action";

export const monitoringAction = async (ctx: IActionContext<ICityParams>) => {
    await setAuthAndDate(ctx);

    ctx.store.dispatch(getContentApi.endpoints.getContent.initiate(StaticContent.FAQ_MONITORING));

    await Promise.all(ctx.store.dispatch(getContentApi.util.getRunningQueriesThunk()));
};
