import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";

import {IUserModel} from "../../../../utils/shared_types/user_model";
import {IRootState} from "../../store/store";
import {loginApi} from "../auth/api/loginApi";
import {logoutApi} from "../auth/api/logoutApi";
import {registerApi} from "../auth/api/registerApi";
import {getCurrentUserApi} from "./api/getCurrentUser";
import {updateCurrentUserApi} from "./api/updateCurrentUser";

// Slice
interface IUsersState {
    formsSharedData: IUserFormsSharedData;
    userData: IUserModel | null;
}

interface IUserFormsSharedData {
    email: string;
    password: string;
}

const initialState: IUsersState = {
    formsSharedData: {
        email: "",
        password: ""
    },
    userData: null
};

export const users_slice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUserFormsSharedLogin: (state, action: PayloadAction<string>) => {
            state.formsSharedData.email = action.payload;
        },
        setUserFormsSharedPassword: (state, action: PayloadAction<string>) => {
            state.formsSharedData.password = action.payload;
        },
        setUserData: (state, action: PayloadAction<IUserModel>) => {
            state.userData = action.payload;
        },
        updateUserData: (state, action: PayloadAction<Partial<IUserModel>>) => {
            if (state.userData) {
                state.userData = {
                    ...state.userData,
                    ...action.payload
                };
            } else {
                throw new Error("Tried to access userData while it's not available");
            }
        }
    },
    extraReducers: (build) => {
        //  We shouldn't do this in other cases, data is available in useGetCurrentUserQuery already.
        //  However, in this case, to avoid unnecessary api calls after hydration, it's justified.
        //
        //  This approach is an exception until an issue is solved - Currently, we can't hydrate getCurrentUser query
        //  because we have different headers per environment. In server we add cookies, so in case of hydration,
        //  Difference in headers makes the call to run again
        //
        // TODO: Solve this issue with headers and update user data with rtk-query tags invalidation

        build.addMatcher(getCurrentUserApi.endpoints.getCurrentUser.matchFulfilled, (state, action) => {
            state.userData = action.payload;
        });

        build.addMatcher(loginApi.endpoints.login.matchFulfilled, (state, action) => {
            state.userData = action.payload;
        });

        build.addMatcher(logoutApi.endpoints.logout.matchFulfilled, (state, action) => {
            state.userData = null;
        });

        build.addMatcher(registerApi.endpoints.register.matchFulfilled, (state, action) => {
            state.userData = action.payload;
        });

        build.addMatcher(updateCurrentUserApi.endpoints.updateCurrentUser.matchFulfilled, (state, action) => {
            state.userData = action.payload;
        });
    }
});

//  Actions
export const {setUserFormsSharedLogin, setUserFormsSharedPassword, setUserData, updateUserData} = users_slice.actions;

// Selectors
export const selectUserFormsSharedData = (state: IRootState) => state.users.formsSharedData;
export const selectUserData = (state: IRootState) => state.users.userData;
