import {ICityParams} from "../../../../common/app/routing/charts_routes";
import {setAppStatus} from "../../../app_status/app_status_slice";
import {IActionContext} from "../create_path_to_action";
import {ResponseStatus} from "../state/app_status/IAppStatusState";
import {setAuthAndUser} from "./set_auth_and_user";

export const newPathRedirectAction =
    (polishPath: string, englishPath: string, newPolishPath: string, newEnglishPath: string) =>
    async (ctx: IActionContext<ICityParams>) => {
        await setAuthAndUser(ctx.store);

        const pathname = ctx.route.pathname;

        ctx.store.dispatch(
            setAppStatus({
                responseStatus: ResponseStatus.REDIRECT_301,
                url: pathname.includes(polishPath)
                    ? pathname.replace(polishPath, newPolishPath)
                    : pathname.replace(englishPath, newEnglishPath)
            })
        );
    };
