import {isEmpty, reduce} from "lodash-es";
import queryString from "query-string";

export type IQueryParams = Record<string, string | string[] | number | number[] | boolean | undefined>;

export const appendQueryString = (currentUrl: string, inputQuery: IQueryParams): string => {
    const query = reduce(inputQuery, (acc, val, key) => (!!key ? {...acc, [key]: val} : acc), {});

    if (isEmpty(query)) {
        return currentUrl;
    }

    const search = queryString.stringify(query); // query may have undefined values & returns empty string
    if (isEmpty(search)) {
        return currentUrl;
    }

    // append query string to currentUrl
    return currentUrl.indexOf("?") === -1 ? `${currentUrl}?${search}` : `${currentUrl}&${search}`;
};
