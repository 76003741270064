import React, {ReactNode} from "react";
import {css} from "@emotion/react";
import {ChevronDownIcon} from "@pg-design/icons";
import {PanelExpandable} from "@pg-design/panel-expandable";
import {Text} from "@pg-design/text";

export interface IFooterPanelExpandableProps {
    children: ReactNode;
    title: string;
    alwaysOpen?: boolean;
    className?: string;
}

export const FooterPanelExpandable = (props: IFooterPanelExpandableProps) => {
    return (
        <PanelExpandable
            css={panelStyle}
            alwaysOpen={props.alwaysOpen}
            className={props.className}
            title={
                <Text variant="headline_6" as="h4">
                    {props.title}
                </Text>
            }
            panelArrowIcon={<ChevronDownIcon size="2.4" fill="#fff" />}
        >
            {props.children}
        </PanelExpandable>
    );
};

const panelStyle = css`
    color: #fff;
`;
