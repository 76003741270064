import {useMemo} from "react";

import {howManyTimesNumberGoesIntoAnotherEvenly} from "../misc";

export const useTimeFilteredData = <D>(
    data: D[],
    selectedRange: number
): {
    filteredData: D[];
    availableRanges: number[];
} => {
    const filteredData = useMemo(() => {
        if (data && data.length > 0) {
            if (data.length < 12) {
                return data;
            }
            const slicedData = data.slice(data.length - selectedRange);
            const hmtngiae = howManyTimesNumberGoesIntoAnotherEvenly(slicedData.length, 12);
            const newData = slicedData.map((el, i) => ((i + 1) % hmtngiae === 0 ? el : null)).filter((el) => el);
            return newData as D[];
        }
        return [];
    }, [data, selectedRange]);

    const availableRanges = useMemo(() => {
        const hmtngiae = howManyTimesNumberGoesIntoAnotherEvenly(data.length, 12);

        const ranges: number[] = new Array(hmtngiae).fill(0).map((_, i) => (i + 1) * 12);

        return ranges;
    }, [data]);

    return {
        filteredData,
        availableRanges
    };
};
