import {Modal} from "@pg-design/modal";

import {PublicationOrderSuccess} from "./download_publication_modal/PublicationOrderSuccess";

interface ISuccessModalProps {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
}

export const FreeDownloadSuccessModal = ({isOpen, setOpen}: ISuccessModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onModalClose={() => {
                setOpen(false);
            }}
        >
            <Modal.Content>
                <PublicationOrderSuccess
                    isPaid={false}
                    onClick={() => {
                        setOpen(false);
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};
