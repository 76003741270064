import {useSelector} from "react-redux";

import {selectAuthStatus} from "../../web/src/features/auth/auth_slice";
import {selectUserData} from "../../web/src/features/users/users_slice";
import {UserAccessStatus} from "../shared_types/user_model";

export enum AccessStatus {
    MINIMAL,
    LIMITED,
    FULL
}

export const useAccessStatus = () => {
    const isLoggedIn = useSelector(selectAuthStatus);
    const userData = useSelector(selectUserData);
    if (!isLoggedIn || !userData || userData.access_status === UserAccessStatus.REJECTED) {
        return AccessStatus.MINIMAL;
    }
    if (userData.access_status === UserAccessStatus.PENDING || userData.access_status === UserAccessStatus.INACTIVE) {
        return AccessStatus.LIMITED;
    }
    return AccessStatus.FULL;
};
