import {css} from "@emotion/react";
import {CenterBox} from "@pg-design/grid";
import {flexAbsoluteCenter, flexJustifyCenter, mb} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";

import {IEntryCategory} from "../../types/Category";
import {EntryType} from "../../types/Entry";
import {NoContent} from "../NoContent";
import {HighlightedArticleListItem} from "./HighlightedArticleListItem";

interface IArticleListProps {
    entries?: Array<IHighlightedEntry>;
    isLoading: boolean;
    isError: boolean;
}

interface IHighlightedEntry {
    category: Array<IEntryCategory> | null;
    id: number;
    images: {
        img_385x193: string;
        img_600x300: string;
    } | null;
    lead: string;
    publication_date: string;
    region_name: string | null;
    slug: string;
    title: string;
    type: EntryType;
}

export const HighlightedArticleList = ({entries, isLoading, isError}: IArticleListProps) => {
    return (
        <ul css={articlesWrapper}>
            {entries &&
                !!entries.length &&
                entries.map((entry) => (
                    <HighlightedArticleListItem
                        css={mb(3)}
                        key={entry.title}
                        title={entry.title}
                        images={entry.images}
                        lead={entry.lead}
                        publication_date={entry.publication_date}
                        region_name={entry.region_name}
                        category={entry.category}
                        id={entry.id}
                        type={entry.type}
                        slug={entry.slug}
                    />
                ))}

            {!isLoading && !entries?.length && <NoContent />}

            {isLoading && (
                <CenterBox css={mb(6)}>
                    <Loader />
                </CenterBox>
            )}

            {isError && <div css={flexAbsoluteCenter}>Coś poszło nie tak, spróbuj odświeżyć stronę</div>}
        </ul>
    );
};

const articlesWrapper = css`
    ${flexJustifyCenter};
    flex-direction: column;
    flex-wrap: wrap;
    ${mb(4)};
`;
