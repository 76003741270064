export enum PublicationPricingType {
    PAID = 1,
    FREE = 2,
    FREE_BD_USER = 3
}

export enum PublicationDistinctionType {
    DEFAULT = 0,
    NEW = 1,
    RECOMMENDED = 2
}

enum ResourceType {
    FILE = 1,
    LINK = 2
}

enum Status {
    WAITING = 1,
    REJECTED = 2,
    ACCEPTED = 3,
    AUTOMATICALLY_ACCEPTED = 4
}

export enum PublicationType {
    REPORT = 1,
    WEBINAR = 2,
    PODCAST = 3
}

export interface IReportRequest {
    create_date: string;
    email_form_value: string;
    name_form_value: string;
    sent_date: string;
    status: Status;
    update_date: string;
}

export interface IPublication {
    distinction_type: PublicationDistinctionType;
    cover_image: string | null;
    date_of_publication: string;
    date_range: {
        bounds: "(]" | "[]" | "[)" | "()";
        lower: string;
        upper: string;
    };
    file: string | null;
    id: number;
    image: string;
    is_highlighted: boolean;
    last_report_request: IReportRequest | null;
    lead: string;
    link: string | null;
    name: string;
    price: number;
    promotional_price: number | null;
    pricing_type: PublicationPricingType;
    publication_type: PublicationType;
    resource_type: ResourceType;
    slug: string;
}
