import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";

import {IRootState} from "../../store/store";
import {getCurrentUserApi} from "../users/api/getCurrentUser";
import {updateCurrentUserApi} from "../users/api/updateCurrentUser";
import {loginApi} from "./api/loginApi";
import {logoutApi} from "./api/logoutApi";
import {registerApi} from "./api/registerApi";
import {isUserAuthorized} from "./utils/user_is_authorized";

// Slice
interface IAuthState {
    authStatus: IAuthStatus;
    currentAuthModal: IAuthModalVariant | null;
    authFormsSharedData: IAuthFormsSharedData;
}

export interface IAuthStatus {
    authChecked: boolean;
    isLoggedIn: boolean;
    isAuthorized: boolean;
}

export type IAuthModalVariant = null | "remindPassword" | "registerUser" | "login";

export interface IAuthFormsSharedData {
    email: string;
    password: string;
}

const initialState: IAuthState = {
    authStatus: {
        authChecked: false,
        isLoggedIn: false,
        isAuthorized: false
    },
    currentAuthModal: null,
    authFormsSharedData: {
        email: "",
        password: ""
    }
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthStatus: (state, action: PayloadAction<IAuthStatus>) => {
            state.authStatus = action.payload;
        },
        setAuthModal: (state, action: PayloadAction<IAuthModalVariant>) => {
            state.currentAuthModal = action.payload;
        },
        setAuthFormsSharedData: (state, action: PayloadAction<IAuthFormsSharedData>) => {
            state.authFormsSharedData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(getCurrentUserApi.endpoints.getCurrentUser.matchFulfilled, (state, action) => {
            state.authStatus.authChecked = true;
            state.authStatus.isLoggedIn = true;
            state.authStatus.isAuthorized = isUserAuthorized(action.payload);
        });

        builder.addMatcher(getCurrentUserApi.endpoints.getCurrentUser.matchRejected, (state, action) => {
            // Api will receive rejection when deciding to not maka a call, for example, because of data being already available in cache (hydrated from server)
            if (
                action.error.name === "ConditionError" &&
                action.error.message === "Aborted due to condition callback returning false."
            ) {
                return;
            }

            state.authStatus.authChecked = true;
            state.authStatus.isLoggedIn = false;
            state.authStatus.isAuthorized = false;
        });

        builder.addMatcher(loginApi.endpoints.login.matchFulfilled, (state, action) => {
            state.authStatus.authChecked = true;
            state.authStatus.isLoggedIn = true;
            state.authStatus.isAuthorized = isUserAuthorized(action.payload);
        });

        builder.addMatcher(logoutApi.endpoints.logout.matchFulfilled, (state, action) => {
            state.authStatus.authChecked = true;
            state.authStatus.isLoggedIn = false;
            state.authStatus.isAuthorized = false;
        });

        builder.addMatcher(registerApi.endpoints.register.matchFulfilled, (state, action) => {
            state.authStatus.authChecked = true;
            state.authStatus.isLoggedIn = true;
            state.authStatus.isAuthorized = isUserAuthorized(action.payload);
        });

        builder.addMatcher(updateCurrentUserApi.endpoints.updateCurrentUser.matchFulfilled, (state, action) => {
            state.authStatus.isAuthorized = isUserAuthorized(action.payload);
        });
    }
});

//  Actions
export const {setAuthFormsSharedData, setAuthModal, setAuthStatus} = authSlice.actions;

// Selectors
export const selectAuthStatus = (state: IRootState) => state.auth.authStatus;
export const selectCurrentAuthModal = (state: IRootState) => state.auth.currentAuthModal;
export const selectAuthFormsSharedData = (state: IRootState) => state.auth.authFormsSharedData;
