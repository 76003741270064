import {ReactNode, useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";
import {css, useTheme} from "@emotion/react";
import {Col, Container, Row} from "@pg-design/grid";
import {flex, mb, ml, mt} from "@pg-design/helpers-css";
import {
    BrandBuildingIcon,
    BrandChartIcon,
    BrandCoinIcon,
    BrandFlagIcon,
    BrandMapPinIcon,
    BrandSkyscrapersIcon
} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {cityConfig} from "../../../../../config/cities";

interface IFeatureProps {
    icon: JSX.Element;
    title: string | ReactNode;
    text: string | ReactNode;
}

const Feature = (props: IFeatureProps) => (
    <Col lg={5} md={4} sm={4}>
        <div css={featureWrapper}>
            {props.icon}

            <div css={ml(3)}>
                <Text as="h4" variant="body_copy_0" strong>
                    {props.title}
                </Text>
                <Text css={mt(2)} variant="body_copy_2">
                    {props.text}
                </Text>
            </div>
        </div>
    </Col>
);

const featureWrapper = css`
    ${mb(9)};
    ${flex()};
`;

export const Features = () => {
    const {t, i18n} = useTranslation();
    const theme = useTheme();

    const citiesCount = Object.entries(cityConfig)
        .filter((city) => city[1].enabled)
        .filter((city) => city[1].isCity).length;

    const features = useMemo(
        () => [
            {
                icon: <BrandBuildingIcon size="4" wrapperSize="4" wrapperColor={theme.colors.highlight} />,
                title: <Trans>{t("landing.features.feature1.title")}</Trans>,
                text: <Trans>{t("landing.features.feature1.text", {citiesCount})}</Trans>
            },
            {
                icon: <BrandCoinIcon size="4" wrapperSize="4" wrapperColor={theme.colors.success} />,
                title: <Trans>{t("landing.features.feature2.title")}</Trans>,
                text: <Trans>{t("landing.features.feature2.text")}</Trans>
            },
            {
                icon: <BrandSkyscrapersIcon size="4" wrapperSize="4" wrapperColor={theme.colors.warning} />,
                title: <Trans>{t("landing.features.feature3.title")}</Trans>,
                text: <Trans>{t("landing.features.feature3.text")}</Trans>
            },
            {
                icon: <BrandMapPinIcon size="4" wrapperSize="4" wrapperColor={theme.colors.success} />,
                title: <Trans>{t("landing.features.feature4.title")}</Trans>,
                text: <Trans>{t("landing.features.feature4.text")}</Trans>
            },
            {
                icon: <BrandFlagIcon size="4" wrapperSize="4" wrapperColor={theme.colors.info} />,
                title: <Trans>{t("landing.features.feature5.title")}</Trans>,
                text: <Trans>{t("landing.features.feature5.text")}</Trans>
            },
            {
                icon: <BrandChartIcon size="4" wrapperSize="4" wrapperColor={theme.colors.background[200]} />,
                title: <Trans>{t("landing.features.feature6.title")}</Trans>,
                text: <Trans>{t("landing.features.feature6.text")}</Trans>
            }
        ],
        [i18n.language]
    );

    return (
        <Container css={mb(8)} as="section">
            <Row>
                <Col lg={10} lgOffset={3} md={10} mdOffset={1} sm={4}>
                    <Text css={mb(6)} align="center" as="h3" variant="headline_1">
                        {t("landing.features.heading")}
                    </Text>
                </Col>
            </Row>

            <Row>
                {features.map((feature, index) => (
                    <Feature key={index} icon={feature.icon} title={feature.title} text={feature.text} />
                ))}
            </Row>
        </Container>
    );
};
