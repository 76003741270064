import {css} from "@emotion/react";
import {calculateRemSize} from "@pg-design/helpers-css";
import {Size} from "@pg-design/helpers-css/src/utils/rem";

export const centeredFlex = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexGrow = (value: number) => css`
    flex-grow: ${value};
`;

export const flexShrink = (value: number) => css`
    flex-shrink: ${value};
`;

export const flexGap = (value: Size) => css`
    gap: ${calculateRemSize(value)};
`;
