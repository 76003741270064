import {useRef} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Link, useHistory, useRouteMatch} from "react-router-dom";

import {
    CitySlug,
    getCityOption,
    mapCitySlugToLocalizedParamSlug,
    mapParamSlugToCitySlug,
    validateOfferType
} from "../../../../../config/cities";
import {getRelatedCitySlug, mapCitySlugToRegionType, RegionType} from "../../../../../config/regions";
import {useAppSelector} from "../../../../../utils/hooks/store_hooks";
import {useAccessDate} from "../../../../../utils/hooks/useAccessDate";
import {capitalize} from "../../../../../utils/misc";
import {FilterOfferType, RequestStatus} from "../../../common/app/constants/enums";
import {chartsRoutes, compilePath, ICityParams} from "../../../common/app/routing/charts_routes";
import {mapOfferTypeToValidPath, OfferType} from "../../../common/app/routing/offer_type";
import {Card} from "../../../components/card/Card";
import {RatioBarChart} from "../../../components/charts/Bar";
import {SwitchableMapChartCard} from "../../../components/charts/chart_cards/switchable_map_chart_card/SwitchableMapChartCard";
import {RatioColumnChart} from "../../../components/charts/Column";
import {PieChart} from "../../../components/charts/Pie";
import {SplineChart} from "../../../components/charts/Spline";
import {Grid} from "../../../components/Grid";
import {withDateGuard} from "../../../components/hoc/withDateGuard";
import {withGTM} from "../../../components/hoc/withGTM";
import {ChartsLayout as Layout} from "../../../components/layout/charts_layout/ChartsLayout";
import {Division, MathEquation} from "../../../components/MathEquation";
import {selectLatestEntry} from "../../ssr/redux/latest_entry_slice";
import {pageviewAlgolyticsHit} from "../../tracking/algolytics/pageview_hits";
import {usePageview} from "../../tracking/use_pageview";
import {pageviewUserComHit} from "../../tracking/user_com/hit_user_com";
import {ViewType} from "../../tracking/view_type";
import {useGetMonthCityStatsQuery, useGetMonthCityTypeStatsQuery} from "../api/analytical_platform_api";
import {Barometer} from "../components/barometer/Barometer";
import {mapRtkQueryStatusToRequestStatus} from "../utils/rtq_status_to_request_status";

export const Dashboard = withDateGuard(
    withGTM(() => {
        const {
            path,
            params: {city}
        } = useRouteMatch<ICityParams>();
        const citySlug = mapParamSlugToCitySlug(city);
        const regionType = mapCitySlugToRegionType(citySlug);
        usePageview(() => {
            pageviewAlgolyticsHit({citySlug, offerType: OfferType.PROPERTY, regionType, viewType: ViewType.DASHBOARD});
            pageviewUserComHit();
        });
        const history = useHistory();
        const {t, i18n} = useTranslation();
        // get current date from initial context for data fetching purposes
        const {currentDate} = useAppSelector(selectLatestEntry);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore withDateGuard makes sure currentDate is not null
        const {upperBound, lowerBound} = useAccessDate(currentDate);
        // use adequate endpoints, depending on being on a city or an agglomeration view
        const isAgglomerationView = getCityOption(citySlug).useAgglomerationApi;

        const requestSlugKey = isAgglomerationView ? "slug_agglomeration" : "slug_city";
        const requestCitySlug = getCityOption(citySlug).requestCitySlug;

        const queryOptions = {
            [requestSlugKey]: requestCitySlug,
            date_start: lowerBound,
            date_end: upperBound,
            scenario: "dashboard"
        };

        const {data, error, isError, isSuccess} = useGetMonthCityStatsQuery(queryOptions);
        const {
            data: typeData,
            isError: isTypeDataError,
            isSuccess: isTypeDataSuccess
        } = useGetMonthCityTypeStatsQuery(queryOptions);

        const dataStatus = mapRtkQueryStatusToRequestStatus(isError, isSuccess);
        const typeDataStatus = mapRtkQueryStatusToRequestStatus(isTypeDataError, isTypeDataSuccess);

        const monthData = Array.isArray(data) && data.length > 0 ? [data[data.length - 1]] : [];
        // define charts references for exporting purposes
        const chart6Ref = useRef(null);
        const chart8Ref = useRef(null);
        const handleCitySelection = (city: CitySlug) => {
            const validOfferType = validateOfferType(city, OfferType.PROPERTY);
            const targetPath = mapOfferTypeToValidPath(
                validOfferType,
                chartsRoutes[i18n.language].properties.city.root,
                i18n
            );
            const localizedCityParam = mapCitySlugToLocalizedParamSlug(city, i18n.language);
            const compiledPath = compilePath(targetPath, {city: localizedCityParam});
            history.push(compiledPath);
        };
        const handleRegionChange = (region: RegionType) => {
            const targetCitySlug = getRelatedCitySlug(citySlug, region);
            const paramCitySlug = mapCitySlugToLocalizedParamSlug(targetCitySlug, i18n.language);
            const compiledPath = compilePath(path, {city: paramCitySlug});
            history.push(compiledPath);
        };
        const getDetailedViewUrl = (url: string) => {
            const validOfferType = validateOfferType(citySlug, OfferType.PROPERTY);
            const targetPath = mapOfferTypeToValidPath(validOfferType, url, i18n);
            const paramCitySlug = mapCitySlugToLocalizedParamSlug(citySlug, i18n.language);
            return compilePath(targetPath, {city: paramCitySlug});
        };

        return (
            <Layout onCitySelection={handleCitySelection} onRegionChange={handleRegionChange}>
                {data && typeData && (
                    <Grid type="dashboard">
                        {/*General Barometer*/}

                        <Card
                            area="chart1"
                            title={<Trans>{t("dashboard.barometer.title")}</Trans>}
                            helper={<Trans>{t("dashboard.barometer.description")}</Trans>}
                        >
                            <Barometer />
                        </Card>
                        {/*Average price per sqm*/}
                        <Card
                            area="chart2"
                            title={<Trans>{t("dashboard.chart2.title", {propertyType: "property"})}</Trans>}
                            helper={<Trans>{t("dashboard.chart2.description", {propertyType: "properties"})}</Trans>}
                            footer={
                                <Link
                                    to={getDetailedViewUrl(chartsRoutes[i18n.language].properties.city.prices)}
                                    rel="nofollow"
                                >
                                    {t("dashboard.more_about_prices")}
                                </Link>
                            }
                        >
                            <SplineChart
                                colorSet="red"
                                data={data}
                                dataStatus={RequestStatus.SUCCESS}
                                field="avg_price_m2"
                                height={175}
                                roundValues
                                currentLabelUnit="pln_m2"
                            />
                        </Card>
                        {/*Number of properties available*/}
                        <Card
                            area="chart3"
                            title={<Trans>{t("dashboard.chart3.title", {propertyType: "properties"})}</Trans>}
                            helper={<Trans>{t("dashboard.chart3.description", {propertyType: "properties"})}</Trans>}
                            footer={
                                <Link
                                    to={getDetailedViewUrl(chartsRoutes[i18n.language].properties.city.offer)}
                                    rel="nofollow"
                                >
                                    {t("dashboard.more_about_supply")}
                                </Link>
                            }
                        >
                            <SplineChart
                                colorSet="green"
                                data={data}
                                dataStatus={dataStatus}
                                field="available_offers"
                                height={175}
                                currentLabelUnit="offer"
                            />
                        </Card>
                        {/*Demand index for properties*/}
                        <Card
                            area="chart4"
                            title={<Trans>{t("dashboard.chart4.title", {propertyType: "properties"})}</Trans>}
                            helper={
                                <>
                                    {t("dashboard.chart4.description", {propertyType: "properties"})}
                                    <br />
                                    <br />
                                    <MathEquation
                                        leftSide={t("common.formulas.number_of_offers")}
                                        rightSide={`${t("common.formulas.sold_offers")} + ${t(
                                            "common.formulas.available_offers"
                                        )}`}
                                    />
                                    <br />
                                    <MathEquation
                                        leftSide={t("common.formulas.demand_indicator")}
                                        rightSide={
                                            <Division
                                                dividend={t("common.formulas.sold_offers")}
                                                divisor={t("common.formulas.number_of_offers")}
                                            />
                                        }
                                    />
                                </>
                            }
                            footer={
                                <Link
                                    to={getDetailedViewUrl(chartsRoutes[i18n.language].properties.city.demand)}
                                    rel="nofollow"
                                >
                                    {t("dashboard.more_about_demand")}
                                </Link>
                            }
                        >
                            <SplineChart
                                colorSet="yellow"
                                data={data}
                                dataStatus={dataStatus}
                                field="demand"
                                currentLabelUnit="percent"
                                valuesUnit="%"
                                height={175}
                                valueMultiplier={100}
                            />
                        </Card>

                        <SwitchableMapChartCard
                            city={citySlug}
                            title={
                                <Trans>{t("dashboard.map.title", {propertyType: "properties", city: citySlug})}</Trans>
                            }
                            helper={
                                <Trans>
                                    {t("dashboard.map.description", {propertyType: "properties", city: citySlug})}
                                </Trans>
                            }
                        />
                        {/*Offers available for sale by size*/}
                        <Card
                            area="chart5"
                            title={<Trans>{t("dashboard.chart5.title")}</Trans>}
                            helper={<Trans>{t("dashboard.chart5.description", {propertyType: "property"})}</Trans>}
                            footer={
                                <Link
                                    to={getDetailedViewUrl(chartsRoutes[i18n.language].properties.city.offer)}
                                    rel="nofollow"
                                >
                                    {t("dashboard.more_about_investments")}
                                </Link>
                            }
                        >
                            <PieChart
                                colorSet="alternate"
                                data={typeData}
                                dataStatus={typeDataStatus}
                                fields={[
                                    "available_investment_type_intimate",
                                    "available_investment_type_small",
                                    "available_investment_type_medium",
                                    "available_investment_type_large",
                                    "available_investment_type_x_large"
                                ]}
                                height={175}
                                offerType={FilterOfferType.FLATS}
                                innerTitle={capitalize(
                                    getCityOption(citySlug).propertyTypeFilters
                                        ? t("common.flats.nominative")
                                        : t("common.investments")
                                )}
                            />

                            <PieChart
                                colorSet="alternate"
                                data={typeData}
                                dataStatus={typeDataStatus}
                                fields={[
                                    "available_investment_type_intimate",
                                    "available_investment_type_small",
                                    "available_investment_type_medium",
                                    "available_investment_type_large",
                                    "available_investment_type_x_large"
                                ]}
                                height={175}
                                offerType={FilterOfferType.HOUSES}
                                innerTitle={capitalize(t("common.houses.nominative"))}
                            />
                        </Card>
                        {/*Properties available*/}
                        <Card
                            area="chart6"
                            title={
                                <Trans>{capitalize(t("dashboard.chart6.title", {propertyType: "properties"}))}</Trans>
                            }
                            helper={<Trans>{t("dashboard.chart6.description", {propertyType: "properties"})}</Trans>}
                            footer={
                                <Link
                                    to={getDetailedViewUrl(chartsRoutes[i18n.language].properties.city.offer)}
                                    rel="nofollow"
                                >
                                    {t("dashboard.more_about_offer")}
                                </Link>
                            }
                            chartRef={chart6Ref}
                        >
                            <RatioBarChart
                                colorSet="purple"
                                data={monthData}
                                dataStatus={dataStatus}
                                fields={[
                                    "available_studio",
                                    "available_2_rooms",
                                    "available_3_rooms",
                                    "available_4_plus_rooms",
                                    "available_house"
                                ]}
                                height={175}
                                innerRef={chart6Ref}
                            />
                        </Card>
                        {/*Properties sold*/}
                        <Card
                            area="chart7"
                            title={
                                <Trans>{capitalize(t("dashboard.chart7.title", {propertyType: "properties"}))}</Trans>
                            }
                            helper={<Trans>{t("dashboard.chart7.description", {propertyType: "properties"})}</Trans>}
                            footer={
                                <Link
                                    to={getDetailedViewUrl(chartsRoutes[i18n.language].properties.city.sold)}
                                    rel="nofollow"
                                >
                                    {t("dashboard.more_about_sold")}
                                </Link>
                            }
                        >
                            <SplineChart
                                colorSet="orange"
                                data={data}
                                dataStatus={dataStatus}
                                field="sold"
                                height={175}
                                currentLabelUnit="sold"
                                currentLabelUnitContext="female" // female = odmiana formy żeńskiej i18n, tutaj ona - nieruchomość
                            />
                        </Card>
                        {/*Offers marketed for sale*/}
                        <Card
                            area="chart8"
                            title={<Trans>{t("dashboard.chart8.title", {propertyType: "properties"})}</Trans>}
                            helper={<Trans>{t("dashboard.chart8.description")}</Trans>}
                            footer={
                                <Link
                                    to={getDetailedViewUrl(chartsRoutes[i18n.language].properties.city.added)}
                                    rel="nofollow"
                                >
                                    {t("dashboard.more_about_added")}
                                </Link>
                            }
                            chartRef={chart8Ref}
                        >
                            <RatioColumnChart
                                colorSet="purple"
                                data={monthData}
                                dataStatus={dataStatus}
                                fields={[
                                    "added_investment_type_intimate",
                                    "added_investment_type_small",
                                    "added_investment_type_medium",
                                    "added_investment_type_large",
                                    "added_investment_type_x_large",
                                    "added_investment_type_house"
                                ]}
                                height={200}
                                innerRef={chart8Ref}
                            />
                        </Card>
                    </Grid>
                )}
            </Layout>
        );
    })
);
