import {i18n} from "i18next";

export enum OfferType {
    FLAT = "flat",
    HOUSE = "house",
    PROPERTY = "property"
}

export const offerTypeToText =
    ([textProperty, textFlat, textHouse]: string[]) =>
    (offerType: OfferType): string => {
        switch (offerType) {
            case OfferType.PROPERTY:
                return textProperty;
            case OfferType.FLAT:
                return textFlat;
            case OfferType.HOUSE:
                return textHouse;
            default:
                throw new Error(`offerTypeToText: invalid offerType: ${offerType}`);
        }
    };

export const mapOfferTypeToValidPath = (offerType: OfferType, path: string, i18n: i18n): string | never => {
    const {language} = i18n;
    let newPath = "";
    switch (true) {
        case offerType === OfferType.PROPERTY && language === "pl":
            newPath = "ceny/nieruchomosci";
            break;
        case offerType === OfferType.FLAT && language === "pl":
            newPath = "ceny/mieszkania";
            break;
        case offerType === OfferType.HOUSE && language === "pl":
            newPath = "ceny/domy";
            break;
        case offerType === OfferType.PROPERTY && language === "en":
            newPath = "prices/properties";
            break;
        case offerType === OfferType.FLAT && language === "en":
            newPath = "prices/flats";
            break;
        case offerType === OfferType.HOUSE && language === "en":
            newPath = "prices/houses";
            break;
        default:
            throw new Error(`Invalid offer type provided to mapOfferTypeToValidPath(): ${offerType}`);
    }
    const patterns: {
        [key: string]: RegExp;
    } = {
        pl: new RegExp("ceny/nieruchomosci|ceny/mieszkania|ceny/domy"),
        en: new RegExp("prices/properties|prices/flats|prices/houses")
    };
    const finalPathname = path.replace(patterns[language], newPath);
    return finalPathname;
};

export const mapPathnameToOfferType = (pathname: string): OfferType | never => {
    switch (true) {
        case pathname.includes("ceny/mieszkania"):
        case pathname.includes("prices/flats"):
            return OfferType.FLAT;
        case pathname.includes("ceny/domy"):
        case pathname.includes("prices/houses"):
            return OfferType.HOUSE;
        case pathname.includes("ceny/nieruchomosci"):
        case pathname.includes("prices/properties"):
            return OfferType.PROPERTY;
        default:
            return OfferType.FLAT;
    }
};
