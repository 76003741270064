import {TooltipFormatterContextObject} from "highcharts";
import {i18n} from "i18next";

import {mapLegendColors} from "../web/src/common/app/constants/colors";
import {voivodeshipCapitalsByName} from "../web/src/common/app/constants/constants";
import {FilterOfferType} from "../web/src/common/app/constants/enums";
import {formatDate, humanDateFormatLong} from "./dates";
import {formatNumber} from "./format";

export const formatTooltip = (context: TooltipFormatterContextObject, unit?: string, roundValues?: boolean): string => {
    let template = "";

    template += `<strong>${context.point.name}:</strong> `;

    template += formatNumber(context.y ?? context.point.value, !roundValues);

    if (unit) {
        template += ` ${unit}`;
    }

    return template;
};

export const formatLineColumnTooltip = (
    context: TooltipFormatterContextObject,
    units?: {
        line?: string;
        column?: string;
    },
    roundValues?: boolean
): string => {
    const {type: seriesType} = context.point.series.userOptions;

    let template = "";

    template += `<strong>${context.point.series.name}:</strong> `;

    if (context.y) {
        template += formatNumber(context.y, !roundValues);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (units && seriesType in units && units[seriesType] !== "") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore withDateGuard makes sure currentDate is not null
        template += ` ${units[seriesType]}`;
    }

    return template;
};

export const formatColoredMapTooltip = (
    context: TooltipFormatterContextObject,
    hideNullValues = false,
    roundValues = false,
    i18n: i18n,
    unit?: string
): string => {
    const {name, value, color} = context.point;

    let template = `<div class="map-colored-tooltip-wrapper" style="border-left-color: ${color}">`;

    template += `<div>${name}</div>`;

    if (context.y) {
        template += `<strong>${formatNumber(context.y, !roundValues)}</strong>`;
    } else if (value) {
        if (value > 0.00001 || !hideNullValues) {
            template += `<strong>${formatNumber(value, !roundValues)}</strong>`;
        } else {
            template += `<strong>Brak danych</strong>`;
        }
    }

    if (unit && value && value > 0.00001) {
        template += `<strong> ${i18n.t(`common.${unit}`, {count: value})}</strong>`;
    }

    template += "</div>";

    return template;
};

export const formatSharedTooltip = (
    context: TooltipFormatterContextObject,
    highlightedPoint: number,
    roundValues = false,
    i18n: i18n,
    prefix?: string
): string => {
    let template = "";
    if (context.x) {
        template += `<strong class="title">${formatDate(context.x.toString(), humanDateFormatLong, i18n)}</strong>`;
    }
    template += `<div class="points">`;
    context.points?.forEach((point) => {
        const match = point.y === highlightedPoint;
        template += `<div class="point ${match && "matchedPoint"}"><span class="dot" style="background-color: ${
            point.color
        };"></span> ${point.series.name}: ${formatNumber(point.y, !roundValues)}${prefix ? "" + prefix : ""}</div>`;
    });
    template += `</div>`;
    return template;
};

// @TODO any
export const formatMapTooltip = (
    context: TooltipFormatterContextObject,
    data: Record<string, Record<string, number | string>>,
    fields: string[],
    i18n: i18n
): string | null => {
    if (context.key) {
        // use just series title if translation is not available
        // if translation returns the key, then translation doesn't exist
        const headingTranslation = i18n.t(`voivodeship.${context.key}`);
        const heading = headingTranslation === `voivodeship.${context.key}` ? context.key : headingTranslation;
        let template = `<div>${heading}</div>`;
        // TODO: we need to check usage of `id`
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const {id} = context.point;
        const cityHeadingTranslation = i18n.t(`city.${voivodeshipCapitalsByName[id]}.nominative`);
        const cityHeading =
            cityHeadingTranslation === `city.${voivodeshipCapitalsByName[id]}.nominative`
                ? null
                : cityHeadingTranslation;
        if (id && cityHeading) {
            template += `<div>${i18n.t(`city.${voivodeshipCapitalsByName[id]}.nominative`)}</div>`;
        }

        try {
            const {id} = context.point.options;

            if (id) {
                const hasValues = !!Object.values(data[id]).filter(
                    (el) => el !== Number.MIN_VALUE && typeof el !== "string"
                ).length;

                if (hasValues) {
                    template += `<div class="values">`;
                    fields.forEach((field) => {
                        template += `<div class="value" style="background-color: ${mapLegendColors[field]}">`;
                        template += formatNumber(Math.round(Number(data[id ?? ""][field])));
                        template += `</div>`;
                    });

                    template += "</div>";
                }
            }
        } catch {
            /* empty */
        }

        return template;
    }

    return null;
};

export const formatPieTooltip = (
    context: TooltipFormatterContextObject,
    i18n: i18n,
    labelKey?: string,
    offerType?: FilterOfferType
) => {
    let template = "";

    if (context.point.name) {
        template += `<strong>${context.point.name}</strong>`;
    }
    // TODO: check why we are trying to use `point.id` while `id` is no a `point` api element
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const dataLabel = dataLabelsExplainers(i18n, offerType)[context.point.id];
    if (dataLabel) {
        template += `<small>(${dataLabel})</small>`;
    }

    if (context.percentage && context.series.userOptions.type === "pie") {
        template += `<b>`;
        template += `${formatNumber(Math.round(context.percentage))}% `;
        template += `</b>`;
    }

    if (typeof context.y === "number" && labelKey) {
        template += `(${formatNumber(context.y)} ${i18n.t(`common.${labelKey}`, {count: context.y})})`;
    }

    return template;
};

const dataLabelsExplainers = (i18n: i18n, offerType?: FilterOfferType): IDictionary => {
    switch (offerType) {
        case FilterOfferType.FLATS:
            return {
                available_investment_type_intimate: i18n.t(
                    "common.investment_types.with_flats.available_investment_type_intimate"
                ),
                available_investment_type_small: i18n.t(
                    "common.investment_types.with_flats.available_investment_type_small"
                ),
                available_investment_type_medium: i18n.t(
                    "common.investment_types.with_flats.available_investment_type_medium"
                ),
                available_investment_type_large: i18n.t(
                    "common.investment_types.with_flats.available_investment_type_large"
                ),
                available_investment_type_x_large: i18n.t(
                    "common.investment_types.with_flats.available_investment_type_x_large"
                )
            };
        case FilterOfferType.HOUSES:
            return {
                available_investment_type_intimate: i18n.t(
                    "common.investment_types.with_houses.available_investment_type_intimate"
                ),
                available_investment_type_small: i18n.t(
                    "common.investment_types.with_houses.available_investment_type_small"
                ),
                available_investment_type_medium: i18n.t(
                    "common.investment_types.with_houses.available_investment_type_medium"
                ),
                available_investment_type_large: i18n.t(
                    "common.investment_types.with_houses.available_investment_type_large"
                ),
                available_investment_type_x_large: i18n.t(
                    "common.investment_types.with_houses.available_investment_type_x_large"
                )
            };
        default:
            return {
                available_investment_type_intimate: i18n.t(
                    "common.investment_types.as_investments.available_investment_type_intimate"
                ),
                available_investment_type_small: i18n.t(
                    "common.investment_types.as_investments.available_investment_type_small"
                ),
                available_investment_type_medium: i18n.t(
                    "common.investment_types.as_investments.available_investment_type_medium"
                ),
                available_investment_type_large: i18n.t(
                    "common.investment_types.as_investments.available_investment_type_large"
                ),
                available_investment_type_x_large: i18n.t(
                    "common.investment_types.as_investments.available_investment_type_x_large"
                )
            };
    }
};

export const formatColumnStackedTooltip = (
    context: TooltipFormatterContextObject,
    unit: string | undefined,
    highlightedPointIndex: number | null
): string => {
    let template = "";
    template += `<strong class="title">${context.x}</strong>`;
    template += `<div class="points">`;
    context.points?.forEach((point) => {
        const match = point.y === highlightedPointIndex;

        template += `<div class="point ${match && "matchedPoint"}"><span class="dot" style="background-color: ${
            point.color
        };"></span> ${point.key}: ${formatNumber(point.y, true)}${unit ? " " + unit : ""}</div>`;
    });
    template += `</div>`;
    return template;
};
