import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import {getBigDataApiPath} from "../../../../../utils/get_big_data_api_path";
import {getClientCookies} from "../../../client/get_client_cookies";
import prepareHeaders from "../../../store/prepare_headers";

interface IResetPasswordPayload {
    email: string;
}

export interface IResetPasswordPayloadError {
    email: string;
}

interface IVerifyPasswordLinkPayload {
    uid: string;
    token: string;
}

export interface IVerifyPasswordLinkPayloadError {
    token: string;
    uid: string;
}

interface IConfirmNewPasswordPayload {
    password: string;
    uid: string;
    token: string;
}

export interface IConfirmNewPasswordPayloadError {
    password: string[];
    token: string;
    uid: string;
}

export const resetPasswordApi = createApi({
    reducerPath: "passwordResetApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${getBigDataApiPath()}users/password/`,
        prepareHeaders: prepareHeaders,
        credentials: "include"
    }),
    endpoints: (build) => ({
        sendPasswordResetLink: build.mutation<void, IResetPasswordPayload>({
            query: (resetPasswordPayload) => ({
                url: `reset/`,
                method: "POST",
                body: resetPasswordPayload
            })
        }),
        verifyPasswordLink: build.mutation<void, IVerifyPasswordLinkPayload>({
            query: (verifyPasswordLinkPayload) => ({
                url: `reset/verify/`,
                method: "POST",
                body: verifyPasswordLinkPayload
            })
        }),
        confirmNewPassword: build.mutation<void, IConfirmNewPasswordPayload>({
            query: (confirmNewPasswordPayload) => ({
                url: `reset/confirm/`,
                method: "POST",
                body: confirmNewPasswordPayload
            })
        })
    })
});

export const {useSendPasswordResetLinkMutation, useVerifyPasswordLinkMutation, useConfirmNewPasswordMutation} =
    resetPasswordApi;
