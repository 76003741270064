import {ReactNode} from "react";
import styled from "@emotion/styled";

interface IProps {
    leftSide: ReactNode | string;
    rightSide: ReactNode | string;
}

export const MathEquation = ({leftSide, rightSide}: IProps) => {
    return (
        <StyledMathEquation>
            <div>{leftSide}</div>
            <div className="equality-sign">=</div>
            <div>{rightSide}</div>
        </StyledMathEquation>
    );
};

const StyledMathEquation = styled.div`
    font-weight: 500;
    font-style: italic;
    display: flex;
    align-items: center;
    text-align: center;

    .equality-sign {
        margin: 0 8px;
    }
`;

interface IDivisionProps {
    dividend: ReactNode | string;
    divisor: ReactNode | string;
}

export const Division = ({dividend, divisor}: IDivisionProps) => {
    return (
        <StyledDivision>
            <div>{dividend}</div>
            <span />
            <div>{divisor}</div>
        </StyledDivision>
    );
};

const StyledDivision = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 8px;

    & > div {
        padding: 4px 8px;
    }

    & > span {
        display: block;
        height: 1px;
        width: 105%;
        margin: 2px 0;
        border-top: 1px solid ${(props) => props.theme.colors.secondary};
    }
`;
