import {useTranslation} from "react-i18next";
import {useTheme} from "@emotion/react";
import {color} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link";
import {Text} from "@pg-design/text";
import i18n from "i18next";

import {chartsRoutes} from "../common/app/routing/charts_routes";

interface IRodoTermsProps {
    className?: string;
}
export const RodoTerms = ({className}: IRodoTermsProps) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Text variant="info_txt_3" align="center" color={theme.colors.gray[800]} className={className}>
            {t("contact.rodo_1")}
            <Link
                href={chartsRoutes[i18n.language].privacyPolicy}
                target="_blank"
                underline
                size="inherit"
                css={color(theme.colors.gray[800])}
            >
                {t("contact.rodo_2")}
            </Link>
        </Text>
    );
};
