import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {setAuthModal} from "../../web/src/features/auth/auth_slice";
import {useAppDispatch} from "./store_hooks";

export const useRegisterModalPopup = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {hash} = useLocation();

    useEffect(() => {
        if (hash === "#register") {
            dispatch(setAuthModal("registerUser"));
            history.replace({hash: ""});
        }
    }, []);
};
