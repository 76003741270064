import {IUserModel, UserAccessStatus} from "../../../../../utils/shared_types/user_model";

export function isUserAuthorized(userData: IUserModel | null) {
    return Boolean(
        userData &&
            userData.bigdata_marketing_consent &&
            userData.email_verified &&
            userData.access_status === UserAccessStatus.ACCEPTED
    );
}
