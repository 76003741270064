import {Reducer, useEffect, useReducer} from "react";

import {RequestStatus} from "../../web/src/common/app/constants/enums";
import {bugsnagNotify} from "../bugsnag";

interface IState<TData> {
    status: RequestStatus;
    data: null | TData;
    error: string | null;
}

const initialState = {
    status: RequestStatus.PENDING,
    data: null,
    error: null
};

type ActionType<TData> = {type: "PENDING"} | {type: "SUCCESS"; data: TData} | {type: "ERROR"; error: string};

const reducer = <TData>(state: IState<TData>, action: ActionType<TData>): IState<TData> => {
    switch (action.type) {
        case "PENDING":
            return {
                ...state,
                status: RequestStatus.PENDING
            };
        case "SUCCESS":
            return {
                status: RequestStatus.SUCCESS,
                data: action.data,
                error: null
            };
        case "ERROR":
            return {
                ...state,
                status: RequestStatus.ERROR,
                error: action.error
            };
        default:
            throw new Error();
    }
};

export const useRpRequest = <TData, TRes = TData>(
    url: string | null,
    selector: (res: TRes) => TData = (a) => a as unknown as TData
) => {
    const [state, dispatch] = useReducer<Reducer<IState<TData>, ActionType<TData>>>(reducer, initialState);

    useEffect(() => {
        if (url == null) {
            return;
        }

        let isMounted = true;

        if (isMounted && state.status !== RequestStatus.PENDING) {
            dispatch({type: "PENDING"});
        }

        (async () => {
            try {
                const response = await fetch(url);
                const data = await response.json();
                if (isMounted) {
                    dispatch({type: "SUCCESS", data: selector(data)});
                }
            } catch (error) {
                if (isMounted) {
                    bugsnagNotify(error as Error, {source: "useRpRequest"});
                    dispatch({type: "ERROR", error: error as string});
                }
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [url]);

    return state;
};
