import {lazy, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Options, SeriesColumnOptions} from "highcharts";

import {useTypeFilteredData} from "../../../../utils/hooks/useTypeFilteredData";
import {formatTooltip} from "../../../../utils/tooltips";
import {FilterOfferType} from "../../common/app/constants/enums";
import {IChartProps} from "./Chart";
import {SuspendedChart} from "./SuspendedChart";
import {withRatioData} from "./withRatioData";

const Chart = lazy(() => import("./ChartDefaultExport"));

export interface IColumnChartProps<D> extends IChartProps {
    data: D[];
    fields: string[];
    fieldsLabels?: string[];
    offerType?: FilterOfferType;
    unit?: string;
    valueMultiplier?: number;
}

export const ColumnChart = <D extends {}>(props: IColumnChartProps<D>) => {
    const {data, fields, fieldsLabels, offerType, unit, valueMultiplier = 1} = props;

    const {t, i18n} = useTranslation();

    const filteredData = useTypeFilteredData(data, offerType);

    const series: SeriesColumnOptions[] = useMemo(() => {
        if (filteredData && filteredData.length > 0) {
            return [
                {
                    type: "column",
                    borderWidth: 0,
                    borderRadiusTopLeft: 4,
                    borderRadiusTopRight: 4,
                    pointWidth: 14,
                    data: fields.map((field, i) => {
                        return {
                            name: fieldsLabels ? fieldsLabels[i] : (t(`fields.${field}`) as string),
                            y: filteredData.reduce((acc: number, curr: any) => {
                                return acc + curr[field] * valueMultiplier;
                            }, 0)
                        };
                    })
                }
            ];
        }

        return [];
    }, [filteredData, i18n.language]);

    const categories = fields.map((field) => t(`fields.${field}`));

    const options: Options = {
        tooltip: {
            formatter: function () {
                return formatTooltip(this, unit);
            }
        },
        xAxis: {
            categories,
            visible: true
        },
        yAxis: {
            labels: {
                enabled: true
            },
            visible: true
        }
    };

    return (
        <SuspendedChart>
            <Chart {...props} customOptions={options} series={series} />
        </SuspendedChart>
    );
};

export const RatioColumnChart = withRatioData(ColumnChart);
