import {useTranslation} from "react-i18next";

import {Error500Icon} from "../components/Error500Icon";
import {ErrorLayout} from "../components/ErrorLayout";

export const Error500 = () => {
    const {t} = useTranslation();
    const icon = <Error500Icon />;

    return (
        <ErrorLayout icon={icon} errorHeadline={t("common.error.500.name")}>
            {t("common.error.500.description")}
        </ErrorLayout>
    );
};
