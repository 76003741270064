import React from "react";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "@pg-design/grid";
import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {withAuthGuard} from "../../../components/hoc/withAuthGuard";
import {SimpleContainer} from "../../../components/layout/SimpleContainer";
import {Panel} from "../components/Panel";
import {UserEditForm} from "../components/user_edit/UserEditForm";

export const UserEdit = withAuthGuard(() => {
    const {t} = useTranslation();

    return (
        <SimpleContainer hideFooter>
            <Container>
                <Row>
                    <Col lg={8} lgOffset={4} md={8} mdOffset={2} sm={4}>
                        <Panel>
                            <Text css={mb(6)} align="center" as="p" variant="headline_3">
                                {t("user.account_settings")}
                            </Text>
                            <UserEditForm />
                        </Panel>
                    </Col>
                </Row>
            </Container>
        </SimpleContainer>
    );
});
